import * as styles from "../../styles/scss/components/Header/Header.module.scss";
import * as rubyStyles from "../../styles/scss/components/Buttons/ButtonRuby.module.scss";
import HexSvg from "../../public/images/common/hex.svg";
import {api} from "../../axios/api";
import {useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import useTranslation from "next-translate/useTranslation";
import {deviceType} from "../../helpers/functions";
import { useRouter } from 'next/router';

export const ButtonRuby = ({responsive}) => {

    const {t} = useTranslation()
    const [opacityAmount, setOpacityAmount] = useState(1)
    const [buttonTitle, setButtonTitle] = useState("Play with Rubi!")
    const [active, setActive] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const {locale} = useRouter()

    useEffect(() => {
        setIsMobile(deviceType() === 'mobile')
    }, [])

    function handleRubyClick() {
        if (! active) {
            setActive(true);
            api.get('/play-vs-ruby-fast').then((response) => {
                window.location.href = `/${locale}/lobby/${response.lobbyUuid}/game/${response.gameUuid}`
            });
            setOpacityAmount(0.3);
            setButtonTitle("Preparing game!")
        }
    }

    if (!! responsive) {
        return (
            <>
                {
                    active
                    && isMobile
                    && <Modal title='Please wait...'>
                        <div className={`${rubyStyles.rubyMessage}`}>
                            {t('Preparing for the game.')}<br />
                            {t('It will take some time.')}<br />
                            {t('You will be redirected to the game as soon as possible.')}
                        </div>
                    </Modal>
                }
                <li
                    className = {styles.navigation_li}
                    onClick = {() => {handleRubyClick()}}
                    style = {{opacity: opacityAmount}}
                    title={buttonTitle}
                >
                    <div className = {styles.navigation_main_cont}>
                        <div className = {styles.navigation_image_container}>
                            <img src = '/images/common/Robot.svg'  alt=''/>
                        </div>
                        <h2 className = {styles.navigation_text}>
                            {t('Play with Rubi!')}
                        </h2>
                    </div>
                </li>
            </>
        );
    } else {
        return (
            <>
                {
                    active
                    && ! isMobile
                    && <Modal title='Please wait...'>
                        <div className={`${rubyStyles.rubyMessage}`}>
                            {t('Preparing for the game.')}<br />
                            {t('It will take some time.')}<br />
                            {t('You will be redirected to the game as soon as possible.')}
                        </div>
                    </Modal>
                }
                <div
                    className = {`${styles.header_menu_button}`}
                    style = {{opacity: opacityAmount}}
                    onClick = {() => {handleRubyClick()}}
                    title={buttonTitle}
                >
                    <div className = {styles.hex}>
                        <HexSvg />
                    </div>
                    <img alt={t('Play with Rubi!')} src='/images/common/Robot.svg'/>
                </div>
            </>
        );
    }

}