import * as styles from '../../styles/scss/components/SocialButtons/SocialButtons.module.scss';
import { BASE_URL } from '../../config/api.config';

const SocialButtons = ({text}) => {
  const saveSocialNetworkName = (socialNetwork) => {
    localStorage.setItem('social', socialNetwork)
  }

  return (
    <div className = {styles.social_buttons}>
      <span>{text}</span>
      <ul className = {styles.social_buttons_ul}>
        <li className = {styles.social_buttons_li}>
          <SocialButton 
            socialNetworkName = 'facebook'
            clickable = {true}
            saveSocialNetworkName = {saveSocialNetworkName}
          />
        </li>
        <li className = {styles.social_buttons_li}>
          <SocialButton 
            socialNetworkName = 'google'
            clickable = {true}
            saveSocialNetworkName = {saveSocialNetworkName}
          />
        </li>
        <li className = {styles.social_buttons_li}>
          <SocialButton 
            socialNetworkName = 'instagram'
            clickable = {false}
            saveSocialNetworkName = {saveSocialNetworkName}
          />
        </li>
        <li className = {styles.social_buttons_li}>
          <SocialButton 
            socialNetworkName = 'twitter'
            clickable = {false}
            saveSocialNetworkName = {saveSocialNetworkName}
          />
        </li>
        <li className = {styles.social_buttons_li}>
          <SocialButton 
            socialNetworkName = 'linkedin'
            clickable = {false}
            saveSocialNetworkName = {saveSocialNetworkName}
          />
        </li>
      </ul>
    </div>
  )
}

export const SocialButton = ({socialNetworkName, clickable, saveSocialNetworkName}) => {
  return (
    <>
      <div className = {styles.social_icon}>
        <img src = {`/images/social/${socialNetworkName}.png`} />
        {clickable && <a href = {`${BASE_URL}/login/${socialNetworkName}`} onClick = {() => {saveSocialNetworkName(socialNetworkName)}}></a>}
      </div>
    </>
  )
}

export default SocialButtons