import * as styles from '../../styles/scss/components/Loader/Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader_container}>
      <img src='/images/loader-global.gif' />
    </div>
  )
}

export default Loader