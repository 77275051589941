export const pagesWithLocales = {
    en: {
        about: 'about-braendi-foundation',
        howToPlay: 'how-to-play',
        privacy: 'privacy'
    },
    de: {
        about: 'ueber-braendi-stiftung',
        howToPlay: 'so-wird-gespielt',
        privacy: 'privacy'

    },
    ru: {
        about: 'o-fonde-brandi',
        howToPlay: 'kak-igrat',
        privacy: 'privacy'
    },

    fr: {
        about: 'a-propos-fondation-braendi',
        howToPlay: 'comment-jouer',
        privacy: 'privacy'

    },
    es: {
        about: 'sobre-fundacion-braendi',
        howToPlay: 'como-jugar',
        privacy: 'privacy'

    },
    it: {
        about: 'sulla-fondazione-braendi',
        howToPlay: 'come-giocare',
        privacy: 'privacy'
    },

    ka: {
        about: 'brendis-shesakheb',
        howToPlay: 'rogor-vitamasho',
        privacy: 'privacy'

    },
    df: {
        about: 'ueber-braendi-stiftung',
        howToPlay: 'so-wird-gespielt',
        privacy: 'privacy'
    },
}

export const getUrl = (locale, url) => {
    const pagesMap = new Map()
    for (const pages in pagesWithLocales) {
        for (const page in pagesWithLocales[pages]) {
            pagesMap.set(pagesWithLocales[pages][page], page)
        }
    }
    if (url[0] === '/') {
        url = url.slice(1, url.length)
    }

    const pageName = pagesMap.get(url);

    return pagesWithLocales[locale][pageName]; 
}

