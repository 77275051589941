import * as actionTypes from './modalsActionTypes';

const initialState = {
  isSidebarOpen: false,
  sidebarType: null,
  userToShow: null,
  hasBackArrow: null,
  showSidebarCloseButton: true,
  callBackSidebarFunction: null,
  hasTitle: null,
  globalModalState: false,
  sidebarData: null,
  isResponsivePopUpOpen: false,
  responsivePopUpType: '',
  isConfirmModalOpen: false,
  confirmModalData: null,
  referrer: '',
  offlineModalState: false,
  buzzOfflineModal: false,
  forRules: false,
  isSearchModalMinimized: false,
}

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.TOGGLE_SIDEBAR:
        return {
          ...state,
          isSidebarOpen: action.payload.isOpen,
          sidebarType: action.payload.sidebarType,
          hasBackArrow: action.payload.hasBackArrow,
          callBackSidebarFunction: action.payload.callBackSidebarFunction,
          hasTitle: action.payload.hasTitle,
          sidebarData: action.payload.sidebarData,
          referrer: action.payload.referrer,
          customTitle: action.payload.customTitle,
          showSidebarCloseButton: action.payload.showSidebarCloseButton,
          forRules: action.payload.forRules
        };
      case actionTypes.TOGGLE_RESPONSIVE_PUP_UP:
        return {
          ...state,
          isResponsivePopUpOpen: action.payload.isOpen,
          responsivePopUpType: action.payload.type
        };
      case actionTypes.SET_USER_FOR_PROFILE_SIDEBAR: {
        return {
          ...state,
          userToShow: action.payload.user
        }
      }
      case actionTypes.SET_GLOBAL_MODAL_STATE: {
        return {
          ...state,
          globalModalState: action.payload.globalModalState
        }
      }
      case actionTypes.TOGGLE_CONFIRM_MODAL: {
        return {
          ...state,
          isConfirmModalOpen: action.payload.isOpen,
          confirmModalData: action.payload.confirmModalData
        }
      }
      case actionTypes.SET_OFFILNE_MODAL_STATE: {
        if (state.offlineModalState === action.payload.state) {
          return {
            ...state
          }
        }
        return {
          ...state,
          offlineModalState: action.payload.state
        }
      }
      case actionTypes.BUZZ_OFFLINE_MODAL: {
        if (state.buzzOfflineModal === action.payload.state) {
          return {
            ...state
          }
        }
        return {
          ...state,
          buzzOfflineModal: action.payload.state
        }
      }
      case actionTypes.TOGGLE_MODAL_RESIZE: {
        const value = typeof action.payload === 'boolean' ? action.payload : !state.isConfirmModalOpen;
        return {
          ...state,
          isSearchModalMinimized: value,
        }
      }
      default:
        return state;
    }
}

export default modalsReducer;