import { useState } from "react";
import { connect } from "react-redux";
import * as styles from '../../../styles/scss/components/SidebarActions/SidebarActions.module.scss';

const SidebarActions = (props) =>{
  const {sidebarData} = props
  const [madeAction, setMadeAction] = useState(false)
  const handleAction = () => {
    if (madeAction) {
      return
    }
    setMadeAction(true)
    sidebarData.acceptActionMethod()
  }
  return (
    <div className = {styles.sidebar_actions}>
      <h3 className = {styles.sidebar_actions_title}>
        {sidebarData.actionTitle}
      </h3>
      <div className = {styles.sidebar_actions_buttons}>
        <div className = {styles.button_container}>
          <button className = {styles.button} onClick = {() => handleAction()}>
            {sidebarData.acceptActionText}
          </button>
        </div>
        <div className = {styles.button_container}>
          <button className = {styles.button} onClick = {sidebarData.rejectActionMethod}>
            {sidebarData.rejectActionText}
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sidebarData: state.modals.sidebarData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarActions)