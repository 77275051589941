
import { useEffect, useRef, useState } from 'react';
import { CloseButton } from '../Buttons/Buttons'
import ResponsiveMenu from '../Header/ResponsiveMenu';
import * as styles from '../../styles/scss/components/ResponsivePopUp/ResponsivePopUp.module.scss'
import useTranslation from 'next-translate/useTranslation';
const ResponsivePopUp = (props) => {
  const { closePopUp, type } = props
  const {t} = useTranslation()
  const [touchstartX, setTouchstartX] = useState(0);
  // const [touchstartY, setTouchstartY] = useState(0);
  let touchstartY = 0;
  const [touchendX, setTouchendX] = useState(0);
  // const [touchendY, setTouchendY] = useState(0);
  let touchendY = 0;

  const popupRef = useRef()
  const [extended, setIsExtended] = useState(false)
  useEffect(() => {
    if (popupRef.current) {
      const gestureZone = popupRef.current;

      gestureZone.addEventListener('touchstart', setTouchStarts);
    
      gestureZone.addEventListener('touchend', setTouchEnds);

      return (() => {
        gestureZone.removeEventListener('touchstart', setTouchStarts)
        gestureZone.removeEventListener('touchend', setTouchEnds)
      })
    }
  }, [popupRef])

  const setTouchStarts = (event) => {
    // setTouchstartX(event.changedTouches[0].screenX);
    // setTouchstartY(event.changedTouches[0].screenY);
    touchstartY = event.changedTouches[0].screenY;
  }
  const setTouchEnds = (event) => {
    // setTouchendX(event.changedTouches[0].screenX);
    // setTouchendY(event.changedTouches[0].screenY);
    touchendY = event.changedTouches[0].screenY
    handleGesture();
  }

  const handleGesture = () => {
    // if (touchendX <= touchstartX) {
    //   alert('Swiped left');
    // }
    
    // if (touchendX >= touchstartX) {
    //   alert('Swiped right');
    // }
    
    if (touchendY + 40 <= touchstartY) {
      //swipe up
      // alert('Swiped up');
      setIsExtended(true)
    }
    
    if (touchendY >= touchstartY + 40) {
      //swipe down
      setIsExtended(false)
      // alert('Swiped down');
    }
    
    // if (touchendY === touchstartY) {
    //   alert('Tap');
    // }
  }
  
  return (
    <div className = {`${styles.pop_up} ${extended && styles.extended}`} ref = {popupRef}>
      <div className = {styles.pop_up_header}>
        <h3 className = {styles.pop_up_title}>
          {type === 'menu' && t('common:sidebar_titles.menu')}
        </h3>
        <div className = {styles.pop_up_close}>
          <CloseButton click = {closePopUp} />
        </div>
      </div>
      <div className = {styles.pop_up_body}>
        {type === 'menu' && <ResponsiveMenu closePopUp = {closePopUp} />}
      </div>
    </div>
  )
}

export default ResponsivePopUp