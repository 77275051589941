import * as actionTypes from './leaderboardActionTypes';

const initialState = {
  // filters: [{
  //   id: 1,
  //   label: 'world',
  //   value: 'world',
  //   active: true,
  //   value: 'world'
  // },
  // {
  //   id: 2,
  //   label: 'country',
  //   value: 'country',
  //   active: false,
  //   value: 'country'
  // },
  // {
  //   id: 6,
  //   label: 'state',
  //   value: 'state',
  //   active: false,
  //   value: 'state'
  // },
  // {
  //   id: 3,
  //   label: 'city',
  //   value: 'city',
  //   active: false,
  //   value: 'city'
  // },
  // {
  //   id: 4,
  //   label: 'age',
  //   value: 'age',
  //   active: false,
  //   value: 'age'
  // },
  // {
  //   id: 5,
  //   label: 'friends',
  //   value: 'friends',
  //   active: false,
  //   value: 'friends'
  // }],
  // typeFilters: [{
  //   id: 1,
  //   label: 'Overall',
  //   value: 'overall',
  //   active: true
  // },
  // {
  //   id: 2,
  //   label: 'Solo',
  //   value: 'solo',
  //   active: false
  // },
  // {
  //   id: 3,
  //   label: 'Team',
  //   value: 'team',
  //   active: false
  // }],
  filters: [
    {
      id: 1,
      label: 'city',
      value: 'city',
      active: true,
      value: 'city'
    },
    {
      id: 2,
      label: 'state',
      value: 'state',
      active: false,
      value: 'state'
    },
    {
      id: 3,
      label: 'country',
      value: 'country',
      active: false,
      value: 'country'
    },
    {
    id: 4,
    label: 'world',
    value: 'world',
    active: false,
    value: 'world'
  },
  {
    id: 5,
    label: 'friends',
    value: 'friends',
    active: false,
    value: 'friends'
  },
  {
    id: 6,
    label: 'age',
    value: 'age',
    active: false,
    value: 'age'
  }],
  typeFilters: [{
    id: 1,
    label: 'Overall',
    value: 'overall',
    active: true
  },
  {
    id: 2,
    label: 'Solo',
    value: 'solo',
    active: false
  },
  {
    id: 3,
    label: 'Team',
    value: 'team',
    active: false
  }],
  leaderboardData: null,
  loading: false,
  currentPage: 1,
  lastPage: 0,
  selectedFilters: {
    type: 'overall',
    countries: null,
    city: null,
    state: null,
    friends: null,
    age: null,
    world: true
  },
  whatToRender: {
    showTable: true,
    infoMissing: ''
  }
}

const getNewTypeFilters = (typeFilters, selectedTypeFilter) => {
  const newTypeFilters = [...typeFilters.map(filter => {
    return {
      ...filter,
      active: filter.id === selectedTypeFilter.id
    }
  })]
  return newTypeFilters
}

const getNewFilters = (filters, selectedFilter) => {
  const newFilters = [...filters.map(filter => {
    return {
      ...filter,
      active: filter.id === selectedFilter.id
    }
  })]
  return newFilters
}

const getNewSelectedFilters = (selectedFilters, selectedTypeFilter, filterType, currentUser) => {
  if (filterType === 'type') {
    return {
      ...selectedFilters,
      type: selectedTypeFilter.value
    }
  } else if (filterType === 'filters') {
    if (selectedTypeFilter.value.toLowerCase() === 'country') {
      if (currentUser.country) {
        return {
          newSelectedFilters: {
            ...selectedFilters,
            city: null,
            age: null,
            friends: null,
            countries: selectedTypeFilter.value.toLowerCase() === 'country' ? [currentUser.country.id] : null,
            state: null,
            world: null
          },
          whatToRender: {
            infoMissing: '',
            showTable: true
          }
        }
      } else {
        return {
          newSelectedFilters: {
            ...selectedFilters,
          },
          whatToRender: {
            infoMissing: 'country',
            showTable: false
          }
        }
      }
    }

    if (selectedTypeFilter.value.toLowerCase() === 'state') {
      if (currentUser.state) {
        return {
          newSelectedFilters: {
            ...selectedFilters,
            age: null,
            friends: null,
            countries: null,
            city: null,
            state: selectedTypeFilter.value.toLowerCase() === 'state' ? currentUser.state.id: null,
            world: null
          },
          whatToRender: {
            infoMissing: '',
            showTable: true
          }
        }
      } else {
        return {
          newSelectedFilters: {
            ...selectedFilters,
          },
          whatToRender: {
            infoMissing: 'state',
            showTable: false
          }
        }
      }
    }
    if (selectedTypeFilter.value.toLowerCase() === 'city') {
      if (currentUser.city) {
        return {
          newSelectedFilters: {
            ...selectedFilters,
            age: null,
            friends: null,
            countries: null,
            city: selectedTypeFilter.value.toLowerCase() === 'city' ? currentUser.city.id : null,
            state: null,
            world: null
          },
          whatToRender: {
            infoMissing: '',
            showTable: true
          }
        }
      } else {
        return {
          newSelectedFilters: {
            ...selectedFilters,
          },
          whatToRender: {
            infoMissing: 'city',
            showTable: false
          }
        }
      }
    }

    if (selectedTypeFilter.value.toLowerCase() === 'age') {
      if (currentUser.dateOfBirth) {
        return {
          newSelectedFilters: {
            ...selectedFilters,
            city: null,
            friends: null,
            countries: null,
            age: true,
            state: null,
            world: null
          },
          whatToRender: {
            infoMissing: '',
            showTable: true
          }
        }
      } else {
        return {
          newSelectedFilters: {
            ...selectedFilters,
          },
          whatToRender: {
            infoMissing: 'age',
            showTable: false
          }
        }
      }
      return
    }

    if (selectedTypeFilter.label === 'friends') {
      if (currentUser.hasFriends) {
        return {
          newSelectedFilters: {
            ...selectedFilters,
            city: null,
            age: null,
            countries: null,
            friends: true,
            state: null,
            world: null
          },
          whatToRender: {
            infoMissing: '',
            showTable: true
          }
        }
      } else {
        return {
          newSelectedFilters: {
            ...selectedFilters,
          },
          whatToRender: {
            infoMissing: 'friends',
            showTable: false
          }
        }
      }
    }
    return {
      newSelectedFilters: {
        type: selectedFilters.type,
        city: null,
        state: null,
        friends: null,
        age: null,
        countries: null,
        world: true,
      },
      whatToRender: {
        infoMissing: '',
        showTable: true
      }
    }
  }
}

const checkFiltersEquality = (incomingFilters, currentFilters) => {
  let selectedFilters = [];
  for (let key in currentFilters) {
    if (currentFilters[key] && key !== 'type') {
      selectedFilters.push(key)
    }
  }
  if (incomingFilters.type === currentFilters.type && selectedFilters.indexOf(incomingFilters.tab) >= 0) {
    return true
  }
  return false
}

const leaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEADERBOARD_DATA_REQUEST : {
      return {
        ...state,
        loading: true
      }
    };
    case actionTypes.GET_LEADERBOARD_DATA_SUCCESS : {
      const checkfilters = checkFiltersEquality(action.payload.filter, state.selectedFilters)
      if (!checkfilters) {
        return {
          ...state,
          loading: false
        }
      }

      const showUserPage = action.payload.user && (action.payload.initialLoad || !action.payload.pageChange)
      const leaderboardData = showUserPage ? action.payload.user.data : action.payload.pagination.data
      const currentPage = showUserPage ? action.payload.user.page : action.payload.pagination.current_page
      return {
        ...state,
        loading: false,
        leaderboardData: [...leaderboardData],
        currentPage: currentPage,
        lastPage: action.payload.pagination.last_page,
      }
    };
    case actionTypes.GET_LEADERBOARD_DATA_FAIL : {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    };
    // HERE
    case actionTypes.SET_TYPE_FILTER: {
      return {
        ...state,
        typeFilters: getNewTypeFilters(state.typeFilters, action.payload.selectedTypeFilter),
        selectedFilters: getNewSelectedFilters(state.selectedFilters, action.payload.selectedTypeFilter, 'type')
      }
    };

    case actionTypes.SET_FILTER: {
      return {
        ...state
      }
      const {newSelectedFilters, whatToRender} = getNewSelectedFilters(state.selectedFilters, action.payload.selectedFilter, 'filters', action.payload.user)
      // debugger;
      return {
        ...state,
        filters: getNewFilters(state.filters, action.payload.selectedFilter),
        selectedFilters: newSelectedFilters,
        whatToRender: whatToRender
      }
    };
    case actionTypes.SET_SELECTED_FILTER: {
      const {filter, data} = action.payload
      const selectedFilters = {
        [filter]: data,
        world: null,
        type: state.selectedFilters.type,
        city: null,
        state: null,
        friends: state.selectedFilters.friends,
        age: state.selectedFilters.age,
      }
      return {
        ...state,
        selectedFilters
      }
    };
    default:
      return state;
    }
}

export default leaderboardReducer;