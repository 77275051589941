import * as actionTypes from "./gameDataActionTypes";
import api from "../../axios/api";
import router from "next/router";

const gameCommontCardEventRequest = (type) => {
  return {
    type: actionTypes.GAME_COMMON_CARD_EVENT_REQUEST,
    payload: {
      type,
    },
  };
};

const gameCommontCardEventSuccess = (data) => {
  return {
    type: actionTypes.GAME_COMMON_CARD_EVENT_SUCCESS,
    payload: {
      data,
    },
  };
};

const gameCommontCardEventFail = (error) => {
  return {
    type: actionTypes.GAME_COMMON_CARD_EVENT_FAIL,
    payload: {
      error,
    },
  };
};

export const gameCommontCardEvent = (requestData) => {
  return async (dispatch) => {
    dispatch(gameCommontCardEventRequest(requestData?.payload?.type));
    api.defaults.headers.common["X-Socket-ID"] = requestData.socketId;
    return api
      .post(`/lobby/${requestData.lobbyId}/game/${requestData.gameId}`, {
        ...requestData.payload,
      })
      .then((response) => {
        if (response.message === "PROVIDE_MARBLES") {
          dispatch(
            gameCommontCardEventSuccess({
              actionGame: [...response.data],
              actionCard: {
                ...requestData.actionCard,
              },
              responseType: response.message,
            })
          );
          return response;
        } else {
          dispatch(
            gameCommontCardEventSuccess({
              ...response.data,
              actionCard: {
                ...requestData.actionCard,
              },
              requestType: requestData.payload.type,
              stealingInProgress: requestData.stealingInProgress,
            })
          );
          return response;
        }
      });
  };
};

const gameCommonMarbleEventRequest = () => {
  return {
    type: actionTypes.GAME_COMMON_MARBLE_EVENT_REQUEST,
  };
};

const gameCommonMarbleEventSuccess = () => {
  return {
    type: actionTypes.GAME_COMMON_MARBLE_EVENT_SUCCESS,
  };
};

const gameSevenMarbleEventSuccess = (data) => {
  return {
    type: actionTypes.GAME_SEVEN_MARBLE_EVENT_SUCCESS,
    payload: data,
  };
};

const gameCommonMarbleEventFail = (error) => {
  return {
    type: actionTypes.GAME_COMMON_MARBLE_EVENT_FAIL,
    payload: {
      error,
    },
  };
};

export const gameCommonMarbleEvent = (requestData) => {
  return async (dispatch) => {
    dispatch(gameCommonMarbleEventRequest());
    api.defaults.headers.common["X-Socket-ID"] = requestData.socketId;
    api
      .post(`/lobby/${requestData.lobbyId}/game/${requestData.gameId}`, {
        ...requestData.payload,
      })
      .then((response) => {
        dispatch(gameCommonMarbleEventSuccess());
      });
  };
};

export const gameSevenMarbleEvent = (requestData) => {
  return async (dispatch) => {
    api.defaults.headers.common["X-Socket-ID"] = requestData.socketId;
    return new Promise((resolve, reject) => {
      api
        .post(`/lobby/${requestData.lobbyId}/game/${requestData.gameId}`, {
          ...requestData.payload,
        })
        .then((response) => {
          dispatch(gameSevenMarbleEventSuccess(response.data));
          return resolve(response.data);
        });
    });
  };
};

const getGameDataRequest = (payload) => {
  return {
    type: actionTypes.GET_GAME_DATA_REQUEST,
    payload,
  };
};

const getGameDataSuccess = (gameData) => {
  return {
    type: actionTypes.GET_GAME_DATA_SUCCESS,
    payload: {
      gameData: {
        ...gameData,
      },
    },
  };
};

const getGameDataFail = (error) => {
  return {
    type: actionTypes.GET_GAME_DATA_FAIL,
    payload: {
      error,
    },
  };
};

export const getGameData = (requestData) => async (dispatch) => {
  // debugger
  api.defaults.headers.common["X-Socket-ID"] = requestData.socketId;
  dispatch(getGameDataRequest({ resetMarbles: requestData.resetMarbles }));
  try {
    await api
      .get(
        `/lobby/${requestData.payload.lobbyId}/game/${requestData.payload.gameId}`
      )
      .then((response) => {
        if (response.message === "GAME_DELETED") {
          router.push("/");
        } else {
          dispatch(getGameDataSuccess(response.data));
        }
        // return response
      });
  } catch (error) {
    dispatch(getGameDataFail(error));
    throw `Error - getGameData_${Math.random() * 10}`;
  }
};

export const hanldeCardExchange = (cardExchangeData) => {
  return {
    type: actionTypes.CARD_EXCHANGE,
    payload: {
      cardExchangeData,
    },
  };
};

export const setPLayersReadyForExchange = (playerId) => {
  return {
    type: actionTypes.SET_PLAYERS_READY_FOR_EXCHANGE,
    payload: {
      playerId,
    },
  };
};

export const handleCardSteal = (cardStealData) => {
  return {
    type: actionTypes.CARD_STEAL,
    payload: {
      cardStealData,
    },
  };
};

export const handleMarbleMove = (marble) => {
  return {
    type: actionTypes.MARBLE_MOVE,
    payload: {
      ...marble,
    },
  };
};

export const handleCardPlay = (cardPlayData) => {
  return {
    type: actionTypes.CARD_PLAY,
    payload: {
      cardPlayData,
    },
  };
};

export const changeTurn = (changeTurnObj) => {
  return {
    type: actionTypes.CHANGE_PLAYER_TURN,
    payload: {
      changeTurnObj,
    },
  };
};

export const setNewRoundData = (roundData) => {
  return {
    type: actionTypes.START_NEW_ROUND,
    payload: {
      roundData: roundData,
    },
  };
};

export const setIsPlayingJoker = (isPlayingJoker) => {
  return {
    type: actionTypes.SET_IS_PLAYING_JOKER,
    payload: {
      isPlayingJoker: isPlayingJoker,
    },
  };
};

export const setActionCard = (actionCard) => {
  return {
    type: actionTypes.SET_ACTION_CARD,
    payload: {
      actionCard: actionCard,
    },
  };
};

export const setCardAliases = (cardAliases) => {
  return {
    type: actionTypes.SET_CARD_ALIASES,
    payload: {
      cardAliases: cardAliases,
    },
  };
};

export const setIsPlayingTwo = (isPlayingTwo) => {
  return {
    type: actionTypes.SET_IS_PLAYING_TWO,
    payload: {
      isPlayingTwo: isPlayingTwo,
    },
  };
};

export const setRoundState = (roundState) => {
  return {
    type: actionTypes.SET_GAME_STATE,
    payload: {
      roundState: roundState,
    },
  };
};

export const setCardPlayAction = (cardPlayAction) => {
  return {
    type: actionTypes.SET_GAME_CARD_PLAY_ACTION,
    payload: {
      cardPlayAction: cardPlayAction,
    },
  };
};

export const setPlayersForStealing = (payload) => {
  return {
    type: actionTypes.SET_PLAYERS_FOR_STEALING,
    payload: {
      ...payload,
    },
  };
};

export const dropCards = (playerId) => {
  return {
    type: actionTypes.DROP_CARDS,
    payload: {
      playerId,
    },
  };
};

// export const setDropDetails = (dropCardsAt) => {
//   return {
//     type: actionTypes.SET_DROP_DETAILS,
//     payload: {
//       dropCardsAt
//     }
//   }
// }

export const setCanPlayStatusToCards = (data) => {
  return {
    type: actionTypes.SET_CAN_PLAY_STATUS_TO_CARDS,
    payload: {
      playerId: data.playerId,
      status: data.status,
    },
  };
};

export const marbleMove = (data) => {
  return {
    type: actionTypes.SET_MARBLE_MOVE,
    payload: {
      moveMarble: data,
    },
  };
};

export const replaySeven = (data) => {
  return {
    type: actionTypes.SET_REPLAY_SEVEN,
    payload: {
      marblesData: data,
    },
  };
};

export const restoreMarblePosition = () => {
  return {
    type: actionTypes.SET_MARBLE_POSITION,
  };
};

export const marbleKill = (data) => {
  return {
    type: actionTypes.SET_MARBLE_KILL,
    payload: {
      killMarble: data,
    },
  };
};

export const marbleSwap = (data) => {
  return {
    type: actionTypes.SET_MARBLE_SWAP,
    payload: {
      marbleSwap: data,
    },
  };
};

export const onGameFinish = (data) => {
  return {
    type: actionTypes.ON_GAME_FINISH,
    payload: {
      onGameFinish: data,
    },
  };
};

export const setRematchApproval = (data) => {
  return {
    type: actionTypes.SET_REMATCH_PLAYER,
    payload: {
      player: data,
    },
  };
};

export const stePLayerMessage = (data) => {
  return {
    type: actionTypes.SET_PLAYER_MESSAGE,
    payload: {
      ...data,
    },
  };
};

export const setRecentAction = (action) => {
  return {
    type: actionTypes.SET_RECENT_ACTIONS,
    payload: {
      action,
    },
  };
};

export const setDroppedCards = (payLoad) => {
  return {
    type: actionTypes.SET_DROPPED_ACTIONS,
    payload: {
      ...payLoad,
    },
  };
};

export const setBoardToggle = (toggle) => {
  return {
    type: actionTypes.SET_BOARD_TOGGLE,
    payload: {
      toggle,
    },
  };
};

export const setChosenVictim = (playerId) => {
  return {
    type: actionTypes.SET_CHOSEN_VICTIM,
    payload: {
      playerId,
    },
  };
};

export const updateScoreboard = (data) => {
  return {
    type: actionTypes.UPDATE_SCORE_BOARD,
    payload: {
      ...data,
    },
  };
};

export const resetGameData = () => {
  return {
    type: actionTypes.RESET_GAME_DATA,
  };
};

export const updatePlayerFriendshipStatus = (payload) => {
  return {
    payload: {
      ...payload,
    },
    type: actionTypes.UPDATE_PLAYER_FRIENDSHIP_STATUS,
  };
};

export const updatePlayTime = (payload) => {
  return {
    type: actionTypes.UPDATE_PLAY_TIME,
    payload,
  };
};

export const updatePlayerStatus = (payload) => {
  return {
    type: actionTypes.UPDATE_PLAYER_STATUS,
    payload,
  };
};

export const setForceFinishedData = (payload) => {
  return {
    type: actionTypes.SET_FORCE_FINISHED_DATA,
    payload,
  };
};

export const togglePreventCardClickWhilePLayingJoker = (state) => {
  return {
    type: actionTypes.TOGGLE_PREVENT_CARD_CLICK_WHILE_PLAYING_JOKER,
    payload: {
      state,
    },
  };
};
