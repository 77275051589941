import * as actionTypes from './privateLobbyActionTypes';

const initialState = {
  avilableRules: null,
  gameModes: null,
  gameStatuses: null,
  gameTypes: null,
  versues: null,
  selectedGameType: null,
  selectedRules: [],
  selectedGameMode:  null,
  selectedPlayerNumber: {},
  selectedVersus: null,
  scheduledAt: null,
  seats: [],
  teams: null,
  name: '',
  chat: {},
  members: [],
  host: null,
  loaded: false,
  freeSpaces: null,
  playerLimit: null,
  playersNumbers: [
    {value: 2, label: 2, enabled: true},
    {value: 3, label: 3, enabled: true},
    {value: 4, label: 4, enabled: true},
    {value: 5, label: 5, enabled: true},
    {value: 6, label: 6, enabled: true}
  ],
  deleteRequesLoading: false,
  deleted: false
}

const getTeams = (versus, seats) => {
  if (versus === '1v1') {
    return null
  } else {
    const seatsWithTeams = [...seats.filter(seat => !!seat.team).map(elem => {
      return {
        team: {...elem.team},
        seatGroup: elem.group
      }
    })]
    const teams = [...new Map(seatsWithTeams.map(item => [item.seatGroup, item])).values()]
    return teams
  }
}


const kickMemberHandler = (newMembers, oldMembers, seats) => {
  // debugger;
  const removedMember = oldMembers.filter(prevMember => !newMembers.find(newMember => newMember.user.id === prevMember.user.id))[0]
  return {
    members: [...oldMembers.filter(prevMember => prevMember.user.id !== removedMember.user.id)],
    seats: [...seats.map(seat => seat?.user?.id === removedMember.user.id ? {...seat, user: null} : {...seat})],
  }
}

const addMemberHandler = (newMembers, oldMembers) => {
  const addedMembers = newMembers.filter(newMember => !oldMembers.find(prevMember => prevMember.user.id === newMember.user.id));
  return {
    members: [...oldMembers, ...addedMembers.map(member => {
      return {
        joined: false,
        user: {
          ...member.user
        }
      }
    })],
    addedMembersCount: addedMembers.length
  }
}

const handleSeatChange = (oldSeats, seatData) =>{
  return [...oldSeats.map(seat => {
      if (!!seat.user && (seat.id === seatData.id || seat.user.id === seatData?.user?.id)) {
        return {
          ...seat,
          user: null
        }
      }
      if (seat.id === seatData.id) {
        return {
          ...seat,
          user: {...seatData.user}
        }
      }
      return {
        ...seat,
      }
    })]
}

const isOnlySolo = (playerNumber) => {
  if (playerNumber < 4 || playerNumber % 2 === 1) {
    return true
  }
  return false
}

const isVersusUpdating = (oldPlayerNumber, newPlayerNumber) => {
  if (oldPlayerNumber === newPlayerNumber) {
    return false
  } else if(isOnlySolo(oldPlayerNumber) === isOnlySolo(newPlayerNumber)) {
    return false
  }
  return true
}

// const handlePlayerStandUp = (oldSeats, seatData) => {
//   return [...oldSeats.map(seat => {
//     if (seat.id === seatData.id) {
//       return {
//         ...seat,
//         user: null
//       }
//     } else {
//       return {
//         ...seat
//       }
//     }
//   })]
// }

const handleTeamUpdate = (data, oldTeams) => {
  let newTeams = [...oldTeams]
  if (data.team === null) {
    newTeams = newTeams.map(team => {
      if (team.seatGroup === data.seatGroup) {
        return {
          ...team,
          team: null
        }
      } else {
        return {
          ...team
        }
      }
    })
  } else {
    // const newTeamIndex = newTeams.findIndex(elem => elem.team?.id === data.team.id)
    const newTeamIndex = newTeams.findIndex(elem => elem.seatGroup === data.seatGroup)
    if (newTeamIndex < 0) {
      newTeams.push(data)
    } else {
      newTeams[newTeamIndex] = {...data}
    }  
  }
  return newTeams
}

const privateLobbyReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.GET_PRIVATE_LOBBY_DATA_REQUEST1:
        return {
          ...state,
          // isLoading: true,
        };
      case actionTypes.GET_PRIVATE_LOBBY_DATA_SUCCESS1:
        return {
          ...state,
          // isLoading: false,
          avilableRules: [...action.payload.mmSettings.avilableRules],
          gameModes: [...action.payload.mmSettings.gameModes],
          gameStatuses: [...action.payload.mmSettings.gameStatuses],
          gameTypes: [...action.payload.mmSettings.gameTypes],
          versues: [...action.payload.mmSettings.versues],
          selectedRules: [...action.payload.rules],
          selectedGameMode: action.payload.mode ? {...action.payload.mmSettings.gameModes.find(gameMode => gameMode.value === action.payload.mode)} : null,
          selectedPlayerNumber: {value: action.payload.memberLimit, label: action.payload.memberLimit},
          selectedVersus: action.payload.vs ? {...action.payload.mmSettings.versues.find(versus => versus.value === action.payload.vs)} : null,
          scheduledAt: action.payload.scheduledAt,
          seats: action.payload.seats ? [...action.payload.seats] : [],
          teams: getTeams(action.payload.vs, action.payload.seats),
          name: action.payload.name,
          chat: {...action.payload.chat},
          members: [...action.payload.members],
          host: {...action.payload.host},
          loaded: true,
          freeSpaces: action.payload.memberLimit - action.payload.members.length,
        };
      case actionTypes.GET_PRIVATE_LOBBY_DATA_FAIL1:
        return {
          ...state,
          // isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PLAYER_SIT_DOWN_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.PLAYER_SIT_DOWN_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case actionTypes.PLAYER_SIT_DOWN_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PLAYER_JOIN_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.PLAYER_JOIN_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case actionTypes.PLAYER_JOIN_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PLAYER_ROLL_DICE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.PLAYER_ROLL_DICE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          rollData: {...action.payload }
        };
      case actionTypes.PLAYER_ROLL_DICE_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PRIVATE_SET_JOINED_PLAYER: {
        // debugger
        const lobbyData = {...state.lobbyData}
        lobbyData.members = [...lobbyData.members.map(member => {
          return {
            ...member,
            gameAccepted: action.payload.userId === member.id ? true : member.gameAccepted,
            currentUser: action.payload.currentUserId === member.id
          }
        })]

        return {
          ...state,
          lobbyData: {...lobbyData}
        }
      };
      case actionTypes.SET_REDIRECT_TO_PRIVATE: {
        return {
          ...state,
          redirectToLobby: action.payload.state
        }
      };
      case actionTypes.UPDATE_GAME_MODE: {
        return {
          ...state,
          selectedGameMode: {...state.gameModes.find(gameMode => gameMode.value === action.payload.mode)}
        }
      };
      case actionTypes.UPDATE_VERSUS: {
        return {
          ...state,
          selectedVersus: !!action.payload.vs ? {...state.versues.find(versus => versus.value === action.payload.vs)} : null
        }
      };
      case actionTypes.UPDATE_SEATS: {
        return {
          ...state,
          seats: [...action.payload.seats]
        }
      };
      case actionTypes.UPDATE_RULES: {
        return {
          ...state,
          selectedRules: [...action.payload.rules]
        }
      };
      case actionTypes.KICK_MEMBERS: {
        const {members, seats} = kickMemberHandler([...action.payload.members], [...state.members], [...state.seats])
        return {
          ...state,
          members: [...members],
          seats: [...seats],
          freeSpaces: state.freeSpaces + 1
        }
      };
      case actionTypes.ADD_MEMBERS: {
        const {members, addedMembersCount} = addMemberHandler([...action.payload.members], [...state.members], [...state.seats])
        return {
          ...state,
          members: [...members],
          freeSpaces: state.freeSpaces - addedMembersCount
        }
      };
      case actionTypes.UPDATE_JOINED_STATUS: {
        return {
          ...state,
          members:[...state.members.map(member => {
            return{
              ...member,
              joined: member.user.id === action.payload.userId ? true: member.joined
            }
          })]
        }
      };
      case actionTypes.HANDLE_CHANGE_PLAYER_SEAT: {
        // debugger
        const seats = handleSeatChange([...state.seats], {...action.payload.seat})
        return {
          ...state,
          seats: [...seats]
        }
      };
      // case actionTypes.HANDLE_PLAYER_STAND_UP: {
      //   const seats = handlePlayerStandUp([...state.seats], {...action.payload.seat});
      //   return {
      //     ...state,
      //     seats: [...seats]
      //   }
      // }
      case actionTypes.UPDATE_TEAM: {
        const teams = handleTeamUpdate({...action.payload.data}, [...state.teams])
        return {
          ...state,
          teams: [...teams]
        }
      };
      case actionTypes.CHANGE_GAME_MODE: {
        // debugger;
        const playerNumber = {...state.selectedPlayerNumber}
        const versus = {
          value: '',
          label: ''
        }
        
        const gameMode = {...state.gameModes.find(gameMode => gameMode.value === action.payload.gameMode)}
        if (gameMode.value === 'solo') {
          versus.value = '1v1',
          versus.label = '1vs1'
        } else if (playerNumber?.value === 4 && gameMode.value === 'team') {
          versus.value = '2v2',
          versus.label = '2vs2'
        }
        
        return {
          ...state,
          selectedGameMode: {...gameMode},
          selectedVersus: {...versus},
          teams: gameMode.value === 'team' ? getTeams(versus.label, [...state.seats]) : null,
          // selectedRules: []
        }
      };
      case actionTypes.SET_ENABLED_STATUS_TO_PLAYERS_NUMBER: {
        return {
          ...state,
          playersNumbers: [...state.playersNumbers.map(item => {
            return {
              ...item,
              enabled: item.value >= state.members.length
            }
          })]
        }
      };
      case actionTypes.SET_PRIVATE_PLAYER_NUMBER: {
        const isSolo = action.payload.number.value > 2 && action.payload.number.value % 2 === 1
        return {
          ...state,
          selectedPlayerNumber: {...action.payload.number},
          // selectedGameMode: isSolo ? {value: "solo", label: "Solo", enabled: true} : null,
          // selectedVersus: isSolo ? {value: "1v1", label: "1vs1", enabled: true} : null,
          selectedGameMode: null,
          selectedVersus: null,
          // selectedRules: [],
          freeSpaces: action.payload.number.value - state.members.length
        }
      };
      case actionTypes.SET_ENABLED_STATUS_TO_GAME_MODE: {
        return {
          ...state,
          gameModes: [...state.gameModes.map(mode => {
            let enabled = false;
            if (mode.value === 'solo') {
              enabled = true
            } else if (mode.value === 'team') {
              if (state.selectedPlayerNumber.value > 2 && state.selectedPlayerNumber.value % 2 === 0) {
                enabled = true
              }
            }
            return {
              ...mode,
              enabled
            }
          })]
        }
      };
      case actionTypes.CHANGE_LOBBY_NAME: {
        return {
          ...state,
          name: action.payload.name
        }
      };
      case actionTypes.DELETE_LOBBY_REQUEST: {
        return {
          ...state,
          deleteRequesLoading: true
        }
      };
      case actionTypes.DELETE_LOBBY_SUCCESS: {
        return {
          ...state,
          deleteRequesLoading: false,
          deleted: true
        }
      };
      case actionTypes.DELETE_LOBBY_FAIL: {
        return {
          ...state,
          deleteRequesLoading: false,
          deleted: false
        }
      };
      case actionTypes.RESET_LOBBY: {
        return {
          avilableRules: null,
          gameModes: null,
          gameStatuses: null,
          gameTypes: null,
          versues: null,
          selectedGameType: null,
          selectedRules: [],
          selectedGameMode:  null,
          selectedPlayerNumber: {},
          selectedVersus: null,
          scheduledAt: null,
          seats: [],
          teams: null,
          name: '',
          chat: {},
          members: [],
          host: null,
          loaded: false,
          freeSpaces: null,
          playerLimit: null,
          playersNumbers: [
            {value: 2, label: 2, enabled: true},
            {value: 3, label: 3, enabled: true},
            {value: 4, label: 4, enabled: true},
            {value: 5, label: 5, enabled: true},
            {value: 6, label: 6, enabled: true}
          ],
          deleteRequesLoading: false,
          deleted: false
        }
      }
      default:
        return state;
    }
}

export default privateLobbyReducer;