import { useEffect, useState } from 'react';
import Link from 'next/link';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as styles from '../../styles/scss/components/Header/Header.module.scss';
import { setUserForProfileSidebar } from '../../store/modals/modalsActions';
import ShopSvg from '../../public/images/common/shop_outline.svg';
import { useRouter } from 'next/router';
import { toggleResponsivePopUp } from '../../store/modals/modalsActions';
import { changeLobbyName } from '../../store/lobby/privateLobby/privateLobbyActions';
import { toggleConfirmModal } from '../../store/modals/modalsActions';
import { deleteLobby } from '../../store/lobby/privateLobby/privateLobbyActions';
import AuthorizedHeader from './AuthorizedHeader';
import LobbyHeader from './LobbyHeader';
import DefaultPageHeader from './DefaultPageHeader';
import HexSvg from '../../public/images/common/hex.svg';
import LogoSvg from '../../public/images/header/logo.svg'
import { Dropdown } from '../FormInput/FormInput';
import {SetLanguagesOnPage} from '../../store/languages/languagesActions';
import { getRouterQuery } from '../../helpers/GetQueryParams';
import { getUrl, pagesWithLocales } from '../../helpers/navigation';
import useTranslation from 'next-translate/useTranslation';
// import { Text} from '../SkeletonLoader/SkeletonLoader';
// import dynamic from 'next/dynamic';


const Header = (props) => {
  // const AuthorizedHeader = dynamic(() => import( './AuthorizedHeader'), {loading: () => <Text width={'100%'} heigth={'100%'}/>});
  // const LobbyHeader = dynamic(() => import( './LobbyHeader'), {loading: () => <Text width={'100%'} heigth={'100%'}/>});
  // const DefaultPageHeader = dynamic(() => import( './DefaultPageHeader'), {loading: () => <Text width={'100%'} heigth={'100%'}/>});

  const {
    user,
    isAuthenticated,
    openSidebar,
    onSetUserForProfileSidebar,
    unreadNotificationCount,
    privateLobby,
    onToggleResponsivePopUp,
    onChangeLobbyName,
    OnToggleConfirmModal,
    onDeleteLobby,
    socketId,
    scrolled
  } = props
  const dispatch = useDispatch();
  // const languageToSwitch = language === 'en' ? 'de' : 'en';
  // const { t } = useTranslation('header')
  const langs = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    ru: 'Русский'
  }

  let currentLang = useSelector(state => (state.languages.currentLang))
  const onboardingInProgress = useSelector(state => (state.onBoarding.onboardingInProgress))
  let [languages, setLanguages] = useState([]);
  const router = useRouter()

  const {t} = useTranslation()
  useEffect(() => {
    if(!isAuthenticated) {
      const languages = Object.keys(langs).filter(lang => lang == 'en' || lang == 'de' || lang === 'es' || lang === 'fr').map(lang => {
        return {
          value: langs[lang],
          id: lang,
        }
      })
      setLanguages(languages)
    }
    dispatch(SetLanguagesOnPage(router.locale));
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      if (window.location.href.indexOf('?rule=') >= 0) {
        return
      }
      openSidebar({ isOpen: false })
    }
  }, [isAuthenticated])
  const [isNotificationsModalOpen, setIsnotificationsModalOpen] = useState(false)

  const changeLanguage = (lang) => {
    dispatch(SetLanguagesOnPage(lang.id))
    if (router.pathname != '/') {
      const redirectUrl = getUrl(lang.id, router.pathname)
      router.push(redirectUrl, {pathname: redirectUrl, query: getRouterQuery(router)}, {locale: lang.id})
    } else {
      router.push('/', {query: getRouterQuery(router)}, {locale: lang.id})
    }
  }

  const getHeader = () => {
    if (router.pathname === '/lobby/[lobbyId]' && privateLobby && user) {
      return (
        <LobbyHeader
          router={router}
          privateLobby={privateLobby}
          user={user}
          onDeleteLobby={onDeleteLobby}
          socketId={socketId}
          OnToggleConfirmModal={OnToggleConfirmModal}
          onChangeLobbyName={onChangeLobbyName}
          openSidebar={openSidebar}
        />
      )
    } else if (router.pathname === '/my-games') {
      return (
        <DefaultPageHeader router={router} title={t('navigation:my_games')} />
      )
    } else if (router.pathname === '/leaderboard') {
      return (
        <DefaultPageHeader router={router} title={t('navigation:leaderboard')} />
      )
    } else if (router.pathname === '/inspector') {
      return (
        <DefaultPageHeader router={router} title={t('navigation:inspector')} />
      )
    } else if (router.pathname === '/how-to-play' && isAuthenticated) {
      return <DefaultPageHeader router={router} title={t('navigation:tutorial')} />
    }
    else {
      return <>
        <div className = {styles.logo}>
          <LogoSvg />
        </div>
        <Link locale={router.locale} href={{pathname: '/', query: getRouterQuery(router)}}><a className={styles.mainLink}>braendi dog online</a></Link>
      </>
    }
  }

  return (
    <header className={`${onboardingInProgress ? styles.onboardingInProgress : ''} ${styles.header} ${isAuthenticated ? styles.authorized : router.pathname === '/' ? styles.not_authorized: ''} ${scrolled && styles.scrolled}`}>
      <div className={styles.left_side}>
        {getHeader()}
      </div>
      <div className={styles.header_right_side}>
        {
          isAuthenticated ?
            (
              <AuthorizedHeader
                openSidebar={openSidebar}
                unreadNotificationCount={unreadNotificationCount}
                user={user}
                onSetUserForProfileSidebar={onSetUserForProfileSidebar}
                onToggleResponsivePopUp={onToggleResponsivePopUp}
                router = {router}
                onboardingInProgress = {onboardingInProgress}
              />
            ) :
            (
              <>
                <nav className={styles.header_nav}>
                  <ul className={styles.nav_ul}>
                    <li className={styles.nav_li}>
                      <Link href={{pathname: getUrl(currentLang, 'how-to-play'), query: getRouterQuery(router)}} locale={currentLang}>
                        <a>{t('navigation:how_to_play')}</a>
                      </Link>
                    </li>
                    <li className={styles.nav_li}>
                      <Link href={{pathname: pagesWithLocales[currentLang]['about'], query: getRouterQuery(router)}} locale={currentLang}>
                        <a>{t(('navigation:about_braendi'))}</a>
                      </Link>
                    </li>
                    <li className={`${styles.nav_li} ${styles.shop_link}`} onClick={() => {
                      openSidebar({
                        sidebarType: 'barendiShop',
                        isOpen: true,
                        hasTitle: true,
                        showSidebarCloseButton: true
                      })
                    }}>
                    <ShopSvg />
                    <span>{t('common:sidebar_titles.braendi_shop')}</span>
                    </li>
                    <li className={styles.nav_li}>
                      <div className={styles.languages_container}>
                        <Dropdown
                            list={languages}
                            change={(lang) => { changeLanguage(lang) }}
                            absolute={true}
                            label={''}
                            value={langs[currentLang]}
                            small={true}
                            autoHeight={true}
                        />
                      </div>
                    </li>
                    <li className={`${styles.nav_li} ${styles.hex_header}`}>
                      <div
                        className = {`${styles.header_menu_button} ${styles.add_friend}`}
                        onClick = {() => {onToggleResponsivePopUp({
                          isOpen:true,
                          type: 'menu'
                          })}}
                          >
                        <div className = {styles.hex}>
                          <HexSvg />
                        </div>
                        <img alt="Hamburger icon" src='/images/common/hamburger.svg' />
                      </div>
                    </li>
                  </ul>
                </nav>
              </>
            )
        }
      </div>
    </header>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    unreadNotificationCount: state.notifications.unreadNotificationCount,
    privateLobby: state.privateLobby,
    socketId: state.pusher.socketId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onToggleResponsivePopUp: (payload) => dispatch(toggleResponsivePopUp(payload)),
    onChangeLobbyName: (name) => dispatch(changeLobbyName(name)),
    OnToggleConfirmModal: (payload) => dispatch(toggleConfirmModal(payload)),
    onDeleteLobby: (lobbyId, socketId) => dispatch(deleteLobby(lobbyId, socketId))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);