import router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as styles from '../../styles/scss/components/PopUpModal/PopUpModal.module.scss';
import { CloseButton } from '../Buttons/Buttons';
import FormInput from '../FormInput/FormInput';
import TeamSign from '../TeamSign/TeamSign';
import useTranslation from 'next-translate/useTranslation';
import { useTeamName } from '../../helpers/hooks';
const PopUpModal = (props) => {
  const {
    popupType,
    closeButtonFunc,
    saveEditFunction,
    gameName,
    teamName,
    teamSymbol,
    teamId
  } = props
  const {t} = useTranslation()

  const getPopUpTitle = (popupType) => {
    switch (popupType) {
      case 'editGameName': return t('common:modals.edit_game_name.edit_name_title');
      case 'editTeam': return t('common:modals.edit_team.edit_team_title')
    }
  }

  return (
    <div className = {styles.popup_modal}>
      <div className = {styles.popup_modal_header}>
        <h2 className = {styles.modal_title}>
          {getPopUpTitle(popupType)}
        </h2>
        <div className = {styles.modal_button_container}>
          <CloseButton click = {closeButtonFunc} />
        </div>
      </div>
      <div className = {styles.popup_modal_body}>
        {popupType === 'editGameName' && 
          <EditGameName 
            saveFunction = {saveEditFunction}
            oldName = {gameName}
          />
        }
        {popupType === 'editTeam' && 
          <EditTeam 
            saveFunction = {saveEditFunction}
            oldTeamName = {teamName}
            oldTeamSymbol = {teamSymbol}
            teamId = {teamId}
          />
        }
      </div>
    </div>
  )
}

export const EditGameName = (props) => {
  const {oldName, saveFunction} = props
  const {t} = useTranslation()
  const [saveIsDisabled, setSaveIsDisabled] = useState(false);


  const [gameName, setGameName] = useState(t('common:inputs.update_game_name.value'))
  useEffect(() => {
    setGameName(oldName)
  }, [])

  const onClickSave = (e) => {
    if(saveIsDisabled) {
      return;
    }
    setSaveIsDisabled(true);
    saveFunction(e, {name: gameName});
  }
  return (
    <div className = {styles.edit_container}>
      <form className = {styles.edit_form}>
          <FormInput
            type = 'text'
            value = {gameName}
            name = 'name'
            change = {(e) => {setGameName(e.target.value)}}
            active = {gameName.length > 0}
            labelText = {t('common:inputs.update_game_name.label')}
            showIndicator = {false}
            id = 'editGameName'
            valid = {gameName.length > 0}
            changed={true}
            disabled = {false}
            errorText = {t('common:inputs.update_game_name.error_text')}
            // showIcon = {true}
          />
        <div className = {styles.save_edit_data_container}>
          <button disabled={gameName?.length === 0 || saveIsDisabled} onClick = {onClickSave}>{t('common:buttons.save_button')}</button>
        </div>
      </form>
    </div>
  )
}

export const EditTeam = (props) => {
  const {
    saveFunction,
    oldTeamName,
    oldTeamSymbol,
    teamId
  } = props
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const logoNames = ['axe', 'boat', 'bow-arrow', 'butterfly', 'cat', 'crown', 'dog', 'dragon', 'dwarf', 'eagle', 'elf', 'goat', 'hammer', 'pirate-swords', 'potion', 'skull', 'star', 'sword', 'top-hat', 'wizard-hat']
  const {t} = useTranslation()
  const [teamSymbol, setTeamSymbol] = useState('')

  const handleSave = (e) => {
    if (!teamName || teamName === '' || buttonIsDisabled) {
      return
    }
    setButtonIsDisabled(true)
    saveFunction(e,{
      name: teamName,
      logo: teamSymbol,
      teamId: teamId
    })
  }

  useEffect(() => {
    setTeamSymbol(oldTeamSymbol)
  }, [])

  const [teamName, setTeamName] = useTeamName(oldTeamName)

  return (
    <div className = {styles.edit_container}>
      <form className = {styles.edit_form}>
        <div className = {styles.edit_inputs}>
          <div className = {styles.input_cotainer}>
            <FormInput
              type = 'text'
              value = {teamName}
              name = 'name'
              change = {(e) => {setTeamName(e.target.value)}}
              active = {teamName.length > 0}
              labelText = {t('common:inputs.game_name')}
              showIndicator = {false}
              id = 'editTeamName'
              valid = {true}
              disabled = {false}
              // errorText = {errors && errors.name}
              // showIcon = {true}
            />
          </div>
          <div className = {styles.symbol_container}>
            <h3  className = {styles.icons_title}>
              {t('common:modals.edit_team.choose_icon')}
            </h3>
            <div className = {styles.icons_list}>
              <ul className = {styles.icons_list_ul}>
                {logoNames.map(logo => {
                  return (
                    <li className = {styles.icons_list_li}>
                      <div className = {styles.icons_container} onClick = {() => {setTeamSymbol(logo)}}>
                        <TeamSign signName = {logo} active = {logo === teamSymbol}/>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className = {styles.save_edit_data_container}>
          <button disabled = {!teamName || teamName === '' || buttonIsDisabled} type='button' onClick = {handleSave}>{t('common:buttons.save_button')}</button>
        </div>
      </form>
    </div>
  )
}

export default PopUpModal;