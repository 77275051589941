
export const GET_AVATARS_LIST_REQUEST = 'GET_AVATARS_LIST_REQUEST';
export const GET_AVATARS_LIST_SUCCESS = 'GET_AVATARS_LIST_SUCCESS';
export const GET_AVATARS_LIST_FAIL = 'GET_AVATARS_LIST_FAIL';

export const GET_COUNTRIES_LIST_REQUEST = 'GET_COUNTRIES_LIST_REQUEST';
export const GET_COUNTRIES_LIST_SUCCESS = 'GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_FAIL = 'GET_COUNTRIES_LIST_FAIL';

export const GET_CITIES_LIST_REQUEST = 'GET_CITIES_LIST_REQUEST';
export const GET_CITIES_LIST_SUCCESS = 'GET_CITIES_LIST_SUCCESS';
export const GET_CITIES_LIST_FAIL = 'GET_CITIES_LIST_FAIL';

export const GET_STATES_LIST_REQUEST = 'GET_STATES_LIST_REQUEST';
export const GET_STATES_LIST_SUCCESS = 'GET_STATES_LIST_SUCCESS';
export const GET_STATES_LIST_FAIL = 'GET_STATES_LIST_FAIL';

export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL';

export const GET_VERSION_INFO_REQUEST = 'GET_VERSION_INFO_REQUEST';
export const GET_VERSION_INFO_SUCCESS = 'GET_VERSION_INFO_SUCCESS';
export const GET_VERSION_INFO_FAIL = 'GET_VERSION_INFO_FAIL';

export const SET_COLABORATORS = 'SET_COLABORATORS';
export const HANDLE_VERSION_OPEN = 'HANDLE_VERSION_OPEN';