import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as styles from '../../../styles/scss/components/EditAvatar/EditAvatar.module.scss';
import { getAvatars, setChosenAvatar, setSaveButtonEnabled } from '../../../store/avatars/avatarsActions';
import { updateUser, setSignUpFormData } from '../../../store/auth/authActions';
import {setReferralAvatar} from '../../../store/friends/friendActions';
import Avatar from '../../Avatar/Avatar';
import { toggleSidebar, setUserForProfileSidebar } from '../../../store/modals/modalsActions';
import useTranslation from 'next-translate/useTranslation';

const EditAvatar = (props) => {
  const {
    avatarsList,
    userProfile,
    userId,
    onUpdateUser,
    onSetSignUpFormData,
    isAuthenticated,
    signUpFormData,
    onToggleSidebar,
    onSetChosenAvatar,
    chosenAvatar,
    profileUpdated,
    onSetUserForProfileSidebar,
    modalState,
    onSetReferralAvatar,
    isInWisard,
    wizardChosenAvatar,
    saveButtonEnabled,
    onSetSaveButtonEnabled,
  } = props
  const {t} = useTranslation();
  const handleSaveAvatar = async () => {
    if (modalState.referrer === 'inviteFriend') {
      onSetChosenAvatar(chosenAvatar)
      onSetReferralAvatar(chosenAvatar)
      onToggleSidebar({
        isOpen: true,
        sidebarType: 'inviteFriend',
        hasTitle: true,
        showSidebarCloseButton: true
      })
      return
    }
    if (isAuthenticated) {
      const editedProfile = {
        name: userProfile.name,
        avatarId: chosenAvatar.id,
      }
      setLoading(true)
      onSetSaveButtonEnabled(false)
      setButtonText(t('common:buttons.saving'))
      onUpdateUser(editedProfile, userId)
      .then(() => {
        setLoading(false)
        setButtonText(t('common:buttons.save_button'))
        onSetSignUpFormData({
          ...signUpFormData,
          avatar: chosenAvatar
        })
        onToggleSidebar({
          isOpen: true,
          sidebarType: 'profile',
          hasTitle: true,
          showSidebarCloseButton: true
        })
      }).catch(() => {
        setLoading(false)
        setButtonText(t('common:buttons.save_button'))
      })
    } else {
      onSetChosenAvatar({
        ...chosenAvatar,
        fromSignUp: true
      })
      onSetSignUpFormData({
        ...signUpFormData,
        avatar: chosenAvatar
      })
      onToggleSidebar({
        isOpen: true,
        sidebarType: 'signUp',
        hasTitle: true,
        customTitle: t('common:sidebar_titles.signup_sidebar_header'),
        showSidebarCloseButton: true
      })
    }
  }

  useEffect(() => {
    if (signUpFormData && signUpFormData.avatar) {
      onSetChosenAvatar(signUpFormData.avatar)
    }
  }, [signUpFormData])

  const [buttontext, setButtonText] = useState(t('common:buttons.save_button'))
  
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (profileUpdated) {
      onSetUserForProfileSidebar({
        ...userProfile,
        isLoggedInUser: true
      })
      
      onSetChosenAvatar(null)
    }
  }, [profileUpdated, userProfile])


  useEffect(() => {
    if (isAuthenticated) {
      if (modalState.referrer === 'inviteFriend') {
        onSetChosenAvatar(avatarsList[0])
      } else {
        onSetChosenAvatar(userProfile.avatar)
      }
    } else {
      if (isInWisard && (chosenAvatar || wizardChosenAvatar)) {
        onSetChosenAvatar(chosenAvatar || wizardChosenAvatar)
        return
      }
      onSetChosenAvatar(avatarsList[0])
    }
  }, [])

  return (
    <div className = {styles.edit_avatar}>
      {avatarsList && chosenAvatar && (
        <div className = {styles.avatars_list}>
          <ul className = {styles.avatars_list_ul}>
            {avatarsList.map((avatar, index) => {
              if (index === 0) return

              return (
                <li
                  className = {`${styles.avatars_list_li}`}
                  key={`avatarList${avatar.id}`}
                  onClick = {() => {
                    onSetSaveButtonEnabled(true)
                    onSetChosenAvatar(avatar)
                    if (isInWisard) {
                      onSetChosenAvatar({
                        ...avatar,
                        fromSignUp: true
                      })
                      onSetSignUpFormData({
                        ...signUpFormData,
                        avatar: avatar
                      })
                    }
                  }}
                  >
                  <div className = {`${styles.avatar_list_item}`}>
                    <div className= {styles.avatar_container}>
                      <Avatar
                        mode = 'normal'
                        hasFlag = {false}
                        size = 'size96'
                        avatarUrls = {avatar.avatars}
                        active = {chosenAvatar.id === avatar.id}
                        showOverlayOnHover = {true}
                      />
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      {!isInWisard && (
        <div className = {styles.avatar_save_button_container}>
          <button
            className = {styles.avatar_save_button}
            onClick = {handleSaveAvatar}
            disabled = {loading || !saveButtonEnabled || chosenAvatar?.breed === 'Default'}
            >
            {buttontext}
          </button>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = state => {
  return {
    avatarsList: state.avatars.avatarsList,
    userProfile: state.auth.user,
    userId: state.auth.user?.id,
    isAuthenticated: state.auth.isAuthenticated,
    signUpFormData: state.auth.signUpFormData,
    chosenAvatar: state.avatars.chosenAvatar,
    profileUpdated: state.auth.profileUpdated,
    modalState: state.modals,
    chosenAvatarIndex: state.avatars.index,
    saveButtonEnabled: state.avatars.saveButtonEnabled
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAvatarList: (payload) => dispatch(getAvatars(payload)),
    onUpdateUser: (payload, userId) => dispatch(updateUser(payload, userId)),
    onSetSignUpFormData: (formData) => dispatch(setSignUpFormData(formData)),
    onToggleSidebar: (sidebarData) => dispatch(toggleSidebar(sidebarData)),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onSetChosenAvatar: (avatar) => dispatch(setChosenAvatar(avatar)),
    onSetReferralAvatar: (avatar) => dispatch(setReferralAvatar(avatar)),
    onSetSaveButtonEnabled: (isEnabled) => dispatch(setSaveButtonEnabled(isEnabled)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAvatar)
