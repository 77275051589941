import * as actionTypes from './userActionTypes.js';

const initialState = {
    users: [],
    isLoading: false,
    userToOpenInSidebar: null,
    userData: null
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_REQUEST:
            return {
              ...state,
              isLoading: true
            };
        case actionTypes.FETCH_USERS_SUCCESS:
            return {
              ...state,
              isLoading: false,
              users: [...action.payload.users]
            };
        case actionTypes.FETCH_USERS_FAIL:
            return {
              ...state,
              isLoading: false
            };
        case actionTypes.UPDATE_USER_REQUEST:
            return {
              ...state,
              isLoading: true
            };
        case actionTypes.UPDATE_USER_SUCCESS:
            return {
              ...state,
              isLoading: false,
              user: [action.payload.user]
            };
        case actionTypes.UPDATE_USER_FAIL:
            return {
              ...state,
              isLoading: false
            };
        case actionTypes.SET_USER: {
          return {
            ...state,
            userData: {...action.payload.userData}
          }
        }
        default:
            return {...state};
    }
}

export default userReducer;