import * as styles from '../../styles/scss/components/Buttons/Buttons.module.scss'
import HexSvg from '../../public/images/common/hex.svg';
import RedHexSvg from '../../public/images/common/redhex.svg';
import ChevronBack from '../../public/images/common/chevron_left.svg';
import EditSvg from '../../public/images/common/edit.svg';
import DeleteSvg from '../../public/images/common/delete.svg'
import LeaveSvg from '../../public/images/common/leave.svg'
import ShareSvg from '../../public/images/common/share.svg'

export const CloseButton = ({click, customStyle}) => {
  return (
    <button type="button" onClick={click} className = {`${styles.close_button} ${customStyle ?? ''}`}>
      <img alt = 'modal action button' src = '/images/common/close.png'/>
    </button>
  )
}

export const MinimizeButton = ({click, state}) => {
  return (
    <button type="button" onClick={click} className = {`${styles.minimize_button} ${state && styles.minimized} minimizeButton`}>
      <span className = {styles.minimize_line}></span>
      <span className = {styles.minimize_line}></span>
    </button>
  )
}

export const BackButton = ({click, rotate}) => {
  return (
    <div className = {`${styles.back_button}`} onClick = {click}>
      <ChevronBack />
      <style jsx>
        {
          `
            transform: rotate(${rotate}deg)
          `
        }
      </style>
    </div>
  )
}

export const HexButtons = props => {
  return(
    <button className={`${styles.hex_buttons} ${styles.hex_buttons_mobile}`}>
      <HexSvg />
      {props.children}
    </button>
  )
}

export const RedHexButtons = props => {
  return(
    <button className={styles.hex_buttons}>
      <RedHexSvg />
      {props.children}
    </button>
  )
}

export const ShareButton = props => {
  return (
    <button className = {styles.share_button} onClick = {props.click}>
      <ShareSvg />
    </button>
  )
}

export const EditButton = ({click}) => {
  return (
    <button name='editAvatar' className = {styles.edit_button} onClick = {click}>
      <EditSvg />
    </button>
  )
}

export const PrimaryRound = (props) => {
  return (
    <button className={styles.primary_round} disabled = {props.disabled} onClick = {props.click}>
      {props.children}
    </button>
  )
}

export const GradientButtons = props => {
  const { small, dark, blue, purple, pink, disabled, click, children } = props;
  return(
    <button 
      data-small={small} 
      data-blue={blue} 
      data-purple={purple} 
      data-pink={pink} 
      data-dark={dark} 
      disabled={disabled} 
      className={`${styles.gradient_buttons}`}
      onClick={click}>
      {children}
    </button>
  )
}

export const DeleteButton = ({click}) => {
  return (
    <button className = {styles.delete_button} onClick = {click}>
      <DeleteSvg />
    </button>
  )
}

export const LeaveButton = ({click}) => {
  return (
    <button className = {styles.delete_button} onClick = {click}>
      <LeaveSvg />
    </button>
  )
}

export const RegularButtons = props => {
  return(
    <button disabled={props.disabled} className={`${styles.regular_buttons}`}>
      {props.children}
    </button>
  )
}

export const MediumPrimaryIndicator = ({buttonText, indicator, clickAction, active}) => {
  return (
    <button className = {`${styles.medium_primary_indicator} ${active && styles.active}`} onClick = {clickAction}>
      <span className = {styles.text}>
        {buttonText}
      </span>
      <div className = {styles.indicator}>
        <span className = {styles.primary}>
          {indicator}
        </span>
      </div>
    </button>
  )
}