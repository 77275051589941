import * as actionTypes from './onBoardingActionTypes'
import api from '../../axios/api';
import { saveSettingsSuccess } from '../settings/settingsActions';

export const setCurrentElement = (currentElement) => {
  return {
    type: actionTypes.SET_CURRENT_ELEMENT,
    payload: {
      currentElement
    }
  }
}

export const setOnboardingBackdropState = (backdropState) => {
  return {
    type: actionTypes.SET_ONBOARDING_BACKDROP_STATE,
    payload: {
      backdropState
    }
  }
}

export const setOnboardingCurrentSetpNumber = (currentStepNumber) => {
  return {
    type: actionTypes.SET_ONBOARDING_CURRENT_STEP_NUMBER,
    payload: {
      currentStepNumber
    }
  }
}

export const setOnboardingTooltipCoordinates = (coordinates) => {
  return {
    type: actionTypes.SET_ONBOARDING_TOOLTIP_COORDINATES,
    payload: {
      coordinates
    }
  }
}

export const setShowOnboardingElement = (state) => {
  return {
    type: actionTypes.SET_SHOW_ONBOARDING_ELEMENT,
    payload: {
      state
    }
  }
}

export const setOnboardingFinish = (state) => {
  return {
    type: actionTypes.SET_ONBOARDING_FINISHED,
    payload: {
      state
    }
  }
}

export const setHideElements = (state) => {
  return {
    type: actionTypes.SET_HIDE_ELEMENTS,
    payload: {
      state
    }
  }
}

export const updateOnboardingSetting = () => {
  return (dispatch) => {
    dispatch(setOnboardingFinish());
    api.patch('/users/seen-onboarding')
    .then(res => {
      dispatch(saveSettingsSuccess(res.data.settings))
    })
    .catch(e => {
      console.log(e)
    })
  }
}