import * as actionTypes from './chatActionTypes';

const initialState = {
  chatIds: [],
  isLoading: false,
  error: null,
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.GET_CHAT_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.SET_CHAT_ID:
        return {
          ...state,
          isLoading: false,
          chatIds: [...chatIds, action.payload.chat.id]
        };
      case actionTypes.REMOVE_CHAT_ID:
        return {
          ...state,
          isLoading: false,
          chatIds: chatIds.filter(chat => chat.id !== action.payload.chat.id)
        };
      case actionTypes.GET_CHAT_DATA_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload.error
        };
      default:
        return state;
    }
}

export default chatReducer;