import * as actionTypes from './authActionTypes';

const initialState = {
  access_token: null,
  refresh_token: null,
  user: null,
  error: null,
  isLoading: true,
  message: null,
  isAuthenticated: false,
  signUpSuccessfull: false,
  signUpFormData: null,
  profileUpdated: false,
  stats: null,
  logOutStarted: false,
}

const getMessageTextForModal = (text, status) => {
  // debugger;
  const messageObject = {}
  switch (text) {
    case 'USER_EXISTS': {
      messageObject.message = 'user_with_such_email_exists';
      messageObject.success = false;
    }; break;
    case 'REGISTRATION_OK': {
      messageObject.message = 'registration_successfull';
      messageObject.success = true;
    }; break;
    case 'INVALID_CREDENTIALS': {
      messageObject.message = 'invalid_credentials';
      messageObject.success = false;
    }; break;
    default : {
      messageObject.message = 'unexpected_error';
      messageObject.success = status
    }
  }
  return messageObject
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.LOGIN_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.LOGIN_SUCCESS:{
        const currentUser = {...action.payload.user}
        localStorage.setItem('userId', currentUser.id)
        return {
          ...state,
          access_token: action.payload.access_token,
          refresh_token: action.payload.refresh_token,
          user: {
            ...currentUser,
            hasRole: (role) => {
              return currentUser.roles.indexOf(role) >=0
            },
            hasAnyRole: (roles) => {
              const filteredArray = currentUser.roles.filter(value => roles.includes(value));
              return filteredArray.length > 0
            }
          },
          stats: action.payload.stats,
          isAuthenticated: true,
          isLoading: false
        };
      }
      case actionTypes.LOGIN_FAIL:
        return {
          ...state,
          error: action.payload,
          message: {...getMessageTextForModal(action.payload.error)},
          isLoading: false
        };
      case actionTypes.REGISTER_REQUEST:
        return{
          ...state,
          isLoading: true,
          signUpSuccessfull: false
        };
      case actionTypes.REGISTER_SUCCESS: {
        // debugger;
        return {
          ...state,
          // message: {...getMessageTextForModal(action.payload.message)},
          message: null,
          isLoading: false,
          signUpSuccessfull: true
        }
      };
      case actionTypes.REGISTER_FAIL:
        return {
          ...state,
          error: action.payload.error,
          message: {...getMessageTextForModal(action.payload.error)},
          isLoading: false,
          signUpSuccessfull: false
        };
      case actionTypes.CHECK_AUTH_REQUEST:
        return{
          ...state,
          isLoading: true 
        };
      case actionTypes.CHECK_AUTH_SUCCESS:{
        const currentUser = {...action.payload.user}
        return {
          ...state,
          user: {
            ...currentUser,
            hasRole: (role) => {
              return currentUser.roles.indexOf(role) >=0
            },
            hasAnyRole: (roles) => {
              const filteredArray = currentUser.roles.filter(value => roles.includes(value));
              return filteredArray.length > 0
            }
          },
          stats: action.payload.stats,
          isAuthenticated: true,
          isLoading: false,
          signUpSuccessfull: false,
          logOutStarted: false,
        };
      }
      case actionTypes.CHECK_AUTH_FAIL:
        return {
          ...state,
          isAuthenticated: false,
          isLoading: false
        };
      case actionTypes.LOGOUT_REQUEST:
        return{
          ...state,
          isLoading: true,
          logOutStarted: true,
        };
      case actionTypes.LOGOUT_SUCCESS:
        return {
          ...state,
          user: null,
          isAuthenticated: false,
          isLoading: false,
          signUpSuccessfull: false,
          logOutStarted: false,
        };
      case actionTypes.LOGOUT_FAIL:
        return {
          ...state,
          isLoading: false
        };
      case actionTypes.UPDATE_USER_REQUEST:
        return {
          ...state,
          // isLoading: true,
          profileUpdated: false
        };
      case actionTypes.UPDATE_USER_SUCCESS: {
        // debugger
        const currentUser = {...action.payload.user}
        return {
          ...state,
          // isLoading: false,
          user: {
            ...currentUser,
            hasRole: (role) => {
              return currentUser.roles.indexOf(role) >=0
            },
            hasAnyRole: (roles) => {
              const filteredArray = currentUser.roles.filter(value => roles.includes(value));
              return filteredArray.length > 0
            }
          },
          profileUpdated: true
        };
      }
      case actionTypes.UPDATE_USER_FAIL:
        return {
          ...state,
          // isLoading: false,
          profileUpdated: false
        };
      case actionTypes.UPDATE_ONLINE_STATUS: {
        return{
          ...state,
          user: {
            ...state.user,
            online: action.payload.state
          }
        }
      }
      case actionTypes.RESET_ONGOING_GAME: {
        return {
          ...state,
          user: {
            ...state.user,
            ongoingGame: null
          }
        }
      }
      // case actionTypes.GET_AVATARS_LIST_REQUEST: {
      //   return {
      //     ...state,
      //     // isLoading: true
      //   }
      // };
      // case actionTypes.GET_AVATARS_LIST_SUCCESS: {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     avatarsList: action.payload.avatarsList
      //   }
      // };
      // case actionTypes.GET_AVATARS_LIST_FAIL: {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     error: action.payload.error
      //   }
      // };
      // case actionTypes.GET_COUNTRIES_LIST_REQUEST: {
      //   return {
      //     ...state
      //   }
      // };
      // case actionTypes.GET_COUNTRIES_LIST_SUCCESS: {
      //   return {
      //     ...state,
      //     countriesList: action.payload.countriesList
      //   }
      // };
      // case actionTypes.GET_COUNTRIES_LIST_FAIL: {
      //   return {
      //     ...state,
      //     error: action.payload.error
      //   }
      // };
      // case actionTypes.GET_CITIES_LIST_REQUEST: {
      //   return {
      //     ...state
      //   }
      // };
      // case actionTypes.GET_CITIES_LIST_SUCCESS: {
      //   return {
      //     ...state,
      //     countriesList: action.payload.citiesList
      //   }
      // };
      // case actionTypes.GET_CITIES_LIST_FAIL: {
      //   return {
      //     ...state,
      //     error: action.payload.error
      //   }
      // };
      case actionTypes.SET_SIGN_UP_FORM_DATA: {
        return {
          ...state,
          signUpFormData: action.payload.formData
        }
      }
      case actionTypes.SET_PROFILE_UPDATED: {
        return {
          ...state,
          profileUpdated: action.payload.profileUpdated
        }
      }
      default:
        return state;
    }
}

export default authReducer;