import { useRouter } from "next/router";
import { connect } from "react-redux";
import { setTypeFilter } from "../../../store/leaderboard/leaderboardActions";
import { toggleSidebar } from "../../../store/modals/modalsActions";
import * as styles from "../../../styles/scss/components/TableFilters/TableFilters.module.scss";
import useTranslation from "next-translate/useTranslation";
const MyGamesFilter = (props) => {
    const { onToggleSidebar, sidebarData } = props;
    const {handleChangeFilter, filters} = sidebarData;
    const { t } = useTranslation();
    const onChangeFilter = (filter) => {
        handleChangeFilter(filter);
        onToggleSidebar({
            isOpen: false,
            sidebarType: "",
        });
    };

    return (
        <div className={styles.filters_right}>
            <div className={styles.filter_types}>
                <ul className={styles.filter_type_ul}>
                    <li className={styles.filter_type_li}>
                        <div className={styles.filter_type_container}>
                            <button
                                className={`${styles.filter_button} ${
                                    filters.mode === '' && styles.active
                                }`}
                                onClick={() => {
                                    onChangeFilter({mode: ''});
                                }}
                            >
                                {t('my_games:all')}
                            </button>
                        </div>
                    </li>
                    <li className={styles.filter_type_li}>
                        <div className={styles.filter_type_container}>
                            <button
                                className={`${styles.filter_button} ${
                                    filters.mode === 'solo' && styles.active
                                }`}
                                onClick={() => {
                                    onChangeFilter({mode: 'solo'});
                                }}
                            >
                                {t('my_games:solo')}
                            </button>
                        </div>
                    </li>
                    <li className={styles.filter_type_li}>
                        <div className={styles.filter_type_container}>
                            <button
                                className={`${styles.filter_button} ${
                                    filters.mode === 'team' && styles.active
                                }`}
                                onClick={() => {
                                    onChangeFilter({mode: 'team'});
                                }}
                            >
                                {t('my_games:team')}
                            </button>
                        </div>
                    </li>
                    {
                        filters.status === 'started' && 
                        <li className={styles.filter_type_li}>
                            <div className={styles.filter_type_container}>
                                <button
                                    className={`${styles.filter_button} ${
                                        filters.myTurn && styles.active
                                    }`}
                                    onClick={() => {
                                        onChangeFilter({myTurn: !filters.myTurn});
                                    }}
                                >
                                    {t('my_games:my_turn')}
                                </button>
                            </div>
                        </li>
                    }
                    {
                        filters.status === 'finished' && 
                        <>
                            <li className={styles.filter_type_li}>
                                <div className={styles.filter_type_container}>
                                    <button
                                        className={`${styles.filter_button} ${
                                            filters.gameType === 'private' && styles.active
                                        }`}
                                        onClick={() => {
                                            onChangeFilter({gameType: 'private'});
                                        }}
                                    >
                                        {t('my_games:friends_game')}
                                    </button>
                                </div>
                            </li>
                            <li className={styles.filter_type_li}>
                                <div className={styles.filter_type_container}>
                                    <button
                                        className={`${styles.filter_button} ${
                                            filters.gameType === 'classic' && styles.active
                                        }`}
                                        onClick={() => {
                                            onChangeFilter({gameType: 'classic'});
                                        }}
                                    >
                                        {t('my_games:online_game')}
                                    </button>
                                </div>
                            </li>
                        </>
                    }
                    
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        typeFilters: state.leaderboard.typeFilters,
        sidebarData: state.modals.sidebarData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetTypeFilter: (selectedFilter) =>
            dispatch(setTypeFilter(selectedFilter)),
        onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyGamesFilter);
