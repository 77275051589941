import * as actionTypes from './lobbyActionTypes';

const initialState = {
  lobbyData: null,
  isLoading: false,
  error: null,
  playersPlaces: [
    {
      color: 'RED',
      player: ''
    },
    {
      color: 'BLUE',
      player: null
    },
    {
      color:'GREEN',
      player: null,
    },
    {
      color: 'YELLOW',
      player: null
    },
    {
      color: 'WHITE',
      player: null
    },
    {
      color: 'BLACK',
      player: null
    }
  ],
  rollData: null,
  privateLobbyData: null,
  redirectToLobby: false
}

const lobbyReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.GET_LOBBY_DATA_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.GET_LOBBY_DATA_SUCCESS:
        // debugger
        return {
          ...state,
          isLoading: false,
          lobbyData: {...action.payload.lobbyData}
        };
      case actionTypes.GET_LOBBY_DATA_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.GET_PRIVATE_LOBBY_DATA_REQUEST:
        return {
          ...state,
          // isLoading: true,
        };
      case actionTypes.GET_PRIVATE_LOBBY_DATA_SUCCESS:
        // debugger
        return {
          ...state,
          // isLoading: false,
          privateLobbyData: {...action.payload.lobbyData}
        };
      case actionTypes.GET_PRIVATE_LOBBY_DATA_FAIL:
        return {
          ...state,
          // isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PLAYER_SIT_DOWN_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.PLAYER_SIT_DOWN_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case actionTypes.PLAYER_SIT_DOWN_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PLAYER_JOIN_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.PLAYER_JOIN_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case actionTypes.PLAYER_JOIN_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.PLAYER_ROLL_DICE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.PLAYER_ROLL_DICE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          rollData: {...action.payload }
        };
      case actionTypes.PLAYER_ROLL_DICE_FAIL:
        return {
          ...state,
          isLoading: false,
          error: {...action.payload.error}
        };
      case actionTypes.SET_JOINED_PLAYER: {
        // debugger
        // const lobbyData = {...state.lobbyData}
        // lobbyData.members = [...lobbyData.members.map(member => {
        //   return {
        //     ...member,
        //     gameAccepted: action.payload.userId === member.id ? true : member.gameAccepted,
        //     currentUser: action.payload.currentUserId === member.id
        //   }
        // })]

        return {
          ...state,
          // lobbyData: {...lobbyData}
        }
      };
      case actionTypes.CRATE_PRIVATE_LOBBY_REQUEST: {
        return {
          ...state,
          isLoading: true
        }
      }
      case actionTypes.CRATE_PRIVATE_LOBBY_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          // privateLobbyData: {...action.payload.privateLobbyData},
          privateLobbyId: action.payload.privateLobbyData.id,
          redirectToLobby: true
        }
      }
      case actionTypes.CRATE_PRIVATE_LOBBY_FAIL: {
        return {
          ...state,
          isLoading: false
        }
      }
      case actionTypes.SET_REDIRECT_TO_PRIVATE: {
        return {
          ...state,
          redirectToLobby: action.payload.state
        }
      }
      case actionTypes.RESET_PUBLIC_LOBBY: {
        return {
          lobbyData: null,
          isLoading: false,
          error: null,
          playersPlaces: [
            {
              color: 'RED',
              player: ''
            },
            {
              color: 'BLUE',
              player: null
            },
            {
              color:'GREEN',
              player: null,
            },
            {
              color: 'YELLOW',
              player: null
            },
            {
              color: 'WHITE',
              player: null
            },
            {
              color: 'BLACK',
              player: null
            }
          ],
          rollData: null,
          privateLobbyData: null,
          redirectToLobby: false
        }
      }
      default:
        return state;
    }
}

export default lobbyReducer;