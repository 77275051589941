import { useSelector } from 'react-redux';
import styles from '../../styles/scss/components/OnboardingTooltips/OnboardingTooltips.module.scss';
import useTranslation from 'next-translate/useTranslation';
export const Tooltip = () => {
  const coordinates = useSelector(state => state.onBoarding.tooltipPositionCoordinates);
  const tooltipPos = useSelector(state => state.onBoarding.tooltipPositionString)
  const currentStepNumber = useSelector(state => state.onBoarding.currentStepNumber);
  const trianglePosition = useSelector(state => state.onBoarding.trianglePosition);
  const stepsData = useSelector(state => state.onBoarding.currentStepsData)
  const hideElements = useSelector(state => state.onBoarding.hideElements)
  const {t} = useTranslation()
  return (
    <div className={`${styles.tooltipContainer} ${styles[tooltipPos]} ${currentStepNumber === 7 ? styles.large : ''} ${hideElements ? styles.hidden: ''} onboarding_container`}
      style={{
        left: coordinates.x + 'px',
        top: coordinates.y + 'px'
      }}>
      <span className={styles.triangle} style={{
        left: trianglePosition
      }}></span>
      <div className={styles.content}>
        <div className={styles.image_container}>
          <img className={`${styles.img} ${(currentStepNumber >= 3 && currentStepNumber <= 4) && styles.bigAvatars }`} src={`/images/onboarding/${stepsData[currentStepNumber - 1]?.imgName}`}/>
        </div>
        <div className={styles.descriptionContainer}>
          <h2>{t(`onboarding:${stepsData[currentStepNumber - 1]?.title}`)}</h2>
          <p>{t(`onboarding:${stepsData[currentStepNumber - 1]?.text}`)}</p>
        </div>
      </div>
    </div>
  );
}