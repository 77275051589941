import React, { useEffect, useState } from 'react'
import * as styles from '../../styles/scss/components/GameModal/GameModal.module.scss'
import Avatar from '../Avatar/Avatar'
import Card from '../Card/Card'
import DroppedCards from './DroppedCards'
import CountDown from '../Countdown/CountDown'
import useTranslation from 'next-translate/useTranslation'
import Countdown from '../Countdown/CountDown'
import ClockSvg from '../../public/images/common/Clock.svg';
import ProgressBar from '../ProgressBar'
import { nameShorten } from '../../helpers/functions'
import { MinimizeButton } from '../../components/Buttons/Buttons'
import { useRouter } from 'next/router'

const GameModal = (props) => {
  const {
    modalType, // type of modals like: exhchange, playTwo, joker, steal
    playTwoData, // object which contains : card with which player is stealing, and players from whom player can steal,
    exchangeData,
    jokerData,
    stealData, // object which contains: which player is stealing and which card is stealing
    boardCards,
    isMyTurn,
    closeModal,
    isMobile,
    removeBackdrop,
    forceFinished
  } = props

  const {t} = useTranslation()
  const [modalTitle, setModalTitle] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [openMore, setOpenMore] = useState(false)
  const [positions, setPositions] = useState({})
  const [cardClickCount, setCardClickCount] = useState(0);
  const [stealRequestInProgress, setStealRequestInProgress] = useState(false)

  const handleClickCount = () => {
    setCardClickCount(prev => prev + 1);
  }

  const {locale} = useRouter()
  useEffect(() => {
    switch (modalType) {
      case 'exchange': setModalTitle(t('common:modals.game_page_modals.exchange')); return;
      case 'playTwo': setModalTitle(t('common:modals.game_page_modals.playTwo')); return;
      case 'joker': setModalTitle(t('common:modals.game_page_modals.joker')); return;
      case 'steal': setModalTitle(t('common:modals.game_page_modals.steal')); return;
      case 'boardCards': setModalTitle(t('common:modals.game_page_modals.boardCards')); return;
      case 'alert': setModalTitle(t('common:modals.game_page_modals.alert'));
    }
  }, [modalType, locale])

  const getUnableToStealFromCount = (victimsArray) => {
    if (!victimsArray || victimsArray?.length === 0) {
      return 0
    }
    const number = [...victimsArray.filter(victim => victim.unableToStealFrom)].length
    return number
  }

  const handleStealProcess = async (victim) => {
    if (victim.unableToStealFrom || forceFinished || stealRequestInProgress) {
      return;
    }
    setStealRequestInProgress(true)
    playTwoData.onSetCardPlayAction('steal')
    const requestData = {
      gameId: playTwoData.gameId,
      lobbyId: playTwoData.lobbyId,
      actionCard: { ...playTwoData.actionCard },
      payload: {
        type: "playCard",
        cardId: playTwoData.actionCard.id,
        playAs: "stealing",
        victimId: victim.id
      },
      stealingInProgress: true
    };
    if (!playTwoData.socketId) {
      playTwoData.handleOpenOfflineModalState(true)
      playTwoData.buzzModalHandler(true)
      return
    }
    await playTwoData.onGameCommontCardEvent(requestData);
    playTwoData.gamePublicActionChannel.trigger('client-choosingVictim', {
      stealerId: playTwoData.currentPlayerId,
      victimId: victim.id,
    })
    playTwoData.onSetChosenVictim(victim.id)
    return;
  }

  const handleChooseItem = (victim) => {
    playTwoData.gamePublicActionChannel.trigger('client-choosingVictim', {
      stealerId: playTwoData.currentPlayerId,
      victimId: victim.id
    })
    playTwoData.onSetChosenVictim(victim.id)
  }

  const [minimizeExchange, setMinimizeExchange] = useState(false);

  const minimizeModal = () => {
    if(modalType !== 'joker'){
      removeBackdrop();
    }
    setMinimizeExchange(prev => !prev);

  }

  return (
    <div className={`${styles.modal_holder} ${(modalIsOpen && styles.open)} ${styles[modalType]} ${((isMobile ? styles.mobile : styles.desktop))} ${(minimizeExchange && styles.minimize)} ${(openMore && styles.joker_more)}`}>
      {
        (modalType === 'exchange' || (modalType === 'joker' && !openMore)) && (
          <div className={styles.modal_minimize}>
            <MinimizeButton click={minimizeModal} state = {minimizeExchange}/>
          </div>
        )
      }
      <div className={styles.modal_top}>
        {modalType === 'joker' &&  (
          <div className={styles.modal_symbol}>
            <img className={styles.joker_image} src={`/images/gameModal/joker.png`}/>
          </div>
        )}
        {modalType === 'exchange' && (
          <div className = {styles.modal_exchange}>
            <div className = {styles.exchange_avatar}>
              <Avatar
                onlineStatus = ''
                size = 'medium'
                hasFlag = {false}
                avatarUrls = {exchangeData.currentPlayer.user.avatar}
                color = {exchangeData.currentPlayer.color}
              />
            </div>
            <div className = {styles.exchange_avatar}>
              <Avatar
                onlineStatus = ''
                size = 'medium'
                hasFlag = {false}
                avatarUrls = {exchangeData.playerWithWhomToExchangeCards.user.avatar}
                color = {exchangeData.playerWithWhomToExchangeCards.color}
              />
            </div>
            <div className={styles.exchange_icon}>
              <img src = '/images/gameModal/exchange.svg'/>
            </div>            
          </div>
          
        )}             
      </div>
      <div className={`${styles.modal_header} ${styles[modalType]} ${modalType === 'playTwo' ? styles.header_playTwo : ''}`}>
        {modalType === 'steal' && (
          <>
            <Avatar
              onlineStatus = ''
              size = 'medium'
              mode = 'stealing'
              hasFlag = {false}
              avatarUrls = {stealData?.stealer?.user?.avatar}
              color = {stealData?.stealer?.color}
              hasShadow = {true}
            />
            <div className={`${styles.playTwo_left_title} ${styles.modal_desc}`}>
              <img src="/images/svg/mask.svg"/>
              {nameShorten(stealData?.stealer?.user.username)} {t('common:is_stealing_from_you')}
            </div>
          </>
        )}
        { 
          modalType === 'exchange' && (
          <div className={styles.exchange_title_container}>
            <h3 className={styles.modal_title}>
              {modalTitle}
            </h3>
            {exchangeData.showProgressBar && (
              <div className={styles.exchange_progress}>
                <ProgressBar 
                  totalTime = {exchangeData.totalTime}
                  type= 'html'
                />
              </div>
            )}

          </div>
        )}
        {modalType === 'playTwo' && (
          <div className = {styles.modal_playTwo}>
            {playTwoData.card.code === 'JK' ? (
              <img className={styles.playTwo_image} src={`/images/gameModal/${playTwoData.card.codeAlias}${playTwoData.card.typeAlias}.png`}/>              
            ) : (
              <img className={styles.playTwo_image} src={`/images/gameModal/${playTwoData.card.code}${playTwoData.card.type}.png`}/>
            )}
          </div>
        )}
        { 
          modalType !== 'alert' || modalType !== 'playTwo' && 
          <h3 className={styles.modal_title}>
            {modalTitle}
          </h3>
        }
        {/* {modalType !== 'boardCards' && modalType !== 'alert' && modalType !== 'playTwo' && (
          <button
            type="button"
            className={styles.modal_button}
            onClick={() => setModalIsOpen(modalIsOpen => !modalIsOpen)}>
            <span className={styles.close_button_line}></span>
            <span className={styles.close_button_line}></span>
          </button>        
        )} */}
        {modalType === 'boardCards' && (
          <button
            type="button"
            className={`${styles.modal_button} ${styles.close_modal_button}`}
            onClick={() => closeModal()}
          >
            <span className={styles.close_button_line}></span>
            <span className={styles.close_button_line}></span>
          </button>
        )}
      </div>
      <div className={`${styles.modal_body} ${modalType === 'playTwo' ? styles.body_playTwo_parent : ''}`}>
        {modalType === 'playTwo' && (
          <div className = {styles.body_playTwo}>
            <div className = {styles.playTwo_right}>
              <div className = {styles.playTwo_card_holder}>
                <Card 
                  card = {
                    {
                      ...playTwoData.card,
                      stealModalCard: true,
                      modalCard: true
                    }
                  }
                  positions = {positions}
                  actionCard = {{}}
                />
              </div>
              <div className = {styles.play_two_button_holder}>
                <button
                  onClick = {() => {playTwoData.onSetCardPlayAction('playTwo')}}
                  disabled = {!playTwoData.canPlayTwo}
                >
                  {t(`common:modals.game_page_modals.play_as_two`)}
                </button>
              </div>
            </div> 
            { getUnableToStealFromCount(playTwoData?.victims) !== playTwoData?.victims?.length && 
              <>
                <span>{t('common:or')}</span>
                <div className = {styles.playTwo_left}>
                  <div className={styles.playTwo_left_title}><img src="/images/svg/mask.svg"/>{t('common:steal_from_opponent')}</div>
                  <div className={styles.playTwo_left_avatars}>
                    {playTwoData.victims.length > 0 && playTwoData.victims.map((victim, index) => {
                        if (victim.unableToStealFrom ) {
                          return
                        }
                        return (
                          <div 
                          onClick = {() => {handleStealProcess(victim)}}
                          className = {`${styles.body_playTwo_avatar} ${victim.unableToStealFrom && styles.unable_to_steal_from}`}
                          key = {`victim${index}`}>
                            <Avatar
                              onlineStatus = ''
                              size = 'medium-plus'
                              mode = 'normal'
                              hasFlag = {false}
                              avatarUrls = {victim.user.avatar}
                              color = {victim.color}
                              hasShadow = {true}
                              avatarName = {nameShorten(victim.user.username)}
                            />
                          </div>
                        )
                      })}
                  </div>
                  {/* <div className = {styles.play_two_button_holder}>
                    <button
                      onClick = {() => {playTwoData.onSetCardPlayAction('steal')}}
                      disabled = {!playTwoData.canSteal}
                    >
                      Steal from Opponent
                    </button>
                  </div> */}
                </div>
              </>
            }
          </div>
        )}
        {modalType === 'exchange' && (
          <div className = {styles.body_exchange}>
            <ul className = {styles.body_exchange_cards_ul}>            
              {exchangeData.currentPlayer.cards.map(card => {
                return (
                  <li
                    key = {`exchange${card.id}`}
                    className = {styles.body_exchange_card}
                  >
                    
                    <Card
                      gameId = {exchangeData.gameId}
                      lobbyId = {exchangeData.lobbyId}
                      card = {
                        {
                          ...card,
                          modalCard: true
                        }
                      }
                      positions = {positions}
                      actionCard = {exchangeData.actionCard}
                      id = {exchangeData.currentPlayer.id}
                      socketId = {exchangeData.socketId}
                      onGameCommontCardEvent = {exchangeData.onGameCommontCardEvent}
                      forceFinished = {forceFinished}
                      roundState = {'exchange'}
                      handleClickCount={handleClickCount}
                    />
                  </li>
                )
              })}
              {
                cardClickCount >= 10 && (
                  <div className={styles.blockCardClickOverlay}>
                    <div className = {styles.chat_delay_content}>
                      <span className = {`${styles.text}`}>{t('common:modals.game_search.please_wait')}</span>
                        <ClockSvg className={styles.clock_image} />
                        <span className = {`${styles.text} ${styles.time}`}>
                          <Countdown 
                            callBackFunc = {() => {setCardClickCount(0)}}
                            time = {5}
                            type="html"
                          />   
                        </span>
                    </div>                    
                  </div>         
                )
              }
            </ul>
          </div>
        )}
        {modalType === 'joker' && (
          <div className = {styles.body_playAs}>
            <ul className = {styles.playAs_cards + ' ' + styles.playAs_card_main}>
              {jokerData.cardAliases.slice(0, 6).map((cardAlias, index) => {
                return (
                  <li
                    className = {styles.playAs_card_holder}
                    key = {`alias_${cardAlias.id}${index}`}
                    >
                    <Card 
                      card = {{
                        ...cardAlias,
                        visible: true,
                        modalCard: true
                      }}
                      lobbyId = {jokerData.lobbyId}
                      gameId = {jokerData.gameId}
                      positions = {positions}
                      isAlias = {true}
                      actionCard = {jokerData.actionCard}
                      id = {jokerData.currentPlayer.id}
                      socketId = {jokerData.socketId}
                      onGameCommontCardEvent = {jokerData.onGameCommontCardEvent}
                      forceFinished = {forceFinished}
                      roundState = {'play'}
                      myTurn = {true}
                      cardPlayAction = {'playAs'}
                    />
                  </li>
                )
              })}
              {/* { openMore && ( */}
                
                    {openMore && jokerData.cardAliases.slice(6, 13).map((cardAlias, index) => {
                      return (
                        <li
                          className = {styles.playAs_card_holder}
                          key = {`alias_${cardAlias.id}${index}`}
                          >
                          <Card 
                            card = {{
                              ...cardAlias,
                              visible: true,
                              modalCard: true
                            }}
                            lobbyId = {jokerData.lobbyId}
                            gameId = {jokerData.gameId}
                            positions = {positions}
                            isAlias = {true}
                            actionCard = {jokerData.actionCard}
                            id = {jokerData.currentPlayer.id}
                            socketId = {jokerData.socketId}
                            onGameCommontCardEvent = {jokerData.onGameCommontCardEvent}
                            forceFinished = {forceFinished}
                            roundState = {'play'}
                            myTurn = {true}
                            cardPlayAction = {'playAs'}
                          />
                        </li>
                      )
                    })}
                  {/* </ul> */}
              {/* )} */}
            </ul>
            <div className = {styles.show_secondary_cards}>
              {
                !minimizeExchange && (
                  <button
                    className = {styles.see_more_button + ' ' + (openMore && styles.active)}
                    onClick = {
                      () => {setOpenMore(openMore => !openMore)}
                    }
                  >
                    <span className = {styles.see_more_text}>
                      {!openMore ? t('common:buttons.see_more') : t('common:buttons.see_less')}
                    </span>
                    <span className = {styles.see_more_arrow}>
                      <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 5L6 1L1 5" stroke="#1860A3" strokeLinecap="round"/>
                      </svg>
                    </span>
                  </button>
                )
              }
            </div>
          </div>
        )}
        {modalType === 'steal' && (
          <div className = {styles.body_steal}>
            <ul className = {styles.body_steal_cards_ul}>
              {stealData.currentPlayer.cards.map(card => {
                return (
                  <li
                    key = {`steal${card.id}`}
                    className = {styles.body_steal_cards_li}
                   >
                    <Card
                      gameId = {stealData.gameId}
                      lobbyId = {stealData.lobbyId}
                      card = {
                        {
                          ...card,
                          modalCard: true
                        }
                      }
                      positions = {positions}
                      gamePublicActionChannel = {stealData.gamePublicActionChannel}
                      actionCard = {stealData.actionCard}
                      id = {stealData.currentPlayer.id}
                      socketId = {stealData.socketId}
                      onGameCommontCardEvent = {stealData.onGameCommontCardEvent}
                      forceFinished = {forceFinished}
                      roundState = {'play'}
                      isStealingMode = {true}
                      isVictim = {true}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {modalType === 'boardCards' && (
          <div className = {styles.body_boardCards}>
            <ul className = {styles.body_boardCards_ul}>
              {boardCards.map((element,index) => {
                return (
                  <li
                    key = {`boardModalCard${index}`}
                    className = {styles.body_board_cards_li}
                  >
                    {
                      element.type === 'dropCards' ? (
                        <DroppedCards droppedCards = {element.cards} />
                      ) : (
                        <div className = {styles.body_board_card_holder}>
                          <Card
                            card = {{
                              ...element.card,
                              isBoardModuleCard: true,
                              isAlias: element.card.codeAlias ? true : false,
                              modalCard: true
                            }}
                            actionCard = {{}}
                          />
                        </div>
                      )
                    }
                      <div className = {styles.board_cards_user_avatar}>
                        <Avatar 
                          onlineStatus = ''
                          size = 'small'
                          mode = 'normal'
                          hasFlag = {false}
                          avatarUrls = {element.player.user.avatar}
                          color = {element.player.color}
                        />
                      </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {modalType === 'alert' && (
          <div className={`${styles.body_alert} ${isMyTurn ? styles.myTurn : ''}`}>
            <img src="/images/gameModal/alert.svg"/>
            <h3 className={styles.body_alert_title}>
              {modalTitle}
            </h3>
            {
              isMyTurn ? (
              <button
                className = {styles.alert_button}
                onClick = {
                  () => {closeModal()}
                }
              >{t('common:buttons.try_again')}</button>
              )
              : (
                <p className={styles.alert_countdown_text}>
                  <CountDown time="5" type="html" callBackFunc={closeModal}/>
                </p>
              )
            }
          </div>
        )}
      </div>
      {props.children}
    </div>
  )
}

// const areEqual = (prevProps, nextProps) => {
//   if (prevProps === nextProps) {
//     return true
//   }
//   return false
// }

export default GameModal
// export default React.memo(GameModal, areEqual)