
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Loader from "../../../../../components/CustomLoader/Loader"

const SyncGameLoader = () => {
  const style = {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    left: "0",
    top: "0",
    background: "rgba(255,255,255,0.64)",
    zIndex: "99999",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return (
    <div style={style}>
      <div style={{
        borderRadius: '50%',
        backgroundColor: '#fff'
      }}>
        <Loader 
          loaderSize={'size60'}
          background = {'transparent'}
        />
      </div>
    </div>
  )
}

export default SyncGameLoader

    export async function getServerSideProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/lobby/[lobbyId]/game/[gameId]/SyncGameLoader',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  