import * as actionTypes from './backdropStateActionTypes';

const initialState = {
  isOpen: false,
}

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.TOGGLE_BACKDROP_STATE:
        return {
          isOpen: action.payload.state
        };
      default:
        return state;
    }
}

export default modalsReducer;