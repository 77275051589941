import React from 'react';

export const Red = () => {
    return(
        <>
            <radialGradient id="red_1_" cx="4370.0122" cy="-11893.2686" r="285.9045" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.5495 -490.8005)" gradientUnits="userSpaceOnUse">
                <stop  offset="0.6894" stopColor="#BA0000"/>
                <stop  offset="0.986" stopColor="#940000"/>
            </radialGradient>
            <radialGradient id="red_2_" cx="7.6714" cy="8.517" r="8.0821" gradientUnits="userSpaceOnUse">
                <stop  offset="3.724167e-03" stopColor="#FF0000"/>
                <stop  offset="1" stopColor="#FF0000" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="red_3_" cx="-18315.7754" cy="10465.5947" r="312.757" gradientTransform="matrix(-1.971628e-02 3.178553e-02 -2.598532e-02 -1.605576e-02 -78.6115 761.9109)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" stopColor="#D50422" stopOpacity="0.8"/>
                <stop  offset="0.3858" stopColor="#C80316" stopOpacity="0.4652"/>
                <stop  offset="0.9218" stopColor="#AE0000" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="red_4_" cx="-23510.0918" cy="-7332.314" r="146.3696" gradientTransform="matrix(-2.050229e-02 4.156532e-02 -3.164666e-02 -1.560986e-02 -706.1443 872.9568)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" stopColor="#FF0106" stopOpacity="0.2"/>
                <stop  offset="3.204195e-02" stopColor="#FF101C" stopOpacity="0.2"/>
                <stop  offset="0.1251" stopColor="#FF3958" stopOpacity="0.2"/>
                <stop  offset="0.2179" stopColor="#FF5C8B" stopOpacity="0.2"/>
                <stop  offset="0.3082" stopColor="#FF79B4" stopOpacity="0.2"/>
                <stop  offset="0.3957" stopColor="#FF8FD5" stopOpacity="0.2"/>
                <stop  offset="0.4794" stopColor="#FF9FEC" stopOpacity="0.2"/>
                <stop  offset="0.5579" stopColor="#FFA9FA" stopOpacity="0.2"/>
                <stop  offset="0.6257" stopColor="#FFACFF" stopOpacity="0.2"/>
                <stop  offset="0.6425" stopColor="#FFA3F2" stopOpacity="0.2"/>
                <stop  offset="0.674" stopColor="#FF8ACE" stopOpacity="0.2"/>
                <stop  offset="0.7168" stopColor="#FF6394" stopOpacity="0.2"/>
                <stop  offset="0.7686" stopColor="#FF2D46" stopOpacity="0.2"/>
                <stop  offset="0.8077" stopColor="#FF0106" stopOpacity="0.2"/>
            </radialGradient>
            <linearGradient id="red_5_" gradientUnits="userSpaceOnUse" x1="689.6495" y1="-686.7079" x2="712.9183" y2="-686.7079" gradientTransform="matrix(0.4408 -0.8976 0.8976 0.4408 326.6475 948.2009)">
                <stop  offset="0" stopColor="#6F0101" stopOpacity="0.8"/>
                <stop  offset="0.3371" stopColor="#8C0101"/>
                <stop  offset="0.6965" stopColor="#6E0000" stopOpacity="0.8"/>
                <stop  offset="0.9944" stopColor="#A3080C" stopOpacity="0.8"/>
            </linearGradient>
            <linearGradient id="red_6_" gradientUnits="userSpaceOnUse" x1="7.619599e-03" y1="13.0339" x2="25.9724" y2="13.0339">
                <stop  offset="0" stopColor="#C9261D"/>
                <stop  offset="0.4967" stopColor="#C7251C"/>
                <stop  offset="0.6756" stopColor="#C0221A"/>
                <stop  offset="0.8031" stopColor="#B51D17"/>
                <stop  offset="0.9055" stopColor="#A41612"/>
                <stop  offset="0.9928" stopColor="#8E0C0C"/>
                <stop  offset="1" stopColor="#8C0B0B"/>
            </linearGradient>
        </>
    )
}
export const Blue = () => {
    return(
        <>
            <radialGradient id="blue_1_" cx="4370.3022" cy="-11892.7715" r="285.8708" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.5018 -490.7835)" gradientUnits="userSpaceOnUse">
                <stop  offset="0.4287" stopColor="#1F3FDB"/>
                <stop  offset="0.9898" stopColor="#0B2096"/>
            </radialGradient>
            <radialGradient id="blue_2_" cx="7.6452" cy="8.4635" r="8.0812" gradientUnits="userSpaceOnUse">
                <stop  offset="3.723816e-03" stopColor="#034FFF"/>
                <stop  offset="0.6526" stopColor="#033AE6" stopOpacity="0.3487"/>
                <stop  offset="1" stopColor="#032DD7" stopOpacity="0"/>
            </radialGradient>
                <radialGradient id="blue_3_" cx="-18317.9473" cy="10465.4502" r="312.7202" gradientTransform="matrix(-1.971628e-02 3.178553e-02 -2.598532e-02 -1.605576e-02 -78.6333 761.8997)" gradientUnits="userSpaceOnUse">
                <stop  offset="3.724391e-03" stopColor="#3246DB"/>
                <stop  offset="0.3226" stopColor="#253FD9" stopOpacity="0.6526"/>
                <stop  offset="0.9168" stopColor="#012DD2" stopOpacity="5.424559e-03"/>
                <stop  offset="0.9218" stopColor="#012DD2" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="blue_4_" cx="-23510.6074" cy="-7333.4829" r="146.3524" gradientTransform="matrix(-2.050229e-02 4.156532e-02 -3.164666e-02 -1.560986e-02 -706.1668 872.9063)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" stopColor="#0008AB" stopOpacity="0.2"/>
                <stop  offset="1.752638e-02" stopColor="#000AAD" stopOpacity="0.2"/>
                <stop  offset="0.2383" stopColor="#021DC4" stopOpacity="0.2"/>
                <stop  offset="0.4459" stopColor="#0329D2" stopOpacity="0.2"/>
                <stop  offset="0.6257" stopColor="#032DD7" stopOpacity="0.2"/>
                <stop  offset="0.6869" stopColor="#0322CA" stopOpacity="0.2"/>
                <stop  offset="0.8009" stopColor="#0406A6" stopOpacity="0.2"/>
                <stop  offset="0.8077" stopColor="#0404A4" stopOpacity="0.2"/>
            </radialGradient>
            <linearGradient id="blue_5_" gradientUnits="userSpaceOnUse" x1="689.7101" y1="-686.7106" x2="712.9761" y2="-686.7106" gradientTransform="matrix(0.4408 -0.8976 0.8976 0.4408 326.6475 948.2009)">
                <stop  offset="0" stopColor="#040378"/>
                <stop  offset="0.3371" stopColor="#010EA8"/>
                <stop  offset="0.6965" stopColor="#010280"/>
                <stop  offset="0.9944" stopColor="#1725C3"/>
            </linearGradient>
        </>
    )
}
export const Yellow = () => {
    return(
        <>
            <radialGradient id="yellow_1_" cx="4370.0552" cy="-11893.0928" r="286.1882" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.537 -490.7911)" gradientUnits="userSpaceOnUse">
                <stop  offset="0.4063" stopColor="#F8D400"/>
                <stop  offset="0.9898" stopColor="#DBA927"/>
            </radialGradient>
            <radialGradient id="yellow_2_" cx="7.6761" cy="8.4585" r="8.0901" gradientUnits="userSpaceOnUse">
                <stop  offset="3.723933e-03" stopColor="#F4D20F"/>
                <stop  offset="1" stopColor="#F5D10D" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="yellow_3_" cx="-18328.5879" cy="10453.0889" r="313.0674" gradientTransform="matrix(-1.971628e-02 3.178553e-02 -2.598532e-02 -1.605576e-02 -78.5859 761.7921)" gradientUnits="userSpaceOnUse">
                <stop  offset="3.724157e-03" stopColor="#F6D300"/>
                <stop  offset="0.9218" stopColor="#F6D300" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="yellow_4_" cx="-23514.9316" cy="-7336.6987" r="146.5149" gradientTransform="matrix(-2.050229e-02 4.156532e-02 -3.164666e-02 -1.560986e-02 -706.3773 872.7869)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" stopColor="#ECC802" stopOpacity="0.2"/>
                <stop  offset="0.2338" stopColor="#F1CD08" stopOpacity="0.2"/>
                <stop  offset="0.6257" stopColor="#F5D10D" stopOpacity="0.2"/>
                <stop  offset="0.7387" stopColor="#E9C406" stopOpacity="0.2"/>
                <stop  offset="0.8077" stopColor="#DFB900" stopOpacity="0.2"/>
            </radialGradient>
            <linearGradient id="yellow_5_" gradientUnits="userSpaceOnUse" x1="689.9116" y1="-686.8241" x2="713.2035" y2="-686.8241" gradientTransform="matrix(0.4408 -0.8976 0.8976 0.4408 326.6475 948.2009)">
                <stop  offset="0" stopColor="#D2A903"/>
                <stop  offset="1" stopColor="#E5CB01"/>
            </linearGradient>
        </>
    )
}
export const Green = () => {
    return(
        <>
            <radialGradient id="green_1_" cx="4370.0552" cy="-11893.0928" r="286.1882" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.537 -490.7911)" gradientUnits="userSpaceOnUse">
                <stop  offset="0.4771" stopColor="#159B1F"/>
                <stop  offset="0.7011" stopColor="#0E8019"/>
                <stop  offset="0.9898" stopColor="#066212"/>
            </radialGradient>
                <radialGradient id="green_2_" cx="7.6761" cy="8.4585" r="8.0901" gradientUnits="userSpaceOnUse">
                <stop  offset="3.724167e-03" stopColor="#23AD30"/>
                <stop  offset="1" stopColor="#24AA2F" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="green_3_" cx="-18317.8574" cy="10465.8662" r="313.0674" gradientTransform="matrix(-1.971628e-02 3.178553e-02 -2.598532e-02 -1.605576e-02 -78.6379 761.9019)" gradientUnits="userSpaceOnUse">
                <stop  offset="3.724391e-03" stopColor="#21AC2F"/>
                <stop  offset="0.2509" stopColor="#1C9F29" stopOpacity="0.7308"/>
                <stop  offset="0.711" stopColor="#0F7B1A" stopOpacity="0.2296"/>
                <stop  offset="0.9218" stopColor="#086912" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="green_4_" cx="-23510.9434" cy="-7333.2021" r="146.5149" gradientTransform="matrix(-2.050229e-02 4.156532e-02 -3.164666e-02 -1.560986e-02 -706.1849 872.9216)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" stopColor="#119018" stopOpacity="0.2"/>
                <stop  offset="0.1266" stopColor="#179920" stopOpacity="0.2"/>
                <stop  offset="0.3939" stopColor="#20A72C" stopOpacity="0.2"/>
                <stop  offset="0.6257" stopColor="#23AC30" stopOpacity="0.2"/>
                <stop  offset="0.7225" stopColor="#1A9F24" stopOpacity="0.2"/>
                <stop  offset="0.8077" stopColor="#108F17" stopOpacity="0.2"/>
            </radialGradient>
                <linearGradient id="green_5_" gradientUnits="userSpaceOnUse" x1="689.6909" y1="-686.7158" x2="712.9828" y2="-686.7158" gradientTransform="matrix(0.4408 -0.8976 0.8976 0.4408 326.6475 948.2009)">
                <stop  offset="0" stopColor="#00480F"/>
                <stop  offset="0.3371" stopColor="#096E13"/>
                <stop  offset="0.6965" stopColor="#055C11"/>
                <stop  offset="0.9944" stopColor="#0B7614"/>
            </linearGradient>
        </>
    )
}

export default function MarblesColor({color}){
    switch(color){
        case 'red' :
            return <Red />;
        case 'blue' :
            return <Blue />;
        case 'green' :
            return <Green />;
        case 'yellow' :
            return <Yellow />;
    }
}