import * as actionTypes from './staticInfoActionTypes'
import api from '../../axios/api';

const getCountriesListRequest = () => {
  return {
    type: actionTypes.GET_COUNTRIES_LIST_REQUEST,
  }
}

const getCountriesListSuccess = (countriesList) => {
  return {
    type: actionTypes.GET_COUNTRIES_LIST_SUCCESS,
    payload: {
      countriesList
    }
  }
}

const getCountriesListFail = (error) => {
  return {
    type: actionTypes.GET_COUNTRIES_LIST_FAIL,
    payload: error
  }
}

export const getCountriesList = (requestData) => {
  return async (dispatch) => {
    dispatch(getCountriesListRequest());
    api.get(`/static/countries`, {
      params: {
        name: requestData && requestData.countryName !== 'all' ? requestData.countryName: '' 
      }
    }).then(res => {
      dispatch(getCountriesListSuccess(res.data));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getCountriesListFail(e))
    })
  }
}


const getCitiesListRequest = () => {
  return {
    type: actionTypes.GET_CITIES_LIST_REQUEST,
  }
}

const getCitiesListSuccess = (citiesList) => {
  return {
    type: actionTypes.GET_CITIES_LIST_SUCCESS,
    payload: {
      citiesList
    }
  }
}

const getCitiesListFail = (error) => {
  return {
    type: actionTypes.GET_CITIES_LIST_FAIL,
    payload: error
  }
}

export const getCitiesList = (payload) => {
  return async (dispatch) => {
    dispatch(getCitiesListRequest());
    api.get(`/static/cities`, {
      params: {
        stateCode: payload.stateCode,
        countryCode: payload.countryCode,
        name: payload.name
      }
    }).then(res => {
      dispatch(getCitiesListSuccess(res.data));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getCitiesListFail(e))
    })
  }
}


const getStatesListRequest = () => {
  return {
    type: actionTypes.GET_STATES_LIST_REQUEST,
  }
}

const getStatesListSuccess = (statesList) => {
  return {
    type: actionTypes.GET_STATES_LIST_SUCCESS,
    payload: {
      statesList
    }
  }
}

const getStatesListFail = (error) => {
  return {
    type: actionTypes.GET_STATES_LIST_FAIL,
    payload: error
  }
}

export const getStatesList = (payload) => {
  return async (dispatch) => {
    dispatch(getStatesListRequest());
    api.get(`/static/states`, {
      params: {
        countryCode: payload.countryCode
      }
    }).then(res => {
      dispatch(getStatesListSuccess(res.data));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getStatesListFail(e))
    })
  }
}


export const setColaborators = (appVersionUpdate) => {
  return {
    type: actionTypes.SET_COLABORATORS,
    payload: {
      appVersionUpdate
    }
  }
}


const getVersionRequest = () => {
  return {
    type: actionTypes.GET_VERSION_REQUEST,
  }
}

const getVersionSuccess = (version) => {
  return {
    type: actionTypes.GET_VERSION_SUCCESS,
    payload: {
      version
    }
  }
}

const getVersionFail = (error) => {
  return {
    type: actionTypes.GET_VERSION_FAIL,
    payload: error
  }
}

export const getVersion = () => {
  return async (dispatch) => {
    dispatch(getVersionRequest());
    api.get(`/static/current-version`).then(res => {
      dispatch(getVersionSuccess(res.data?.version || ''));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getVersionFail(e))
    })
  }
}

const getVersionInfoRequest = () => {
  return {
    type: actionTypes.GET_VERSION_INFO_REQUEST,
  }
}

const getVersionInfoSuccess = (versionInfo) => {
  return {
    type: actionTypes.GET_VERSION_INFO_SUCCESS,
    payload: {
      versionInfo
    }
  }
}

const getVersionInfoFail = (error) => {
  return {
    type: actionTypes.GET_VERSION_INFO_FAIL,
    payload: error
  }
}

export const getVersionInfo = () => {
  return async (dispatch) => {
    dispatch(getVersionInfoRequest());
    api.get(`/static/versions`).then(res => {
      dispatch(getVersionInfoSuccess(res.data || null));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getVersionInfoFail(e))
    })
  }
}

export const handleVersionOpen = (versionIndex) => {
  return {
    type: actionTypes.HANDLE_VERSION_OPEN,
    payload: {
      versionIndex
    }
  }
}

export const sendStatsToBackend = ({data, type}) => {
  return async () => {
    try{
      api.post(`/stat/collect`, {
        "type": type,
        "data": data
      },{
        headers: {hideErrorPage: true}
      })
    } catch(e) {
      console.log(e);
    }
  }
}