import React, { useEffect, useLayoutEffect } from 'react';
import * as styles from '../../../styles/scss/components/Notifications/NotificationsSidebar.module.scss';
import Notification from '../NotificationTypes/NotificationTypes';
import { sendAccept, sendDecline } from '../../../store/friends/friendActions';
import {connect} from 'react-redux';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import { setUserForProfileSidebar } from '../../../store/modals/modalsActions';
import { getNotifications } from '../../../store/notifications/notificationActions';
import { deleteNotifications } from '../../../store/notifications/notificationActions';
import { getPagIndex } from '../../../store/notifications/notificationActions';
import { markNotificationAsRead, changeGameRequestNotificationMessage } from '../../../store/notifications/notificationActions';
import {deviceType} from '../../../helpers/functions'

const NotificationsSidebar = (props) => {
  const {
    notifications,
    onFriendRequestAccept,
    onFriendRequestDecline,
    onToggleSidebar,
    onSetUserForProfileSidebar,
    onMarkNotificationAsRead,
    onChangeGameRequestNotificationMessage,
    onGetNotifications,
    // onDeleteNotifications,
    pagIndex,
    onGetPagIndex
  } = props
  
  // useLayoutEffect(() => {
  //   onDeleteNotifications()
  // }, [])

  useEffect(() => {
    console.log('pagIndex', pagIndex)
  }, [pagIndex])

  // for pagination
  const listInnerRef = React.useRef()
  const [currPage, setCurrPage] = React.useState(0); // storing current page number
  const [prevPage, setPrevPage] = React.useState(0); // storing prev page number
  const [wasLastList, setWasLastList] = React.useState(false); // setting a flag to know the last list

  useEffect(() => {
    setCurrPage(pagIndex)
    setPrevPage(pagIndex)
  }, [])

  const getNotificationInformation = (notification) => {
    const data = {
      ...notification
    }
    switch (notification.type) {
      case 'FriendshipRequested' : {
        data.accept = () => {onFriendRequestAccept(notification.friendship.id, notification.id) },
        data.decline = () => { onFriendRequestDecline(notification.friendship.id, notification.id)}
  
      }; break;
      case 'GameRequested': {
        data.accept = onChangeGameRequestNotificationMessage
      }; break;
      case 'MatchReady': {
        data.accept = onChangeGameRequestNotificationMessage
      }; break;
    }
    return data
  }

  const handleUserProfileOpen = (user) => {
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'profile',
      hasBackArrow: true,
      customTitle: 'profile',
      showSidebarCloseButton: false,      
      hasTitle: true,
      callBackSidebarFunction: () => {
        onToggleSidebar({
          isOpen: true,
          sidebarType: 'notification',
          hasBackArrow: false,
          showSidebarCloseButton: true,
          hasTitle: true,
          customTitle: 'notifications'
        })
      }
    })
    onSetUserForProfileSidebar(user)
  }

  const handleScroll = () => {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setCurrPage(currPage + 1);
          onGetPagIndex({pagIndex: currPage + 1})
        }
      }
  };

  React.useEffect(() => {
      const fetchData = () => {
        let perPage = 10;
        const isTallerThanMobile = window.matchMedia("(min-height: 992px)").matches
        isTallerThanMobile ? perPage = 20 : perPage = 10
        onGetNotifications(currPage, perPage)
        if(!notifications){
          setWasLastList(true)
          return
        }
        setPrevPage(currPage);
      }
  
      if (!wasLastList && prevPage !== currPage) {
        fetchData();
      }
  }, [currPage, wasLastList, prevPage, notifications]);

  if(notifications.length === 0){
    return (
      <div></div>
    )
  } 
  else {
  return (
    <div className = {styles.notifications}>
      <ul className = {styles.notifications_ul} onScroll={handleScroll} ref={listInnerRef}>
        {notifications.length && notifications.map(notification => {
          return (
            <li
            key={notification.id}
            className = {styles.notifications_li}
            onClick = {() => {
                if (!notification.readAt) {
                  onMarkNotificationAsRead({
                    all: false,
                    notificationId: notification.id
                  })}  
                }
              }
              >
              <Notification
                data = {getNotificationInformation(notification)}
                onFriendRequestAccept = {() => { onFriendRequestAccept(notification.friendship.id) }}
                handleUserProfileOpen = {handleUserProfileOpen}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications.notifications,
    pagIndex: state.notifications.pagIndex
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFriendRequestAccept: (friendshipId, notificationUuid) => dispatch(sendAccept(friendshipId, notificationUuid)),
    onFriendRequestDecline: (friendshipId, notificationUuid) => dispatch(sendDecline(friendshipId, notificationUuid)),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    onMarkNotificationAsRead: (payload) => dispatch(markNotificationAsRead(payload)),
    onChangeGameRequestNotificationMessage: (payload) => dispatch(changeGameRequestNotificationMessage(payload)),
    onGetNotifications: (page, perPage) => dispatch(getNotifications(page, perPage)),
    // onDeleteNotifications: () => dispatch(deleteNotifications()),
    onGetPagIndex: (data) => dispatch(getPagIndex(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSidebar);
