import * as actionTypes from './pusherActionTypes';
import Pusher from 'pusher-js';
import config from '../../config/pusher.config';

const initialState = {
  pusher: null,
  isLoading: false,
  error: null,
  socketId: null,
  blockedBySocket: true,
  instantated: false
}

const pusherReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.INSTANTIATE_PUSHER:
        localStorage.removeItem('socketId')
        if(!localStorage.getItem('access_token')) {
          return state;
        }
        // const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, config)
        // window.pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, config)

        return {
          ...state,
          // pusher: pusher
        };
      case actionTypes.SET_SOCKET_ID:
        return {
          ...state,
          socketId: action.payload.socketId
        }
      case actionTypes.SET_BLOCKED_BY_SOCKET:
        return {
          ...state,
          blockedBySocket: action.payload.state
        }
      default:
        return state;
    }
}

export default pusherReducer;