import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import ShopSvg from '../../public/images/common/shop_outline.svg';
import * as styles from '../../styles/scss/components/Header/Header.module.scss';
import { Dropdown } from '../FormInput/FormInput';
import { useEffect, useState, useRef } from 'react';
import {SetLanguagesOnPage} from '../../store/languages/languagesActions';
import { useRouter } from 'next/router';
import { getRouterQuery } from '../../helpers/GetQueryParams';
import useTranslation from 'next-translate/useTranslation';
import { getUrl } from '../../helpers/navigation';
const UnAuthorizedHamburger = (props) => {
  const {
    openSidebar,
    closePopUp
  } = props
  const router = useRouter()
  const {t} = useTranslation()
  const aboutPagePathes = {
    en: '/about-braendi-foundation',
    de: '/ueber-braendi-stiftung',
    fr: '/a-propos-fondation-braendi',
    es: '/sobre-fundacion-braendi',
    it: '/sulla-fondazione-braendi',
    ru: '/o-fonde-brandi'
  }
  const langs = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    ru: 'Русский'
  }
  const dispatch = useDispatch()
  const [languages, setLanguages] = useState([]);
  const currentLang = useSelector(state => (state.languages.currentLang))

  const refDropdown = useRef(null)
  const refUnAuthorizedHeaderUl = useRef(null)

  const changeLanguage = (lang) => {
    dispatch(SetLanguagesOnPage(lang.id))
    if (router.pathname != '/') {
      router.push(aboutPagePathes[lang.id], {query: getRouterQuery(router)}, {locale: lang.id});
    } else {
      router.push('/', {query: getRouterQuery(router)}, {locale: lang.id})
    }
    closePopUp()
  }
  useEffect(() => {
    // dispatch(SetLanguagesOnPage('de'))
    // const languages = Object.keys(langs).map(lang => {
    //   return {
    //     value: langs[lang],
    //     id: lang,
    //   }
    // })
    const languages = Object.keys(langs).filter(lang => lang == 'en' || lang == 'de' || lang === 'es' || lang === 'fr').map(lang => {
      return {
        value: langs[lang],
        id: lang,
      }
    })
    setLanguages(languages)
  }, [])

  return (
    <div className={styles.unAuthorized_header_container}>
      <ul ref={refUnAuthorizedHeaderUl} className={styles.unAuthorized_header_ul} style={{overflowY: 'scroll'}}>
        <li className={styles.navigation_li}>
          <Link locale={router.locale} href={{pathname: getUrl(router.locale, 'how-to-play'), query: getRouterQuery(router)}}>
           <a> 
             <span onClick = {closePopUp}>{t('navigation:how_to_play')}
            </span>
          </a>
          </Link>
        </li>
        <li className={styles.navigation_li}>
          <Link locale={router.locale} href={{pathname: aboutPagePathes[currentLang] ?? '/ueber-braendi-stiftung', query: getRouterQuery(router)}}>
           <a> <span onClick = {closePopUp}>{t('navigation:about_braendi')}
            </span></a>
          </Link>
        </li>
        <li className={styles.navigation_li}>
          <Link locale={router.locale} href={{pathname: 'privacy', query: getRouterQuery(router)}}>
           <a> <span onClick = {closePopUp}>{t('navigation:privacy')}
            </span></a>
          </Link>
        </li>
        <li className={`${styles.navigation_li} ${styles.shop_link}`} 
          onClick={() => {
            openSidebar({
              sidebarType: 'barendiShop',
              isOpen: true,
              hasTitle: true,
              showSidebarCloseButton: true
            })
            closePopUp()
          }}>
          <ShopSvg />
          <span>{t('common:sidebar_titles.braendi_shop')}</span>
        </li>
        <li className={`${styles.navigation_li}`} 
        onClick={() => {
          setTimeout(() => {
            if(refDropdown && refUnAuthorizedHeaderUl){
              if(refDropdown.current && refUnAuthorizedHeaderUl.current){
                const refDropdownY = refDropdown.current.getBoundingClientRect().y
                refUnAuthorizedHeaderUl.current.scrollTo({top: refDropdownY, behavior: 'smooth'})
              }
            }
          }, [200])
        }} ref={refDropdown}>
            <div className={styles.languages_container}>
              <Dropdown
                list={languages}
                change={(lang) => { changeLanguage(lang) }}
                absolute={true}
                label={''}
                value={langs[currentLang]}
                small={true}
                autoHeight={true}
              />
            </div>
          </li>
      </ul>
      <div className = {styles.buttons_container}>
        <div className={`${styles.button_container} ${styles.log_in_container}`}>
          <button 
            onClick = {() => {
              openSidebar({
                isOpen: true,
                sidebarType: 'logIn',
                hasTitle: true,
                customTitle: t('common:buttons.login_button'),
                showSidebarCloseButton: true
              })
              closePopUp()
            }}
            >{t('common:buttons.login_button')}</button>
        </div>
        <div className={`${styles.button_container} ${styles.sign_up_container}`}>
          <button
            onClick = {() => {
              openSidebar({
                isOpen: true,
                sidebarType: 'signUp',
                hasTitle: true,
                customTitle: t('common:buttons.login_button'),
                showSidebarCloseButton: true
              })
              closePopUp()
            }}>{t('common:buttons.signup_button')}</button>
        </div>
      </div>
    </div>
  )
}

export default UnAuthorizedHamburger