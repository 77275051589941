import { connect } from 'react-redux';
import { EditGameName } from '../PopUpModal/PopUpModal';
import * as styles from '../../styles/scss/components/PopUpModal/PopUpModal.module.scss';

const EditTeamSidebar = ({sidebarData}) => {
  return (
    <div className = {styles.sidebar_edit}>
      <EditGameName 
        saveFunction = {sidebarData.saveEditFunction}
        oldName = {sidebarData.gameName}
      />
    </div>
  )
}


const mapStateToProps = state => {
  return {
    sidebarData: state.modals.sidebarData
  }
}

export default connect(mapStateToProps, null)(EditTeamSidebar)
