import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import FormInput from '../../FormInput/FormInput';
import { SocialButton } from '../../SocialButtons/SocialButtons';
import { registerWithSocial } from '../../../store/auth/authActions';
import * as styles from '../../../styles/scss/components/SocialRedirect/SocialRedirect.module.scss';
import api from '../../../axios/api';

const SocialRedirect = ({formData, onRegisterWithSocial}) => {

  const [username, setUsername] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^[a-zA-Z0-9_]{2,}$/,
    changed: false
  })
  const usernameRef = useRef();

  const [email, setEmail] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    changed: false
  })
  const emailRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (username.changed) {
      
        if (username.value === usernameRef.current.value) {
          const valid = username.regEx.test(username.value);
          if (valid) {
            if (usernameRef.current.value.length >= 2) {
              // onSearchPeople({
              //   keyword: inputRef.current.value
              // })
              api.post('/check-availability', {
                username: usernameRef.current.value,
                // type: 'username'
              }).then(response => {
                setUsername(prevState => {
                  return {
                    ...prevState,
                    valid: true,
                    errorText: '',
                    changed: true
                  }
                })
              }).catch(() => {
                setUsername(prevState => {
                  return {
                    ...prevState,
                    valid: false,
                    errorText: 'username already taken',
                    changed: true
                  }
                })
              })
            } 
          } else {
            setUsername(prevState => {
              return {
                ...prevState,
                valid: false,
                errorText: 'Username can contain only Letters, numbers and underscores, minimum 2 characters',
                changed: true
              }
            })
          }
        }
      
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [username.value, username.changed ,usernameRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email.changed) {
        
        if (email.value === emailRef.current.value) {
          const valid = email.regEx.test(email.value);
          if (valid) {
            if (emailRef.current.value.length >= 2) {
              api.post('/check-availability', {
                email: emailRef.current.value,
                // type: 'username'
              }).then(response => {
                setEmail(prevState => {
                  return {
                    ...prevState,
                    valid: true,
                    errorText: '',
                    changed: true
                  }
                })
              }).catch(() => {
                setEmail(prevState => {
                  return {
                    ...prevState,
                    valid: false,
                    errorText: 'Email already taken',
                    changed: true
                  }
                })
              })
            }
          } else {
            setEmail(prevState => {
              return {
                ...prevState,
                valid: false,
                errorText: 'Invalid Email',
                changed: true
              }
            })
          }
        }
        
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [email.value, email.changed, emailRef]);

  const handleChange = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case 'username': {
        setUsername(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }; break;
      case 'email': {
        setEmail(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }; break;
      case 'password': {
        setPassword(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }
    }
  }

  const handleFinishRegister = (e) => {
    const newFormData = {
      username: formData.username ? formData.username : username.value,
      email: formData.email ? formData.email: email.value,
      accountId: formData.accountId,
      fullName: formData.fullName
    }
    e.preventDefault();
    onRegisterWithSocial(formData.provider, newFormData);
  }

  return(
    <div className = {styles.social_redirect}>
      <div className = {styles.social_data}>
        <div className = {styles.social_network_container}>
          <SocialButton socialNetworkName = {formData.provider}/>
        </div>
        <h3 className = {styles.social_text}>
          Please provide information
        </h3>
      </div>
      {!formData.username && (
        <div className = {styles.form_group}>
          <FormInput
            reference = {usernameRef}
            type = 'text'
            value = {username.value}
            name = 'username'
            change = {handleChange}
            active = {username.value.length > 0}
            labelText = 'Username'
            showIndicator = {true}
            id = 'signupUsername'
            valid = {username.valid}
            errorText = {username.errorText}
            changed = {username.changed}
            // showIcon = {true}
          />
        </div>      
      )}
      {!formData.email && (
        <div className = {styles.form_group}>
          <FormInput
            reference = {emailRef}
            type = 'email'
            value = {email.value}
            name = 'email'
            change = {handleChange}
            active = {email.value.length > 0}
            labelText = 'Email'
            showIndicator = {true}
            id = 'signupEmail'
            valid = {email.valid}
            errorText = {email.errorText}
            changed = {email.changed}
            // showIcon = {true}
          />
        </div>
      )}
      <div className = {styles.confirm_social_reg}>
        <button onClick = {handleFinishRegister}>confirm</button>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    formData: state.modals.sidebarData,
    signUpSuccessfull: state.auth.signUpSuccessfull,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginSuccess: (user) => dispatch(loginSuccess(user)),
    onRegisterWithSocial: (provider, payload) => dispatch(registerWithSocial(provider, payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialRedirect)
