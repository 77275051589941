import * as actionTypes from './gameDataActionTypes';
import { PlayerTime } from '../../config/api.config';
const boardToggle = () => {
  if(typeof(Storage) !== "undefined") {
    if(localStorage.boardToggle){
      return localStorage.boardToggle;
    }else{
      return false;
    }
  }
}

const initialState = {
  isLoading: true,
  // currentPlayerCards: null,
  players: null,
  marbles: null,
  rules: {},
  mode: null,
  type: null,
  vs: null,
  status: null,
  chatId: null,
  isGameDataLoaded: false,
  currentPlayer: null,
  boardCards: [],
  isPlayingJoker: false,
  actionCard: null,
  isPlayingTwo: false,
  roundState: null,
  cardPlayAction: '',
  playersToStealFrom: [],
  isStealingMode: false,
  roundStarterObj: null,
  victims: null,
  playerWithWhomToExchangeCards: null,
  marblesData: [],
  moves: [],
  lastMarblePlayed: [],
  replaySeven: false,
  onMarbleMove : {},
  onKillMarble : [],
  onSwapMarble: {},
  stealer: null,
  recentActions: [],
  playersApproved: [],
  boardRotate:  boardToggle(),
  boardCardsMaxNumber: 4,
  playerIsInTheGame: true,
  gameFinished : false,
  colors: ['red', 'green', 'blue', 'yellow'],
  playersScoreboard: null,
  choosingVictim: false,
  timesLeft: [],
  forceFinished: false,
  leaver: null,
  showSevenSteps: false,
  preventCardClickWhilePlayingJoker: false
}

const setCardPlayAs  = (card) => {
  if (!card.visible) {
    return card
  }
  const newCard = {...card}
  if (newCard.code === '2') {
    newCard.playAs = 'playingTwo'
  } else if (newCard.code === 'JK') {
    newCard.playAs = 'playingJoker'
  }
  return newCard
}

const handleDropCards = (oldCards, newCards) => {
  let canPlay = false;
  const playerCards = [...oldCards];
  if (newCards && newCards.length > 0) {
    for (let i = 0; i < newCards.length; i++) {
      if (newCards[i].canPlay) {
        canPlay = true
      }
      for (let j = 0; j < playerCards.length; j++) {
        if (playerCards[j].id === newCards[i].id) {
          playerCards[j].canPlay = newCards[i].canPlay
        }
      }
    }
  }
  const dropCardsHandlerObj = {
    canPlay,
    playerCards
  }
  return dropCardsHandlerObj
}

const handleDropTiming = (dropCardsAt) => {
  let dropCardsAtObj = {}
  if (dropCardsAt.length) {
    const start = new Date(dropCardsAt[0]);
    const end = new Date(dropCardsAt[1]);
    dropCardsAtObj.start = start
    dropCardsAtObj.end = end
    dropCardsAtObj.gap = Math.floor((end - start) / 1000)
  }
  return dropCardsAtObj
}

const getRoundObj = (players, roundStarterObj) => {
  let dealer = {}
  for (let i = 0; i < players.length; i++) {
    if (roundStarterObj.roundStarts.id === players[i].id) {
      if (i === 0) {
        dealer = {
          ...players[players.length - 1]
        }
      } else {
        dealer = {
          ...players[i - 1]
        }
      }
    }
  }
  const newRoundStarterObj = {
    ...roundStarterObj,
    dealer: {
      ...dealer
    }
  }
  return newRoundStarterObj
}

const setRandomCoordinatesForBoardCards = (boardCard) => {
  return {
    ...boardCard,
    left: (Math.floor(Math.random() * 60) - 30),
    top: (Math.floor(Math.random() * 60) - 30),
    transform: (Math.floor(Math.random() * 30) - 15)
  }
}

const getVictims = (victims, players, currentPlayer) => {
  const newVictims = [];
  // debugger
  if (currentPlayer.victim) {
    for (let i = 0; i < players.length; i++) {
      if (currentPlayer?.team?.id === players[i]?.team?.id) {
        newVictims.push(players[i])
      }
    }
  } else {
    for (let i = 0; i < players.length; i++) {
      if (currentPlayer?.team?.id !== players[i]?.team?.id) {
        newVictims.push(players[i])
      }
    }
  }
  for (let i = 0; i < newVictims.length; i++) {
    newVictims[i].unableToStealFrom = true
    for (let j = 0; j < victims.length; j++) {
      if (newVictims[i].id === victims[j].id) {
        newVictims[i].unableToStealFrom = false
      }
    }
  }
  return newVictims
  
}

const getPlayerWithWhomToExchangeCards = (players, gameMode, currentPlayerIndex) => {
  let playerIndex = 0
  if (gameMode === 'team') {
    playerIndex = (currentPlayerIndex + 2) % players.length
  } else {
    playerIndex = (currentPlayerIndex + 1) % players.length
  }
  return players[playerIndex]
}

const getPlayersPositions = (players) => {
  const newPlayers = [...players]
  newPlayers[0].position = 'bottom';
  if (newPlayers.length === 2) {
    newPlayers[1].position = 'top';
  } else if (newPlayers.length > 2 && newPlayers.length < 5) {
    if (newPlayers.length === 3) {
      if (newPlayers[0].playerIndex === 0 ){
        newPlayers[1].position = 'right'
        newPlayers[2].position = 'top'
      } else if (newPlayers[0].playerIndex === 1) {
        newPlayers[1].position = 'right'
        newPlayers[2].position = 'left'
      } else if (newPlayers[0].playerIndex === 2) {
        newPlayers[1].position = 'top'
        newPlayers[2].position = 'left'
      }
    } else {
      newPlayers[1].position = 'right'
      newPlayers[2].position = 'top'
      newPlayers[3].position = 'left'
    }
  } else if (newPlayers.length >= 5) {
    newPlayers[1].position = 'right'
    newPlayers[2].position = 'top'
    newPlayers[3].position = 'top'
    newPlayers[4].position = 'left'
    if (newPlayers[5]) {
      newPlayers[5].position = 'bottom'
    }
  }
  return newPlayers
}

const getSortedColorsArray = (currentPlayerColor, colors) => {
  let position = 0;
  for (let i = 0; i < colors.length; i++) {
    if (currentPlayerColor === colors[i]) {
      position = i
      break
    }
  }
  const sortedColors = [];
  for (let i = 0; i < colors.length; i++) {
    let index = (i + (colors.length - position)) % colors.length;
    sortedColors[index] = colors[i];
  }
  return sortedColors
}

const AddPlayerStats = (players, stats) => {
  const newPlayers = [...players]
  for (let i = 0; i < players.length; i++) {
    for (let j = 0; j < stats.length; j++) {
      if (newPlayers[i].id === stats[j].player.id) {
        newPlayers[i] = {
          ...newPlayers[i],
          stats: {
            finishedMarbles: stats[j].finishedMarbles,
            gotKilled: stats[j].gotKilled,
            kills: stats[j].kills,
            team: stats[j].team
          }
        }
      }
    }
  }
  return newPlayers
}

const addRematchStatuses = (players) => {
  const newPlayers = [...players.map(player => {
    if (player.rematchRequested === true) {
      return {
        ...player,
        rematchRequestStatus: 'ready'
      }
    } else if (player.rematchRequested === false) {
      return {
        ...player,
        rematchRequestStatus: 'declined'
      }
    } else {
      return {
        ...player,
        rematchRequestStatus: 'loading'
      }
    }
  })]
  return newPlayers
}

const checkShowSevenSteps = (players, cardPlayed) => {
  if(!cardPlayed){
    return;
  }
  const isPlaying = players?.find(player => player.playingMarble);
  return (cardPlayed.code === "7" || cardPlayed.codeAlias === "7") && isPlaying ? true : false;
}

const gameDataReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.GET_GAME_DATA_REQUEST:
      const {resetMarbles} = action.payload
      const stateMarbles = state.marbles ? [...state.marbles] : []
      return {
        ...state,
        isLoading: true,
        marbles: resetMarbles? [] : stateMarbles,
        moves: resetMarbles ? [] : state.moves
      }
    case actionTypes.GET_GAME_DATA_SUCCESS: {
      const gamePLayers = [...action.payload.gameData.players];
      let sortedByCurrentPlayerPerPerspective = [];
      let position = 0;
      let isPlayingJoker = false;
      let isPlayingTwo = false;
      let actionCard = {};
      let isStealingMode = false;
      let victims = []
      let stealer = {}
      let currentPlayerIndex = 0;
      let choosingVictim = false;
      for (let i = 0; i < gamePLayers.length; i++) {
        if (action.payload.gameData.myself.id === gamePLayers[i].id) {
          currentPlayerIndex = i;
          break;
        }
      }

      let {availableMarbles} = action.payload.gameData;
  
      const currentPlayer = {
        ...action.payload.gameData.myself,
        availableMarbles,
        currentPlayerIndex,
        canPlayTwo: availableMarbles.length > 0 || action.payload.gameData.myself.canPlayTwo,
        cards: [...action.payload.gameData.myself.cards.map(card => {
          return {
            ...setCardPlayAs(card)
          }
        })],
        stealingCard: false,
        timeLeft: action.payload.gameData.myself.secondsLeft
      }

      for (let i = 0; i < gamePLayers.length; i++) {
        if (gamePLayers[i].id === currentPlayer.id) {
          const cards = [...gamePLayers[i].cards];
          const dropCardsAt = {...handleDropTiming(gamePLayers[i].dropCardsAt)}
          // may not needed 
          const dropCardsHandlerObj = handleDropCards(currentPlayer.cards, cards);
          gamePLayers[i].canPlay = dropCardsHandlerObj.canPlay;
          gamePLayers[i].cards = [...dropCardsHandlerObj.playerCards];
          gamePLayers[i].dropCardsAt = {...dropCardsAt}
          currentPlayer.canPlay = dropCardsHandlerObj.canPlay;
          currentPlayer.cards = [...dropCardsHandlerObj.playerCards];
          currentPlayer.dropCardsAt = {...dropCardsAt}
          //
          position = i
          for (let j = 0; j < gamePLayers[i].cards.length; j++) {
            if (gamePLayers[i].cards[j].markedForExchange) {
              actionCard = {...gamePLayers[i].cards[j]}
              break
            }
          }

          break
        }
      }

      for (let i = 0; i < gamePLayers.length; i++) {
        let index = (i + (gamePLayers.length - position)) % gamePLayers.length;
        if (gamePLayers[i].beingRobbed) {
          choosingVictim = true
        }
        sortedByCurrentPlayerPerPerspective[index] = {
          ...gamePLayers[i], 
          playerIndex: i,
          chosenAsVictim: gamePLayers[i].beingRobbed
        };
        const gamePlayerCards = sortedByCurrentPlayerPerPerspective[index].cards;

        if (gamePLayers[i].currentPlayer && gamePLayers[i].playingTwo) {
          isPlayingTwo = true;
          for (let j = 0; j < gamePlayerCards.length; j++) {
            if (gamePlayerCards[j].status === 'onBoard' || gamePlayerCards[j].status === 'playedToSteal') {
              actionCard = {...gamePlayerCards[j]};
            }
            if (gamePlayerCards[j].status === 'playedToSteal') {
              isPlayingTwo = false;
            }
          }
        }

        if (gamePLayers[i].playingTwo && action.payload.gameData.victims.length > 0) {
          sortedByCurrentPlayerPerPerspective[index].stealer = true
          stealer = {...sortedByCurrentPlayerPerPerspective[index]}
        }

        for (let j = 0; j < gamePlayerCards.length; j++) {
          const playerCard = {...setCardPlayAs(gamePlayerCards[j])}
          gamePlayerCards[j] = {...playerCard}
        }

        sortedByCurrentPlayerPerPerspective[index].cards = [...gamePlayerCards]

        if (gamePLayers[i].currentPlayer && gamePLayers[i].playingJoker) {
          isPlayingJoker = true
        }

        // if (gamePLayers[i].canSteal) {
        //   stealer = {...gamePLayers[i]}
        // }
      }

      let boardCards = [...action.payload.gameData.boardCards];
      if (boardCards.length > state.boardCardsMaxNumber) {
        let newBoardCards = []
        for (let i = boardCards.length - 1; i >= boardCards.length - state.boardCardsMaxNumber; i-- ) {
          newBoardCards.unshift(boardCards[i])
        }
        boardCards = [...newBoardCards]
      }

      // for (let i = 0; i < boardCards.length; i++) {
      //   boardCards[i] = {
      //     ...setRandomCoordinatesForBoardCards(boardCards[i])
      //   }
      // }

      const playerWithWhomToExchangeCards = getPlayerWithWhomToExchangeCards(gamePLayers,  action.payload.gameData.mode, currentPlayerIndex)
      const cardAliases = action.payload.gameData.cardAliases.map(card => {
        return {
          ...card,
          jokerModalCard: true
        }
      })

      const roundStarterObj = getRoundObj(sortedByCurrentPlayerPerPerspective,action.payload.gameData.round)

      if (action.payload.gameData.victims.length > 0) {
        victims = getVictims(action.payload.gameData.victims, sortedByCurrentPlayerPerPerspective ,currentPlayer)
        if (action.payload.gameData?.someoneIsPlayingTwo.status === 'playedToSteal') {
          isStealingMode = true
        }
      }

      // debugger
      const newColors = [...getSortedColorsArray(currentPlayer.color, state.colors)];

      sortedByCurrentPlayerPerPerspective = getPlayersPositions(sortedByCurrentPlayerPerPerspective)
      sortedByCurrentPlayerPerPerspective = AddPlayerStats(sortedByCurrentPlayerPerPerspective, [...action.payload.gameData?.playerStats] )
      sortedByCurrentPlayerPerPerspective = addRematchStatuses(sortedByCurrentPlayerPerPerspective)
      const timesLeft = [...sortedByCurrentPlayerPerPerspective.map(player => {
        return {
          id: player.id,
          timeLeft: player.secondsLeft
        }
      })]

      const showSteps = checkShowSevenSteps(action.payload.gameData.players, action.payload.gameData.lastPlayedCard)
      // sortedByCurrentPlayerPerPerspective = [...sortedByCurrentPlayerPerPerspective.map(player => {
      //   return {
      //     ...player,
      //     chosenAsVictim : true
      //   }
      // })]
      return {
        ...state,
        isLoading: false,
        isGameDataLoaded: true,
        marbles: [...action.payload.gameData.marbles],
        players: [...sortedByCurrentPlayerPerPerspective],
        holes: [...action.payload.gameData.holes],
        rules: {...action.payload.gameData.rules},
        mode: action.payload.gameData.mode,
        type: action.payload.gameData.type,
        vs: action.payload.gameData.vs,
        status: action.payload.gameData.status,
        chatId: action.payload.gameData.chatId,
        round: action.payload.gameData.round,
        name: action.payload.gameData.name,
        currentPlayer: currentPlayer,
        boardCards: [...boardCards],
        // boardCards: [...boardCards, {objectType: 'dropped', cards: [...boardCards], player: {...boardCards[0].player}}], // for testing
        roundState: action.payload.gameData.round.state,
        // actionCard: actionCard,
        playersScoreboard: [...action.payload.gameData?.playerStats],
        cardAliases: cardAliases,
        isPlayingTwo: isPlayingTwo,
        isPlayingJoker: isPlayingJoker,
        isStealingMode: isStealingMode,
        roundStarterObj: {...roundStarterObj},
        victims: victims,
        playerWithWhomToExchangeCards: playerWithWhomToExchangeCards,
        stealer: stealer,
        marblesData: action.payload.gameData.availableMarbles ,
        actionCard: action.payload.gameData.lastCardOfCurrentPlayer ? action.payload.gameData.lastCardOfCurrentPlayer : actionCard,
        recentActions: [...action.payload.gameData.lastActions],
        gameFinished : action.payload.gameData.gameFinished ? action.payload.gameData.gameFinished : false,
        chat: action.payload.gameData.chat,
        scoreboard: action.payload.gameData.scoreboard,
        colors: [...newColors],
        choosingVictim: choosingVictim,
        lastPlayedCard: action.payload.gameData.lastPlayedCard,
        moves: action.payload.gameData?.lastPlayedCard?.moves || boardCards[boardCards.length - 1]?.card?.moves,
        timesLeft: timesLeft,
        forceFinished: action.payload.gameData.forceFinished,
        leavers: action.payload.gameData.leavers,
        showSevenSteps: showSteps
      }
    };

    case actionTypes.GET_GAME_DATA_FAIL:
      const error = action.payload.error
      let playerIsInTheGame = true
      if (error?.response?.data?.message === 'WRONG_GAME') {
        playerIsInTheGame = false
      }
      return {
        ...state,
        isLoading: false,
        isGameDataLoaded: false,
        playerIsInTheGame
      };

    case actionTypes.GAME_COMMON_CARD_EVENT_REQUEST:
      return {
        ...state,
        currentPlayer: {
          ...state.currentPlayer,
          playingCard: state.roundState !== 'exchange',
          stealingCard: state.roundState !== 'exchange' && action?.payload?.type === 'steal'
        }
        // isLoading: true
      };

    case actionTypes.GAME_COMMON_CARD_EVENT_SUCCESS: {
      // debugger
      const data = action.payload.data;
      const modal = data ? data.modal : null
      let cardAliases = []
      const actionCard = {...action.payload.data.actionCard}
      if (modal && modal.isPlayingJoker) {
        cardAliases = [...data.cards.map(card => {
          return {
            ...card,
            jokerModalCard: true
          }
        })]
      }
      const currentPlayer = {...state.currentPlayer}
      currentPlayer.canSteal = data ? data.canSteal : false
      currentPlayer.canPlayTwo = data ? data.canPlayTwo : false
      // debugger
      if (state.roundState === 'play' && action.payload.data.requestType !== 'exchange' && action.payload.data.requestType !== 'steal') {
        // currentPlayer.playingCard = true;
        //old code makho 
        // currentPlayer.playingCard = true;
        // currentPlayer.playingMarble = false;
        
        if (action.payload.data.responseType === 'PROVIDE_MARBLES') {
          currentPlayer.playingMarble = true;          
        } else {
          currentPlayer.playingMarble = false;
        }
      }
      //added for stealing fix 
      if (state.roundState === 'play' && action.payload.data.requestType !== 'exchange' && action.payload.data.requestType === 'steal') {
        // debugger;
        currentPlayer.playingMarble = false;
        currentPlayer.stealingCard = false
      }
      //end of code

      // const gamePLayers = [...state.players];
      // debugger;
      let playersToStealFrom = [];
      if (action.payload.data.stealingInProgress) {
        playersToStealFrom = [...state.victims]
      } else if (state.roundState === 'play' && action.payload.data.victims) {
        playersToStealFrom = getVictims(action.payload.data.victims, state.players, currentPlayer)
      }

      // if (action.payload.data.victims) {
      //   playersToStealFrom = [...action.action.payload.data.victims];
      //   for (let i = 0; i < gamePLayers.length; i++) {
      //     const gamePlayer = {
      //       ...gamePLayers[i],
      //       victim: false
      //     }
      //     for (let j = 0; j < playersToStealFrom.length; j++) {
      //       if (gamePLayers[i].id === playersToStealFrom[j].id) {
      //         gamePlayer.victim = true
      //       }
      //     }
      //     gamePLayers[i] = {...gamePlayer}
      //   }
      // }
      let marblesData = [];
      if(data.actionGame){
        marblesData = data.actionGame
      }
      return {
        ...state,
        // isLoading: false,
        isPlayingJoker: modal? modal.isPlayingJoker: false,
        isPlayingTwo: modal? modal.isPlayingTwo: false,
        cardAliases: [...cardAliases],
        currentPlayer: {...currentPlayer},
        cardPlayAction: '',
        // players: [...gamePLayers],
        victims: [...playersToStealFrom],
        actionCard: actionCard,
        marblesData
      };
    };


    case actionTypes.GAME_SEVEN_MARBLE_EVENT_SUCCESS: { 
     let {marbles} = action.payload;
     const sevenObj = {
       ...action.payload.marble,
       stillPlaying: action.payload.stillPlaying
     }
      return {
        ...state,
        marblesData: marbles,
        moves: action.payload.moves,
        lastMarblePlayed: sevenObj
      }
    }

    case actionTypes.GAME_COMMON_CARD_EVENT_FAIL:
      return {
        ...state,
        currentPlayer: {
          ...state.currentPlayer,
          playingCard: false
        }
        // isLoading: false
      };
    
    case actionTypes.CARD_EXCHANGE: {
      // TODO: look thorugh code again and change exchange logic beacuse of much rewriting in drop logic
      // debugger;
      const players = [...state.players];
      const currentPlayer = {...state.currentPlayer};
      for (let i = 0; i < players.length; i++) {
        if (players[i].id === action.payload.cardExchangeData.targetPlayer.id) {
          const player = {...players[i]}
          const playerCards = [...players[i].cards]
          const randomIndex = Math.floor(Math.random() * playerCards.length);
          // playerCards.push({...setCardPlayAs(action.payload.cardExchangeData.card)})
          playerCards.splice(randomIndex, 0, {...setCardPlayAs(action.payload.cardExchangeData.card)})
          player.cards = [...playerCards.map(card => {return {...card, canPlay: false}})]
          players[i] = {...player}
          if (players[i].id === currentPlayer.id) {
            currentPlayer.cards = [...playerCards.map(card => {return {...card, canPlay: false}})]
          }
        } else if (players[i].id === action.payload.cardExchangeData.actionPlayer.id) {
          const player = {...players[i]}
          const playerCards = [...players[i].cards.filter(card => card.id !== action.payload.cardExchangeData.card.id)];
          player.cards = [...playerCards.map(card => {return {...card, canPlay: false}})]
          players[i].cards = [...playerCards.map(card => {return {...card, canPlay: false}})]
          players[i] = {...player}
          if (players[i].id === currentPlayer.id) {
            currentPlayer.cards = [...playerCards.map(card => {return {...card, canPlay: false}})]
          }
        }
      }
      return {
        ...state,
        players: [...players],
        currentPlayer: {...currentPlayer},
        roundState: 'play'
      };
    };
    
    case actionTypes.SET_PLAYERS_READY_FOR_EXCHANGE: {
      const players = [...state.players.map(player => {
        return {
          ...player,
          readyToExchange: action.payload.playerId === player.id || player.readyToExchange
        }
      })];
      return {
        ...state,
        players: [...players]
      }
    };

    case actionTypes.CARD_PLAY: {
      // debugger
      const players = [...state.players];
      let boardCards = [...state.boardCards];
      const playedCard = {
        card: {
          ...action.payload.cardPlayData.card,
          fromSocket: true
        },
        player: {
          ...action.payload.cardPlayData.actionPlayer
        },
        type: 'playCard'
      }
      const currentPlayer = {...state.currentPlayer}
      for (let i = 0; i < players.length; i++) {
        if (players[i].id === action.payload.cardPlayData.actionPlayer.id) {
          const player = {...players[i]}
          const playerCards = [...players[i].cards.filter(card => card.id !== playedCard.card.id)];
          player.cards = [...playerCards]
          if (players[i].id === currentPlayer.id) {
            currentPlayer.cards = [...playerCards]
            currentPlayer.playingMarble = action.payload.cardPlayData.card.status !== "playedToSteal"
            currentPlayer.playingCard = false
          }
          // debugger
          if (boardCards.find(cardObj => cardObj.card && cardObj.card.id === playedCard.card.id)) {
            boardCards = [...boardCards];
          } else {
            boardCards = [...boardCards, playedCard];
          }
          if (boardCards.length > state.boardCardsMaxNumber) {
            boardCards.shift()
          }
          players[i] = {...player};
          break;
        }
      }
      // for (let i = 0; i < boardCards.length; i++) {
      //   if (!boardCards[i].top) {
      //     boardCards[i] = {
      //       ...setRandomCoordinatesForBoardCards(boardCards[i])
      //     }
      //   }
      // }
      return {
        ...state,
        // isLoading: false,
        players: [...players],
        boardCards: [...boardCards],
        currentPlayer: {...currentPlayer},
        moves: boardCards[boardCards.length - 1]?.card?.moves,
        lastPlayedCard: {...action.payload.cardPlayData.card},
        showSevenSteps: action.payload.cardPlayData.card.code === "7" || action.payload.cardPlayData.card.codeAlias === "7" ? true : false
      };
    }
    case actionTypes.CARD_STEAL: {
      // debugger;
      const players = [...state.players];
      const currentPlayer = {...state.currentPlayer}
      for (let i = 0; i < players.length; i++) {
        if (players[i].id === action.payload.cardStealData.actionPlayer.id) {
          const player = {...players[i]}
          player.victim = false;
          player.canSteal = false;
          player.myTurn = false;
          let card = {}
          if (player.currentPlayer) {
            card = {
              ...setCardPlayAs(action.payload.cardStealData.card),
              visible: true,
              canPlay: false
            }
            //added for stealing fix 
            currentPlayer.stealingCard = false
            currentPlayer.myTurn = false
            //end of code
          } else {
            card = {
              id: action.payload.cardStealData.card.id,
              visible: false
            }
          }
          const playerCards = [...players[i].cards];
          const randomIndex = Math.floor(Math.random() * playerCards.length)
          playerCards.splice(randomIndex, 0, {...card})
          player.cards = [...playerCards];
          players[i] = {...player};
        } else if (players[i].id === action.payload.cardStealData.targetPlayer.id) {
          const player = {...players[i]}
          player.victim = false;
          player.canSteal = false;
          const playerCards = [...players[i].cards.filter(card => card.id !== action.payload.cardStealData.card.id)];
          player.cards = [...playerCards];
          if (players[i].currentPlayer) {
            currentPlayer.cards = [...playerCards]
          }
          players[i] = {...player, chosenAsVictim: false};
        }
        if (players[i].victim) {
          players[i].victim = false
        }
      }
      const newVictims = [...state.victims.map(victim => {
        return {
          ...victim,
          chosenAsVictim: false
        }
      })]
      // debugger;
      return {
        ...state,
        players: [...players],
        isStealingMode: false,
        stealer: {},
        currentPlayer: {...currentPlayer},
        choosingVictim: false,
        victims: newVictims
        // currentPlayerCards: [...state.currentPlayerCards, action.payload.card]
      };
    };

    case actionTypes.GAME_COMMON_MARBLE_EVENT_REQUEST:
      return {
        ...state,
        // isLoading: true
      };

    case actionTypes.GAME_COMMON_MARBLE_EVENT_SUCCESS:
      return {
        ...state,
        currentPlayer: {...state.currentPlayer, playingMarble: false, myTurn: false}
        // isLoading: false
      };

    case actionTypes.GAME_COMMON_MARBLE_EVENT_FAIL:
      return {
        ...state,
        // isLoading: false
      };

    case actionTypes.MARBLE_MOVE:
      return {
        ...state,

      };

    case actionTypes.CHANGE_PLAYER_TURN: {
      const changePlayersTurn = [...state.players];
      const changeTurnObj = {...action.payload.changeTurnObj}
      const currentPlayer = {...state.currentPlayer};
      // if (currentPlayer.id === changeTurnObj.nextPlayer.id) {d
      //   currentPlayer.myTurn = true;
      //   currentPlayer.playingCard = false;
      //   currentPlayer.playingMarble = false;
      // } else {
      //   currentPlayer.myTurn = false;
      // }
      // debugger
      for (let i = 0; i < changePlayersTurn.length; i++) {
        if (changePlayersTurn[i].id === changeTurnObj.nextPlayer.id) {
          const player =  {
            ...changePlayersTurn[i],
            victim: false,
            status: 'playing'
          }
          player.myTurn = true;
          player.playingCard = false;
          player.playingMarble = false;
          player.playingTwo = false;
          player.playingJoker = false;
          if (changeTurnObj.playerCards.length) {
            const dropCardsHandlerObj = handleDropCards(player.cards, changeTurnObj.playerCards);
            player.canPlay = dropCardsHandlerObj.canPlay;
            player.cards = [...dropCardsHandlerObj.playerCards];
            currentPlayer.canPlay = dropCardsHandlerObj.canPlay;
            currentPlayer.cards = [...dropCardsHandlerObj.playerCards]
            currentPlayer.myTurn = true;
            currentPlayer.playingCard = false;
            currentPlayer.playingMarble = false;
            currentPlayer.stealingCard = false;
            currentPlayer.victim = false
    
          }
          changePlayersTurn[i] = {...player};
          const previousPlayerIndex = i === 0 ? changePlayersTurn.length - 1 : i - 1
          changePlayersTurn[previousPlayerIndex] = {
            ...changePlayersTurn[previousPlayerIndex],
            myTurn: false,
            playingCard: false,
            playingMarble: false,
            playingJoker: false,
            playingTwo: false
          }
          if (changePlayersTurn[previousPlayerIndex].id === currentPlayer.id) {
            currentPlayer.myTurn = false;
            currentPlayer.playingCard = false;
            currentPlayer.playingMarble = false;   
            currentPlayer.stealingCard = false;
            currentPlayer.victim = false
          }
        } else {
          changePlayersTurn[i].myTurn = false;
          changePlayersTurn[i].playingCard = false;
          changePlayersTurn[i].playingMarble = false;
          changePlayersTurn[i].status = 'playing'
        }
      }
      if (changeTurnObj.nextPlayer.id !== currentPlayer.id) {
        currentPlayer.myTurn = false;
        currentPlayer.playingCard = false;
        currentPlayer.playingMarble = false;   
        currentPlayer.stealingCard = false;
        currentPlayer.victim = false
      }
      const timesLeft = [...state.timesLeft.map(obj => {
        return {
          ...obj,
          timeLeft: PlayerTime
        }
      })]
      return {
        ...state,
        players: [...changePlayersTurn],
        currentPlayer: {...currentPlayer},
        timesLeft: timesLeft,
        moves: [],
        showSevenSteps: false
      };
    }
    case actionTypes.START_NEW_ROUND: {
      // debugger
      const players = [...state.players];
      const newCardData = [...action.payload.roundData.cardData];
      const currentPlayer = {
        ...state.currentPlayer,
        dropped: false,
        playingCard: false,
        playingMarble: false,
      }
      currentPlayer.myTurn= false
      currentPlayer.playingCard= false
      currentPlayer.stealingCard = false
      currentPlayer.playingMarble= false
      currentPlayer.timeLeft = PlayerTime
      for (let i = 0; i < players.length; i++) {
        // if (players[i].id === action.payload.roundData.roundStarterObj.roundStarts.id) {
        //   players[i].myTurn = true;
        // } else {
        //   players[i].myTurn = false;
        // }
        const player = {...players[i]}
        player.readyToExchange = false
        player.playingCard = false
        player.playingMarble = false
        for (let j = 0; j < newCardData.length; j++) {
          if (player.id === newCardData[j].targetPlayer.id) {
            const playerCards = [...newCardData[j].cards]
            for (let k = 0; k < playerCards.length; k++) {
              const playerCard = {...setCardPlayAs(playerCards[k])}
              playerCards[k] = {...playerCard}
            }
            player.cards = [...playerCards]
            players[i] = {...player}
          }
        }
        if (newCardData[i].targetPlayer.id === currentPlayer.id) {
          currentPlayer.cards = [...newCardData[i].cards]
          if (newCardData[i].targetPlayer.myTurn) {
            currentPlayer.myTurn = true
          }
        }
      }

      const roundStarterObj = getRoundObj(players, action.payload.roundData.roundStarterObj)

      const timesLeft = [...state.timesLeft.map(obj => {
        return {
          ...obj,
          timeLeft: PlayerTime
        }
      })]

      return {
        ...state,
        roundState: 'exchange',
        players: [...players],
        currentPlayer: {...currentPlayer},
        roundStarterObj: {...roundStarterObj},
        timesLeft: timesLeft
      }
    };

    // case actionTypes.SET_IS_PLAYING_JOKER: 
    //   return {
    //     ...state,
    //     isPlayingJoker: action.payload.isPlayingJoker
    //   };

    case actionTypes.SET_ACTION_CARD: 
      return {
        ...state,
        actionCard: action.payload.actionCard
      };

    // case actionTypes.SET_CARD_ALIASES:
    //   return {
    //     ...state,
    //     cardAliases: [...action.payload.cardAliases]
    //   };

    // case actionTypes.SET_GAME_STATE:
    //   return {
    //     ...state,
    //     roundState: action.payload.roundState
    //   };

    case actionTypes.SET_GAME_CARD_PLAY_ACTION:
      return {
        ...state,
        cardPlayAction: action.payload.cardPlayAction
      }

    // case actionTypes.SET_IS_PLAYING_TWO:
    //   return {
    //     ...state,
    //     isPlayingTwo: action.payload.isPlayingTwo
    //   }
    
    case actionTypes.SET_PLAYERS_FOR_STEALING: {
      const gamePLayers = [...state.players];
      const playersToStealFrom = [...action.payload.victims]
      for (let i = 0; i < gamePLayers.length; i++) {
        const gamePlayer = {
          ...gamePLayers[i],
          victim: false,
          stealer: gamePLayers[i].id === action.payload.stealer.id
        }
        for (let j = 0; j < playersToStealFrom.length; j++) {
          if (gamePLayers[i].id === playersToStealFrom[j].id) {
            gamePlayer.victim = true
          }
        }
        gamePLayers[i] = {...gamePlayer}
      }
      return {
        ...state,
        players: [...gamePLayers],
        isStealingMode: true,
        isPlayingTwo: false,
        victims: [...playersToStealFrom],
        stealer: {...action.payload.stealer}
      }
    }

    // case actionTypes.DROP_CARDS_REQUEST: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   }
    // }

    // case actionTypes.DROP_CARDS_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoading: false
    //   }
    // }

    // case actionTypes.DROP_CARDS_FAIL: {
    //   return {
    //     ...state,
    //     isLoading: false
    //   }
    // }

    case actionTypes.DROP_CARDS: {
      const players = [...state.players]
      const currentPlayer = {...state.currentPlayer}

      if (action.payload.playerId === currentPlayer.id) {
        currentPlayer.cards = []
        currentPlayer.myTurn = false
        currentPlayer.playingCard = false
        currentPlayer.stealingCard = false
        currentPlayer.playingMarble = false
      }

      for (let i = 0; i < players.length; i++) {
        if (players[i].id === action.payload.playerId) {
          players[i].cards = [];
          players[i].myTurn = false
          players[i].playingCard = false
          players[i].playingMarble = false
        }
      }
      return {
        ...state,
        players: [...players],
        currentPlayer : {...currentPlayer}
      }
    }

    // case actionTypes.SET_DROP_DETAILS: {
    //   const dropCardsAt = {... handleDropTiming(action.payload.dropCardsAt)};
    //   const currentPlayer = {...state.currentPlayer};
    //   const players = [...state.players];
    //   currentPlayer.dropCardsAt = {...dropCardsAt}
    //   for (let i = 0; i < players.length; i++) {
    //     if (players[i].id === currentPlayer.id) {
    //       players[i].dropCardsAt = {...currentPlayer.dropCardsAt}
    //     }
    //   }
    //   return {
    //     ...state,
    //     currentPlayer: {...currentPlayer},
    //     players: [...players]
    //   }
    // }

    case actionTypes.SET_MARBLE_MOVE: {
      const {moveMarble} = action.payload;
      return {
        ...state,
        onMarbleMove: {...moveMarble},
        lastMarblePlayed: moveMarble,
        moves: moveMarble.moves
      }
    } 
    
    case actionTypes.SET_REPLAY_SEVEN: {
      const {marblesData} = action.payload;
      return {
        ...state,
        marbles: marblesData,
        replaySeven: true
      }
    } 
    
    case actionTypes.SET_MARBLE_POSITION: {

      return {
        ...state,
        onMarbleMove: {},
        moves: [1,1,1,1,1,1,1],
        currentPlayer : {
          ...state.currentPlayer,
          availableMarbles: [...state.marblesData]
        },
        replaySeven: false,
      }
    } 

    
    case actionTypes.SET_MARBLE_KILL: {
      const {killMarble} = action.payload;
      return {
        ...state,
        onKillMarble: [...killMarble]
      }
    } 

    case actionTypes.SET_MARBLE_SWAP: {
      const {marbleSwap} = action.payload;
      return {
        ...state,
        onSwapMarble: {...marbleSwap}
      }
    }

    case actionTypes.SET_CAN_PLAY_STATUS_TO_CARDS: {
      const currentPlayer = {
        ...state.currentPlayer,
        dropped: true
      };
      const players = [...state.players];
      const newPlayerCards = [];

      for (let i = 0; i < players.length; i++) {
        if (players[i].id === action.payload.playerId) {
          const playerCards = [...players[i].cards]
          for (let j = 0; j < playerCards.length; j++) {
            const card = {
              ...playerCards[i],
              canPlay: action.payload.status
            }
            newPlayerCards.push(card);
          }
          players[i].cards = [...playerCards];
          currentPlayer.cards = [...playerCards];
        }
      }

      return {
        ...state,
        currentPlayer: {...currentPlayer},
        players: [...players]
      }
    }

    case actionTypes.SET_PLAYER_MESSAGE : {
      const players = [...state.players]
      for (let i = 0; i < players.length; i++) {
        if (players[i].user.id === action.payload.userId) {
          players[i] = {
            ...players[i],
            message: action.payload.message
          }
        }
      }
      return {
        ...state,
        players: [...players]
      }
    }

    case actionTypes.SET_RECENT_ACTIONS: {
      // debugger;
      const recentAction = {...action.payload.action}
      if (recentAction.type === 'stealCard') {
        recentAction.card = {
          ...recentAction.card,
          id: recentAction.card.id,
          playerId: recentAction.card.playerId,
          visible: state.currentPlayer.id === recentAction.actionPlayer.id || state.currentPlayer.id === recentAction.targetPlayer.id
        }
      }
      const newRecentActions = [ recentAction, ...state.recentActions.filter((elem, index) => index < 4)]
      return {
        ...state,
        recentActions: newRecentActions
      }
    }

    case actionTypes.SET_DROPPED_ACTIONS: {
      const droppedCards = [...action.payload.cards];
      for (let i = 0; i < droppedCards.length; i++) {
        // droppedCards[i] = setRandomCoordinatesForBoardCards({
        //   ...droppedCards[i],
        //   player: {
        //     ...action.payload.actionPlayer
        //   }
        // })
        droppedCards[i] = {
          ...droppedCards[i],
          fromSocket: true
        }
      }
      const boardCards = [...state.boardCards, {
        type: 'dropCards',
        cards: [...droppedCards],
        player: {
          ...action.payload.actionPlayer
        }
      }]
      if (boardCards.length > state.boardCardsMaxNumber) {
        boardCards.shift()
      }
      return {
        ...state,
        boardCards: boardCards
      }
    }

    case actionTypes.ON_GAME_FINISH : {

      return{
        ...state,
        scoreboard: action.payload.onGameFinish.action.scoreboard,
        gameFinished: true,
        rematchEnabled: action.payload.onGameFinish.action.rematchEnabled ?? true,
      }

    }

    case actionTypes.SET_REMATCH_PLAYER : {
      const requestMadeBy = {...action.payload.player}
      const newPlayers  = [...state.players.map(player => {
        if (player.id === requestMadeBy.id) {
          return {
            ...player,
            rematchRequestStatus: requestMadeBy.rematchRequested ? 'ready' : 'declined'
          }
        } else {
          return {
            ...player,
            rematchRequestStatus: player.rematchRequestStatus ?? 'loading'
          }
        }
      })]
      return {
        ...state,
        playersApproved : [...state.playersApproved, action.payload.player],
        players: [...newPlayers]
      }
    }
    case actionTypes.SET_BOARD_TOGGLE : {
      localStorage.boardToggle = !state.boardRotate;
      return {
        ...state,
        boardRotate: !state.boardRotate
      }
    }
    case actionTypes.SET_CHOSEN_VICTIM : {
      // debugger;
      const playerId = action.payload.playerId
      const players = [...state.players]
      for (let i = 0; i < players.length; i++) {
        players[i].chosenAsVictim = playerId === players[i].id
      }
      const newVictims = [...state.victims.map(victim => {
        return {
          ...victim,
          chosenAsVictim: victim.id === playerId
        }
      })]
      return {
        ...state,
        players: [...players],
        choosingVictim: true,
        victims: newVictims
      }
    };
    case actionTypes.UPDATE_SCORE_BOARD: {
      const {playerId, score} = action.payload;
      const players = [...state.players];
      
      for (let i = 0; i < players.length; i++) {
        if (playerId === players[i].id) {
          players[i] = {
            ...players[i],
            stats: {
              finishedMarbles: score.finishedMarbles,
              gotKilled: score.gotKilled,
              kills: score.kills,
              team: score.team
            }
          }
        }
      }

      const playersScoreboard = [...state.playersScoreboard.map(stat => {
        if (stat.player.id === playerId) {
          return {
            ...stat,
            ...score
          }
        } else {
          return {
            ...stat
          }
        }
      })];
      // debugger;
      return {
        ...state,
        playersScoreboard: [...playersScoreboard],
        players: [...players]
      }
    };
    case actionTypes.RESET_GAME_DATA: {
      return {
        isLoading: false,
        // currentPlayerCards: null,
        players: null,
        marbles: null,
        rules: {},
        mode: null,
        type: null,
        vs: null,
        status: null,
        chatId: null,
        isGameDataLoaded: false,
        currentPlayer: null,
        boardCards: [],
        isPlayingJoker: false,
        actionCard: null,
        isPlayingTwo: false,
        roundState: null,
        cardPlayAction: '',
        playersToStealFrom: [],
        isStealingMode: false,
        roundStarterObj: null,
        victims: null,
        playerWithWhomToExchangeCards: null,
        marblesData: [],
        replaySeven: false,
        onMarbleMove : {},
        onKillMarble : [],
        onSwapMarble: {},
        stealer: null,
        recentActions: [],
        playersApproved: [],
        boardRotate:  boardToggle(),
        boardCardsMaxNumber: 4,
        playerIsInTheGame: true,
        gameFinished : false,
        colors: ['red', 'green', 'blue', 'yellow'],
        playersScoreboard: null
      }
    }
    case actionTypes.UPDATE_PLAYER_FRIENDSHIP_STATUS:{
      const newPlayers = [...state.players]
      const playerId = action.payload.recipient
      for (let i = 0; i < newPlayers.length; i++) {
        if (newPlayers[i].user.id === playerId) {
          newPlayers[i].user.friendshipStatus = 'pending'
        }
      }
      return{
        ...state,
        players: [...newPlayers]
      }
    };
    case actionTypes.UPDATE_PLAY_TIME: {
      const playerToUpdate = action.payload;
      const timesLeft = [...state.timesLeft.map(obj => {
        return obj.id === playerToUpdate.id ? {
          ...obj,
          timeLeft: playerToUpdate.timeLeft
        } : {
          ...obj
        }
      })]
      return {
        ...state,
        timesLeft
      }
    };
    case actionTypes.SET_FORCE_FINISHED_DATA: {
      return {
        ...state,
        forceFinished: true
      }
    };
    case actionTypes.UPDATE_PLAYER_STATUS: {
      const players = [...state.players.map(player => {
        return player.id === action.payload.id ? {
          ...player,
          status: action.payload.reason
        } : {
          ...player
        }
      })]
      return {
        ...state,
        players
      }
    }
    case actionTypes.TOGGLE_PREVENT_CARD_CLICK_WHILE_PLAYING_JOKER: {
      return {
        ...state,
        preventCardClickWhilePlayingJoker: action.payload.state
      }
    }
    default:
      return state;
  }
}

export default gameDataReducer;