import * as actionTypes from "./matchMakingActionTypes";
import api from '../../axios/api';

const matchMakingRequest = () => {
  return {
    type: actionTypes.MATCH_MAKING_REQUEST
  }
}

export const matchMakingSuccess = (gameData) => {
  return {
    type: actionTypes.MATCH_MAKING_SUCCESS,
    payload: {
      gameData: {
        ...gameData
      }
    }
  }
}

const matchMakingFail = (error) => {
  return {
    type: actionTypes.MATCH_MAKING_FAIL,
    payload:{
      error: {
        ...error
      }
    }
  }
}

export const startMatchMaking = (gameInfo) => {
  return async (dispatch) => {
    dispatch(matchMakingRequest());
    return api.post('/match-making', gameInfo)
    .then(response => {
      // debugger;
      dispatch(matchMakingSuccess(response.data));
      return response
    })
    .catch(error =>{
      console.log(error);
      dispatch(matchMakingFail(error));
      return error
    })
  }
}


const cancelMakingRequest = () => {
  return {
    type: actionTypes.CANCEL_MATCH_MAKING_REQUEST
  }
}

const cancelMakingSuccess = () => {
  return {
    type: actionTypes.CANCEL_MATCH_MAKING_SUCCESS,
  }
}

const cancelMakingFail = (error) => {
  return {
    type: actionTypes.CANCEL_MATCH_MAKING_SUCCESS,
    payload: {
      error
    }
  }
}

export const cancelMatchMaking = (mmId) => {
  return async (dispatch) => {
    dispatch(cancelMakingRequest());
    return api.delete(`/match-making/${mmId}`)
    .then(response => {
      clearTimeout(timout)
      dispatch(cancelMakingSuccess(response.data));
      return response
    })
    .catch(e =>{
      dispatch(cancelMakingFail(e));
      return e;
    })
  }
}




const quickPlayRequest = () => {
  return {
    type: actionTypes.QUICK_PLAY_REQUEST
  }
}

export const quickPlaySuccess = (gameData) => {
  return {
    type: actionTypes.QUICK_PLAY_SUCCESS,
    payload: {
      gameData: {
        ...gameData
      }
    }
  }
}

const quickPlayFail = (error) => {
  return {
    type: actionTypes.QUICK_PLAY_FAIL,
    payload:{
      error: {
        ...error
      }
    }
  }
}

export const startquickPlay = () => {
  return async (dispatch) => {
    dispatch(quickPlayRequest());
    api.post('/match-making/quick-play', {rame: 'rame'})
    .then(response => {
      dispatch(quickPlaySuccess(response.data));
    })
    .catch(error =>{
      console.log(error);
      dispatch(quickPlayFail(error));
    })
  }
}

export const resetMm = () => {
  return {
    type: actionTypes.RESET_MATCHMAKING
  }
}

export const toggleAborting = (state) => {
  return {
    type: actionTypes.TOGGLE_ABORTING,
    payload: {
      state
    }
  }
}

export const toggleDisableAborting = (state) => {
  return {
    type: actionTypes.TOGGLE_DISBALE_ABORTING,
    payload: {
      state
    }
  }
}