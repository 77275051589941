import {useSelector, useDispatch} from 'react-redux';
import * as styles from '../../styles/scss/components/ConnectionLostModal/ConnectionLostModal.module.scss';
import ResetSvg from '../../public/images/common/refreshIcon.svg'
import OfflineSvg from '../../public/images/common/offline.svg'
import { buzzOfflineModal } from '../../store/modals/modalsActions';
import api from '../../axios/api';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const connectionLostModal = (props) => {
  const {additionalClickActions} = props
  const {t} = useTranslation()
  const router = useRouter();
  const refreshHandler = () => {
    additionalClickActions()
    location.reload()
  }
  const dispatch = useDispatch()
  const buzzModalHandler = (state) => {
    dispatch(buzzOfflineModal(state))
  }
  const buzzModal = useSelector(state => state.modals.buzzOfflineModal)
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      buzzModalHandler(false)
    }, 500)
  }, [buzzModal])

  useEffect(() => {
      const {
        gameId,
        lobbyId
      } = router.query;
      api.post('/feedback/connection-lost', {
        route: router.pathname,
        gameUuid: gameId,
        lobbyUuid: lobbyId,
      });
  }, [])
  return(
    <div className = {`${styles.connection_lost_modal_container} ${buzzModal && styles.buzz}`} onClick={refreshHandler}>
      <div className = {styles.offline_icon_container}>
        <OfflineSvg />
      </div>
      <h3 className = {styles.offline_text}>
        {t('errors:connection_lost.text')} <span>{t('errors:connection_lost.link_text')}</span>
      </h3>
      <div className= {styles.refresh_icon_container}>
        <ResetSvg />
      </div>
    </div>
  )
}


export default connectionLostModal