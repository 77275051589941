export const TOGGLE_BACKGROUND_MUSIC = 'TOGGLE_BACKGROUND_MUSIC';
export const TOGGLE_SOUND_EFFECT = 'TOGGLE_SOUND_EFFECT';
export const TOGGLE_ASSIST_MODE = 'TOGGLE_ASSIST_MODE';
export const SET_CARD_STYLE = 'SET_CARD_STYLE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const TOGGLE_PUSH_NOTIFICATIONS = 'TOGGLE_PUSH_NOTIFICATIONS';
export const TOGGLE_EMAIL_NOTIFICATIONS = 'TOGGLE_EMAIL_NOTIFICATIONS';
export const TOGGLE_ONLINE_STATUS = 'TOGGLE_ONLINE_STATUS';
export const SET_LANDING_TYPE = 'SET_LANDING_TYPE';
export const SET_THEME = 'SET_THEME';
export const SET_WEBPAGE_THEME = 'SET_WEBPAGE_THEME';
export const SET_SCREEN_MODE = 'SET_SCREEN_MODE';

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';

export const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAIL = 'SAVE_SETTINGS_FAIL';

export const SET_MEINTANACE_MODE = 'SET_MEINTANACE_MODE';