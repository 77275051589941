export const MATCH_MAKING_REQUEST = 'MATCH_MAKING_REQUEST';
export const MATCH_MAKING_SUCCESS = 'MATCH_MAKING_SUCCESS';
export const MATCH_MAKING_FAIL = 'MATCH_MAKING_FAIL';

export const QUICK_PLAY_REQUEST = 'QUICK_PLAY_REQUEST';
export const QUICK_PLAY_SUCCESS = 'QUICK_PLAY_SUCCESS';
export const QUICK_PLAY_FAIL = 'QUICK_PLAY_FAIL';

export const CANCEL_MATCH_MAKING_REQUEST = 'CANCEL_MATCH_MAKING_REQUEST';
export const CANCEL_MATCH_MAKING_SUCCESS = 'CANCEL_MATCH_MAKING_SUCCESS';
export const CANCEL_MATCH_MAKING_FAIL = 'CANCEL_MATCH_MAKING_FAIL';

export const RESET_MATCHMAKING = 'RESET_MATCHMAKING';

export const TOGGLE_ABORTING = 'TOGGLE_ABORTING'
export const TOGGLE_DISBALE_ABORTING = 'TOGGLE_DISBALE_ABORTING'