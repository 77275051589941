import React from 'react';
import SwapIcon from './SwapIcon';

const Marble = ({id, transform, height, width, handleMarbleClick, color, swapped}) => {

    return(
        <>
            <g className="marbles" id={`_${id}`} transform={transform} onClick={handleMarbleClick}>
                {/* <Marble width={width} height={height} /> */}
                <path opacity="0.7" transform='translate(-2, -2) scale(0.98)' fill="url(#marbleShadow)" d="M25.9,5.4c6.6,6.6,7.3,16.5,1.6,22.1S12,32.4,5.4,25.9S-1.8,9.4,3.8,3.8S19.4-1.2,25.9,5.4z"/>

                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            xmlSpace="preserve" width={width} height={height}>
                
                <circle className="st0" fill={`url(#${color}_1_)`} cx={width / 2} cy={width / 2} r={width / 2}/>
                <circle className="st1" fill={`url(#${color}_6_)`} cx={width / 2} cy={width / 2} r={width / 2}/>
                <circle fill={`url(#${color}_1_)`} cx={width / 2} cy={width / 2} r={width / 2}/>
                <circle fill={`url(#${color}_2_)`} cx={width / 2} cy={width / 2} r={width / 2}/>
                {/* <path className="st4" fill="#FF0000" fillOpacity="0.2" d="M17.4,0.7c-0.2-0.1-0.3-0.1-0.5-0.1c6.4,2.6,9.8,9.8,7.4,16.4c-2.4,6.6-9.5,10.1-16.1,8
                    c0.2,0.1,0.3,0.2,0.4,0.2c6.8,2.4,14.2-1.1,16.6-7.9C27.6,10.6,24.1,3.2,17.4,0.7z"/> */}
                <path fill={`url(#${color}_3_)`} d="M16.6,2.3c4.9,3,6.9,9.1,3.6,14.6
                    s-10,7.5-14.9,4.5S-1.1,11.5,2.2,6S11.6-0.7,16.6,2.3z"/>
                <path fill={`url(#${color}_4_)`} d="M8.7,4.6C8,4.7,5.5,5.1,4,8.1
                    c-1.5,3-0.3,5.4,0.1,6c0.4,0.6,1.5,1.6,2.3,0.8c0.8-0.8,0.2-2,1.6-4.8c1.4-2.8,2.7-3,2.8-4.2C10.9,4.9,9.5,4.6,8.7,4.6z"/>
                <path className="st7" fillOpacity="0.8" fill="#FFFFFF" d="M6.5,11.6c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.5-0.1-1.1-0.2c-0.7-0.2-1-0.4-1.2-0.5c0,0,0-0.1,0-0.1
                    c0-0.2,0.2-0.7,0.7-1.6c0.4-0.6,0.6-0.9,0.7-1c0-0.1,0.1-0.1,0.2,0C5.9,8.3,6.5,8.8,6.9,9l0.5,0.3c0,0,0.1,0.1,0,0.1
                    c-0.1,0.2-0.3,0.8-0.5,1.3C6.7,11.2,6.6,11.5,6.5,11.6z"/>
                <path className="st7" fillOpacity="0.8" fill="#FFFFFF" d="M7.9,8.2c0,0-0.1,0-0.1,0C7.6,8.2,7.4,8.1,7,7.9C6.5,7.7,6.3,7.5,6.3,7.4c0,0,0,0,0-0.1
                    c0.1-0.1,0.3-0.4,0.8-0.9C7.5,6.2,7.7,6,7.8,6c0,0,0.1,0,0.1,0C8,6.1,8.4,6.5,8.6,6.7l0.3,0.3c0,0,0,0.1,0,0.1
                    C8.8,7.2,8.5,7.5,8.3,7.8C8,8,7.9,8.2,7.9,8.2z"/>
                <path className="st8" fillOpacity="0.3" fill="#FFFFFF" d="M19.7,3.2c-0.1,0.1-0.5-0.3-1-0.5c-0.5-0.3-1-0.4-1-0.6c0-0.2,0.7-0.3,1.3,0C19.6,2.5,19.8,3.1,19.7,3.2z"/>
                <path className="st8" fillOpacity="0.3" fill="#FFFFFF" d="M7.9,23.7C8,23.6,8.4,23.9,9,24c0.6,0.2,1.1,0.2,1.1,0.3c0,0.1-0.6,0.4-1.3,0.2C8.2,24.4,7.8,23.9,7.9,23.7z"
                    />
                <path className="st9" fillOpacity="0.43" fill="#FFFFFF" d="M6,10.6C7.1,8,8.8,5.9,10.8,4.3c-2.2,1.3-4.1,3.3-5.3,5.8c-1,2.2-1.2,4.5-1,6.7C4.7,14.7,5.1,12.6,6,10.6z"/>		

                </svg>
                {swapped  && swapped.includes(id) && <SwapIcon />}
            </g>
        </>
    )
}

const areEquals = (prevProps, nextProps) => {
    if(prevProps.transform !== nextProps.transform || prevProps.handleMarbleClick !== nextProps.handleMarbleClick){
        return false;
    }
    return true;
}

export default React.memo(Marble, areEquals);
