import * as styles from '../../../styles/scss/components/NewSignUp/NewSignUp.module.scss';
import {connect, useSelector} from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { register, login } from '../../../store/auth/authActions';
import Avatar from '../../Avatar/Avatar';
import FormInput, { CheckBox } from '../../FormInput/FormInput';
// import { CheckBox } from '../Buttons/Buttons';
import Link from 'next/link';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import { setSignUpFormData } from '../../../store/auth/authActions';
import api from '../../../axios/api';
import { useRouter } from 'next/router';
import {deviceType} from '../../../helpers/functions';
import Wizard from '../NewLogin/wizard';
import EditAvatar from '../EditAvatar/EditAvatar';
import useTranslation from 'next-translate/useTranslation';
import {sendStatsToBackend} from '../../../store/static/staticInfoActions'
import {toggleIsCookiePopupHidden} from '../../../store/toggles/toggleStateActions.js'
// const formReducer = (state, action) => {
//   switch (action.type) {
//     case "input":
//       return {
//         ...state,
//         [action.name]: action.value
//       };
//   }
// }

// const initialState = {
//   username: '',
//   email: '',
//   password: ''
// };

const NewSignUp = (props) => {

  const {
    onRegisterRequest, 
    signUpSuccessfull, 
    onLogin, 
    avatarsList,
    onToggleSidebar,
    signUpFormData,
    onSetSignUpFormData,
    onSendStatToBackend,
    onToggleIsCookiePopupHidden
  } = props

  const {isCookiePopupHidden} = useSelector(state => state.toggles)
  // const [errors, setErrors] = useState();
  const [username, setUsername] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,19}$/igm,
    changed: false
  })
  const usernameRef = useRef();
  const router = useRouter();
  const modalState = useSelector(state => state.modals)
  const {t} = useTranslation()
  const [avatarValidation, setAvatarValidation] = useState({
    show: false,
    text: t('errors:avatar.text')
  })
  
  const [usernameRulesChecker, setUsernameRulesChecker] = useState([
    {
      text: t('errors:username.character_validation'),
      valid: false,
      type: 'validSymbols'
    },
    {
      text: t('errors:username.start_end_vavigation'),
      valid: false,
      type: 'starteEnd'
    },
    {
      text: t('errors:username.period_validation'),
      valid: false,
      type: 'sequence'
    },
    {
      text: t('errors:username.max_length_validation'),
      valid: false,
      type: 'maxLength'
    }
  ])
  const [showWizard, setShowWizard] = useState(false)
  const [wizardIndexToOpen, setWizardIndexToOpen] = useState(0)
  const [inputFocused, setInputFocused] = useState(false)
  const openWizardHandler = (field, index) => {
    let valid = false
    if (field === 'username') {
      valid = username.valid
    } else if (field === 'email') {
      valid = email.valid
    } else if (field === 'password') {
      valid = password.valid
    }
    if (deviceType() === 'mobile') {
      if (valid) {
        setWizardIndexToOpen(index)
      }
      setShowWizard(true)
    }
  }

  const [showUsernameHints, setShowUsernameHints] = useState(false)

  const allSymbolsAreValid = (username) => {
    for (let i = 0; i < username.length; i++) {
      if (!symbolIsValid(username[i])) {
        return false
      }
    }
    return true
  }

  const symbolIsValid = (symbol) => {
    if (
      (symbol >= 'a' && symbol <= 'z') || 
      (symbol >= 'A' && symbol <= 'Z') ||
      (+symbol >= 0 && +symbol <= 9) ||
      (symbol === '_') || (symbol === '.')
      ) {
        return true
      }
    return false
  }

  const checkSequentiallSymbols = (username) => {
    for (let i = 0; i < username.length; i++) {
      if (username[i + 1]) {
        if (username[i] === username[i + 1] && username[i] === '.') {
          return false
        }
      }
    }
    return true
  }

  const checkStartAndEnd = (username) => {
    if (username[0] === '.' || username[username.length - 1] === '.') {
      return false
    }
    return true
  }

  const updateValidityTexts = (username, returnValue = false) => {
    const symbolsAreValid = allSymbolsAreValid(username);
    const startAndEndAreValid = checkStartAndEnd(username);
    const sequentialSymbolsAreValid = checkSequentiallSymbols(username);
    const checkLengthValidity = username.length <= 20 && username.length >= 3
    const isValid = symbolsAreValid &&  startAndEndAreValid && sequentialSymbolsAreValid && checkLengthValidity
    setUsernameRulesChecker(prevState => [...prevState.map(rule =>{
      if (rule.type === 'validSymbols') {
        return {
          ...rule,
          valid: symbolsAreValid
        }
      } else if (rule.type === 'starteEnd') {
        return {
          ...rule,
          valid: startAndEndAreValid
        }
      } else if (rule.type === 'sequence') {
        return {
          ...rule,
          valid: sequentialSymbolsAreValid
        }
      } else if (rule.type === 'maxLength') {
        return {
          ...rule,
          valid: checkLengthValidity
        }
      }
    })])
    setUsername(prevState => {
      return {
        ...prevState,
        valid: isValid
      }
    })
    if (returnValue) {
      return isValid
    }
  }

  const [email, setEmail] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    changed: false
  })
  const emailRef = useRef();

  const [password, setPassword] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^().{6,}$/,
    changed: false
  })

  const [checked, setChecked] = useState({
    value: true,
    valid: false,
    changed: false
  })

  // const [checked, setChecked] = useState(false);
  const [passworInputType, setPassowrdInputType] = useState(true)
  const [chosenAvatar, setChosenAvatar] = useState({})

  // const formRegEx = {
  //   username: /^[a-zA-Z0-9_]{2,}$/,
  //   // name: /^[a-z\s]+$/i,
  //   email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //   // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  //   password:  /^().{6,}$/
  // }


  const handleAvatarEdit = () => {
    if (deviceType() === 'mobile') {
      setWizardIndexToOpen(0)
      setShowWizard(true)
      return
    }
    onSetSignUpFormData({
      username: username.value,
      email: email.value,
      avatar: chosenAvatar
    })
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'editAvatar',
      hasTitle: true,
      hasBackArrow: true,
      showSidebarCloseButton: false,
      callBackSidebarFunction: () => {
        onToggleSidebar({
          isOpen: true,
          sidebarType: 'signUp',
          hasBackArrow: false,
          showSidebarCloseButton: true,
          hasTitle: true
        })
      }
    })
  }

  useEffect(() => {
    let avatarId = router.query.avatarId || localStorage.getItem('avatarId');
    if (avatarId) {
      const referrerAvatar = avatarsList.find((avatar) => {
        return avatar.id == avatarId;
      })
      if (referrerAvatar) {
        setChosenAvatar(referrerAvatar);
      }
    }
    if (modalState.sidebarData?.email) {
      setEmail(prevState => {
        return {
          ...prevState,
          value: modalState.sidebarData?.email,
        }
      })
    }
  } , [avatarsList])

  useEffect(() => {
    // debugger;
    if (signUpFormData) {
      setUsername(prevState => {
        return {
          ...prevState,
          value: signUpFormData.username || '',
          valid: !!signUpFormData.username,
          changed: true
        }
      })
      setEmail(prevState => {
        return {
          ...prevState,
          value: signUpFormData.email || '',
          valid: !!signUpFormData.email,
          changed: true
        }
      })
      setPassword(prevState => {
        return {
          ...prevState,
          value: signUpFormData.password || '',
          valid: !!signUpFormData.password,
          changed: true
        }
      })
      if (signUpFormData.avatar) {
        setChosenAvatar({...signUpFormData.avatar, fromSignUp: true})
      }
    }
  }, [signUpFormData])


  useEffect(() => {
    const timer = setTimeout(() => {
      if (username.changed && !!usernameRef.current) {
        const regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/igm
        if (username.value === usernameRef.current.value) {
          const valid = regex.test(username.value);
          updateValidityTexts(username.value)
          if (valid) {
            if (usernameRef.current.value.length >= 1) {
              api.post('/check-availability', {
                username: usernameRef.current.value,
              }, {
                headers: {hideErrorPage: true}
              }).then(response => {
                setUsername(prevState => {
                  return {
                    ...prevState,
                    valid: prevState.valid,
                    errorText: '',
                    changed: true
                  }
                })
              }).catch((e) => {
                setUsername(prevState => {
                  return {
                    ...prevState,
                    valid: false,
                    errorText: t(`errors:username.${e.response.data.message}`),
                    changed: true
                  }
                })
              })
            } 
          } else {
            setUsername(prevState => {
              return {
                ...prevState,
                valid: false,
                errorText: t('errors:username.invalid'),
                // errorText: 'Username can contain only Letters, numbers and underscores, minimum 2 characters',
                changed: true
              }
            })
          }
        }
      
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [username.value, username.changed ,usernameRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email.changed && !!emailRef.current) {
        
        if (email.value === emailRef.current.value) {
          const valid = email.regEx.test(email.value);
          if (valid) {
            if (emailRef.current.value.length >= 2) {
              api.post('/check-availability', {
                email: emailRef.current.value,
              },{
                headers: {hideErrorPage: true}
              }).then(response => {
                setEmail(prevState => {
                  return {
                    ...prevState,
                    valid: true,
                    errorText: '',
                    changed: true
                  }
                })
              }).catch(() => {
                setEmail(prevState => {
                  return {
                    ...prevState,
                    valid: false,
                    errorText: t('errors:email.already_exists'),
                    changed: true
                  }
                })
              })
            }
          } else {
            setEmail(prevState => {
              return {
                ...prevState,
                valid: false,
                errorText: t('errors:email.invalid'),
                changed: true
              }
            })
          }
        }
        
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [email.value, email.changed, emailRef]);


  useEffect(() => {
    if (password.changed) {
      const valid = password.regEx.test(password.value);

      if (valid) {
        setPassword(prevState => {
          return {
            ...prevState,
            valid: true,
            errorText: '',
            changed: true
          }
        })
      } else {
        setPassword(prevState => {
          return {
            ...prevState,
            valid: false,
            errorText: t('errors:password.length'),
            changed: true
          }
        })
      }
    }
  }, [password.value, password.changed]);

  // useEffect(() =>{
  //   if (username?.value > 0 && chosenAvatar && Object.keys(chosenAvatar).length > 0) {
      
  //   }
  // }, [username.value, chosenAvatar])

  const handleChange = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case 'username': {
        setUsername(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }; break;
      case 'email': {
        setEmail(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }; break;
      case 'password': {
        setPassword(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }
    }
  }

  const handleRegister = (e) => {
    onSendStatToBackend({type: "CLICK_BUTTON_SIGN_UP", data: isCookiePopupHidden || localStorage.getItem('isCookiePopupHidden') ? "COOKIES_ACCEPTED": "COOKIES_NOT_ACCEPTED"})
    const avatarChosen = Object.keys(chosenAvatar).length > 0 && chosenAvatar?.id && chosenAvatar?.breed !== 'Default'
    if (!avatarChosen) {
      setAvatarValidation(prevState => ({...prevState, show: true}))
    }
    const isUsernameValid = username.valid && updateValidityTexts(username.value, true)
    if (!isUsernameValid) {
      setShowUsernameHints(true)
    }
    const canRegister = isUsernameValid && password.valid && email.valid &&  avatarChosen
    e.preventDefault();
    // let { errors, canRegister } = validate(userCredentials);
    if (canRegister) {
      const userCredentials = {
        username: username.value,
        email: email.value,
        password: password.value,
        termsAndConditions: true,
        avatarId: chosenAvatar ? chosenAvatar.id : (avatarsList ? avatarsList[0].id : null),
        referrerCode: router.query.referralCode || router.query.rc || localStorage.getItem('referralCode') || null,
        language: router.locale
      }
      onRegisterRequest(userCredentials)
    } else {
      setUsername(prevState => {
        return {
          ...prevState,
          // valid: username.regEx.test(username.value),
          changed: true
        }
      })
      setEmail(prevState => {
        return {
          ...prevState,
          // valid: email.regEx.test(email.value),
          changed: true
        }
      })
      setPassword(prevState => {
        return {
          ...prevState,
          // valid: password.regEx.test(password.value),
          changed: true
        }
      })
      setChecked(prevState => {
        return {
          ...prevState,
          valid: prevState.value,
          changed: true
        }
      })
    }
  }

  const handleClose = (state) => {
    onSetSignUpFormData({
      username: username.value,
      email: email.value,
      avatar: chosenAvatar,
      password: password.value
    })
    setShowWizard(state)
  }

  useEffect(() => {
    if (signUpSuccessfull) {
      const credentials = {
        email: email.value,
        password: password.value
      }
      localStorage.removeItem('avatarId');
      localStorage.removeItem('referralCode');
      onLogin(credentials)
    }
  }, [signUpSuccessfull])


  return (
    <div className = {`${styles.signUp} ${showWizard ? styles.no_padding : ''}`}>
      {!showWizard &&
        <>
          <div className = {styles.avatar_holder} >
            {avatarsList && (
              <div className = {styles.avatar_container} onClick = {handleAvatarEdit}>
                <Avatar
                  size = 'large'
                  hasFlag = {false}
                  mode = 'normal'
                  showEditIcon = {true}
                  avatarUrls = {chosenAvatar.avatars || avatarsList[0]?.avatars || {}}
                  />
              </div>
            )}
            {avatarValidation.show &&  (
              <h3 className = {styles.avatar_text}>{avatarValidation.text}</h3>
            )}            
          </div>
          <form className={styles.sign_up_form}>
            <div className = {styles.form_group_container} onClick = {() => {openWizardHandler('username', 1)}}>
              <FormInput
                reference = {usernameRef}
                type = 'text'
                value = {username.value}
                name = 'username'
                change = {handleChange}
                active = {username.value.length > 0}
                labelText = {t('common:inputs.username')}
                showIndicator = {true}
                id = 'signupUsername'
                valid = {username.valid}
                errorText = {username.errorText}
                changed = {username.changed}
                focusHandler = {setShowUsernameHints}
                disabled={deviceType() === 'mobile'}
              />
              {/* {showUsernameHints && ( */}
                <div className={styles.input_hints_list}>
                  <h4 className={styles.input_hints_title}>{t('errors:username.username_requirements')}</h4>
                  <ul className={styles.input_hints_list_ul}>
                    {usernameRulesChecker.map(rule => {
                      return (
                        <li className={styles.input_hint_li} key={rule.type}>
                          <div className={`${styles.container} ${rule.valid ? styles.valid : username.changed ? styles.invalid : ''}`}>
                            <div className={styles.check_container}>
                              {rule.valid ? <img src='/images/common/check.svg' alt='check image' /> : username.changed ? <img src='/images/common/cross.svg' alt='cross image'/> : <></>}
                            </div>
                            <span className={styles.hint_text}>{rule.text}</span>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              {/* )} */}
            </div>
            <div className = {styles.form_group_container} onClick = {() => {openWizardHandler('email', 2)}}>
              <FormInput
                reference = {emailRef}
                type = 'email'
                value = {email.value}
                name = 'email'
                change = {handleChange}
                active = {email.value.length > 0}
                labelText = {t('common:inputs.email')}
                showIndicator = {true}
                id = 'signupEmail'
                valid = {email.valid}
                errorText = {email.errorText}
                changed = {email.changed}
                disabled={deviceType() === 'mobile'}
                // showIcon = {true}
              />
            </div>
            <div className = {styles.form_group_container} onClick = {() => {openWizardHandler('password', 3)}}>
              <FormInput 
                type = {passworInputType ? 'password' : 'text'}
                value = {password.value}
                name = 'password'
                change = {handleChange}
                active = {password.value.length > 0}
                labelText = {t('common:inputs.password')}
                showIndicator = {true}
                id = 'signupPassword'
                showIcon = {true}
                iconFunction = {() => {setPassowrdInputType(prevState => !prevState)}}
                valid = {password.valid}
                errorText = {password.errorText}
                changed = {password.changed}                
                disabled={deviceType() === 'mobile'}
              />
            </div>        
          </form>
          <div className = {styles.button_container}>
            <div className = {`${styles.form_group_container} ${styles.therms}`}>
              {/* <input 
                type='checkbox' 
                id = 'terms_checkbox' 
                onChange = {() => {setChecked(prevState => {
                  return {
                    ...prevState,
                    value: !prevState.value,
                    changed: true
                  }
                })}}
                name = 'checked'
                value = {checked.value}
              /> */}
              <label className = {styles.terms_text} htmlFor='terms_checkbox'>
                {/* <CheckBox checked = {checked.value} /> */}
                <span className = {styles.text}>
                  {t('common:sidebar_body.sign_up.i_accept_the')} {' '}
                  <Link href='/privacy' locale={router.locale}>
                    <a >
                      {t('common:sidebar_body.sign_up.terms_and_conditions')}
                    </a>
                  </Link>
                  {' '}
                  {t('common:sidebar_body.sign_up.last_part')}
                </span>
              </label>
              {checked.changed && !checked.value && <span className = {styles.errorText}>{t('errors:terms_and_conditions.label')}</span>}
            </div>
            <div className = {styles.form_group_container}>
              <button
                className = {styles.log_in_button}
                type = 'button'
                onClick = {(e) => {handleRegister(e)}}
              >
                {t('common:buttons.signup_button')}
              </button>
            </div>
            <div className = {styles.have_an_account}>
              <h3>{t('common:sidebar_body.sign_up.already_have_an_account')} <span  onClick = {() => {
                onToggleSidebar({
                  isOpen: true,
                  sidebarType: 'logIn',
                  hasBackArrow: false,
                  showSidebarCloseButton: true,
                  hasTitle: true,
                  customTitle: t('common:sidebar_titles.login_sidebar_header')
                })
              }}
              >{t('common:sidebar_titles.login_sidebar_header')}</span></h3>
            </div>
          </div>
        </>
      }

      {showWizard && (
        <Wizard
          handleShowWizard = {handleClose}
          wizardIndexToOpen = {wizardIndexToOpen}
          moveButtonOnIos = {true}
          inputFocused = {inputFocused}
          showUsernameHints = {showUsernameHints}
          wizardData = {[
            {
              id: 'wizardAvatar',
              valid: chosenAvatar && Object.keys(chosenAvatar).length > 0 &&  chosenAvatar.id !== avatarsList[0].id,
              element : (
                <EditAvatar isInWisard = {true} wizardChosenAvatar = {chosenAvatar} />
              )
            },
            {
              id: 'wizardsignupUsername',
              valid: username.valid,
              data: username,
              element: (
                <>  
                  <FormInput
                    reference = {usernameRef}
                    type = 'text'
                    value = {username.value}
                    name = 'username'
                    change = {handleChange}
                    active = {username.value.length > 0}
                    labelText = {t('common:inputs.username')}
                    showIndicator = {true}
                    id = 'signupUsername'
                    valid = {username.valid}
                    errorText = {username.errorText}
                    changed = {username.changed}
                    focusHandler = {(state) => {setInputFocused(state); setShowUsernameHints(state)}}
                    // clickHandler = {openWizardHandler}
                    autoFocus = {true}
                    // showIcon = {true}
                  />
                  { (
                    <div className={styles.input_hints_list}>
                      <h4 className={styles.input_hints_title}>{t('errors:username.username_requirements')}</h4>
                      <ul className={styles.input_hints_list_ul}>
                        {usernameRulesChecker.map(rule => {
                          return (
                            <li className={styles.input_hint_li} key={rule.type}>
                              <div className={`${styles.container} ${rule.valid ? styles.valid : username.changed ? styles.invalid : ''}`}>
                                <div className={styles.check_container}>
                                  {rule.valid ? <img src='/images/common/check.svg' alt='check image' /> : username.changed ? <img src='/images/common/cross.svg' alt='cross image'/> : <></>}
                                </div>
                                <span className={styles.hint_text}>{rule.text}</span>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                </>
              )
            },
            {
              id: 'wizardsignupEmail',
              valid: email.valid,
              data: email,
              element: (
                <FormInput
                  reference = {emailRef}
                  type = 'email'
                  value = {email.value}
                  name = 'email'
                  change = {handleChange}
                  active = {email.value.length > 0}
                  labelText = {t('common:inputs.email')}
                  showIndicator = {true}
                  id = 'signupEmail'
                  valid = {email.valid}
                  errorText = {email.errorText}
                  changed = {email.changed}
                  focusHandler = {(state) => {setInputFocused(state)}}
                  // clickHandler = {openWizardHandler}
                  autoFocus = {true}
                  // showIcon = {true}
                />
              )
            },
            {
              id: 'wizardsignupPassword',
              valid: password.valid,
              data: password,
              element: (
                <FormInput 
                type = {passworInputType ? 'password' : 'text'}
                value = {password.value}
                name = 'password'
                change = {handleChange}
                active = {password.value.length > 0}
                labelText = {t('common:inputs.password')}
                showIndicator = {true}
                id = 'signupPassword'
                showIcon = {true}
                iconFunction = {() => {setPassowrdInputType(prevState => !prevState)}}
                valid = {password.valid}
                errorText = {password.errorText}
                changed = {password.changed}
                // clickHandler = {openWizardHandler}
                autoFocus = {true}
                focusHandler = {(state) => {setInputFocused(state)}}
              />
              )
            }
          ]}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    signUpSuccessfull: state.auth.signUpSuccessfull,
    avatarsList: state.avatars.avatarsList,
    signUpFormData: state.auth.signUpFormData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRegisterRequest: (credentials) => dispatch(register(credentials)),
    onLogin: (credentials) => dispatch(login(credentials)),
    onToggleSidebar: (sidebarState) => dispatch(toggleSidebar(sidebarState)),
    onSetSignUpFormData: (formData) => dispatch(setSignUpFormData(formData)),
    onSendStatToBackend: (payload) => dispatch(sendStatsToBackend(payload)),
    onToggleIsCookiePopupHidden: () => dispatch(toggleIsCookiePopupHidden())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSignUp)
