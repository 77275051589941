import * as actionTypes from './toggleStateActionTypes';

// action to toggle [show and hide] chat modal
export const toggleShowChatState = (payload) => {
  return {
    type: actionTypes.TOGGLE_SHOW_CHAT_STATE,
    payload: {
      ...payload
    }
  }
}

// action to toggle [show and hide] delay on chat modal modal 
export const toggleDelayIsActive = (payload) => {
    return {
      type: actionTypes.TOGGLE_DELAY_IS_ACTIVE,
      payload: {
        ...payload
      }
    }
  }

// action to toggle [show and hide] backdrop inside game page
export const toggleAppearBackdrop = (payload) => {
  return {
    type: actionTypes.TOGGLE_APPEAR_BACKDROP,
    payload: {
      ...payload
    }
  }
}

// action to toggle [show and hide] cookie popup
export const toggleIsCookiePopupHidden = (payload) => {
  return {
    type: actionTypes.TOGGLE_IS_COOKIE_POPUP_HIDDEN,
    payload: {
      ...payload
    }
  }
}

// action to toggle [show and hide] refresh popup in gamepage
export const toggleIsRefreshPopupHidden = (payload) => {
  return {
    type: actionTypes.TOGGLE_IS_REFRESH_POPUP_HIDDEN,
    payload: {
      ...payload
    }
  }
}

// action to toggle [show and hide] refresh popup in gamepage
export const showRefreshPopup = (payload) => {
  return {
    type: actionTypes.SHOW_REFRESH_POPUP,
    payload: {
      ...payload
    }
  }
}
