import * as actionTypes from "./privateLobbyActionTypes";
import api from '../../../axios/api';
import router from "next/router";

const getPrivateLobbyRequest = () => {
  return {
    type: actionTypes.GET_PRIVATE_LOBBY_DATA_REQUEST1
  }
}

export const getPrivateLobbySuccess = (lobbyData, gameSetupData) => {
  return {
    type: actionTypes.GET_PRIVATE_LOBBY_DATA_SUCCESS1,
    payload: {
      ...lobbyData,
      mmSettings: {
        ...gameSetupData
      }
    }
  }
}

const getPrivateLobbyFail = (error) => {
  return {
    type: actionTypes.GET_PRIVATE_LOBBY_DATA_FAIL1,
    payload:{
      error
    }
  }
}

export const getPrivateLobby1 = (lobbyId, gameSetupData) => {
  return async (dispatch) => {
    dispatch(getPrivateLobbyRequest());
    return api.get(`/lobby/${lobbyId}`)
    .then(response => {
      dispatch(getPrivateLobbySuccess(response.data, gameSetupData));
      return response
    })
    .catch(error =>{
      // console.log(error);
      dispatch(getPrivateLobbyFail(error));
      return error
    })
  }
}


const  playerSitDownRequest = () => {
  return {
    type: actionTypes.PLAYER_SIT_DOWN_REQUEST
  }
}

const playerSitDownSuccess = () => {
  return {
    type: actionTypes.PLAYER_SIT_DOWN_SUCCESS,
  }
}

const playerSitDownFail = (error) => {
  return {
    type: actionTypes.PLAYER_SIT_DOWN_FAIL,
    payload:{
      error
    }
  }
}

export const playerSitDown = (lobbyId) => {
  return async (dispatch) => {
    dispatch(playerSitDownRequest);
    api.post(`/lobby/${lobbyId}/join`)
    .then(response => {
      dispatch(playerSitDownSuccess());
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerSitDownFail(error));
    })
  }
}


const  playerJoinRequest= () => {
  return {
    type: actionTypes.PLAYER_JOIN_REQUEST
  }
}

const playerJoinSuccess = () => {
  return {
    type: actionTypes.PLAYER_JOIN_SUCCESS,
  }
}

const playerJoinFail = (error) => {
  return {
    type: actionTypes.PLAYER_JOIN_FAIL,
    payload:{
      error
    }
  }
}

export const playerJoin = (lobbyId) => {
  return async (dispatch) => {
    dispatch(playerJoinRequest);
    api.post(`/lobby/${lobbyId}/join`)
    .then(response => {
      dispatch(playerJoinSuccess());
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerJoinFail(error));
    })
  }
}


const  playerRollDiceRequest= () => {
  return {
    type: actionTypes.PLAYER_ROLL_DICE_REQUEST
  }
}

const playerRollDiceSuccess = (payload) => {
  return {
    type: actionTypes.PLAYER_ROLL_DICE_SUCCESS,
    payload: {
      ...payload
    }
  }
}

const playerRollDiceFail = (error) => {
  return {
    type: actionTypes.PLAYER_ROLL_DICE_FAIL,
    payload:{
      error
    }
  }
}

export const playerRollDice = (payload) => {
  return async (dispatch) => {
    dispatch(playerRollDiceRequest);
    api.post(`/lobby/${payload.lobbyId}/dice`, payload.diceResult)
    .then(response => {
      dispatch(playerRollDiceSuccess(response));
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerRollDiceFail(error));
    })
  }
}


export const setJoinedPlayer = (userId, currentUserId) => {
  return {
    type: actionTypes.PRIVATE_SET_JOINED_PLAYER,
    payload: {
      userId,
      currentUserId
    }
  }
}

export const setRedirectState = (state) => {
  return {
    type: actionTypes.SET_REDIRECT_TO_PRIVATE,
    payload: {
      state
    }
  }
}

export const updateGameMode = (mode) => {
  return {
    type: actionTypes.UPDATE_GAME_MODE,
    payload: {
      mode
    }
  }
}

export const updateVersus = (vs) => {
  return {
    type: actionTypes.UPDATE_VERSUS,
    payload: {
      vs
    }
  }
}

export const updateSeats = (seats) => {
  return {
    type: actionTypes.UPDATE_SEATS,
    payload: {
      seats
    }
  }
}

export const updateRules = (rules) => {
  return {
    type: actionTypes.UPDATE_RULES,
    payload: {
      rules
    }
  }
}

export const kickMember = (members) => {
  return {
    type: actionTypes.KICK_MEMBERS,
    payload: {
      members
    }
  }
}

export const addMember = (members) => {
  return {
    type: actionTypes.ADD_MEMBERS,
    payload: {
      members
    }
  }
}

export const updateJoinedStatus = (userId) => {
  return {
    type: actionTypes.UPDATE_JOINED_STATUS,
    payload: {
      userId
    }
  }
}

export const handleChangePlayerSeat = (seat) => {
  return {
    type: actionTypes.HANDLE_CHANGE_PLAYER_SEAT,
    payload: {
      seat
    }
  }
}

export const handlePlayerStandUp = (seat) => {
  return {
    type: actionTypes.HANDLE_PLAYER_STAND_UP,
    payload: {
      seat
    }
  }
}

export const updateTeam = (data) => {
  return {
    type: actionTypes.UPDATE_TEAM,
    payload: {
      data
    }
  }
}

export const changeGameMode = (gameMode) => {
  return {
    type: actionTypes.CHANGE_GAME_MODE,
    payload: {
      gameMode
    }
  }
}

export const setEnabledStatusToPlayersNumbers = () => {
  return {
    type: actionTypes.SET_ENABLED_STATUS_TO_PLAYERS_NUMBER,
  }
}

export const setEnabledStatusToGameMode = () => {
  return {
    type: actionTypes.SET_ENABLED_STATUS_TO_GAME_MODE,
  }
}

export const setPlayerNumber = (number) => {
  return {
    type: actionTypes.SET_PRIVATE_PLAYER_NUMBER,
    payload: {
      number
    }
  }
}

const  playerGetUpRequest = () => {
  return {
    type: actionTypes.PLAYER_GET_UP_REQUEST
  }
}

const playerGetUpSuccess = () => {
  return {
    type: actionTypes.PLAYER_GET_UP_SUCCESS,
  }
}

const playerGetUpFail = (error) => {
  return {
    type: actionTypes.PLAYER_GET_UP_FAIL,
    payload:{
      error
    }
  }
}

export const playerGetUp = (lobbyId) => {
  return async (dispatch) => {
    dispatch(playerGetUpRequest);
    api.post(`/lobby/${lobbyId}/join`)
    .then(response => {
      dispatch(playerGetUpSuccess());
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerGetUpFail(error));
    })
  }
}


const deleteLobbyRequest = () => {
  return {
    type: actionTypes.DELETE_LOBBY_REQUEST
  }
}

const deleteLobbySuccess = () => {
  return {
    type: actionTypes.DELETE_LOBBY_SUCCESS,
  }
}

const deleteLobbyFail = (error) => {
  return {
    type: actionTypes.DELETE_LOBBY_FAIL,
    payload:{
      error
    }
  }
}

export const resetLobby = () => {
  return {
    type: actionTypes.RESET_LOBBY
  }
}

export const deleteLobby = (lobbyId, socketId) => {
  return async (dispatch) => {
    dispatch(deleteLobbyRequest());
    api.defaults.headers.common['X-Socket-ID'] = socketId
    return api.delete(`/lobby/${lobbyId}`)
    .then(response => {
      dispatch(deleteLobbySuccess());
      resetLobby()
      return true;
    })
    .catch(error =>{
      // console.log(error);
      dispatch(deleteLobbyFail(error));
    })
  }
}

export const changeLobbyName = (name) => {
  return {
    type: actionTypes.CHANGE_LOBBY_NAME,
    payload: {
      name
    }
  }
}
