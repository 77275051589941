import {connect} from 'react-redux';
import React, { useEffect } from 'react';
import Loader from '../components/Loader/Loader';
import { setErrorsData } from '../store/errors/errorsActions';

const withAuthorizationGuard = ({isAuthenticated, isLoading, children, onSetErrorsData}) => {
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      onSetErrorsData({
        errorOccured: true,
        statusCode: 401,
        errorMessage: 'Unauthorized'
      })
    }
  }, [isAuthenticated, isLoading])

  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>
  } else {
    return <Loader />
  }

}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    errorsData: state.errors
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetErrorsData: (payload) => {dispatch(setErrorsData(payload))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuthorizationGuard)