import * as styles from '../../styles/scss/components/NoCardsModal/NoCardsModal.module.scss'

const NoCardsModal = ({device, text}) => {
  
  return (
    <div className = {`${styles.no_cards_outer} ${styles[device]}`}>
      <img className = {styles.no_cards_image} src='/images/gameModal/noCards.svg' />
      <span className = {styles.no_cards_text}>{text}</span>
    </div>
  )
}

export default NoCardsModal