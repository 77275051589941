import * as actionTypes from './leaderboardActionTypes'
import api from '../../axios/api';


const getLeaderboardDataRequest = () => {
  return {
    type: actionTypes.GET_LEADERBOARD_DATA_REQUEST,
  }
}

const getLeaderboardDataSuccess = (payload) => {
  return {
    type: actionTypes.GET_LEADERBOARD_DATA_SUCCESS,
    payload: {
      ...payload
    }
  }
}

const getLeaderboardDataFail = (error) => {
  return {
    type: actionTypes.GET_LEADERBOARD_DATA_FAIL,
    payload: error
  }
}

export const getLeaderboardData = (payload) => {
  return async (dispatch) => {
    dispatch(getLeaderboardDataRequest());
    return api.get(`leaderboard`, {
      params: {
        type: payload.type,
        countries: payload.countries,
        city: payload.city,
        state: payload.state,
        perPage: payload.perPage,
        page: payload.page,
        age: payload.age,
        friends: payload.friends,
        actionUserId: payload.actionUserId
      }
    }).then(res => {
      dispatch(getLeaderboardDataSuccess({...res.data, initialLoad: payload.initialLoad, pageChange: payload.pageChange}));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getLeaderboardDataFail(e))
      return e
    })
  }
}

export const setTypeFilter = (selectedTypeFilter) => {
  return {
    type: actionTypes.SET_TYPE_FILTER,
    payload: {
      selectedTypeFilter
    }
  }
}

export const setFilter = (selectedFilter, user) => {
  return {
    type: actionTypes.SET_FILTER,
    payload: {
      selectedFilter,
      user
    }
  }
}

export const setSelectedFilter = (filter, data) => {
  return {
    type: actionTypes.SET_SELECTED_FILTER,
    payload: {
      filter,
      data
    }
  }
}