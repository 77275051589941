import * as actionTypes from './staticInfoActionTypes';

const initialState = {
  error: null,
  countriesLoading: false,
  countriesList: null,
  avatarsLoading: true,
  avatarsList: null,
  citiesLoading: true,
  citiesList: null,
  statesLoading: true,
  statesList: null,
  appVersionUpdate: null,
  verion: '',
  versionInfoLoading: false,
  versionInfo: null
}

const getUpdatedVersions = (versions) => {
  let newVersion = [...versions]
  newVersion= [...newVersion.map((version, index) => {
    return {
      ...version,
      open: version.isCurrent === true
    }
  })]
  return newVersion
}

const handleVersionOpen = (versions, versionIndex) => {
  const newVersion = [...versions.map((version, index) => {
    return {
      ...version,
      open: index === versionIndex
    }
  })]
  return newVersion
}

const staticInfo = (state = initialState, action) => {
  switch (action.type) {
      // case actionTypes.GET_AVATARS_LIST_REQUEST: {
      //   return {
      //     ...state,
      //     // isLoading: true
      //   }
      // };
      // case actionTypes.GET_AVATARS_LIST_SUCCESS: {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     avatarsList: action.payload.avatarsList
      //   }
      // };
      // case actionTypes.GET_AVATARS_LIST_FAIL: {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     error: action.payload.error
      //   }
      // };
      case actionTypes.GET_COUNTRIES_LIST_REQUEST: {
        return {
          ...state,
          countriesLoading: true
        }
      };
      case actionTypes.GET_COUNTRIES_LIST_SUCCESS: {
        return {
          ...state,
          countriesList: action.payload.countriesList,
          countriesLoading: false
        }
      };
      case actionTypes.GET_COUNTRIES_LIST_FAIL: {
        return {
          ...state,
          error: action.payload.error,
          countriesLoading: false
        }
      };
      case actionTypes.GET_STATES_LIST_REQUEST: {
        return {
          ...state,
          statesLoading: true
        }
      };
      case actionTypes.GET_STATES_LIST_SUCCESS: {
        return {
          ...state,
          statesList: action.payload.statesList,
          statesLoading: false
        }
      };
      case actionTypes.GET_STATES_LIST_FAIL: {
        return {
          ...state,
          error: action.payload.error,
          statesLoading: false
        }
      };
      case actionTypes.GET_CITIES_LIST_REQUEST: {
        return {
          ...state,
          citiesLoading: true
        }
      };
      case actionTypes.GET_CITIES_LIST_SUCCESS: {
        return {
          ...state,
          citiesList: action.payload.citiesList,
          citiesLoading: false
        }
      };
      case actionTypes.GET_CITIES_LIST_FAIL: {
        return {
          ...state,
          error: action.payload.error,
          citiesLoading: false
        }
      };
      case actionTypes.SET_COLABORATORS: {
        const versionUpdate = action.payload.appVersionUpdate ? {...action.payload.appVersionUpdate} : null
        return {
          ...state,
          appVersionUpdate: versionUpdate
        }
      }
      case actionTypes.GET_VERSION_SUCCESS: {
        return {
          ...state,
          version: action.payload.version
        }
      }
      case actionTypes.GET_VERSION_INFO_REQUEST: {
        return {
          ...state,
          versionInfoLoading: true
        }
      };
      case actionTypes.GET_VERSION_INFO_SUCCESS: {
        const updatedVersions = getUpdatedVersions(action.payload.versionInfo)
        return {
          ...state,
          versionInfo: updatedVersions,
          versionInfoLoading: false
        }
      };
      case actionTypes.GET_VERSION_INFO_FAIL: {
        return {
          ...state,
          error: action.payload.error,
          versionInfoLoading: false
        }
      };
      case actionTypes.HANDLE_VERSION_OPEN : {
        const newVersions = [...handleVersionOpen([...state.versionInfo], action.payload.versionIndex)]
        return {
          ...state,
          versionInfo: newVersions
        }
      }
      default:
        return state;
    }
}

export default staticInfo;