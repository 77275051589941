import * as actionTypes from './settingsActionTypes';
import { SetLanguagesOnPage } from '../languages/languagesActions';
import { updateOnlineStatus } from '../auth/authActions';
import api from '../../axios/api';
import { setOnboardingFinish, setShowOnboardingElement } from '../onBoarding/onBoardingActions';
import router from 'next/router';

export const setLandingType = (type) => {
  return {
    type: actionTypes.SET_LANDING_TYPE,
    payload: {
      type
    }
  }
}

export const toggleBackgroundMusic = (state) => {
  return {
    type: actionTypes.TOGGLE_BACKGROUND_MUSIC,
    payload: {
      state
    }
  }
}

export const toggleSoundEffect = (state) => {
  return {
    type: actionTypes.TOGGLE_SOUND_EFFECT,
    payload: {
      state
    }
  }
}

export const toggleAssistMode = (state) => {
  return {
    type: actionTypes.TOGGLE_ASSIST_MODE,
    payload: {
      state
    }
  }
}

export const setCardStyle = (style) => {
  return {
    type: actionTypes.SET_CARD_STYLE,
    payload: {
      style
    }
  }
}

export const setLanguage = (language) => {
  return {
    type: actionTypes.SET_CARD_STYLE,
    payload: {
      language
    }
  }
}

export const togglePushNotifications = (state) => {
  return {
    type: actionTypes.TOGGLE_PUSH_NOTIFICATIONS,
    payload: {
      state
    }
  }
}

export const toggleEmailNotifications = (state) => {
  return {
    type: actionTypes.TOGGLE_EMAIL_NOTIFICATIONS,
    payload: {
      state
    }
  }
}

export const toggleOnlineStatus = (state) => {
  return {
    type: actionTypes.TOGGLE_ONLINE_STATUS,
    payload: {
      state
    }
  }
}

export const handleThemeChange = (theme) => {
  return {
    type: actionTypes.SET_THEME,
    payload:{
      theme
    }
  }
}

export const handleScreenModeChange = (mode) => {
  return {
    type: actionTypes.SET_SCREEN_MODE,
    payload: {
      mode
    }
  }
}

export const handleWebpageThemeChange = (theme) => {
  return {
    type: actionTypes.SET_WEBPAGE_THEME,
    payload: {
      theme
    }
  }
}

const getSettingsRequest = () => {
  return {
    type: actionTypes.GET_SETTINGS_REQUEST
  }
}

const getSettingsSuccess = (settings) => {
  return {
    type: actionTypes.GET_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  }
}

const getSettingsFail = (error) => {
  return {
    type: actionTypes.GET_SETTINGS_FAIL,
    payload:{
      error
    }
  }
}

export const getSettings = () => {
  return async (dispatch) => {
    dispatch(getSettingsRequest());
    api.get('users/settings')
    .then(response => {
      dispatch(getSettingsSuccess(response.data));
      dispatch(SetLanguagesOnPage(response.data.settings.language));
      dispatch(setShowOnboardingElement(response.data.settings.showOnboarding));
    })
    .catch(error =>{
      dispatch(getSettingsFail(error));
    })
  }
}


const saveSettingsRequest = () => {
  return {
    type: actionTypes.SAVE_SETTINGS_REQUEST
  }
}

export const saveSettingsSuccess = (settings) => {
  return {
    type: actionTypes.SAVE_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  }
}

const saveSettingsFail = (error) => {
  return {
    type: actionTypes.SAVE_SETTINGS_FAIL,
    payload:{
      error
    }
  }
}

export const saveSettings = (payload) => {
  return async (dispatch) => {
    dispatch(saveSettingsRequest());
    return api.put('/users/settings', {
      ...payload
    })
    .then(response => {
      dispatch(saveSettingsSuccess(response.data));
      dispatch(updateOnlineStatus(!response.data.hideOnlineStatus))
      dispatch(setShowOnboardingElement(response.data.showOnboarding))
      return response
    })
    .catch(error =>{
      console.log(error);
      dispatch(saveSettingsFail(error));
      return error
    })
  }
}

export const setMaintenanceMode = (state) => {
  return {
    type: actionTypes.SET_MEINTANACE_MODE,
    payload: {
      state
    }
  }
}