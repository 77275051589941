import * as actionTypes from './avatarsActionTypes';
import api from '../../axios/api';

const getAvatarsRequest = () => {
  return {
    type: actionTypes.GET_AVATARS_REQUEST
  }
}

const getAvatarsSuccess = (avatarsList) => {
  return {
    type: actionTypes.GET_AVATARS_SUCCESS,
    payload: {
      avatarsList
    }
  }
}

const getAvatarsFail = (error) => {
  return {
    type: actionTypes.GET_AVATARS_FAIL,
    payload:{
      error
    }
  }
}

export const getAvatars = (requestData) => {
  return async (dispatch) => {
    dispatch(getAvatarsRequest());
    api.get(`/static/avatars?gender=${requestData && requestData.gender !== 'all' ? requestData.gender: '' }`).then(res => {
      dispatch(getAvatarsSuccess(res.data));
      return res.data;
    }).catch(e => {
      console.log(e);
      dispatch(getAvatarsFail(e))
    })
  }
}

export const setChosenAvatar = (avatar) => {
  return {
    type: actionTypes.SET_CHOSEN_AVATAR,
    payload: {
      avatar
    }
  }
}

export const setChosenAvatarById = (avatarId) => {
  return {
    type: actionTypes.SET_CHOSEN_AVATAR_BY_ID,
    payload: avatarId
  }
} 

export const setSaveButtonEnabled = (isEnabled) => {
  return {
    type: actionTypes.SET_SAVE_BUTTON_ENABLED,
    payload: isEnabled
  }
}