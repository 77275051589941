import { useEffect, useState } from 'react';
const GameRules = (props) => {

  const {
    chooseCustomRule,
    incomingRuleIndex
  } = props

  useEffect(() => {
    chooseCustomRule(incomingRuleIndex)
  }, [incomingRuleIndex])

  return (
    <>
    </>
  )
}
export default GameRules
