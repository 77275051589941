import { useEffect, useState } from "react"

const Countdown = (props) => {
  const {
    time,
    // playerId,
    callBackFunc,
    format = 'ss'
  } = props

  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft => timeLeft - 1)
      } else {
        clearInterval(interval)
        if (callBackFunc) {
          callBackFunc()
        }
      }
    }, 1000)
    return () => clearInterval(interval);
  }, [timeLeft])

  const getFormatedTime = () => {
    const time = timeLeft;
    let minutes = Math.floor(time / 60)
    let seconds = minutes > 0 ? time % (minutes * 60) : time
    minutes = minutes < 10 ? `0${minutes}`: minutes
    seconds = seconds < 10 ? `0${seconds}`: seconds
    return `${minutes}:${seconds}`
  }

  if(props.type === "html"){
    return (
      <span className="htmlCountdownText">
        <b>{timeLeft}</b>
        s
      </span>
    )
  }else{
    return (
      <text className="countdown">
        {format === 'ss' && <>{timeLeft}<tspan dx="1">s</tspan></>}
        {format === 'mm:ss' && <>{getFormatedTime()}</>}
      </text>
    )
  }
}

export default Countdown