import * as actionTypes from "./toggleStateActionTypes";

// default value
const initialState = {
  showChatState: false,
  delayIsActive: false,
  appearBackdrop: false,
  isCookiePopupHidden: false,
  isRefreshPopupShow: false,
};

// reducer to toggle chat and delay.
const togglesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SHOW_CHAT_STATE:
      return {
        ...state,
        showChatState: !state.showChatState,
      };
    case actionTypes.TOGGLE_DELAY_IS_ACTIVE:
      return {
        ...state,
        delayIsActive: action.payload.delayIsActive,
      };
    case actionTypes.TOGGLE_APPEAR_BACKDROP:
      return {
        ...state,
        appearBackdrop: !state.appearBackdrop,
      };
    case actionTypes.TOGGLE_IS_COOKIE_POPUP_HIDDEN:
      return {
        ...state,
        isCookiePopupHidden: !state.isCookiePopupHidden,
      };
    case actionTypes.TOGGLE_IS_REFRESH_POPUP_HIDDEN:
      return {
        ...state,
        isRefreshPopupShow: !state.isRefreshPopupShow,
      };
    case actionTypes.SHOW_REFRESH_POPUP:
      return {
        ...state,
        isRefreshPopupShow: true,
      };
    default:
      return state;
  }
};

export default togglesReducer;
