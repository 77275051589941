import FormInput from "../../FormInput/FormInput";
import api from '../../../axios/api';
import * as styles from '../../../styles/scss/components/ForgotPassword/ForgotPassword.module.scss';
import {SideBarSuccess} from '../../SidebarSuccess/SidebarSuccess';
import { useState, useEffect, useRef } from "react";
import useTranslation from 'next-translate/useTranslation'

const ForgotPassword = (props) => {
  const [email, setEmail] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    changed: false
  })
  const {t} = useTranslation();
  const [contentType, setContentType] = useState('reset')
  const [contentMessage, setContentMessage] = useState('')
  const [resetButtonText, setResetButtontext] = useState(t('common:buttons.send_request'));
  const [loading, setLoading] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const handleSendPasswordReset = (e) => {
    e.preventDefault()
    setResetButtontext(t('common:buttons.sending'))
    setEnabled(false)
    setLoading(false)
    if (email.valid && enabled) {
      api.post('/send-reset-link', {
        email: email.value
      }, {
        headers: {hideErrorPage: true}
      }).then(response => {
        setContentMessage(t('common:sidebar_body.reset_password.reset_password_link_text'))
        setContentType('success')
        setEnabled(false)
        setLoading(false)
      }).catch(e => {
        // debugger;
        setContentMessage(t(`errors:${e.response.data.message?.toLowerCase()}`))
        setContentType('error')
        setResetButtontext(t('common:buttons.send_request'))
        setEnabled(false)
        setLoading(false)
      })
    }
  }

  const handleChange = (e) => {
    setEnabled(true)
    setEmail(prevState => {
      return {
        ...prevState,
        value: e.target.value,
        changed: true
      }
    })
  }

  const emailRef = useRef()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email.changed) {
        
        if (email.value === emailRef.current.value) {
          const valid = email.regEx.test(email.value);
          if (valid) {
            if (emailRef.current.value.length >= 2) {
              api.post('/check-availability', {
                email: emailRef.current.value,
                // type: 'username'
              }, {
                headers: {hideErrorPage: true}
              }).then(response => {
                setEmail(prevState => {
                  return {
                    ...prevState,
                    valid: false,
                    errorText: t('errors:email.not_exists'),
                    changed: true
                  }
                })
              }).catch(() => {
                setEmail(prevState => {
                  return {
                    ...prevState,
                    valid: true,
                    errorText: '',
                    changed: true
                  }
                })
              })
            }
          } else {
            setEmail(prevState => {
              return {
                ...prevState,
                valid: false,
                errorText: t('errors:email.invalid'),
                changed: true
              }
            })
          }
        }
        
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [email.value, email.changed]);

  return (
    <>
      {contentType === 'reset' && (
        <div className = {styles.forgot_input}>
          <FormInput
            reference = {emailRef}
            type = 'email'
            value = {email.value}
            name = 'email'
            change = {handleChange}
            active = {email.value.length > 0}
            labelText = {t('common:inputs.email')}
            showIndicator = {true}
            id = 'signupEmail'
            valid = {email.valid}
            errorText = {email.errorText}
            changed = {email.changed}
            textBelow = {t('common:sidebar_body.reset_password.textBelow')}
          />
          <div className = {styles.button_container}>
            <button 
              type = 'button' 
              onClick = {handleSendPasswordReset}
              disabled = {loading || !email.valid || loading}
              >
                {resetButtonText}
            </button>
          </div>
        </div>
      )}
      {
        contentType === 'success' && (
          <SideBarSuccess 
            title = {t('common:sidebar_body.reset_password.request_successfully_sent')}
            message = {contentMessage}
            handleDone = {() => {
              props.toggleSidebar({
                isOpen: false
              })
            }}
            success = {true}
            buttonText = {t('common:buttons.done')}
          />
        )
      }
      {
        contentType === 'error' && (
          <SideBarSuccess
            title = {t('errors:general_error')}
            message = {contentMessage}
            handleDone = {() => {
              setContentType('reset')
              setContentMessage('')
              setEmail(prevState => {
                return {
                  ...prevState,
                  value: '',
                  valid: false,
                  errorText: '',
                  changed: false
                }
              })
            }}
            success = {false}
            buttonText = {t('common:buttons.try_again')}
          />
        )
      }
    </>
  )
}

export default ForgotPassword