
import useTranslation from 'next-translate/useTranslation'
import * as styles from '../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss'
const Principles = () => {
  const {t} = useTranslation();
  return(
    <div className={styles.single_rule_container}>
      <div className={`${styles.image_container} ${styles.principle}`}>
        <img src='/images/rules/game-principles-sidebar.svg' />
      </div>

      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.first_paragraph")}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.round_cards_first")}
      </p>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.round_cards_second")}
      </p>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.round_cards_third")}
      </p>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.round_cards_fourth")}
      </p>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.round_cards_fifth")}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.second_paragraph")}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.third_paragraph")}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.fourth_paragraph")}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t("game_rules:game_principle.fifth_paragraph")}
      
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text} ${styles.landscape}` }>
      {t("game_rules:game_principle.sixth_paragraph")}
      </p>
    </div>
  )
}

export default Principles