import * as styles from '../../../styles/scss/components/GameRules/GameRules.module.scss'
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

const GameRules = (props) => {

  const {
    gameData,
    generalRules,
    chooseCustomRule
  } = props
  const {t} = useTranslation();
  const [changedRules, setChangedRules] = useState(null);
  const [playerNumber, setPlayerNumber] = useState(null);
  const [gameMode, setGameMode] = useState(null)

  useEffect(() => {
    if (gameData.isGameDataLoaded) {
      const changedRules = []
      for (let i = 0; i < gameData.rules.all.length; i++) {
        const rule = {...gameData.rules.all[i]}
        for (let j = 0; j < gameData.rules.active.length; j++) {
          if (rule.id === gameData.rules.active[j].id) {
            rule.active = true
          }
        }
        changedRules.push(rule)
      }
      setChangedRules(changedRules)
      setPlayerNumber(gameData.players.length)
      setGameMode(gameData.mode)
    }
  }, [gameData])

  return (
    <div className = {styles.gameRules}>
      <div className = {styles.rules_modules}>
        <h2 className = {styles.rules_modules_title}>
          {t('game_rules:game_rules_general.current_game')}
        </h2>
        <div className = {styles.current_game_rules}>
          <div className = {styles.current_game_rules_module}>
            <div className = {styles.module_title}>{t('game_rules:game_rules_general.game_mode')}</div>
            <div className = {styles.module_information}>
              <span className = {styles.module_information_text}>{t(`game_rules:game_rules_general.mode.${gameMode}`)}</span>
              <img className = {styles.module_information_image} src = {`/images/rules/${gameMode}-dog.svg`}/>
            </div>
          </div>
          <div className = {styles.current_game_rules_module}>
            <div className = {styles.module_title}>{t('game_rules:game_rules_general.number_of_players')}</div>
            <div className = {styles.module_information}>
              <span className = {styles.module_information_text}>{playerNumber}</span>
              <img className = {styles.module_information_image} src = {`/images/rules/${playerNumber}players.svg`}/>              
            </div>
          </div>
          <div className = {`${styles.current_game_rules_module} ${styles.rules_holder}`}>
            <div className = {styles.module_title}>{t('game_rules:game_rules_general.rules')}</div>
            <div className = {styles.module_information}>
              {!!changedRules && (
                <ul className = {styles.module_rules}>
                  {changedRules.map(changedRule => {
                    if (changedRule.symbol === 'any' || changedRule.symbol === 'ccs') return
                    return (
                      <li
                        key = {`modalRule${changedRule.id}`}
                        className = {`${styles.single_rule} ${changedRule.active ? styles.active: ''}`}
                        >
                        <span className = {styles.rule_name}>{t(`common:sidebar_body.lobby_sidebar.rules.${changedRule.symbol}`)}</span>
                        <div className = {styles.rule_image_holder}>
                          <img src={`/images/rules/${changedRule.symbol}.svg`} />
                        </div>
                      </li>
                    )
                    })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className = {`${styles.rules_modules} ${styles.rules_list}`}>
        <h2 className = {styles.rules_modules_title}>
          {t('common:sidebar_titles.general_rules')}
        </h2>
        <div className = {styles.general_rules}>
          <ul className = {styles.general_rules_list}>
            {generalRules.map((generalRule, index) => {
              return (
                <li
                  key = {`generalRule${generalRule.id}`}
                  className = {styles.general_rule_item}
                  onClick = {() => {chooseCustomRule(index)}}
                >
                  <div className = {styles.general_rule}>
                    <div className = {styles.general_rule_top}>
                      <img
                        src = {generalRule.image}
                        className = {styles.rule_image}
                      />
                    </div>
                    <div className = {styles.general_rule_bottom}>
                      <span className = {styles.rule_text}>
                        {generalRule.rule_text}
                      </span>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* <div className={styles.rules_qr_code}>
        <div className={styles.qr_code_holder}>
          <img src="/images/sidebar/qr.png" className={styles.qr_code} />
          <div className={styles.qr_text}>
            <h4 className={styles.qr_title}>Scan QR Code</h4>
            <span className={styles.qr_description}>
              to view rules on mobile
            </span>
          </div>
        </div>
      </div> */}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    gameData: state.gameData
  }
}

// const mapDispatchToProps = dispatch => {
//   return {
//   }
// }
export default connect(mapStateToProps, null)(GameRules)
