import * as styles from '../../styles/scss/components/Tooltip/TextTooltip.module.scss'

const TextTooltip = (props) => {
  const {text, position} = props

  return (
    <span className = {`${styles.tooltip_text} ${styles[position]}`}>
      {text}
    </span>
  )
}

export default TextTooltip