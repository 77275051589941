import { useState, useEffect, useRef } from 'react';
import * as styles from '../../styles/scss/components/GameSearchingStateModals/index.module.scss';
import { CloseButton, MinimizeButton } from '../Buttons/Buttons'
import SearchingBody from './SearchingBody';
import { CSSTransition } from "react-transition-group";
import FoundBackSvg from '../../public/images/common/found_back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalSize } from '../../store/modals/modalsActions';
import useTranslation from 'next-translate/useTranslation';
import Countdown from '../Countdown/CountDown';
import { startMatchMaking, toggleAborting, toggleDisableAborting } from '../../store/matchMaking/matchMakingActions';
import { resetPublicLobby } from '../../store/lobby/publicLobby/lobbyActions';
import { resetMm } from '../../store/matchMaking/matchMakingActions';
import { toggleConfirmModal } from '../../store/modals/modalsActions';
import api from '../../axios/api';
import { useRouter } from 'next/router';
import { deviceType } from '../../helpers/functions';
import { setGamePending } from '../../store/gameSetup/gameSetupActions';
const CommonSearchingModal = (props) => {
  const {
    type,
    action,
    matchMaking,
    hasHeaderActionButton,
    modalType,
    lobbyData,
    headerActionButtonType,
    handleGameCouldNotStart,
    secondaryAction
  } = props


  const isConfirmModalOpen = useSelector(state => state.modals.isConfirmModalOpen)
  const {playersSearching} = useSelector(state => state.gameSetup)
  const {disbaleAborting} = useSelector(state => state.matchMaking)
  const getTitle = () => {
    switch(modalType) {
      case 'classic' : return t('wizard:online_game.title');
      case 'private' : return t('wizard:friends_game.title');
      case 'any': return t('common:buttons.quick_play')
    }
  }


  const {t} = useTranslation()



  const aborting = useSelector(state => state.matchMaking.aborting)
  const [device, setDevice] = useState(deviceType())
  const dispatch = useDispatch();

  const {locale} = useRouter()

  const modalIsMinimized = useSelector(state => state.modals.isSearchModalMinimized)
  const [listenerAdded, setListenerAdded] = useState(false)
  const minimizeHandler = (e, fromButton = false) => {
    e.stopPropagation()
    if (modalIsMinimized && !fromButton) {
      return
    }
    dispatch(toggleModalSize(!modalIsMinimized));
  }

  const handleSearchAgain = () => {
    const matchMakingDetails = {
      mode: lobbyData.mode,
      type: lobbyData.type,
      players: lobbyData.members.length,
      rules: lobbyData.rules.map(rule => rule.id),
      vs: lobbyData.vs,
      region: 'global',
      name: t('common:online_game'),
    }
    dispatch(resetPublicLobby())
    dispatch(resetMm())
    dispatch(startMatchMaking(matchMakingDetails))
    secondaryAction()

  }
  const draggableRef = useRef()
  
  useEffect(() => {
    if (draggableRef) {
      // alert('1 - draggableRef')
      const element = draggableRef.current
      if (!modalIsMinimized) {
        // alert('2- !modalIsMinimized')
        if (window.innerWidth > 1023) {
          // alert('3 - (window.innerWidth > 1023)')
          element.style.right = 'auto'
          element.style.left = '50%'
          element.style.top = '50%'
          element.style.transform = "translate(-50%, -50%)"
        } else {
          // alert('4 - NOT (window.innerWidth > 1023)')
          element.style.top = '0';
          element.style.left = '0'
        }  
      } else {
        // alert('5- !modalIsMinimized')
        element.style.transform = null
          element.style.top = 'auto'
          element.style.bottom = '0.3em'
          element.style.left = 'auto'
          element.style.right = '0.3em'
      }
    }
  }, [modalIsMinimized, draggableRef, device])

  useEffect(() => {
    window.addEventListener("orientationchange", resetMinimizedPopupSizeLandscapePortrait, false);

    return () => {
      window.removeEventListener('orientationchange', resetMinimizedPopupSizeLandscapePortrait)
    }
  }, [])

  function resetMinimizedPopupSizeLandscapePortrait(){
    // alert('6 - resetMinimizedPopupSizeLandscapePortrait')
    const element = draggableRef.current
    if(this.window.orientation === 0 || this.window.orientation === 90){
      if(modalIsMinimized){
        // alert('7 - (this.window.orientation === 0 || this.window.orientation === 90)')
        element.style.inset = 'auto 0.3em 0.3em auto'
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", resizeDesktop, false);

    return () => {
      window.removeEventListener('resize', resizeDesktop)
    }
  }, [modalIsMinimized])

  function resizeDesktop() {
    // alert('8 - resizeDesktop')
    if(window.matchMedia('(min-width: 992px)').matches){
      // alert("9 - window.matchMedia('(min-width: 992px)').matches")
      const element = draggableRef.current
      if(modalIsMinimized){
        // alert('10 - modalIsMinimized')
        element.style.inset = 'auto 0.3em 0.3em auto'
      } else {
        // alert('11 - !modalIsMinimized')
        element.style.right = 'auto'
        element.style.left = '50%'
        element.style.top = '50%'
        element.style.transform = "translate(-50%, -50%)"
      }
    }
  }
  
  function dragElementDesktop(elmnt, shouldBeDragged = true) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (!elmnt) return
    let top = elmnt.getBoundingClientRect().top
    let left = elmnt.getBoundingClientRect().left
    if (elmnt) {
      /* if present, the header is where you move the DIV from:*/
      elmnt.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }  
  
    function dragMouseDown(e) {
      top = elmnt.getBoundingClientRect().top
      left = elmnt.getBoundingClientRect().left
      if (!shouldBeDragged) return
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e) {
      if (!shouldBeDragged) return
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      if (top - pos2 > window.innerHeight - elmnt.getBoundingClientRect().height) {
        top = window.innerHeight - elmnt.getBoundingClientRect().height
      } else if (top - pos2 > 0) {
        top = top - pos2  
      } else {
        top = 0
      }

      if (left - pos2 > window.innerWidth - elmnt.getBoundingClientRect().width) {
        left = window.innerWidth - elmnt.getBoundingClientRect().width
      }else if (left - pos1 > 0) {
        left = left - pos1  
      } else {
        left = 0
      }

      elmnt.style.top = top + "px";
      elmnt.style.left = left + "px";
    }
  
    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  const dragElemendMobile = (elmnt, shouldBeDragged) => {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (!elmnt) return
    let top = elmnt.getBoundingClientRect().top
    let left = elmnt.getBoundingClientRect().left
    if (elmnt) {
      /* if present, the header is where you move the DIV from:*/
      elmnt.ontouchstart = dragTouchDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.ontouchstart = dragTouchDown;
    }

    function dragTouchDown(e) {
      top = elmnt.getBoundingClientRect().top
      left = elmnt.getBoundingClientRect().left
      if (!shouldBeDragged) return
      e = e || window.event;
      // e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.targetTouches[0].clientX;
      pos4 = e.targetTouches[0].clientY;
      document.ontouchend = closeDragElement;
      // call a function whenever the cursor moves:
      document.ontouchmove = elementDrag;
    }

    function elementDrag(e) {
      if (!shouldBeDragged) return
      e = e || window.event;
      // e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.targetTouches[0]?.clientX;
      pos2 = pos4 - e.targetTouches[0]?.clientY;
      pos3 = e.targetTouches[0]?.clientX;
      pos4 = e.targetTouches[0]?.clientY;
      // set the element's new position:
      if (top - pos2 > window.innerHeight - elmnt.getBoundingClientRect().height) {
        top = window.innerHeight - elmnt.getBoundingClientRect().height
      } else if (top - pos2 > 0) {
        top = top - pos2  
      } else {
        top = 0
      }

      if (left - pos2 > window.innerWidth - elmnt.getBoundingClientRect().width) {
        left = window.innerWidth - elmnt.getBoundingClientRect().width
      }else if (left - pos1 > 0) {
        left = left - pos1
      } else {
        left = 0
      }

      elmnt.style.top = top + "px";
      elmnt.style.left = left + "px";
    }


    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.ontouchend = null;
      document.ontouchmove = null;
    }
  }


  const addDragableAction = (element, modalIsMinimized) => {
    if(!element) return
    if (device === 'desktop') {
      dragElementDesktop(element, modalIsMinimized)
    } else {
      dragElemendMobile(element, modalIsMinimized)
    }
  }

  addDragableAction(draggableRef.current, modalIsMinimized)
  const getSoloSearchingNumber = () => {
    const playersNumber = matchMaking.selectedGameData.playerLimit
    if (playersNumber === 'any') {
      const summedUp = playersSearching[0] + playersSearching[1] + playersSearching[2] + playersSearching[4]
      // return summedUp > 0 ? summedUp - 1 : 0
      return summedUp > 0 ? summedUp : 0
    }
    else if (playersNumber == 2) {
      // return playersSearching[0] > 0 ? playersSearching[0] - 1 : 0
      return playersSearching[0] > 0 ? playersSearching[0] : 0
    } else if (playersNumber == 3) {
      // return playersSearching[1] > 0 ? playersSearching[1] - 1 : 0
      return playersSearching[1] > 0 ? playersSearching[1] : 0
    } else {
    //  return playersSearching[2] > 0 ? playersSearching[2] - 1 : 0 
    return playersSearching[2] > 0 ? playersSearching[2] : 0 
    }
  }

  useEffect(() => {
    if (type === 'searchingGame') {
      dispatch(toggleAborting(false))
    }
  }, [type])

  useEffect(() => {
    dispatch(toggleDisableAborting(false))
  }, [])
  return(
    <div 
      onClick={(e) => {minimizeHandler(e, false)}} 
      className = {`${styles.modal_container} ${isConfirmModalOpen ? styles.low_z_index: ''} ${styles.zIndexHigh} ${modalIsMinimized ? styles.minimized : ''} ${styles[type]}`}
      ref = {draggableRef}
      >
      {type === 'gameFound' && 
        <div className={styles.found_back}>
          <FoundBackSvg />
        </div>
      }
      <div className = {styles.upper_part}>
        <div className = {styles.modal_head}>
          <CSSTransition  
            in = {!modalIsMinimized}
            timeout = {modalIsMinimized ? 1 : 500}
            // apperar = {true}
            unmountOnExit
            classNames = 'modal_header'
            >
            <div className = {styles.modal_details_container}>
              <div className = {`${styles.modal_image_container} ${styles[modalType]}`}>
                <img className = {styles.modal_image} src = {`/images/dashboard/${modalType}_bottom.svg`} />
              </div>
              <div className = {styles.modal_title_container}>
                <h2 className = {styles.modal_title}>{getTitle()}</h2>
              </div>
            </div>

          </CSSTransition>
          {hasHeaderActionButton && (
            <div className = {styles.modal_close_button_container}>
              {headerActionButtonType === 'minimize' && 
                <MinimizeButton 
                  click = { (e) => {minimizeHandler(e, true)} }
                  state = { modalIsMinimized }
                />
              }
            </div>
          )}
        </div>
        <div className = {styles.dynamic_part}>
          {/* <CSSTransition
            in = {!minimized}
            timeout = {minimized ? 1: 500}
            unmountOnExit
            apperar = {true}
            classNames = 'modal_header'
            > */}
              <>
                {type === 'searchingGame' && (
                  <div className= {styles.search_progress_container}>
                    <SearchingBody 
                      gameMode = {matchMaking.selectedGameData.gameMode.label}
                      playerNumber = {matchMaking.selectedGameData.playerLimit}
                      rules = {[...matchMaking.selectedGameData.rules]}
                      versus = {matchMaking.selectedGameData.vs.label}
                      type = {type}
                      minimized = {modalIsMinimized}
                      currentlySearchingPLayersNumber = {{
                        solo: getSoloSearchingNumber(),
                        // team: playersSearching[3] > 0 ? playersSearching[3] - 1 : 0,
                        team: playersSearching[3] > 0 ? playersSearching[3] : 0,
                        any: playersSearching.reduce((partialSum, elem) => partialSum + elem, 1)
                      }}
                    />
                  </div>
                )}
                {type === 'gameFound' && (
                  <div className= {styles.search_progress_container}>
                    <SearchingBody 
                      gameMode = {lobbyData.mode}
                      playerNumber = {lobbyData.members.length}
                      rules = {[...lobbyData.rules]}
                      versus = {lobbyData.vs.label}
                      type = {type}
                      minimized = {modalIsMinimized}
                    />
                  </div>
                )}
                {type === 'gameCouldNotStart' && (
                  <div className= {styles.search_progress_container}>
                    <SearchingBody 
                      gameMode = {lobbyData.mode}
                      playerNumber = {lobbyData.members.length}
                      rules = {[...lobbyData.rules]}
                      versus = {lobbyData.vs.label}
                      type = {type}
                      minimized = {modalIsMinimized}
                    />
                  </div>
                )}
                {type === 'ongoingGame' && (
                  <div className= {styles.search_progress_container}>
                    <SearchingBody 
                      gameMode = {lobbyData.mode}
                      playerNumber = {lobbyData.members.length}
                      rules = {[...lobbyData.rules]}
                      versus = {lobbyData?.vs}
                      type = {type}
                      minimized = {modalIsMinimized}
                  />
                </div>
              )}
              </>
          {/* </CSSTransition> */}
        </div>
      </div>
      <div className = {`${styles.bottom_part} ${styles.extended}`}>
        <div className = {styles.left_side}></div>
        <div className = {styles.center}>
          {type === 'searchingGame' && (
            aborting ? (
              <button 
                className = {styles.abort_button}
              disabled={aborting}>
                <span className={styles.text}>
                  {t('common:buttons.aborting')}
                </span>
                <div className={styles.indicator}>
                  <span><Countdown time="15" type="html"/></span>
                </div>
            </button>
            ) : (
              <button className = {styles.reject_button} onClick={(e) => {
                dispatch(setGamePending(true))
                e.stopPropagation();
                if (aborting || disbaleAborting) {
                  return
                }
                action();
                dispatch(toggleAborting(true))
              }} 
              disabled={aborting || disbaleAborting}>
                  {t('common:buttons.abort_capital')}
              </button>
            )

          )}
          {type === 'gameFound' && (
            <button disabled className = {`${styles.accept_button} ${styles.with_indicator}`} onClick={action}>
              <span className={styles.text}>
                {t('common:buttons.starting')}
              </span>
              <div className={styles.indicator}>
                <span className={styles.primary}>
                  <Countdown time={10} type="html" callBackFunc={() => {handleGameCouldNotStart(true)}}/>
                </span>
              </div>
            </button>
          )}
          {type === 'gameCouldNotStart' && (
            <div className={`${styles.buttons_container} ${styles.counld_not_start}`}>
              <button className = {styles.accept_button} onClick={(e) => {
                    e.stopPropagation()
                    handleSearchAgain()
                  }}
                >
                {t('common:buttons.search_again')}
              </button>
              <button className={styles.cancel_button} onClick = {(e) => {
                e.stopPropagation()
                dispatch(resetPublicLobby())
                dispatch(resetMm())
                secondaryAction()           
              }}>
                {t('common:buttons.cancel')}
              </button>
            </div>
          )}
          {type === 'ongoingGame' && (
            <div className={`${styles.buttons_container} ${styles.counld_not_start}`}>
              <button className={styles.reject_button} onClick = {(e) => {
                e.stopPropagation()
                dispatch(toggleConfirmModal(
                  {
                    isOpen: true,
                    confirmModalData: {
                      text: t('common:modals.leave_game_title.leave_game_title'),
                      subText: t('common:modals.leave_game_title.leave_game_description'),
                      acceptText: t('common:buttons.reconnect'),
                      rejectText: t('common:buttons.leave'),
                      closeFunc: () => {dispatch(toggleConfirmModal({isOpen: false}))},
                      acceptFunc: () => {window.location.href = `/${locale}/lobby/${lobbyData.lobbyId}/game/${lobbyData.id}`},
                      rejectFunc: async () => {                        
                        const response  = await api.post(`/lobby/${lobbyData.lobbyId}/game/${lobbyData.id}/leave`)
                        dispatch(toggleConfirmModal({isOpen: false}))
                        sessionStorage.removeItem('isQuickSearch')
                        secondaryAction()
                      }
                    }
                  }
                ))
              }}>
                {t('common:buttons.leave')}
              </button>
              <button className = {styles.accept_button} onClick={(e) => {
                    e.stopPropagation()
                    window.location.href = `/${locale}/lobby/${lobbyData.lobbyId}/game/${lobbyData.id}`
                    // handleSearchAgain()
                  }}
                >
                {t('common:buttons.reconnect')}
              </button>
            </div>
          )}
        </div>
          <div className = {styles.right_side}>
          </div>
      </div>
    </div>
  )
}

export default CommonSearchingModal