import {combineReducers} from 'redux';
import userReducer from './user/userReducer';
import authReducer from './auth/authReducer';
import friendsReducer from './friends/friendReducer';
import chatReducer from './chat/chatReducer';
import pusherReducer from './pusher/puhserReducer';
import gameSetupReducer from './gameSetup/gameSetupReducer';
import matchMakingReducer from './matchMaking/matchMakingReducer';
import lobbyReducer from './lobby/publicLobby/lobbyReducer';
import gameDataReducer from './gameData/gameDataReducer'
import modalsReducer from './modals/modalsReducer';
import notificationReducer from './notifications/notificationReducer';
import settingsReducer from './settings/settingsReducer';
import backdropStateReducer from './backdropState/backdropStateReducer';
import avatarsReducer from './avatars/avatarsReducer';
import privateLobbyReducer from './lobby/privateLobby/privateLobbyReducer';
import myGamesReducer from './myGames/myGamesReducer';
import staticInfoReducer from './static/staticInfoReducer';
import leaderboardReducer from './leaderboard/leaderboardReducer';
import languagesReducer from './languages/languagesReducer';
import errorsReducer from './errors/errorsReducer'
import onBoardingReducer from './onBoarding/onBoardingReducer';
import togglesReducer from './toggles/toggleStateReducer';
import statEventReducer from './statEvent/statEventReducer';
import leaderboardNewReducer from './leaderboardNew/leaderboardNewReducer';

const rootReducer = combineReducers({
    toggles: togglesReducer,
    user: userReducer,
    auth: authReducer,
    friends: friendsReducer,
    chat: chatReducer,
    pusher: pusherReducer,
    gameSetup: gameSetupReducer,
    matchMaking: matchMakingReducer,
    lobby: lobbyReducer,
    gameData: gameDataReducer,
    modals: modalsReducer,
    notifications: notificationReducer,
    settings: settingsReducer,
    backdropState: backdropStateReducer,
    avatars: avatarsReducer,
    privateLobby: privateLobbyReducer,
    myGames: myGamesReducer,
    static: staticInfoReducer,
    leaderboard: leaderboardReducer,
    languages: languagesReducer,
    errors: errorsReducer,
    onBoarding: onBoardingReducer,
    statEvent: statEventReducer,
    leaderboardNew: leaderboardNewReducer,
})

export default rootReducer;