import { useEffect, useRef, useState } from "react";
import useTranslation from "next-translate/useTranslation";
export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return { ref, isComponentVisible, setIsComponentVisible };
}


export function useOutsideAlerter(initialIsVisible, callbackfunction) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (isComponentVisible) {
        setIsComponentVisible(false);
        if (!!callbackfunction) {
          callbackfunction()
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return [ref, isComponentVisible, setIsComponentVisible];
}


export function useTeamName (team) {
  const {t} = useTranslation()
  const [teamName, setTeamName] = useState('')
  const [restructuredTeam, setRestructuredTeam] = useState('')
  const teamNameKeys = [
    "axe",
    "boat",
    "bow_arrow",
    "butterfly",
    "cat",
    "crown",
    "dog",
    "dragon",
    "dwarf",
    "eagle",
    "elf",
    "goat",
    "hammer",
    "pirate_swords",
    "potion",
    "skull",
    "star",
    "sword",
    "top_hat",
    "wizard_hat",
  ]

  const handleTeamNameChange = (teamName) => {
    if (teamNameKeys.indexOf(teamName) < 0) {
      setRestructuredTeam(teamName)
    } else {
      setRestructuredTeam(t(`team_names:${teamName}`))
    }
  }


  useEffect(() => {
    setTeamName(team)
  }, [team])
  
  useEffect(() => {
    handleTeamNameChange(teamName)
  }, [teamName])


  return [restructuredTeam, setTeamName]
}