export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const SET_GLOBAL_MODAL_STATE = 'SET_GLOBAL_MODAL_STATE';

export const SET_USER_FOR_PROFILE_SIDEBAR = 'SET_USER_FOR_PROFILE_SIDEBAR';

export const TOGGLE_RESPONSIVE_PUP_UP = 'TOGGLE_RESPONSIVE_PUP_UP';

export const TOGGLE_CONFIRM_MODAL = 'TOGGLE_CONFIRM_MODAL';

export const SET_OFFILNE_MODAL_STATE = 'SET_OFFILNE_MODAL_STATE';
export const BUZZ_OFFLINE_MODAL = 'BUZZ_OFFLINE_MODAL';

export const TOGGLE_MODAL_RESIZE = 'TOGGLE_MODAL_RESIZE';