export const generateQueryString = (obj) => {
    if (!obj) {
        return ''
    }
    let query = '?'
    let params = [];
    Object.keys(obj).map(key => {
        let value = '';
        switch(typeof obj[key]) {
            case 'number':
            case 'string': {
                value = obj[key]
                break;
            }
            default : {
                value = JSON.stringify(obj[key]);
            }
        }
        if(value) {
            params.push(`${key}=${value}`);
        }
    })
    return '?' + params.join('&');
}