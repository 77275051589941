import * as actionTypes from './friendActionTypes.js';

const initialState = {
  isLoading: false,
  friendsList: [],
  peopleList: [],
  isDataLoaded: false,
  inviteFriend: {
    email: '',
    avatar: '',
  }
}

const updateFriendsOnlineStatus = (friendsList, friendId, onlineStatus) => {
  const updatedFriendsList = [...friendsList.map(friendObj=>{
    if (friendObj.friend.id === friendId) {
      return {
        ...friendObj,
        friend: {
          ...friendObj.friend,
          online: onlineStatus
        }
      }
    }
    return {
      ...friendObj
    }
  })]
  return updatedFriendsList
}

const friendReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_FRIEND_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEND_FRIEND_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_FRIEND_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.GET_FRIEND_LIST_SUCCESS:
      return {
        ...state,
        friendsList: [...action.payload.friendsList],
        isLoading: false
      };
    case actionTypes.GET_FRIEND_LIST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEND_ACCEPT_FRIEND_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_ACCEPT_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        friendsList: [...state.friendsList, action.payload.friend],
        isLoading: false
      };
    case actionTypes.SEND_ACCEPT_FRIEND_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEND_DECLINE_FRIEND_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_DECLINE_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        // friendsList: [...action.payload.friendsList],
        isLoading: false
      };
    case actionTypes.SEND_DECLINE_FRIEND_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEND_BLOCK_FRIEND_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_BLOCK_FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        friendsList: [...action.payload.friendsList],
        isLoading: false
      };
    case actionTypes.SEND_BLOCK_FRIEND_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEND_DELETE_FRIEND_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_DELETE_FRIEND_REQUEST_SUCCESS: {
      const filteredFriends = state.friendsList.filter(friendshipObj => friendshipObj.id !== action.payload.friendshipId)
      return {
        ...state,
        friendsList: [...filteredFriends],
        isLoading: false
      };
    }
    case actionTypes.SEND_DELETE_FRIEND_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.SEARCH_PEOPLE_REQUEST: {
      return { 
        ...state,
        isDataLoaded: false,
        isLoading: true
      }
    }
    case actionTypes.SEARCH_PEOPLE_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        peopleList: action.payload.peopleList,
        isDataLoaded: true
      }
    }
    case actionTypes.SEARCH_PEOPLE_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        isDataLoaded: false
      }
    }
    case actionTypes.SET_REFFERAL_EMAIL: {
      return {
        ...state,
        inviteFriend: {
          ...state.inviteFriend,
          email: action.payload
        }
      }
    }
    case actionTypes.SET_REFFERAL_AVATAR: {
      return {
        ...state,
        inviteFriend: {
          ...state.inviteFriend,
          avatar: action.payload
        }
      }
    }
    case actionTypes.UPDATE_FRIEND_ONLINE_STATUS: {
      // debugger;
      const {friendId, onlineStatus} = action.payload
      const updatedFriendsList = updateFriendsOnlineStatus([...state.friendsList], friendId, onlineStatus)
      return{
        ...state,
        friendsList: [...updatedFriendsList]
      }
    }
    default:
      return {...state};
  }
}

export default friendReducer;