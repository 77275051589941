import { connect } from 'react-redux';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { MediumPrimaryIndicator } from '../Buttons/Buttons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as styles from '../../styles/scss/components/MyGames/MyGames.module.scss';
import { getMyGames } from '../../store/myGames/myGamesActions';
import Avatar from '../Avatar/Avatar';
import useTranslation from 'next-translate/useTranslation';

const MyGames = () => {
  const settings = {
    showIndicators: false,
    showStatus: false,
    useKeyboardArrows: true,
    emulateTouch: false,
    autoPlay: false,
    infiniteLoop: false,
    showArrows: false,
    showThumbs: false,
    width: 100
  };

  const [activeFilter, setActiveFilter] = useState('active')

  return (
    <section className = {styles.myGames_section}>
      <div className = {styles.myGames_filter_container}>
        <Carousel 
          {...settings}
          centerMode
          >
          <div className = {styles.filter_button_container}>
            <MediumPrimaryIndicator
              buttonText = 'Active'
              indicator = {5}
              clickAction = {() => {setActiveFilter('active')}}
              active = {activeFilter === 'active'}
            />
          </div>
          <div className = {styles.filter_button_container}>
            <MediumPrimaryIndicator
              buttonText = 'finished'
              indicator = {5}
              clickAction = {() => {setActiveFilter('finished')}}
              active = {activeFilter === 'finished'}
            />
          </div>
        </Carousel> 
      </div>
      <div className = {styles.games_list}>
        <ul className = {styles.games_list_ul}>
          <li className = {styles.games_list_li}>
            <div className = {styles.game_container}>
              <div className = {styles.game_type}>
                <div className = {`${styles.game_type_image_container} ${styles.private}`}>
                  <img className = {styles.game_type} src = {`/images/dashboard/${'private'}_bottom.svg`} />
                </div>
                <h3 className = {styles.game_type_title}>
                  Friends Game
                </h3>
              </div>
              <div className = {styles.game_details}>
                <div className = {styles.game_setup}>
                  <button>Now</button>
                  <button>Solo</button>
                  <button>4</button>
                </div>
              </div>
              <div className = {styles.game_rules}>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'sbd'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'sc'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'cs'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'jf'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'js'}.svg`} />
                  </button>
                </div>
              </div>
              <div className = {styles.members}>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'
                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
              </div>
              <div className = {styles.open_game_button_container}>
                <button className = {styles.open_game_button}>open</button>
              </div>
            </div>
          </li>
          <li className = {styles.games_list_li}>
            <div className = {styles.game_container}>
              <div className = {styles.game_type}>
                <div className = {`${styles.game_type_image_container} ${styles.private}`}>
                  <img className = {`${styles.game_type}`} src = {`/images/dashboard/${'private'}_bottom.svg`} />
                </div>
                <h3 className = {styles.game_type_title}>
                  Friends Game
                </h3>
              </div>
              <div className = {styles.game_details}>
                <div className = {styles.game_setup}>
                  <button>Now</button>
                  <button>Solo</button>
                  <button>4</button>
                </div>
              </div>
              <div className = {styles.game_rules}>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'sbd'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'sc'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'cs'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'jf'}.svg`} />
                  </button>
                </div>
                <div 
                  className = {styles.rule_button_container}
                  >
                  <button 
                    className = {styles.selected_rules}
                    onClick = {() => {showDropDown('rules')}}
                  >
                    <img src={`/images/rules/${'js'}.svg`} />
                  </button>
                </div>
              </div>
              <div className = {styles.members}>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'
                  />
                </div>
                <div className = {styles.member_container}>
                  <Avatar 
                    mode = 'normal'
                    hasFlag = {false}
                    avatarUrls = {{}}
                    size = 'size48'

                  />
                </div>
              </div>
              <div className = {styles.open_game_button_container}>
                <button className = {styles.open_game_button}>open</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    // auth: state.auth,
    gameList: state.myGames.myGamesList,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onGetMyGames: (payload) => dispatch(getMyGames(payload)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyGames);