import * as actionTypes from './avatarsActionTypes';

const initialState = {
  isLoading: false,
  saveButtonEnabled: false,
  avatarsList: [],
  chosenAvatar: null,
  index: 0,
}

const notificationReducer = (state = initialState, action) => {

  const getCurrentAvatarIndex = (avatar) => {
    if(!avatar) {
      return state.index > 0 ? 0 : state.avatarsList.length - 1;
    }
    return state.avatarsList ? state.avatarsList.findIndex((avatar) => avatar.id == action.payload.avatar.id) : 0
  }

  switch (action.type) {
    case actionTypes.SET_SAVE_BUTTON_ENABLED: {
      return {
        ...state,
        saveButtonEnabled: action.payload
      }
    }
    case actionTypes.GET_AVATARS_REQUEST: {
      return {
        ...state,
        // isLoading: true
      }
    };
    case actionTypes.GET_AVATARS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        avatarsList: action.payload.avatarsList
      }
    };
    case actionTypes.GET_AVATARS_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    };
    case actionTypes.SET_CHOSEN_AVATAR: {
      let newIndex = getCurrentAvatarIndex(action.payload.avatar);
      return {
        ...state,
        chosenAvatar: action.payload.avatar,
        index: newIndex,
      }
    }
    case actionTypes.SET_CHOSEN_AVATAR_BY_ID: {
      let chosen = state.avatarsList.find((avatar) => avatar.id == action.payload)
      return {
        ...state,
        chosenAvatar: chosen ? chosen : (state.avatarsList.length ? state.avatarsList[0]: {})
      }
    }
    default:
      return state;
    }
}



export default notificationReducer;