export const GET_PRIVATE_LOBBY_DATA_REQUEST1 = 'GET_PRIVATE_LOBBY_DATA_REQUEST';
export const GET_PRIVATE_LOBBY_DATA_SUCCESS1 = 'GET_PRIVATE_LOBBY_DATA_SUCCESS1';
export const GET_PRIVATE_LOBBY_DATA_FAIL1 = 'GET_PRIVATE_LOBBY_DATA_FAIL1';

export const PLAYER_SIT_DOWN_REQUEST = 'PLAYER_SIT_DOWN_REQUEST';
export const PLAYER_SIT_DOWN_SUCCESS = 'PLAYER_SIT_DOWN_SUCCESS';
export const PLAYER_SIT_DOWN_FAIL = 'PLAYER_SIT_DOWN_FAIL';

export const PLAYER_GET_UP_REQUEST = 'PLAYER_GET_UP_REQUEST';
export const PLAYER_GET_UP_SUCCESS = 'PLAYER_GET_UP_SUCCESS';
export const PLAYER_GET_UP_FAIL = 'PLAYER_GET_UP_FAIL';

export const DELETE_LOBBY_REQUEST = 'DELETE_LOBBY_REQUEST';
export const DELETE_LOBBY_SUCCESS = 'DELETE_LOBBY_SUCCESS';
export const DELETE_LOBBY_FAIL = 'DELETE_LOBBY_FAIL';

export const PLAYER_JOIN_REQUEST = 'PLAYER_JOIN_REQUEST';
export const PLAYER_JOIN_SUCCESS = 'PLAYER_JOIN_SUCCESS';
export const PLAYER_JOIN_FAIL = 'PLAYER_JOIN_FAIL';

export const PLAYER_ROLL_DICE_REQUEST = 'PLAYER_ROLL_DICE_REQUEST';
export const PLAYER_ROLL_DICE_SUCCESS = 'PLAYER_ROLL_DICE_SUCCESS';
export const PLAYER_ROLL_DICE_FAIL = 'PLAYER_ROLL_DICE_FAIL';


export const PRIVATE_SET_JOINED_PLAYER = 'PRIVATE_SET_JOINED_PLAYER';
export const SET_REDIRECT_TO_PRIVATE = 'SET_REDIRECT_TO_PRIVATE';
export const SET_MM_SETTING_FOR_LOBBY = 'SET_MM_SETTING_FOR_LOBBY';

export const UPDATE_GAME_MODE = 'UPDATE_GAME_MODE';
export const UPDATE_VERSUS = 'UPDATE_VERSUS';
export const UPDATE_SEATS = 'UPDATE_SEATS';
export const UPDATE_RULES = 'UPDATE_RULES';
export const KICK_MEMBERS = 'KICK_MEMBERS';
export const ADD_MEMBERS = 'ADD_MEMBERS';
export const UPDATE_JOINED_STATUS = 'UPDATE_JOINED_STATUS';
export const HANDLE_CHANGE_PLAYER_SEAT = 'HANDLE_CHANGE_PLAYER_SEAT';
export const HANDLE_PLAYER_STAND_UP = 'HANDLE_PLAYER_STAND_UP';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const CHANGE_GAME_MODE = 'CHANGE_GAME_MODE';
export const SET_ENABLED_STATUS_TO_PLAYERS_NUMBER = 'SET_ENABLED_STATUS_TO_PLAYERS_NUMBER';
export const SET_ENABLED_STATUS_TO_GAME_MODE = 'SET_ENABLED_STATUS_TO_GAME_MODE';
export const SET_PRIVATE_PLAYER_NUMBER = 'SET_PRIVATE_PLAYER_NUMBER';
export const CHANGE_LOBBY_NAME = 'CHANGE_LOBBY_NAME';
export const RESET_LOBBY = 'RESET_LOBBY';
