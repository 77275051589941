import {useEffect, useState} from 'react';

import styles from '../../styles/scss/components/SidebarSuccess/SidebarSuccess.module.scss';
import { SubmitButton } from '../SubmitButton/SubmitButton';

export const SideBarSuccess = (props) => {
  const [buttonState, setButtonState] = useState({
    text: 'Done',
    loading: false,
    valid: true,
    handleSubmit: (e) => handleDone(e),
    loadingComponent: null,
    errorText: '',
    success: true,
    size: 'medium',
  });

  const defaultState = {
    title: '',
    message: '',
    handleDone: () => {},
    success: true,
    buttonText: 'Done'
  }

  const state = {...defaultState, ...props}

  useEffect(() => {
    setButtonState({
      ...buttonState,
      text: props.buttonText ?? buttonState.text 
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    state.handleDone();
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.success_circle} ${state.success ? styles.success : styles.error}`}>
        <img src={`/images/sidebar/${state.success ? 'tick-green' : 'tick-x'}.svg`}/>
      </div>
      <p className={`${styles.title} ${state.success ? styles.success : styles.error}`}>{state.title}</p>
      <p className={styles.message}>{state.message}</p>
      <div className={styles.btn_cont}>
      <SubmitButton {...buttonState} handleSubmit={handleSubmit}/>
      </div>
    </div>
  )
}