import * as styles from '../../styles/scss/components/TeamSign/TeamSign.module.scss'

const TeamSign = ({signName, active}) => {
  return (
    <div className={`${styles.team_sign} ${active && styles.active}`}>
      <img className={styles.logo_iamge} src={`/images/teamIcons/${signName}.svg`}/>
    </div>
  );
}

export default TeamSign;