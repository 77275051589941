import { useRouter } from 'next/router';
import {connect} from 'react-redux';
import { setTypeFilter } from '../../../store/leaderboard/leaderboardActions';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import * as styles from '../../../styles/scss/components/TableFilters/TableFilters.module.scss';
import useTranslation from 'next-translate/useTranslation';
// New
import * as leaderboardNewActions from '../../../store/leaderboardNew/leaderboardNewActions'

const TableFilters = (props) => {
  const {
    typeFilters,
    onToggleSidebar,
    // NEW
    filterNew,
    filterTypeNew,
    onSetFilterTypeNew,
    onToggleMobileChangeType
  } = props
  const router = useRouter();
  const {t} = useTranslation()

  const handleSetTypeFilter = (selectedTypeFilter) => {
    onSetFilterTypeNew({filterType: selectedTypeFilter})
    onToggleMobileChangeType({isToggleMobileChangeType: true})
    router.push('', {query: {filter: filterNew, tab: selectedTypeFilter}})
    onToggleSidebar({
      isOpen: false,
      sidebarType: ''
    })
  }

  return (
    <>
      <div className = {styles.filters_right}>
        <div className = {styles.filter_name}>
          <h2>{t('common:sidebar_titles.leaderboard_sidebar_title')}</h2>
        </div>
        <div className = {styles.filter_types}>
          <div className = {styles.filter_type_ul}>
            {typeFilters.map(filter => {
              return (
                <div className = {styles.filter_type_li} key = {`typeFilter${filter.id}`}>
                  <div className = {styles.filter_type_container}>
                    <button 
                      className = {`${styles.filter_button} ${filterTypeNew == filter.value && styles.active}`}
                      onClick = {() => {handleSetTypeFilter(filter.value)}}
                      >
                      {t(`leaderboard:tab_${filter.label.toLowerCase()}`)}
                    </button>
                  </div>
                </div>
              )
            })}              
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    typeFilters: state.leaderboard.typeFilters,

    // NEW
    filterNew: state.leaderboardNew.filter,
    filterTypeNew: state.leaderboardNew.filterType,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    // NEW
    onSetFilterTypeNew: (filterType) => dispatch(leaderboardNewActions.setFilterType(filterType)),
    onToggleMobileChangeType: (toggle) => dispatch(leaderboardNewActions.toggleMobileChangeType(toggle))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters)