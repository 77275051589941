import * as actionTypes from './leaderboardNewActionTypes';

export const setFilter = (payload) => {
  return {
    type: actionTypes.SET_FILTER,
    payload: {
      ...payload
    }
  }
}

export const setFilterType = (payload) => {
  return {
    type: actionTypes.SET_FILTER_TYPE,
    payload: {
      ...payload
    }
  }
}

export const toggleMobileChangeType = (payload) => {
  return {
    type: actionTypes.TOGGLE_MOBILE_CHANGE_TYPE,
    payload: {
      ...payload
    }
  }
}