import * as actionTypes from './authActionTypes'
import api from '../../axios/api';
import router from 'next/router'
import { setColaborators } from '../static/staticInfoActions'
export const loginRequest = () => {
  return {
    type: actionTypes.LOGIN_REQUEST,
  }
}

export const loginSuccess = (user) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: user,
  }
}

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    payload: {error},
  }
}

export const login = (credentials) => {
    return  async (dispatch) => {
      dispatch(loginRequest());
      api.post('/login', {
        ...credentials
      }, {
        headers: {hideErrorPage: true}
      })
        .then(response => {
          localStorage.setItem('access_token', response.data.token)
          dispatch(loginSuccess(response.data));
          dispatch(setColaborators(response.data.user.appVersionUpdate))
        })
        .catch(e =>{
          console.log(e);
          // dispatch(loginFail(e.response));
          dispatch(loginFail(e?.response?.data?.message));
        })
    }
}

const registerRequest = () => {
  return {
    type: actionTypes.REGISTER_REQUEST,
  }
}

const registerSuccess = (message) => {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: {message}
  }
}

const registerFail = (error) => {
  return {
    type: actionTypes.REGISTER_FAIL,
    payload: {error},
  }
}

export const register = (credentials) => {
  return async (dispatch) => {
    dispatch(registerRequest())
    api.post('/register', credentials)
      .then(response => {
        dispatch(registerSuccess('REGISTRATION_OK'));
      })
      .catch(e => {
        dispatch(registerFail(e.response.data.message));
      })
  }
}

export const registerWithSocial = (provider, credentials) => {
  return async (dispatch) => {
    // debugger;
    dispatch(registerRequest());
    api.post(`/login/${provider}`, credentials)
      .then(res => {
        // debugger;
        localStorage.setItem('access_token', res.data.token)
        dispatch(loginSuccess(res.data));
        // checkAuth();
        router.push('/')
        // return res;
      })
      .catch(e => {
        dispatch(registerFail(e));
      })
  }
}


export const checkAuthRequest = () => {
  return {
    type: actionTypes.CHECK_AUTH_REQUEST,
  }
}

export const checkAuthSuccess = (user) => {
  return {
    type: actionTypes.CHECK_AUTH_SUCCESS,
    payload: user
  }
}

export const checkAuthFail = () => {
  return {
    type: actionTypes.CHECK_AUTH_FAIL,
  }
}

export const checkAuth = () => {
  return async (dispatch) => {
    dispatch(checkAuthRequest())
    if (localStorage.getItem('access_token')) {
      api.get('/user')
      .then(response => {
          const user = {
            user: {
              ...response.data.data
            },
            stats: [
              ...response.data.stats
            ]
          };
          dispatch(checkAuthSuccess(user));
          
          dispatch(setColaborators(response.data.data.appVersionUpdate))
        })
        .catch(e =>{
          dispatch(checkAuthFail());
      })
    } else {
      dispatch(checkAuthFail());
    }
  }
}

const logoutRequest = () => {
  return {
    type: actionTypes.LOGOUT_REQUEST,
  }
}

const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  }
}

const logoutFail = () => {
  return {
    type: actionTypes.LOGOUT_FAIL,
  }
}

export const logout = () => {
  return async (dispatch) => {
    dispatch(logoutRequest())
    api.get('/logout')
      .then(response => {
        localStorage.removeItem('access_token');
        dispatch(logoutSuccess());
        router.push('/')
      })
      .catch(e => {
        dispatch(logoutFail());
      })
  }
}

export const updateUserRequest = () => {
  return {
    type: actionTypes.UPDATE_USER_REQUEST,
  }
}

export const updateUserSuccess = (user) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: {
      user
    }
  }
}

export const updateUserFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    payload: error
  }
}

export const updateUser = (userObj, userId) => {
  // debugger
  const requestOptions = {
    'Content-Type': 'multipart/form-data' 
  }
  return async (dispatch) => {
    dispatch(updateUserRequest());
    return api.put(`/users/${userId}`, userObj, { headers: requestOptions}).then(res => {
      dispatch(updateUserSuccess(res.data));
      return res;
    }).catch(e => {
      console.log(e);
      dispatch(updateUserFail(e))
      return e
    })
  }
}

export const setSignUpFormData = (formData) => {
  return {
    type: actionTypes.SET_SIGN_UP_FORM_DATA,
    payload: {
      formData
    }
  }
}

export const setIsProfileUdated = (state) => {
  return {
    type: actionTypes.SET_PROFILE_UPDATED,
    payload: {
      state
    }
  }
}

export const updateOnlineStatus = (status) => {
  return {
    type: actionTypes.UPDATE_ONLINE_STATUS,
    payload:{
      status: status
    }
  }
}

export const resetOngoingGame = () => {
  return {
    type: actionTypes.RESET_ONGOING_GAME,
  }
}