import { useRouter } from 'next/router'
import NotificationSvg from '../../../public/images/common/notification.svg'
import AccessibilitySvg from '../../../public/images/common/accessibility.svg';
import GameRulesSvg from '../../../public/images/common/game_rules.svg';

import SettingsSvg from '../../../public/images/common/settings.svg';
import Avatar from '../../Avatar/Avatar';
import {GamePageHanburgerMenu} from '../../../pages/lobby/[lobbyId]/game/[gameId]/index'
import * as styles from '../../../styles/scss/components/GamePageMenu/GamePageMenu.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import { handleReturnAsAdmin } from '../../../helpers/Impersonate';
import HomeSvg from '../../../public/images/common/home.svg';
import useTranslation from 'next-translate/useTranslation';

const GamePageMenu = (props) => {
  const {t} = useTranslation();
  const sideBarData = useSelector(state => state.modals.sidebarData);
  const dispatch = useDispatch();
  const router = useRouter();
  const {onSetUserForProfileSidebar, user, openSidebar} = props
  const unreadNotificationCount = useSelector(state => state.notifications.unreadNotificationCount)
  return (
    <>
    <div className = {styles.game_page_menu}>
      <div className = {styles.hamburger_container}>
        <ul className = {styles.hamburger_menu_ul}>
          <li className = {styles.hamburger_menu_li} onClick = {() => {
            onSetUserForProfileSidebar({
              ...user,
              isLoggedInUser: true
            })
            openSidebar({
              isOpen: true,
              sidebarType: 'profile',
              hasTitle: true,
              showSidebarCloseButton: true
            })
          }}>
            <div className = {styles.hamburger_menu_image}>
              <div className = {styles.image_container}>
                <Avatar 
                  hasFlag = {false}
                  avatarUrls = {user.avatar}
                  size = "small"
                />              
              </div>
            </div>
            <div className = {styles.hamburger_menu_text}>
              <span className = {styles.text}>{t('common:sidebar_titles.my_profile')}</span>
            </div>
          </li>
          <li className = {styles.hamburger_menu_li} onClick = {() => {openSidebar({
                isOpen: true,
                sidebarType: 'notification',
                hasTitle: true,
                showSidebarCloseButton: true
              })}}
            >
            <div className = {styles.hamburger_menu_image}>
              <div className = {styles.image_container}>
                <NotificationSvg />
              </div>
            </div>
            <div className = {styles.hamburger_menu_text}>
              <span className = {styles.text}>{t('common:sidebar_titles.notifications')}</span>
              {unreadNotificationCount > 0 &&<span className={styles.indicator}>{unreadNotificationCount}</span>}
            </div>
          </li>
          {/* <li className = {styles.hamburger_menu_li} onClick = {() => {openSidebar({
                isOpen: true,
                sidebarType: 'accessibility',
                hasTitle: true,
                showSidebarCloseButton: true
              })}}
            >
            <div className = {styles.hamburger_menu_image}>
              <div className = {styles.image_container}>
                <AccessibilitySvg />
              </div>
            </div>
            <div className = {styles.hamburger_menu_text}>
              <span className = {styles.text}>Accessibility</span>
            </div>
          </li> */}
          <li className = {styles.hamburger_menu_li} onClick = {() => {openSidebar({
                sidebarType: 'rules',
                isOpen: true,
                hasTitle: true,
                showSidebarCloseButton: true
              })}}
            >
            <div className = {styles.hamburger_menu_image}>
              <div className = {styles.image_container}>
                <GameRulesSvg />
              </div>
            </div>
            <div className = {styles.hamburger_menu_text}>
              <span className = {styles.text}>{t('common:sidebar_titles.game_rules')}</span>
            </div>
          </li>
          <li className = {styles.hamburger_menu_li} onClick = {() => {openSidebar({
                isOpen: true,
                sidebarType: 'settings',
                hasTitle: true,
                showSidebarCloseButton: true
              })}}>
            <div className = {styles.hamburger_menu_image}>
              <div className = {styles.image_container}>
                <SettingsSvg />
              </div>
            </div>
            <div className = {styles.hamburger_menu_text}>
              <span className = {styles.text}>{t('common:sidebar_titles.settings')}</span>
            </div>
          </li>
          {localStorage.getItem('impersonate_token') && (
            <li className = {styles.hamburger_menu_li} onClick = {async () => {
              const response = await handleReturnAsAdmin()
              if (response) {
                window.location.href = '/'
              }
            }}>
              <div className = {styles.hamburger_menu_image}>
                <div className = {styles.image_container}>
                  <HomeSvg />
                </div>
              </div>
              <div className = {styles.hamburger_menu_text}>
                <span className = {styles.text}>{t('common:buttons.back_as_admin')}</span>
              </div>
            </li>
          )}
        </ul>
      {/* <div className = {styles.leave_button_container}>
        <button className = {styles.leave_button} onClick = {() => {router.push('/')}}>
          Leave
        </button>
      </div> */}
      </div>
        </div>
        <div className = {styles.button_container}>
            <button className = {styles.leave_button} onClick = {() => {
                sideBarData.handleClick();
                dispatch(toggleSidebar())
              }}>
                {t('common:buttons.leave')}
              </button>
          </div>
      </>
  )
}

export default GamePageMenu