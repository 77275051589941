import { useEffect, useState, useRef } from 'react';
import * as styles from '../../../styles/scss/components/Versions/Versions.module.scss';
import { getCustomDate } from '../../../helpers/functions';
import { getVersionInfo, handleVersionOpen} from '../../../store/static/staticInfoActions';
import { useDispatch, useSelector } from 'react-redux';
import { BlockElement, Text } from '../../SkeletonLoader/SkeletonLoader';
import useTranslation from 'next-translate/useTranslation';
const ProjectVersions = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const versionInfo = useSelector(state => state.static.versionInfo)
  const versionInfoLoading = useSelector(state => state.static.versionInfoLoading)

  const monthes = [
    t('common:months.January'),
    t('common:months.February'),
    t('common:months.March'),
    t('common:months.April'),
    t('common:months.May'),
    t('common:months.June'),
    t('common:months.July'),
    t('common:months.August'),
    t('common:months.September'),
    t('common:months.October'),
    t('common:months.November'),
    t('common:months.December'),
   ]
   
  useEffect(() => {
    if (!versionInfo) {
      dispatch(getVersionInfo())
    }
  }, [])

  const handleOpenVersion = (version) => {
    dispatch(handleVersionOpen(version))
  }

  return (
    <section className = {styles.versions}>
      <div className={styles.versions_list}>
        {versionInfoLoading ? 
        <ul className={styles.version_list_ul}>
          <li className={styles.version_list_li}>
            <BlockElement width={'100%'} height = {'50px'} /> 
          </li>
          <li className={styles.version_list_li}>
            <BlockElement width={'100%'} height = {'50px'} /> 
          </li>
          <li className={styles.version_list_li}>
            <BlockElement width={'100%'} height = {'50px'} /> 
          </li>
          <li className={styles.version_list_li}>
            <BlockElement width={'100%'} height = {'50px'} /> 
          </li>
          <li className={styles.version_list_li}>
            <BlockElement width={'100%'} height = {'50px'} /> 
          </li>
        </ul>
        : (
        <ul className={styles.version_list_ul}>
          {versionInfo?.map((version, verIndex) => {
            const customDate = getCustomDate(version.date, monthes, {
              month: {
                asString: true,
                fullString: true
              }
            })
            return (
              <li className={styles.version_list_li}  key={`${version.date}${version.version}`} onClick={() => {handleOpenVersion(verIndex)}}>
                <div className={styles.single_version_container}>
                  <div className={styles.single_version_header}>
                    <h4 className={styles.version_number}>
                      {t('common:version')} {version.version}
                      {version.isCurrent && <span className={styles.newbadge}>{t('common:new')}</span>}
                    </h4>
                    <span className={styles.version_date}>
                      {customDate}
                    </span>
                  </div>
                  {version.open && (
                    <div className={styles.single_version_updates}>                    
                      {version.updates.map((update, index) => {
                        return (
                          <ul className={styles.single_version_updates_ul}  key = {`singleItem${index}`}>
                            <li className={styles.single_version_updates_li}>
                              <div className={styles.single_version_update_container}>
                                <h5 className={styles.single_update_title}>
                                  {update.type}
                                </h5>
                                <ul className={styles.updates_ul}>
                                  {update.updates.map((updt, index) => {
                                    return (
                                      <li className={styles.updates_li} key = {`update${index}`}>
                                        <span className={styles.list_symbol}></span>
                                        <h6>{updt}</h6>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </li>
                          </ul>
                        )
                      })}
                    </div>
                  )}                  
                </div>
              </li>
            )
          })}
        </ul>
      )}
      </div>      
    </section>
  )
}
export default ProjectVersions;