import * as actionTypes from "./gameSetupActionTypes";
import api from '../../axios/api';

export const handleRuleChange = (id) => {
  return {
    type: actionTypes.HANDLE_RULE_CHANGE,
    payload: {
      id
    }
  }
}

export const setGameType = (gameType) => {
  return {
    type: actionTypes.SET_GAME_TYPE,
    payload: {
      gameType
    }
  }
}

export const setGameMode = (gameMode) => {
  // setAvilablePlayersNumbers(gameMode)
  return {
    type: actionTypes.SET_GAME_MODE,
    payload: {
      gameMode
    }
  }
}

export const setPlayerNumber = (playerNumber) => {
  return {
    type: actionTypes.SET_PLAYER_NUMBER,
    payload: {
      playerNumber
    }
  }
}

export const setVersus = (versus) => {
  return {
    type: actionTypes.SET_VERSUS,
    payload: {
      versus
    }
  }
}

const setAvilablePlayersNumbers = (gameMode) => {
  let playersNumbers = []
  if (gameMode) {
    playersNumbers = [2,3,4,5,6]
  } else {
    playersNumbers = [4,6]
  }
  return {
    type: actionTypes.SET_AVILABLE_PLAYERS_NUMBERS,
    payload: {
      playersNumbers
    }
  }
}

const getGameSetupDataRequest = () => {
  return {
    type: actionTypes.GET_GAME_SETUP_DATA_REQUEST
  }
}

const getGameSetupDataSuccess = (setupData) => {
  return {
    type: actionTypes.GET_GAME_SETUP_DATA_SUCCESS,
    payload: {
      setupData
    }
  }
}

const getGameSetupDataFail = (error) => {
  return {
    type: actionTypes.GET_GAME_SETUP_DATA_FAIL,
    payload: {
      error
    }
  }
}

export const getGameSetupData = () => {
  return async (dispatch) => {
    dispatch(getGameSetupDataRequest());
    return api.get(`/mm-settings`)
    .then(response => {
      dispatch(getGameSetupDataSuccess(response.data));
      return response
    })
    .catch(error =>{
      dispatch(getGameSetupDataFail(error));
      return error
    })
  }
}

export const resetSetup = () => {
  return {
    type: actionTypes.RESET_SETUP
  }
}

export const resetRules = () => {
  return {
    type: actionTypes.RESET_RULES
  }
}

export const updateLiveSearchingPlayerNumber = (payload) => {
  return {
    type: actionTypes.UPDATE_LIVE_SREARCHING_PLAYERS_NUMBER,
    payload
  }
}

export const setGamePending = (state) => {
  return {
    type: actionTypes.TOGGLE_GAME_PENDING,
    payload: {
      state
    }
  }
}