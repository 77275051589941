import useTranslation from "next-translate/useTranslation";
import * as styles from "../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss";
const Overview = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.single_rule_container}>
            <p className={`${styles.text}`}>
                {t("game_rules:overview.first_paragraph")}
                <br></br>
                <br></br>
                {t("game_rules:overview.second_paragraph")}
                <br></br>
                <br></br>
                <b>{t("game_rules:overview.third_paragraph")}</b>
                <br></br>
                <br></br>
                <b>{t("game_rules:overview.first_heading")}</b>
                <br></br>
                <br></br>
                {t("game_rules:overview.fourth_paragraph")}
            </p>
            <div
                className={`${styles.image_container} ${styles.landscape}`}
            >
                <img src="/images/rules/overview-sidebar.svg" />
            </div>
        </div>
    );
};

export default Overview;
