import * as styles from '../../styles/scss/components/Trigger/Trigger.module.scss'

const Trigger = ({active, clickFunction}) => {

  return (
    <div
      className = {`${styles.trigger} ${active ? styles.active : ''}`}
      onClick = {clickFunction}>
      <span className = {styles.triggerCircle}></span>
    </div>
  )
}

export default Trigger