export const getFileType = (fileName) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
}


const getMonthString = (month, months, full = true, symbolCount) => {
  if (full) {
    return months[month]
  } else {
    return months[month].substr(0, symbolCount)
  }
}

export const getCustomDate = (date, monthes, params = {}) => {
  let localParams = {
    dateType: 'ddmmyyyy',
    month: {
      asString: false,
      fullString: false,
    }
  }
  localParams = {
    ...localParams,
    ...params
  }
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const day = newDate.getDate();
  let month = null;
  const localMonth = newDate.getMonth()
  if (localParams.month.asString) {
    if (localParams.month.fullString) {
      month = getMonthString(localMonth, monthes, true)
    } else {
      month = getMonthString(localMonth, monthes, false, localParams.month.symbolCount)
    }
  } else {
    month = localMonth < 10 ? '0' + localMonth : localMonth
  }

  if (localParams.dateType === 'ddmmyyyy' || !localParams.dateType) {
    if (localParams.month.asString) {
      return day + ' ' + month + ', ' + year
    } else {
      return day + '/' + month + '/' + year
    }
  }
}

export const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

export const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "android"
  }
  else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
    return "ios"
  }
  return "other"
}
export const getOnboardingCurrentElementPosition = () => {
  const positions = document.querySelector('.onboardingCurrentElement').getBoundingClientRect()
  return positions
}

export const nameShorten = name => {
  if (!name) {
    return ''
  }
  return name.length > 10 ? name.substring(0,10) + "..." : name;
}

export const marbleMoveDuration = 0.3;
export const marbleFinishedDelay = 0.5;