export const GET_LOBBY_DATA_REQUEST = 'GET_LOBBY_DATA_REQUEST';
export const GET_LOBBY_DATA_SUCCESS = 'GET_LOBBY_DATA_SUCCESS';
export const GET_LOBBY_DATA_FAIL = 'GET_LOBBY_DATA_FAIL';

export const GET_PRIVATE_LOBBY_DATA_REQUEST = 'GET_PRIVATE_LOBBY_DATA_REQUEST';
export const GET_PRIVATE_LOBBY_DATA_SUCCESS = 'GET_PRIVATE_LOBBY_DATA_SUCCESS';
export const GET_PRIVATE_LOBBY_DATA_FAIL = 'GET_PRIVATE_LOBBY_DATA_FAIL';

export const PLAYER_SIT_DOWN_REQUEST = 'PLAYER_SIT_DOWN_REQUEST';
export const PLAYER_SIT_DOWN_SUCCESS = 'PLAYER_SIT_DOWN_SUCCESS';
export const PLAYER_SIT_DOWN_FAIL = 'PLAYER_SIT_DOWN_FAIL';

export const PLAYER_JOIN_REQUEST = 'PLAYER_JOIN_REQUEST';
export const PLAYER_JOIN_SUCCESS = 'PLAYER_JOIN_SUCCESS';
export const PLAYER_JOIN_FAIL = 'PLAYER_JOIN_FAIL';

export const PLAYER_ROLL_DICE_REQUEST = 'PLAYER_ROLL_DICE_REQUEST';
export const PLAYER_ROLL_DICE_SUCCESS = 'PLAYER_ROLL_DICE_SUCCESS';
export const PLAYER_ROLL_DICE_FAIL = 'PLAYER_ROLL_DICE_FAIL';


export const SET_JOINED_PLAYER = 'SET_JOINED_PLAYER';
export const SET_REDIRECT_TO_PRIVATE = 'SET_REDIRECT_TO_PRIVATE';

export const CRATE_PRIVATE_LOBBY_REQUEST = 'CRATE_PRIVATE_LOBBY_REQUEST';
export const CRATE_PRIVATE_LOBBY_SUCCESS = 'CRATE_PRIVATE_LOBBY_SUCCESS';
export const CRATE_PRIVATE_LOBBY_FAIL = 'CRATE_PRIVATE_LOBBY_FAIL';
export const RESET_PUBLIC_LOBBY = 'RESET_PUBLIC_LOBBY';