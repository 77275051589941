import * as styles from '../../../styles/scss/components/Notifications/NotificationsModal.module.scss'
import  Notification from '../NotificationTypes/NotificationTypes'
import { toggleSidebar } from '../../../store/modals/modalsActions';
import {connect} from 'react-redux';
import { sendAccept, sendDecline } from '../../../store/friends/friendActions';
import { useRouter } from 'next/router';
import { setUserForProfileSidebar } from '../../../store/modals/modalsActions';
import { markNotificationAsRead, changeGameRequestNotificationMessage } from '../../../store/notifications/notificationActions';
import useTranslation from 'next-translate/useTranslation';
const NotificationsModal = (props) => {
  const {
    onToggleSidebar,
    callBackFunction, 
    lastNotifications,
    onFriendRequestAccept,
    onFriendRequestDecline,
    onSetUserForProfileSidebar,
    onMarkNotificationAsRead,
    onChangeGameRequestNotificationMessage
  } = props

  const {t} = useTranslation()

  const router = useRouter();
  const handleNotificationSidebarOpen = () => {
    callBackFunction()
    onMarkNotificationAsRead({all: true})
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'notification',
      hasTitle: true,
      customTitle: 'notifications',
      showSidebarCloseButton: true
    })
  }

  const handleUserProfileOpen = (user) => {
    callBackFunction()
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'profile',
      hasBackArrow: false,
      customTitle: 'profile',
      showSidebarCloseButton: true,
      hasTitle: true
    })
    onSetUserForProfileSidebar(user)
  }

  const getNotificationInformation = (notification) => {
    const data = {
      ...notification
    }
    switch (notification.type) {
      case 'FriendshipRequested' : {
        data.accept = () => { onFriendRequestAccept(notification.friendship.id, notification.id) },
        data.decline = () => { onFriendRequestDecline(notification.friendship.id, notification.id)}
  
      }; break;
      case 'GameRequested': {
        data.accept = onChangeGameRequestNotificationMessage
      }; break;
      case 'MatchReady': {
        data.accept = onChangeGameRequestNotificationMessage
      }; break;
    }
    return data
  }

  // const getNotificationModalType = (notificationType) => {
  //   // console.log(notificationType)
  //   switch(notificationType) {
  //     case 'MatchReady': return 'text';
  //     case 'FriendshipRequested': return 'withButtons'
  //   }
  // }

  return (
    <div className = {styles.notification_modal}>
      <div className = {styles.notification_modal_header}>
        <h3 className = {styles.modal_title}>{t('common:sidebar_titles.notifications')}</h3>
        <span className = {styles.view_all_button} onClick = {handleNotificationSidebarOpen}>{t('common:see_all')}</span>
      </div>
      <div className = {styles.notifications_container}>
        <ul className = {styles.notifications_container_ul}>
          {lastNotifications && lastNotifications.map(notification => {
            return (
              <li
                key = {notification.id}
                className = {styles.notifications_container_li}
                onClick = {() => {
                  if (!notification.readAt) {
                    onMarkNotificationAsRead({
                      all: false,
                      notificationId: notification.id
                    })}  
                  }
                }
                >
                <Notification
                  data = {getNotificationInformation(notification)}
                  onFriendRequestAccept = {() => { onFriendRequestAccept(notification.friendship.id) }}
                  handleUserProfileOpen = {handleUserProfileOpen}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lastNotifications: state.notifications.lastNotifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    onFriendRequestAccept: (friendshipId, notificationUuid) => dispatch(sendAccept(friendshipId, notificationUuid)),
    onFriendRequestDecline: (friendshipId, notificationUuid) => dispatch(sendDecline(friendshipId, notificationUuid)),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onMarkNotificationAsRead: (payload) => dispatch(markNotificationAsRead(payload)),
    onChangeGameRequestNotificationMessage: (payload) => dispatch(changeGameRequestNotificationMessage(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsModal);
