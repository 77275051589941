import * as actionTypes from './matchMakingActionTypes';

const initialState = {
  gameData: null,
  isLoading: false,
  error: null,
  searching: false,
  isSearchingCancelled: false,
  selectedGameData: null,
  aborting: false,
  disbaleAborting: false
}

const matchMakingReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.MATCH_MAKING_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.MATCH_MAKING_SUCCESS:
        // debugger
        return {
          ...state,
          isLoading: false,
          searching: true,
          // isGameFound: true,
          gameData: {...action.payload.gameData.mmId},
          selectedGameData: {...action.payload.gameData.ongoingSearch}
        };
      case actionTypes.MATCH_MAKING_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload.error
        };
      case actionTypes.QUICK_PLAY_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case actionTypes.QUICK_PLAY_SUCCESS:
        // debugger
        return {
          ...state,
          isLoading: false,
          searching: true,
          // isGameFound: true,
          gameData: {...action.payload.gameData.mmId},
          selectedGameData: {...action.payload.gameData.ongoingSearch}
        };
      case actionTypes.QUICK_PLAY_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload.error
        };
      case actionTypes.CANCEL_MATCH_MAKING_REQUEST:
        return {
          ...state,
          isLoading: false
        };
      case actionTypes.CANCEL_MATCH_MAKING_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isSearchingCancelled: true,
          searching: false
        };
      case actionTypes.CANCEL_MATCH_MAKING_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload.error
        };
      case actionTypes.RESET_MATCHMAKING: {
        // debugger;
        return {
          ...state,
          gameData: null,
          isLoading: false,
          error: null,
          searching: false,
          isSearchingCancelled: false,
          selectedGameData: null
        }
      }
      case actionTypes.TOGGLE_ABORTING: {
        return {
          ...state,
          aborting: action.payload.state
        }
      }
      case actionTypes.TOGGLE_DISBALE_ABORTING: {
        return {
          ...state,
          disbaleAborting: action.payload.state
        }
      }
      default:
        return state;
    }
}

export default matchMakingReducer;