import * as actionTypes from './errorsActionTypes';

const initialState = {
  errorOccured: false,
  statusCode: '',
  errorMessage: ''
}

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ERROR_DATA: {
      const {errorOccured, statusCode, errorMessage} = action.payload.errorData
      return {
        ...state,
        errorOccured,
        statusCode,
        errorMessage
      }
    };
    default:
      return state;
    }
}

export default errorsReducer;