import * as actionTypes from './modalsActionTypes';

export const toggleSidebar = (payLoad) => {
  return {
    type: actionTypes.TOGGLE_SIDEBAR,
    payload: {
      ...payLoad
    }
  }
}

export const toggleResponsivePopUp = (payLoad) => {
  return {
    type: actionTypes.TOGGLE_RESPONSIVE_PUP_UP,
    payload: {
      ...payLoad
    }
  }
}

export const setUserForProfileSidebar = (user) => {
  return {
    type: actionTypes.SET_USER_FOR_PROFILE_SIDEBAR,
    payload: {user}
  }
}

export const setGlobalModalState = (globalModalState) => {
  return {
    type: actionTypes.SET_GLOBAL_MODAL_STATE,
    payload: {
      globalModalState
    }
  }
}

export const toggleConfirmModal = (payload) => {
  return {
    type: actionTypes.TOGGLE_CONFIRM_MODAL,
    payload: {
      ...payload
    }
  }
}

export const setOfflineModalState = (state) => {
  return {
    type: actionTypes.SET_OFFILNE_MODAL_STATE,
    payload: {
      state: state
    }
  }
}
export const buzzOfflineModal = (state) => {
  return {
    type: actionTypes.BUZZ_OFFLINE_MODAL,
    payload: {
      state: state
    }
  }
}

export const toggleModalSize = (value = null) => {
  return {
    type: actionTypes.TOGGLE_MODAL_RESIZE,
    payload: value
  }
} 