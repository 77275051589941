
import React, { useEffect, useState } from 'react';
import { PrimaryRound } from '../../Buttons/Buttons';
import ChevronBack from '../../../public/images/common/chevron_left.svg';
import * as styles from '../../../styles/scss/components/FormWizard/FormWIzard.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { getMobileOS } from '../../../helpers/functions';
const Wizard = (props) => {

  const [steps, setSteps] = useState(null)
  const {t} = useTranslation()
  const [os, setOs] = useState(getMobileOS())
  
  useEffect(() => {
    const localSteps = []
    props.wizardData.map((singleWizard, index) => {
      localSteps.push({
        id: singleWizard.id,
        stepNumber: index,
        active: index === props.wizardIndexToOpen
      })
    })
    setSteps(localSteps)
  }, [props.wizardIndexToOpen])


  const changeStep = (step) => {
    const currentStepIndex = getActiveStepNumber()
    const nextActiveStepNumber = step ? currentStepIndex + 1 : currentStepIndex - 1
    if (nextActiveStepNumber < 0) {
      return
    } else if (nextActiveStepNumber >= steps.length) {
      props.handleShowWizard(false)
    }
    setSteps(prevState => 
      [...prevState.map(step => 
        {
          return {
            ...step,
            active : step.stepNumber === nextActiveStepNumber
          }
        })
      ]
    )
  }

  const getActiveStepNumber = () => {
    return steps.find(step => step.active).stepNumber
  }

  const isNextButtonDisabled = () => {
    const activeStepNumber = getActiveStepNumber()
    return !props.wizardData[activeStepNumber].valid
  }

  const isStepValid = (index) => {
    return props.wizardData[index].valid && index <= getActiveStepNumber()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isNextButtonDisabled()) {
      changeStep(true)
    }
    return;
  }

  return (
    <div className={`${styles.form_wizard_container} ${styles[os]}`}>
      <form onSubmit={handleSubmit}>
        {!!steps && props.wizardData?.map((singleWizard, index) => {
          const active = steps.find(step => step.id === singleWizard.id).active
          if (!active) {
            return <React.Fragment  key = {`singlwWizard${index}`}></React.Fragment>
          }
          return (
            <div className = {`singlwWizard${index}`}key = {`singlwWizard${index}`}>
              {singleWizard.element}
            </div>  
          )
        })}
      </form>
      <div className={`${styles.form_wizard_stepper} stepper`}>
        <div className={styles.form_wizard_steps}>
          <ul className={styles.from_wizard_steps_ul}>
            {!!steps && steps.map((step, index) => {
              return (
                <li
                  className={styles.form_wizard_steps_li} 
                  key = {`wizardStepper${index}`}>
                    <div className={`${styles.form_wizard_step} ${step.active ? styles.active : ''} ${isStepValid(index) ? styles.filled : ''}`}></div>
                </li>
              )
            })}
          </ul>
        </div>
        {!!steps && (
          <div className={`${styles.buttons_container}`}>
            <PrimaryRound 
              disabled = {getActiveStepNumber() === 0} 
              click={() => {changeStep(false)}}
              >
              <ChevronBack />
            </PrimaryRound>
            <button 
              className={`${styles.wizard_button} ${styles.wizard_next}`} 
              onClick={() => {changeStep(true)}}
              disabled = {isNextButtonDisabled()}
              >
                {t('common:buttons.next')}
              </button>
              <button type='submit' style={{
                position: 'absolute',
                left: '-5000px',
                bottom: '-5000px',
              }}></button>
          </div>
        )}        
      </div>
    </div>
  )
}

export default Wizard