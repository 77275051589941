import * as actionTypes from "./lobbyActionTypes";
import api from '../../../axios/api';
import router from "next/router";

const getLobbyRequest = () => {
  return {
    type: actionTypes.GET_LOBBY_DATA_REQUEST
  }
}

export const getLobbySuccess = (lobbyData) => {
  return {
    type: actionTypes.GET_LOBBY_DATA_SUCCESS,
    payload: {
      lobbyData
    }
  }
}

const getLobbyFail = (error) => {
  return {
    type: actionTypes.GET_LOBBY_DATA_FAIL,
    payload:{
      error
    }
  }
}

export const getLobby = (lobbyId) => {
  return async (dispatch) => {
    dispatch(getLobbyRequest());
    api.get(`/lobby/${lobbyId}`)
    .then(response => {
      dispatch(getLobbySuccess(response.data));
    })
    .catch(error =>{
      // console.log(error);
      dispatch(getLobbyFail(error));
    })
  }
}

const getPrivateLobbyRequest = () => {
  return {
    type: actionTypes.GET_PRIVATE_LOBBY_DATA_REQUEST
  }
}

export const getPrivateLobbySuccess = (lobbyData) => {
  return {
    type: actionTypes.GET_PRIVATE_LOBBY_DATA_SUCCESS,
    payload: {
      lobbyData
    }
  }
}

const getPrivateLobbyFail = (error) => {
  return {
    type: actionTypes.GET_PRIVATE_LOBBY_DATA_FAIL,
    payload:{
      error
    }
  }
}

export const getPrivateLobby = (lobbyId) => {
  return async (dispatch) => {
    dispatch(getPrivateLobbyRequest());
    return api.get(`/lobby/${lobbyId}`)
    .then(response => {
      dispatch(getPrivateLobbySuccess(response.data));
      return response
    })
    .catch(error =>{
      // console.log(error);
      dispatch(getPrivateLobbyFail(error));
      return error
    })
  }
}


const  playerSitDownRequest= () => {
  return {
    type: actionTypes.PLAYER_SIT_DOWN_REQUEST
  }
}

const playerSitDownSuccess = () => {
  return {
    type: actionTypes.PLAYER_SIT_DOWN_SUCCESS,
  }
}

const playerSitDownFail = (error) => {
  return {
    type: actionTypes.PLAYER_SIT_DOWN_FAIL,
    payload:{
      error
    }
  }
}

export const playerSitDown = (lobbyId) => {
  return async (dispatch) => {
    dispatch(playerSitDownRequest);
    api.post(`/lobby/${lobbyId}/join`)
    .then(response => {
      dispatch(playerSitDownSuccess());
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerSitDownFail(error));
    })
  }
}


const  playerJoinRequest= () => {
  return {
    type: actionTypes.PLAYER_JOIN_REQUEST
  }
}

const playerJoinSuccess = () => {
  return {
    type: actionTypes.PLAYER_JOIN_SUCCESS,
  }
}

const playerJoinFail = (error) => {
  return {
    type: actionTypes.PLAYER_JOIN_FAIL,
    payload:{
      error
    }
  }
}

export const playerJoin = (lobbyId) => {
  return async (dispatch) => {
    dispatch(playerJoinRequest);
    api.post(`/lobby/${lobbyId}/join`)
    .then(response => {
      dispatch(playerJoinSuccess());
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerJoinFail(error));
    })
  }
}


const  playerRollDiceRequest= () => {
  return {
    type: actionTypes.PLAYER_ROLL_DICE_REQUEST
  }
}

const playerRollDiceSuccess = (payload) => {
  return {
    type: actionTypes.PLAYER_ROLL_DICE_SUCCESS,
    payload: {
      ...payload
    }
  }
}

const playerRollDiceFail = (error) => {
  return {
    type: actionTypes.PLAYER_ROLL_DICE_FAIL,
    payload:{
      error
    }
  }
}

export const playerRollDice = (payload) => {
  return async (dispatch) => {
    dispatch(playerRollDiceRequest);
    api.post(`/lobby/${payload.lobbyId}/dice`, payload.diceResult)
    .then(response => {
      dispatch(playerRollDiceSuccess(response));
    })
    .catch(error =>{
      // console.log(error);
      dispatch(playerRollDiceFail(error));
    })
  }
}




const createPrivateLobbyRequest = () => {
  return {
    type: actionTypes.CRATE_PRIVATE_LOBBY_REQUEST
  }
}

const createPrivateLobbySuccess = (privateLobbyData) => {
  return {
    type: actionTypes.CRATE_PRIVATE_LOBBY_SUCCESS,
    payload: {
      privateLobbyData
    }
  }
}

const createPrivateLobbyFail = (error) => {
  return {
    type: actionTypes.CRATE_PRIVATE_LOBBY_FAIL,
    payload:{
      error: {
        ...error
      }
    }
  }
}

export const createPrivateLobby = (lobbyData) => {
  return async (dispatch) => {
    dispatch(createPrivateLobbyRequest());
    return api.post('/lobby', lobbyData)
    .then(response => {
      dispatch(createPrivateLobbySuccess(response.data));
      if(response?.data.games[0]) {
        router.push(`/lobby/${response.data.games[0].lobbyId}`)
      }
      return response.data
    })
    .catch(error =>{
      console.log(error);
      dispatch(createPrivateLobbyFail(error));
      return error
    })
  }
}


export const setJoinedPlayer = (userId, currentUserId) => {
  return {
    type: actionTypes.SET_JOINED_PLAYER,
    payload: {
      userId,
      currentUserId
    }
  }
}

export const setRedirectState = (state) => {
  return {
    type: actionTypes.SET_REDIRECT_TO_PRIVATE,
    payload: {
      state
    }
  }
}

export const resetPublicLobby = () => {
  return {
    type: actionTypes.RESET_PUBLIC_LOBBY
  }
}