export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const CHECK_AUTH_REQUEST = "CHECK_AUTH_REQUEST";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const CHECK_AUTH_FAIL = "CHECK_AUTH_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const GET_AVATARS_LIST_REQUEST = 'GET_AVATARS_LIST_REQUEST';
export const GET_AVATARS_LIST_SUCCESS = 'GET_AVATARS_LIST_SUCCESS';
export const GET_AVATARS_LIST_FAIL = 'GET_AVATARS_LIST_FAIL';


export const GET_COUNTRIES_LIST_REQUEST = 'GET_COUNTRIES_LIST_REQUEST';
export const GET_COUNTRIES_LIST_SUCCESS = 'GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_FAIL = 'GET_COUNTRIES_LIST_FAIL';

export const GET_CITIES_LIST_REQUEST = 'GET_CITIES_LIST_REQUEST';
export const GET_CITIES_LIST_SUCCESS = 'GET_CITIES_LIST_SUCCESS';
export const GET_CITIES_LIST_FAIL = 'GET_CITIES_LIST_FAIL';

export const SET_SIGN_UP_FORM_DATA = 'SET_SIGN_UP_FORM_DATA';


export const SET_PROFILE_UPDATED = 'SET_PROFILE_UPDATED';
export const UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS';
export const RESET_ONGOING_GAME = 'RESET_ONGOING_GAME';