import { BackButton } from "../Buttons/Buttons"
import * as styles from '../../styles/scss/components/Header/Header.module.scss';

const DefaultPageHeader = (props) => {
  const {router, title} = props
  return (
    <div className = {styles.default_header}>
      <div className = {styles.button_container}>
        <BackButton click = {() => {router.push('/')}}/>
      </div>
      <h3 className = {styles.page_name}>{title}</h3>
    </div>
  )
}

export default DefaultPageHeader