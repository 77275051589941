import { EditButton, DeleteButton, LeaveButton, ShareButton } from "../../Buttons/Buttons"
import * as styles from '../../../styles/scss/components/LobbyHeaderActions/LobbyHeaderActions.module.scss';
import {connect} from 'react-redux'
import { toggleSidebar } from "../../../store/modals/modalsActions";
import {useRef, useState, useEffect} from 'react'
import TextTooltip from '../../Tooltip/TextTooltip';
import { CSSTransition } from "react-transition-group";
import useTranslation from "next-translate/useTranslation";

const LobbyHeaderActions = (props) => {
  const {
    sidebarData, 
    onToggleSidebar
  } = props
  const {t} = useTranslation();
  const linkInputRef = useRef()
  const [showToolTip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (showToolTip) {
      const interval = setInterval(() => {
        setShowTooltip(false)
        clearInterval(interval)
      }, 1000)
    }
  }, [showToolTip])

  return (
    <div className = {styles.buttons_container}>
      <ul className = {styles.buttons_ul}>
        {sidebarData.showEditButton && (
          <li className = {styles.buttons_li} onClick = {() => {
              onToggleSidebar({
                  sidebarType: 'editGame',
                  hasTitle: true,
                  isOpen: true,
                  sidebarData: {...sidebarData},
                  showSidebarCloseButton: true
                }
              )
            }}>
            <div className = {styles.button_container}>
              <EditButton click = {() => {}} />
            </div>
            <h4 className = {styles.button_text}>{t('common:buttons.edit')}</h4>
          </li>
        )}
        {sidebarData.showDeleteLobbyButton ? (
          <li className = {styles.buttons_li} 
            // onClick = {() => sidebarData.handleDeleteGame()}
            onClick= {() => {
              onToggleSidebar({
                sidebarType: 'sidebarActions',
                hasTitle: false,
                isOpen: true,
                showSidebarCloseButton: true,
                sidebarData: {
                  ...sidebarData,
                  acceptActionMethod: sidebarData.deleteLobbyHandler,
                  rejectActionMethod: () => {
                    onToggleSidebar({
                      isOpen: false
                    })
                  },
                  acceptActionText: t('common:buttons.delete'),
                  rejectActionText: t('common:buttons.cancel'),
                  actionTitle: t('common:modals.delete_game_modal.delete_game_title')
                }
              })
            }}
            >
            <div className = {styles.button_container}>            
              <DeleteButton click = {() => {}} />
            </div>
            <h4 className = {styles.button_text}>{t('common:buttons.delete')}</h4>
          </li>)
          : (
            <li className = {styles.buttons_li} 
              // onClick = {() => sidebarData.handleLeaveGame()}
              onClick = {() => {
                onToggleSidebar({
                  sidebarType: 'sidebarActions',
                  hasTitle: false,
                  isOpen: true,
                  showSidebarCloseButton: true,
                  sidebarData: {
                    ...sidebarData,
                    acceptActionMethod: sidebarData.leaveGameHandler,
                    rejectActionMethod: () => {
                    onToggleSidebar({
                        isOpen: false
                      })
                    },
                    acceptActionText: 'LEAVE',
                    rejectActionText: 'CANCEL',
                    actionTitle: 'Are you sure you want to leave the Game?'
                  }
                })
              }}
              >
              <div className = {styles.button_container}>
                  <LeaveButton  click = {() => {}}/>
              </div>
              <h4 className = {styles.button_text}>Leave</h4>
            </li>
          )
        }
        <li className = {styles.buttons_li} onClick = {()=> {
            setShowTooltip(true)
            linkInputRef.current.select();
            document.execCommand('copy');
            linkInputRef.current.blur();
          }}>
          <div className = {styles.button_container}>
            <ShareButton click = {() => {}} />
            <input className = {styles.lobby_link} ref = {linkInputRef} type = 'text' value = {window.location.href} onChange = {() => {}}/>
          </div>
          <h4 className = {styles.button_text}>{t('common:buttons.share')}</h4>
            <CSSTransition
              in = {showToolTip}
              timeout = {500}
              unmountOnExit
              classNames = 'tooltip'
              >
              <div className = {styles.tooltip_container}>
                <TextTooltip 
                  text = {t('common:copied')}
                  position = {'right'}
                />
              </div>
            </CSSTransition>
        </li>
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sidebarData: state.modals.sidebarData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onToggleSidebar: (sidebarData) => dispatch(toggleSidebar(sidebarData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LobbyHeaderActions);
