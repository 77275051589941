import React from 'react';

const SwapIcon = () => {
    return(
        <svg className="swapIcon" width="25" height="25" x="10" y="-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6.00098" r="6" fill="#25282B" fillOpacity="0.64"/>
            <path d="M9 6.001C9 4.84142 8.29352 3.79205 7.2002 3.32764C7.05816 3.26732 6.89278 3.33032 6.83076 3.46835C6.76873 3.60637 6.83354 3.76715 6.97549 3.82745C7.86445 4.20505 8.43885 5.0582 8.43885 6.001C8.43885 6.7479 8.07751 7.439 7.48507 7.88159L7.67565 6.95028C7.70587 6.80255 7.60719 6.65896 7.45522 6.62958C7.30317 6.60021 7.15554 6.69612 7.12529 6.84384L6.81273 8.37119C6.79823 8.44213 6.81327 8.51577 6.85462 8.57593C6.89596 8.63607 6.96019 8.67778 7.03316 8.69189L8.60442 8.99569C8.6229 8.99925 8.64133 9.00098 8.65947 9.00098C8.79059 9.00098 8.90777 8.91118 8.93433 8.7814C8.96456 8.63367 8.86587 8.49011 8.7139 8.46071L7.85337 8.29435C8.56642 7.7493 9 6.90855 9 6.001Z" fill="white"/>
            <path d="M4.54493 5.37236C4.56341 5.37592 4.58184 5.37765 4.59998 5.37765C4.7311 5.37765 4.84828 5.28785 4.87484 5.15807L5.18737 3.63073C5.21759 3.483 5.11891 3.33942 4.96694 3.31004L3.39573 3.00625C3.24375 2.97694 3.09605 3.07281 3.0658 3.22052C3.03558 3.36826 3.13426 3.51182 3.28623 3.54122L4.14743 3.70771C3.43444 4.2533 3 5.09542 3 6.001C3 7.16062 3.70648 8.20997 4.79981 8.67435C4.8364 8.68987 4.8745 8.69724 4.912 8.69724C5.02011 8.69724 5.12317 8.63611 5.16924 8.53364C5.23127 8.3956 5.16647 8.23482 5.02449 8.17453C4.13555 7.79696 3.56115 6.94381 3.56115 6.001C3.56115 5.25594 3.9229 4.5637 4.51502 4.12069L4.3245 5.05167C4.29428 5.1994 4.39296 5.34296 4.54493 5.37236Z" fill="white"/>
        </svg>
    )
}

export default SwapIcon;