import * as actionTypes from './myGamesActionTypes';
import api from '../../axios/api';
import { generateQueryString } from '../../helpers/generateQueryString';

export const getMyGamesRequest = () => {
  return {
    type: actionTypes.GET_MY_GAMES_REQUEST
  }
}

export const getMyGamesSuccess = (gameData, filters) => {
  return {
    type: actionTypes.GET_MY_GAMES_SUCCESS,
    payload: {data: gameData.data, filters}
  }
}

export const getMyGamesFail = (error) => {
  return {
    type: actionTypes.GET_MY_GAMES_FAIL,
    payload:{
      error
    }
  }
}

const getFiltersObj = (filters) => {
  if(filters.myTurn === false) {
    delete filters.myTurn;
  }
  return filters;
}

export const getMyGames = (filters) => {
  return async (dispatch) => {
    dispatch(getMyGamesRequest());
    const modifiedFilters = getFiltersObj(filters);
    const qs = generateQueryString(modifiedFilters);
    return api.get(`/my-games-new${qs}`)
    .then(response => {
      dispatch(getMyGamesSuccess(response.data, modifiedFilters));
      return response
    })
    .catch(error =>{
      console.log(error);
      dispatch(getMyGamesFail(error));
      return error
    })
  }
}

