import router from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../styles/scss/components/GameModal/FeedbackModal.module.scss'
import { CloseButton, RegularButtons } from '../Buttons/Buttons'
import useTranslation from 'next-translate/useTranslation';
export const FeedbackModal = ({ showInput, issueType, toggleIssueType, handleSubmit, handleChange, loading, comment, handleClose, gameType, handleLeave }) => {
    let currentLang = useSelector(state => state.languages.currentLang)
    const {t} = useTranslation()
    const handleRedirect = () => {
        window.location.href = `/${currentLang}`;
    }
    return <div className={styles.feedbackContainer}>
        <div className={styles.closeBtn}>
            <CloseButton click={handleClose}/>
        </div>
        <h2 className={styles.title}>
            {t('common:modals.leave_game_modal.leave_game_modal_title')}
        </h2>
        <div className={styles.buttonContainer}>
            <button 
                onClick={() => {
                    toggleIssueType('game');
                }} 
                disabled={showInput && issueType == 'game'}
                className={`${styles.actionButton}`}>
                   {t('common:modals.leave_game_modal.game_issue')}
            </button>
            <button
                onClick={() => {
                    toggleIssueType('other');
                }} 
                disabled={showInput && issueType == 'other'} 
                className={styles.actionButton}>
                    {t('common:other')}
            </button>
        </div>
        {
            !showInput && gameType === 'private' &&
            <div className={styles.leaveContainer}>
                <button onClick={handleRedirect} className={styles.leaveBtn}>{t('common:modals.leave_game_modal.skip_to_dashboard')}</button>
            </div>
        }

        {
            showInput && 
            <div>
                <h3 className={styles.commentTitle}>
                    {t('common:modals.leave_game_modal.description_text')}
                </h3>
                <textarea value={comment} onChange={(value) => handleChange(value)} placeholder="Your comment..." className={styles.textarea}/>
                <div className={`${styles.leaveContainer} ${styles.submitContainer}`}>
                    <button disabled={loading || !comment || comment.length === 0} onClick={handleSubmit} className={`${styles.actionButton} ${styles.submit_button}`}>
                        {loading ? t('common:buttons.leaving') : t('common:buttons.submit_capital')}
                    </button>
                </div>
            </div>
        }
        {!showInput && gameType === 'classic' && (
            <div className={styles.classic_leave_container}>
                <p className={styles.text_container}>{t('common:modals.leave_game_title.leave_game_description')}</p>
                <div className={styles.button_container}>
                    <button onClick={handleLeave}>
                        {t('common:buttons.leave')}
                    </button>
                </div>
            </div>
        )}
    </div>
}