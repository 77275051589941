import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as friendshipActions from '../../../store/friends/friendActions';
import * as styles from '../../../styles/scss/components/Friends/Friends.module.scss';
import Avatar from '../../Avatar/Avatar';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import { setUserForProfileSidebar } from '../../../store/modals/modalsActions';
import { searchPeople, searchPeopleSuccess } from '../../../store/friends/friendActions';
import { SearchInput } from '../../FormInput/FormInput';
import useTranslation from 'next-translate/useTranslation';

const Friends = (props) => {
  const {
    onFrinedshipRequest,
    onFrinedsListRequest,
    friendsList,
    onToggleSidebar,
    onSetUserForProfileSidebar,
    onSearchPeople,
    onResetSearchPeople,
    isListDataLoaded,
    peopleList
  } = props;
  const {t} = useTranslation();
  const [enteredFilter, setEnteredFilter] = useState('');
  const [filteredFriends, setFilteredFriends] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    onFrinedsListRequest()
  }, [])

  const handleUserProfileOpen = (user) => {
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'profile',
      hasBackArrow: true,
      showSidebarCloseButton: false,
      hasTitle: true,
      callBackSidebarFunction: () => {
        onToggleSidebar({
          isOpen: true,
          sidebarType: 'friends',
          hasBackArrow: false,
          showSidebarCloseButton: true,
          hasTitle: true
        })
      }
    })
    onSetUserForProfileSidebar(user)
  }

  useEffect(() => {
    if (isListDataLoaded) {
      setFilteredFriends(friendsList.filter(person => {
        if (person.friend.username.toLowerCase().indexOf(inputRef.current.value.toLowerCase()) >=0) {
          return person
        }
      }))
    }
  }, [isListDataLoaded])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (enteredFilter === inputRef.current.value) {
        if (inputRef.current.value.length >= 2) {
          onSearchPeople({
            keyword: inputRef.current.value
          })
        } else {
          onResetSearchPeople()
          setFilteredFriends(friendsList)
        }
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [enteredFilter, inputRef]); //add search friends function as dependancy

  useEffect(() => {
    if (!!friendsList) {
      setFilteredFriends(friendsList)
    }
  }, [friendsList])

  return (
    <section className = {styles.frineds}>
      <div className={styles.main_content_container}>
        <div className={styles.main_content}>
          <div className = {styles.seacrch}>
            <SearchInput 
              reference = {inputRef}
              value = {enteredFilter}
              change = {event => setEnteredFilter(event.target.value)}
              active = {enteredFilter.length > 0}
              showIcon = {enteredFilter.length > 0}
              iconFunction = {() => setEnteredFilter('')}
            />
            {/* <input
              ref={inputRef}
              type="text"
              value={enteredFilter}
              onChange={event => setEnteredFilter(event.target.value)}
            /> */}
          </div>
          <div className = {styles.friends_list}>
            {!!filteredFriends && (
              <>
                <h2>{t('common:sidebar_titles.friends')}</h2>
                <ul className = {styles.frineds_list_ul}>
                  {filteredFriends.map(friend => {
                    return (
                      <li
                        className = {styles.frineds_list_li}
                        key = {`frinedList${friend.friend.id}`}
                        >
                        <div 
                          className = {styles.user_details}
                          onClick = {() => {handleUserProfileOpen({ 
                            ...friend.friend,
                            isFriend: true})
                          }}
                          >
                          <div className = {styles.user_avatar}>
                            <Avatar
                              size = 'medium'
                              hasFlag = {false}
                              avatarUrls = {friend.friend.avatar}
                              mode = 'normal'
                              isOnline = {friend.friend.online}
                            />
                          </div>
                          <h3 className = {styles.user_name}>{friend.friend.username}</h3>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
          </div>
          <div className = {styles.friends_list}>
            {peopleList && (
              <>
                <h2>{t('common:sidebar_body.friends.others')}</h2>
                <ul className = {styles.frineds_list_ul}>
                  {peopleList.map(person => {
                    return (
                      <li
                        className = {styles.frineds_list_li}
                        key = {`frinedList${person.id}`}>
                        <div
                          className = {styles.user_details}
                          onClick = {() => {handleUserProfileOpen({ 
                            ...person,
                            isFriend: false})
                          }}
                          >
                          <div className = {styles.user_avatar}>
                            <Avatar
                              size = 'medium'
                              hasFlag = {false}
                              avatarUrls = {person.avatar}
                              mode = 'normal'
                              isOnline = {person.online}
                            />
                          </div>
                          <h3 className = {styles.user_name}>{person.username}</h3>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </>
              
            )}
          </div>
        </div>
      </div>
      {/* <div className = {styles.button_container}>
        <button className = {styles.log_in} onClick = {() => {
            onToggleSidebar({
              isOpen: true,
              sidebarType: 'inviteFriend',
              hasBackArrow: true,
              hasTitle: true,
              callBackSidebarFunction: () => {
                onToggleSidebar({
                  isOpen: true,
                  sidebarType: "friends",
                  hasBackArrow: false,
                  hasTitle: true,
                });
              },
            })
          }}
          >
          Invite friends
        </button>
      </div> */}
    </section>
  )
}


const mapStateToProps = state => {
  return {
    // auth: state.auth,
    friendsList: state.friends.friendsList,
    peopleList: state.friends.peopleList,
    isListDataLoaded: state.friends.isDataLoaded
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onFrinedshipRequest: (credentials) => dispatch(friendshipActions.friendRequest(credentials)),
    onFrinedsListRequest: () => dispatch(friendshipActions.getFriendList()),
    onToggleSidebar: (sidebarData) => dispatch(toggleSidebar(sidebarData)),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onSearchPeople: (payload) => dispatch(searchPeople(payload)),
    onResetSearchPeople: () => dispatch(searchPeopleSuccess(null))
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Friends);
