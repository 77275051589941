import * as actionTypes from './leaderboardNewActionTypes';

const enumType = {
    overall: 'overall',
    solo: 'solo',
    team: 'team'
  }

  const enumFilter = {
    world: 'world',
    country: 'country',
    state: 'state',
    city: 'city',
    age: 'age',
    friends: 'friends'
  }

const initialState = {
    filter: enumFilter.city,
    filterType: enumType.overall,
    isToggleMobileChangeType: false
}

const leaderboardNewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTER:
            return {
                ...state, 
                filter: action.payload.filter
            };
        case actionTypes.SET_FILTER_TYPE:
            return {
                ...state, 
                filterType: action.payload.filterType
            };
        case actionTypes.TOGGLE_MOBILE_CHANGE_TYPE:
            return {
                ...state, 
                isToggleMobileChangeType: action.payload.isToggleMobileChangeType
            };
        default:
            return state;
    }
}

export default leaderboardNewReducer;