import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setOnboardingCurrentSetpNumber, setOnboardingTooltipCoordinates, setOnboardingFinish, setHideElements, updateOnboardingSetting } from "../../store/onBoarding/onBoardingActions"
import * as styles from '../../styles/scss/components/OnboardingTooltips/OnboardingTooltips.module.scss';
import { toggleConfirmModal } from "../../store/modals/modalsActions";
import useTranslation from "next-translate/useTranslation";
const OnboardingStepper = () => {
  const currentStepNumber = useSelector(state => state.onBoarding.currentStepNumber)
  const totalStepNumbers = useSelector(state => state.onBoarding.totalStepNumbers)
  const hideElements = useSelector(state => state.onBoarding.hideElements)
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const handleSetNextElement = (e) => {
    if (currentStepNumber === totalStepNumbers && e.type === 'click') {
      dispatch(updateOnboardingSetting());
      document.querySelector('.onboardingCurrentElement').classList.remove('onboardingCurrentElement');
      return
    }
    const nextStepNumber = e.type === 'click' ? currentStepNumber + 1 : currentStepNumber
    const element = document.querySelector(`.onboardingElement[data-stepnumber='${nextStepNumber}']`)
    if (nextStepNumber !== currentStepNumber) {
      if(currentStepNumber == 3){
        document.querySelector(`.onboardingElement[data-stepnumber='3']`).parentElement.classList.remove('onboardingCurrentElement')
      }
      document.querySelector(`.onboardingElement[data-stepnumber='${currentStepNumber}']`).classList.remove('onboardingCurrentElement')
    }

    const positions = element.getBoundingClientRect()
    const onboardingElement = document.querySelector('.onboarding_container')
    const newPositions = {
      x: positions.x,
      y: positions.y,
      width: positions.width,
      height: positions.height,
      tooltipWidth: onboardingElement.getBoundingClientRect().width,
      tooltipHeight: onboardingElement.getBoundingClientRect().height,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      tooltipPos: element.getAttribute('data-tooltippos')
    }
    dispatch(setOnboardingTooltipCoordinates(newPositions));
    if(element.getAttribute('data-stepnumber') == '3'){
      element.parentElement.classList.add('onboardingCurrentElement');
    }
     else {
      element.classList.add('onboardingCurrentElement');
    }
    dispatch(setOnboardingCurrentSetpNumber(nextStepNumber));
  }

  const handleOnboaringSkip = () => {
    dispatch(setHideElements(true))
    document.querySelector('.onboardingCurrentElement').classList.add('hidden')
    dispatch(toggleConfirmModal({
      isOpen: true,
      confirmModalData: {
        text: t('common:modals.skip_tutorial.title'),
        acceptText: t('common:buttons.yes'),
        rejectText: t('common:buttons.go_back'),
        sameButtons: true,
        closeFunc: () => {
          dispatch(toggleConfirmModal({isOpen: false}))
          dispatch(setHideElements(false))
          document.querySelector('.onboardingCurrentElement').classList.remove('hidden')
        },
        acceptFunc: () => {
          // dispatch(setOnboardingFinish());
          dispatch(updateOnboardingSetting());
          dispatch(toggleConfirmModal({isOpen: false}));
          document.querySelector('.onboardingCurrentElement').classList.remove('onboardingCurrentElement');
          dispatch(setHideElements(false))
        },
        rejectFunc: () => {
          dispatch(toggleConfirmModal({isOpen: false}))
          dispatch(setHideElements(false))
          document.querySelector('.onboardingCurrentElement').classList.remove('hidden')
        }
      }
    }))
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetNextElement)
    return () => {window.removeEventListener('resize', handleSetNextElement)}
  }, [currentStepNumber])
  return (
    <div className={`${styles.stepper_container} ${hideElements ? styles.hidden: ''}`}>
      <span className={styles.skip_text} onClick = {handleOnboaringSkip}>{currentStepNumber !== totalStepNumbers && t('onboarding:skip_tutorial')}</span>
      
      <div className={styles.action_button_container}>
        <button onClick={handleSetNextElement}>
          <span className={styles.text}>{currentStepNumber !== totalStepNumbers ? t('onboarding:next') : t('onboarding:finish')}</span>
          <div className={styles.indicator}>
            <span className={styles.info}>{currentStepNumber} / {totalStepNumbers}</span>
          </div>
        </button>
        </div>
    </div>
  )
}

export default OnboardingStepper