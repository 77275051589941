import * as styles from "../../styles/scss/components/GameMember/GameMember.module.scss";
import Image from "next/image";
import TeamSign from "../TeamSign/TeamSign";
import Marble from "../Board/Marble";


const GameMember = ({ teamInfo, displayFullName, removeFullName, mousePos }) => {
  return (
    <div className={styles.cont}>
      <div className={styles.flexChild}>        
        <TeamSign signName = {teamInfo.logo} />
        <h2 data-name={teamInfo.name} onMouseMove={displayFullName} onMouseOut={removeFullName} className={`${styles.textGeneral} ${styles.team_name}`}>
          {teamInfo.name.length > 10 ? `${teamInfo.name.substring(0,10)}...` : teamInfo.name}
        </h2>
        <span style={{left: mousePos.x}} className={styles.player_fullname}>{teamInfo.name}</span>
      </div>
      <div className={`${styles.flexChild} ${styles.numCont}`}>
        <div className={styles.team_marbles}>
          {
            teamInfo.players.map( player => {
              return (
                <svg key={player.id} width="32" height="32">
                  <circle cx="16" cy="16" r="13" strokeWidth='6' stroke={`url('#${player.color}Stroke')`} fill="none" />
                  <Marble color={player.color} transform="translate(3,3)" width="26" height="26" rx="5.9" ry="5.9" />
                </svg>
              )
            })
          }          
        </div>
        <span className={styles.textGeneral}>{teamInfo.stat}</span>
      </div>
    </div>
  );
};

export default GameMember;
