import * as actionTypes from './myGamesActionTypes';

const initialState = {
  games: [],
  loading: false,
  activeGamesCount: 0,
  finishedGamesCount: 0,
  dataLoaded: false
}

const myGamesReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.GET_MY_GAMES_REQUEST:
        return {
          ...state,
          loading: true
        };
      case actionTypes.GET_MY_GAMES_SUCCESS: {
        const payload = action.payload.data;
        const filters = action.payload.filters;
        const updateActives = filters.status === 'started' || !filters.status;
        const updateFinished = filters.status === 'finished' || (updateActives && Object.keys(filters).length < 2);
        return {
          ...state,
          games: payload.myGames,
          finishedGamesCount: updateFinished ? payload.totalFinished : state.finishedGamesCount,
          activeGamesCount: updateActives ? payload.totalActive : state.activeGamesCount,
          loading: false,
          dataLoaded: true
        }
      }
      case actionTypes.GET_MY_GAMES_FAIL: {
        return {
          ...state,
          loading: true,
          dataLoaded: false
        }
      }
      default:
        return state;
    }
}

export default myGamesReducer;