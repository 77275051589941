import { useRef, useState, useEffect } from "react"
import styles from './styles.module.scss'
import { PlayerTime } from "../../config/api.config"
const ProgressBar = (props) => {
  const {
    totalTime, 
    callBackFunc = () => {},
    type = 'svg',
    timeConst = PlayerTime,
    forceUpdate,
  } = props
  const [timeLeft, setTimeLeft] = useState(totalTime)
  
  useEffect(() => {
    if (!!forceUpdate && forceUpdate.timeLeft === PlayerTime) {
      setTimeLeft(totalTime)
    }
  }, [forceUpdate])
  const [startGlowing, setStartGlowing] = useState(false)
  const firstColorFirstCoeficient = (255 - 69) / (timeConst / 2)
  const firstColorSecondCoeficient = (153 - 183) / (timeConst / 2)
  const firstColorThirdCoeficient = (60 - 59) / (timeConst / 2)

  const secondColorFirstCoeficient = (255 - 237) / (timeConst / 2)
  const secondColorSecondCoeficient = (153 - 65) / (timeConst / 2)
  const secondColorThirdCoeficient = (60 - 83) / (timeConst / 2)

  const innerRef = useRef()

  useEffect(() => {
    if (innerRef?.current) {
      const interval = setInterval(() => {
        if (timeLeft >= 0) {
          let innerWidth = (100 * timeLeft) / timeConst
          innerWidth = innerWidth > 10 ? innerWidth : 10
          if (innerWidth <= 20) {
            setStartGlowing(true)
          }

          if(timeLeft >= (timeConst / 2)) {
            innerRef.current && (innerRef.current.style.background = `rgb(${69 + (firstColorFirstCoeficient * (timeConst - timeLeft))}, ${183 + (firstColorSecondCoeficient * (timeConst - timeLeft))}, ${59 + (firstColorThirdCoeficient * (timeConst - timeLeft))})`)
          } else {
            innerRef.current && (innerRef.current.style.background = `rgb(${237 + (secondColorFirstCoeficient * (timeLeft))}, ${65 + (secondColorSecondCoeficient * (timeLeft))}, ${83 + (secondColorThirdCoeficient * (timeLeft))})`)
          }
          innerRef.current && (innerRef.current.style.width = `${innerWidth}%`)
          setTimeLeft(timeLeft => timeLeft - 1)
        } else {
          clearInterval(interval)
          if (callBackFunc) {
            callBackFunc()
          }
        }
      }, 1000)
      return () => clearInterval(interval);
    }
  }, [timeLeft, innerRef.current, type])

    return (
      <div className={styles.progress_bar_container}>
        <div className={`${styles.inner_container} ${startGlowing ? styles.glowing: ''}`} ref={innerRef}></div>
      </div>
    )

}

export default ProgressBar