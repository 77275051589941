export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const MARK_NOTIFICATION_AS_READ_REQUEST = 'MARK_NOTIFICATION_AS_READ_REQUEST';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAIL = 'MARK_NOTIFICATION_AS_READ_FAIL';

export const SET_NEW_NOTIFCATION = 'SET_NEW_NOTIFCATION';
export const CHANGE_GAME_REQUEST_NOTIFICATION_MESSAGE = 'CHANGE_GAME_REQUEST_NOTIFICATION_MESSAGE';

export const MAKE_ACTION_SUCCESS = 'MAKE_ACTION_SUCCESS';

// export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'

export const PAG_INDEX = 'PAG_INDEX'