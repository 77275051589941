const getQueryParams = (string) => {
  const link = string
  const params = {};
  const paramsStartingIndexes = [];
  for (let i = 0; i < link.length; i++) {
    if (link[i] === '#' || link[i] === '&' || link[i] === '?') {
      paramsStartingIndexes.push(i)
    }
  }
  for (let i = 0; i < paramsStartingIndexes.length; i++) {
    const start = paramsStartingIndexes[i]
    let end = 0;
    for (let j = start; j < link.length; j++) {
     if (link[j] === '=') {
       end = j;
       break
     }
    }
    const paramName = link.substring(start + 1, end)
    const paramValue = link.substring(end + 1, paramsStartingIndexes[i + 1] ? paramsStartingIndexes[i + 1] : link.length)
    params[paramName] = paramValue
  }
  return params
}

export const getRouterQuery = (router) => {
  if(!router) return {}
  let queryString = '';
  if(router.asPath.includes('#')) {
    queryString = router.asPath.includes('#') ? router.asPath.substring(router.asPath.indexOf('?')+1, router.asPath.length) : '';
  } else {
    queryString = router.asPath.includes('?') ? router.asPath.substring(router.asPath.indexOf('?')+1, router.asPath.length) : '';
  }
  let firstCon = Object.keys(router.query) == 0;
  let secondCon = queryString != '';
  if(firstCon && secondCon) {
    let query = {};
    queryString.split('&').map(el => {
      query[el.substring(0, el.indexOf('='))] = el.substring(el.indexOf('=')+1, el.length) 
    })
    return query;
  } else {
    return router.query;
  }
}

export default getQueryParams