export const SEND_FRIEND_REQUEST = "SEND_FRIEND_REQUEST";
export const SEND_FRIEND_REQUEST_SUCCESS = "SEND_FRIEND_REQUEST_SUCCESS ";
export const SEND_FRIEND_REQUEST_FAIL = "SEND_FRIEND_REQUEST_FAIL";

export const GET_FRIEND_LIST_REQUEST = "GET_FRIEND_LIST_REQUEST";
export const GET_FRIEND_LIST_SUCCESS = "GET_FRIEND_LIST_SUCCESS";
export const GET_FRIEND_LIST_FAIL = "GET_FRIEND_LIST_FAIL";

export const SEND_ACCEPT_FRIEND_REQUEST = "SEND_ACCEPT_FRIEND_REQUEST";
export const SEND_ACCEPT_FRIEND_REQUEST_SUCCESS = "SEND_ACCEPT_FRIEND_REQUEST_SUCCESS";
export const SEND_ACCEPT_FRIEND_REQUEST_FAIL = "SEND_ACCEPT_FRIEND_REQUEST_FAIL";

export const SEND_DECLINE_FRIEND_REQUEST = "SEND_DECLINE_FRIEND_REQUEST";
export const SEND_DECLINE_FRIEND_REQUEST_SUCCESS = "SEND_DECLINE_FRIEND_REQUEST_SUCCESS";
export const SEND_DECLINE_FRIEND_REQUEST_FAIL = "SEND_DECLINE_FRIEND_REQUEST_FAIL";

export const SEND_BLOCK_FRIEND_REQUEST = "SEND_BLOCK_FRIEND_REQUEST";
export const SEND_BLOCK_FRIEND_REQUEST_SUCCESS = "SEND_BLOCK_FRIEND_REQUEST_SUCCESS";
export const SEND_BLOCK_FRIEND_REQUEST_FAIL = "SEND_BLOCK_FRIEND_REQUEST_FAIL";

export const SEND_DELETE_FRIEND_REQUEST = "SEND_DELETE_FRIEND_REQUEST";
export const SEND_DELETE_FRIEND_REQUEST_SUCCESS = "SEND_DELETE_FRIEND_REQUEST_SUCCESS";
export const SEND_DELETE_FRIEND_REQUEST_FAIL = "SEND_DELETE_FRIEND_REQUEST_FAIL";

export const SEARCH_PEOPLE_REQUEST = "SEARCH_PEOPLE_REQUEST";
export const SEARCH_PEOPLE_REQUEST_SUCCESS = "SEARCH_PEOPLE_REQUEST_SUCCESS";
export const SEARCH_PEOPLE_REQUEST_FAIL = "SEARCH_PEOPLE_REQUEST_FAIL";

export const SET_REFFERAL_EMAIL = "SET_REFERRAL_EMAIL";
export const SET_REFFERAL_AVATAR = "SET_REFFERAL_AVATAR";
export const UPDATE_FRIEND_ONLINE_STATUS = "UPDATE_FRIEND_ONLINE_STATUS";