import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useTranslation from "next-translate/useTranslation"

export const useUpdateUser = () => {
    const userState = useSelector(store => store.auth.user)
    const {t} = useTranslation()
    const [emailState, setEmailState] = useState({
        value: '',
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/,
        valid: false,
        changed: false,
        errorText: t('errors:email.invalid'),
    })

    useEffect(() => {
        setEmailState(prevState => ({...prevState, value: userState.email, valid: true}))
    }, [])

    const setEmailAsInvalid = () => {
        setEmailState(prevState => ({ ...prevState,valid: false, errorText: t('errors:email.already_exists')}))
    }

    const handleInputChange = (e) => {
        switch(e.target.name) {
            case 'email':
                const value = e.target.value.trim();
                const valid = emailState.regex.test(value);
                setEmailState(prevState => ({...prevState, value, changed: true, valid, errorText: valid ? '' : t('errors:email.invalid')}))
                break;
            default: {
                return;
            }
        }
    }

    return {handleInputChange, emailState, setEmailAsInvalid};
}