import * as styles from '../../styles/scss/components/AvatarsSlider/AvatarsSlider.module.scss';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Avatar from '../Avatar/Avatar';
import { BackButton } from '../Buttons/Buttons';
import { setChosenAvatar, setSaveButtonEnabled } from '../../store/avatars/avatarsActions';


const AvatarsSlider = (props) => {
  const {
    avatarsList,
    onSetChosenAvatar,
    chosenAvatarIndex,
    onSetSaveButtonEnabled,
  } = props

  const handleSelectedItemChange = (index) => {
    index == 0 ? onSetSaveButtonEnabled(false) : onSetSaveButtonEnabled(true)
    onSetChosenAvatar(avatarsList[index])
  }

  const handleIncrementChange = (number) => {
    if (number === 1) {
      onSetChosenAvatar(avatarsList[chosenAvatarIndex + 1])
    } else if (number === -1) {
      onSetChosenAvatar(avatarsList[chosenAvatarIndex - 1])
    }
  }

  const settings = {
    showIndicators: false,
    showStatus: false,
    useKeyboardArrows: true,
    emulateTouch: true,
    autoPlay: false,
    infiniteLoop: true,
    showArrows: false,
    showThumbs: false
  };
  return (
    <div className = {styles.avatars_slider_container}>
      {avatarsList && (
        <div className = {styles.avatars_slider_inner}>
          <div className = {`${styles.slider_buttons} ${styles.prev_button}`}>
            <BackButton click = {()=> {handleIncrementChange(-1)}}/>
          </div>
          <Carousel 
            {...settings}
            selectedItem = {chosenAvatarIndex}
            onChange = {(data) => {handleSelectedItemChange(data)}}
            >
            {avatarsList.map(avatar => {
              return (
                <div className = {styles.slider_custom_item} key = {`avatarSlider${avatar.id}`}>
                  <Avatar 
                    mode = 'normal'
                    size = 'size400'
                    avatarUrls = {avatar.avatars}
                    hasFlag = {false}
                  />
                </div>
              )
            })}
          </Carousel>
          <div className = {`${styles.slider_buttons} ${styles.next_button}`}>
            <BackButton click = {()=> {handleIncrementChange(1)}} rotate = {180}/>
          </div>
        </div>
      )}
    </div>
  )
}



const mapStateToProps = state => {
  return {
    avatarsList: state.avatars.avatarsList,
    chosenAvatar: state.avatars.chosenAvatar,
    chosenAvatarIndex: state.avatars.index,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetChosenAvatar: (avatar) => dispatch(setChosenAvatar(avatar)),
    onSetSaveButtonEnabled: (isEnabled) => dispatch(setSaveButtonEnabled(isEnabled)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarsSlider);
