import * as styles from '../../styles/scss/components/SkeletonLoader/SkeletonLoader.module.scss';

export const Circle = ({size}) => {
  return (
    <div className = {`${styles.skeleton} ${styles.circle}`}>
      <style jsx>{`
        .${styles.circle} {
          width: ${size};
          height: ${size};
        }
      `}</style>
    </div>
  )
}

export const Text = ({width, height}) => {
  return (
    <div className = {`${styles.skeleton} ${styles.text}`}>
      <style jsx>{`
        .${styles.text} {
          width: ${width};
          height: ${height};
        }
      `}</style>
    </div>
  )
}

export const BlockElement = ({width, height}) => {
  return (
    <div className = {`${styles.skeleton} ${styles.block}`}>
      <style jsx>{`
        .${styles.block} {
          width: ${width};
          height: ${height};
        }
      `}</style>
    </div>
  )
}