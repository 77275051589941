import { useEffect, useRef, useState, useDisputch, useLayoutEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'

import api from '../../../axios/api';
import FormInput from '../../FormInput/FormInput';
import CopySvg from '../../../public/images/common/copy.svg';
import * as styles from '../../../styles/scss/components/InviteFriend/InviteFriend.module.scss';
import { SubmitButton } from '../../SubmitButton/SubmitButton';
import {SideBarSuccess} from '../../SidebarSuccess/SidebarSuccess';
import Avatar from '../../Avatar/Avatar';
import {setReferralAvatar, setReferralEmail} from '../../../store/friends/friendActions';
import useTranslation from 'next-translate/useTranslation';
import { deviceType } from '../../../helpers/functions'

// Invite frind is component that is displayed in sidebar, you can send email invitation to user or copy referral link.
const InviteFriend = (props) => {
  const {
    user,
    toggleSidebar
  } = props;

  const {t} = useTranslation();

  const friendsState = useSelector(state => state.friends.inviteFriend);
  const avatarsList = useSelector(state => state.avatars.avatarsList);
  const dispatch = useDispatch();

  const currentLang = useSelector(state => state.languages.currentLang)
  const [copyBtnText, setCopyBtnText] = useState(null)

  useEffect(() => {
    if (currentLang) {
      setCopyBtnText(t('common:buttons.copy'))
      setButtonState(prevState => {
        return {
          ...prevState,
          text: t('common:buttons.send_invite')
        }
      })
    }
  }, [currentLang])

  const [avatarState, setAvararState] = useState({
    size: 'large',
    hasFlag: false,
    avatarUrls: friendsState.avatar.avatars,
    showEditIcon: true,
    country: ''
  })

  useEffect(() => {
    if(!friendsState.avatar) {
      dispatch(setReferralAvatar(avatarsList[0]))
      setAvararState(prevState => ({...prevState, avatarUrls: avatarsList[0].avatars}))
    }
    setAvararState(prevState => ({...prevState, avatarUrls: friendsState.avatar.avatars}))

  }, [friendsState])

  
  const textRef = useRef(null);
  
  const [mail, setMail] = useState(friendsState.email)
  const [buttonState, setButtonState] = useState({
    text: t('common:buttons.send_invite'),
    loading: false,
    isValid: true,
    handleSubmit: (e) => handleSendInvitation(e),
    // loadingComponent: <Loader loaderSize = {'size35'} />,
    loadingComponent: t('common:buttons.sending'),
    errorText: '',
    showSuccessView: false,
  });

  const handleAvatarEdit = (e) => {
    dispatch(setReferralEmail(mail))
    toggleSidebar({
      isOpen: true,
      sidebarType: 'editAvatar',
      hasTitle: true,
      hasBackArrow: true,
      showSidebarCloseButton: false,
      referrer: 'inviteFriend',
      callBackSidebarFunction: () => {
        toggleSidebar({
          isOpen: true,
          sidebarType: 'inviteFriend',
          hasBackArrow: false,
          showSidebarCloseButton: true,
          hasTitle: true
        })
      }
    })
  }

  const handleChange = (e) => {
    setButtonState(prevState => {
      return {
        ...prevState,
        isValid: true,
        errorText: '',
      }
    });
    setMail(e.target.value);
  }


  let handleSendInvitation = (e) => {
    e.preventDefault()
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let isValidMail = regex.test(mail);
    if(mail === '') {
      setButtonState(prevState => ({
        ...prevState,
        isValid: false,
        errorText: t('errors:email.not_empty')
      }))
      return;
    }
    if (isValidMail) {
      setButtonState(prevstate => (
        {
          ...prevstate,
          loading: true,
        }
      ))
      api.post('/users/send-referral-email', 
        {
          email: mail,
          avatarId: friendsState.avatar.id
        },
        {
          headers: {hideErrorPage: true}
        }
      ).then(response => {
        setButtonState(prevstate => (
          {
            ...prevstate,
            loading: false,
            showSuccessView: true,
          }
        ))
      }).catch(e => {
        const invitationAlreadySent = e?.response?.data?.message === 'INVITATION_ALREADY_SENT';
        setButtonState(prevstate => (
          {
            ...prevstate,
            loading: false,
            errorText: invitationAlreadySent ? t('errors:email.invitation_already_sent') : t('errors:email.user_exists'),
            isValid: false,
          }
        ))
      })
    } else {
      setButtonState(prevState => ({
        ...prevState,
        isValid: false,
        errorText: t('errors:email.invalid')
      }))
    }
  }

  const handleDone = () => {
    setButtonState(prevState => {
      return {
        ...prevState,
        showSuccessView: false,
      }
    })
    setMail('');
    dispatch(setReferralEmail(''));
  }

  const [orientation, setOrientation] = useState()

  useEffect(() => {
    if(window.orientation === 90){
      setOrientation('landscape')
    } else if(window.orientation === 0){
      setOrientation('portrait')
    }
    const handleRotation = () => {
      if(window.orientation === 0){
        setOrientation('portrait')
      } else if(window.orientation === 90){
        setOrientation('landscape')
      }
    }
    window.addEventListener("orientationchange", handleRotation, false);
    return () => {
      window.removeEventListener('orientationchange', handleRotation);
    }
  }, [])

  const InviteFriendsComponent =  () => {
    if(orientation === 'landscape' && deviceType() === 'mobile') {
      return (
        <div className = {styles.invite_friend_sidebar}>
        <div className={styles.form_container}>
          <div className={styles.form}>
            <div className = {`${styles.avatar_container}`} onClick={handleAvatarEdit}>
              <Avatar {...avatarState}/>
              <h5 className={styles.avatar_text}>{t('common:sidebar_body.invite_friend.choose_avatar_for_friend')}</h5>
            </div>
              <div className = {styles.referal_code_contaier} onClick = {() => {
                      setCopyBtnText(t('common:buttons.copied'))
                setTimeout(() => {
                  setCopyBtnText(t('common:buttons.copy'))
                }, 2000)
  
                textRef.current.select()
                document.execCommand('copy')
                textRef.current.blur()
              }}>
            <input className = {styles.referal_code} ref = {textRef} type = 'text' value = {`${user.referralLink}&ai=${friendsState.avatar.id}`} onChange = {() => {}}/>
            <span className = {styles.referal_code}>{t('common:sidebar_body.invite_friend.link')}</span>
            <button className = {styles.copy_button} >
              <CopySvg />
              <span className = {styles.text}>{copyBtnText}</span>
            </button>
          </div>
          </div>
        </div>
        <div className = {styles.referal_container}>
          <h2 style={{fontWeight: '400', fontSize: '14px', justifyContent:'flex-start',
          alignSelf:'baseline',
          paddingBottom:'15px'
        
        }}>Invite via Email</h2>
        <form onSubmit={handleSendInvitation} className = {`${styles.single_input}`}>
          <FormInput
            type = 'mail'
            value = {mail}
            name = 'mail'
            change = {(e) => handleChange(e)}
            active = {mail.length > 0}
            labelText = {t('common:inputs.friends_email')}
            showIndicator = {false}
            id = 'friendsMail'
            valid = {buttonState.isValid}
            errorText = {buttonState.errorText}
            changed = {true}
          />
          </form>
          <div className={styles.button_container}>
            <SubmitButton {...buttonState} handleSubmit={handleSendInvitation} />
          </div>
          </div>
      </div>
      )
    } else {
      return (
        <div className = {styles.invite_friend_sidebar}>
        <div className={styles.form_container}>
          <div className={styles.form}>
            <div className = {`${styles.avatar_container}`} onClick={handleAvatarEdit}>
              <Avatar {...avatarState}/>
              <h5 className={styles.avatar_text}>{t('common:sidebar_body.invite_friend.choose_avatar_for_friend')}</h5>
            </div>
            <form onSubmit={handleSendInvitation} className = {`${styles.single_input}`}>
                <FormInput
                  type = 'mail'
                  value = {mail}
                  name = 'mail'
                  change = {(e) => handleChange(e)}
                  active = {mail.length > 0}
                  labelText = {t('common:inputs.friends_email')}
                  showIndicator = {false}
                  id = 'friendsMail'
                  valid = {buttonState.isValid}
                  errorText = {buttonState.errorText}
                  changed = {true}
                />
            </form>
          </div>
        </div>
        <div className = {styles.referal_container}>
          <div className={styles.button_container}>
            <SubmitButton {...buttonState} handleSubmit={handleSendInvitation} />
          </div>
          <h2 className = {styles.referal_container_title}>{t('common:sidebar_body.invite_friend.send_referal_link')}</h2>
          <div className = {styles.referal_code_contaier} onClick = {() => {
                setCopyBtnText(t('common:buttons.copied'))
                setTimeout(() => {
                  setCopyBtnText(t('common:buttons.copy'))
                }, 2000)
  
                textRef.current.select()
                document.execCommand('copy')
                textRef.current.blur()
              }}>
            <input className = {styles.referal_code} ref = {textRef} type = 'text' value = {`${user.referralLink}&ai=${friendsState.avatar.id}`} onChange = {() => {}}/>
            <span className = {styles.referal_code}>{t('common:sidebar_body.invite_friend.link')}</span>
            <button className = {styles.copy_button} >
              <CopySvg />
              <span className = {styles.text}>{copyBtnText}</span>
            </button>
          </div>
          </div>
      </div>
      )
    }};
  // Popup that is displayed after successfull send referral invitation
  const SuccessView = <SideBarSuccess 
    handleDone = {handleDone} 
    message={`${t('common:sidebar_body.invite_friend.success_view.message')} ${mail}`} 
    title={t('common:sidebar_body.invite_friend.success_view.title')}
    buttonText= {t('common:sidebar_body.invite_friend.success_view.button_text')}
  /> 
  
  return buttonState.showSuccessView ? SuccessView : InviteFriendsComponent();
}

export default InviteFriend