import React from 'react';
import Avatar from "../Avatar/Avatar";
import * as styles from '../../styles/scss/components/OtherPlayersIndicator/OtherPlayersIndicator.module.scss';
import useTranslation from 'next-translate/useTranslation'

function OtherPlayersIndicator({
        players,
        roundState,
        myTurn,
        isMobile
    }){

    const {t} = useTranslation();

    const playersTurn = () => {
        const player = players?.find( player => player.myTurn && !player.currentPlayer );
        if(player){
          return player;
        }
    }

    return(
        playersTurn() && roundState !== "exchange" && !myTurn && isMobile
        ?
            <div className={styles.others_turn}>
              <div className={`${styles.others_turn_container} ${styles[playersTurn().color]}`}>
                <div className={styles.others_turn_avatar}>
                  <Avatar 
                    hasFlag = {true}
                    avatarUrls = {playersTurn().user?.avatar}
                    size = "small"
                    color={playersTurn().color}
                    country={playersTurn().user?.countryCode ? playersTurn().user?.countryCode : '00'}
                  />
                </div>
                <div className={styles.others_turn_text}>
                  <p><b>{playersTurn().user?.username}</b>{t('common:players_turn')}</p>
                  <p>{t('common:modals.game_search.please_wait')}</p>
                </div>
              </div>
            </div>
        :
        ""
    )
}

export default React.memo(OtherPlayersIndicator);
