import * as actionTypes from './onBoardingActionTypes';

const initialState = {
  currentElement:'',
  currentStepNumber: 1,
  totalStepNumbers: 5,
  tooltipPositionCoordinates: {
    x: 0,
    y: 0
  },
  tooltipPositionString: 'bottom',
  trianglePosition: 'center',
  onboardingInProgress: false,
  showOnboardingElement: false,
  currentStepsData: [
    {
      id: 'og',
      imgName: 'online-game.svg',
      title: 'online_game_title',
      text: 'online_game_description'
    },
    // {
    //   id: 'hg',
    //   imgName: 'hero-game.svg',
    //   title: 'Hero Game',
    //   text: 'Become the hero you always dreamed of and rescue the ongoing game by replacing the player who could not finish the game. '
    // },
    // {
    //   id: 'ttg',
    //   imgName: 'team-tactics-game.svg',
    //   title: 'Team Tactics Game',
    //   text: 'Make a call to your friend and play each move even more planned than usual.'
    // },
    {
      id: 'fg',
      imgName: 'friends-game.svg',
      title: 'friends_game_title',
      text: 'friends_game_description'
    },
    {
      id: 'qp',
      imgName: 'quick-play.svg',
      title: 'quick_play_title',
      text: 'quick_play_description'
    },
    {
      id: 'iz',
      imgName: 'info-zone.svg',
      title: 'info_zone_title',
      text: 'info_zone_description'
    },
    {
      id: 'mpns',
      imgName: 'notifications-settings.svg',
      title: 'notification_title',
      text: 'notification_description',
    }
  ],
  scrollIsNeeded: false,
  hideElements: false,
}

// const setCurrentSteps = (windowWidth, orientation, stepsData) => {
//   let currentStepsData = [];
//   if (windowWidth < 768) {
//     for (let i = 0; i < 6; i++) {
//       currentStepsData.push({...stepsData[i]})
//     }
//     currentStepsData.push({
//       id: 'mpns',
//       imgName: 'notifications-settings.svg',
//       title: 'My Profile, Notifications & Settings',
//       text: 'From here you can view and edit your profile information, change your avatar and see overall statistics, also invite friends, check notifications, and change game settings such as language, sound, and so on.',
//     })
//   } else {
//     return stepsData
//   }
//   return currentStepsData
// }

const onBoardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ELEMENT: {
      return {
        ...state,
        currentElement: action.payload.currentElement
      }
    }
    case actionTypes.SET_ONBOARDING_BACKDROP_STATE: {
      return {
        ...state,
        showBackdrop: action.payload.backdropState
      }
    }
    case actionTypes.SET_ONBOARDING_CURRENT_STEP_NUMBER: {
      return {
        ...state,
        currentStepNumber: action.payload.currentStepNumber
      }
    }
    case actionTypes.SET_ONBOARDING_TOOLTIP_COORDINATES: {
      const currentElementCoordinates = action.payload.coordinates
      let left = currentElementCoordinates.x + (currentElementCoordinates.width / 2) - (currentElementCoordinates.tooltipWidth / 2);
      let trianglePosition = '50%'
      let top = 0;
      const tooltipPos = currentElementCoordinates.tooltipPos;
      if (left < 0) {
        left = 10
        trianglePosition = (currentElementCoordinates.x + (currentElementCoordinates.width / 2) - left) + 'px'
      } else if (left + currentElementCoordinates.tooltipWidth > currentElementCoordinates.windowWidth) {
        left = currentElementCoordinates.windowWidth - currentElementCoordinates.tooltipWidth - 10
        trianglePosition =  (currentElementCoordinates.x + (currentElementCoordinates.width / 2) - left) + 'px'
      }
      if (state.currentStepNumber === 4 || state.currentStepNumber === 5) {
        trianglePosition = '50%'
        if (currentElementCoordinates.windowWidth <= 767) {
          trianglePosition = '90%'
        }
      }
      // top = currentElementCoordinates.y + currentElementCoordinates.height + 20
      if (!tooltipPos || tooltipPos === 'bottom') {
        top = currentElementCoordinates.y + currentElementCoordinates.height + 20
      } else {
        top = currentElementCoordinates.y - 20
      }
      let scrollIsNeeded = false
      if (top + currentElementCoordinates.tooltipHeight > currentElementCoordinates.windowHeight) {
        scrollIsNeeded = true
      }

      const coordinates = {
        x: left,
        y: top
      }
      return {
        ...state,
        tooltipPositionCoordinates: coordinates,
        tooltipPositionString: tooltipPos || 'bottom',
        trianglePosition: trianglePosition,
        scrollIsNeeded
      }
    }
    case actionTypes.SET_SHOW_ONBOARDING_ELEMENT: {
      // const currentStepsData = setCurrentSteps(window.innerWidth, '', state.stepsData)
      return {
        ...state,
        showOnboardingElement: action.payload.state,
        onboardingInProgress: action.payload.state,
        currentStepNumber: 1,
        tooltipPositionString: 'bottom',
        trianglePosition: 'center'
        // currentStepsData: currentStepsData,
        // totalStepNumbers: currentStepsData.length        
      }
    }
    case actionTypes.SET_ONBOARDING_FINISHED: {
      return {
        ...state,
        showOnboardingElement: false,
        showBackdrop: false,
        onboardingInProgress: false
      }
    }
    case actionTypes.SET_ONBOARDING_STEPS: {
      return{
        ...state
      }
    }
    case actionTypes.SET_HIDE_ELEMENTS: {
      return{
        ...state,
        hideElements: action.payload.state
      }
    }
    default:
      return state;
  }
}

export default onBoardingReducer;