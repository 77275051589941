
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import NotFound from "./404";
import ServerError from "./500";
import AccessDenied from "./403";
import NotAuthorized from "./401";
import TooManyRequest from "./429";
import Head from "next/head";

function Error(err) {

  console.log("An unexpected error ocured______", err);

  if (err.statusCode === 404) {
    return <NotFound />;
  } else if (err.statusCode === 403) {
    return <AccessDenied />;
  } else if (err.statusCode === 401) {
    return <NotAuthorized />;
  } else if (err.statusCode === 429) {
    return <TooManyRequest />;
  } else if (err.statusCode >= 500) {
    return <ServerError />;
  }
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        {process.env.NEXT_ENABLE_GTAG == "true" && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                      page_path: window.location.pathname,
                    });
                  `,
              }}
            />
          </>
        )}
      </Head>
      <ServerError />
    </>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const errorMessage = err.message
    ? err.message
    : "An error ocured, we are on it, please refresh the page";
  return { statusCode, errorMessage };
};

const __Page_Next_Translate__ = Error;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  