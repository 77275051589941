import { useState } from "react";
import Card from '../Card/Card';
import * as styles from '../../styles/scss/components/DroppedCardsInModal/DroppedCardsInModal.module.scss';
import useTranslation from "next-translate/useTranslation";

const DroppedCards = (props) => {
  const  {
    droppedCards
  } = props
  const {t} = useTranslation();

  const [droppedCardsPositions, setDroppedCardsPositions] = useState ([
    {
      positionX: -40,
      positionY: -18,
      rotation: 15
    },
    {
      positionX: -30,
      positionY: -15,
      rotation: -12
    },
    {
      positionX: -30,
      positionY: -25,
      rotation: 12
    },
    {
      positionX: 0,
      positionY: 0,
      rotation: 0
    },
    {
      positionX: 15,
      positionY: 20,
      rotation: 10
    },
    {
      positionX: 30,
      positionY: 18,
      rotation: 16
    }
  ])
  return (
    <div className = {styles.dropped_cards_container}>
      <ul className = {styles.dropped_cards_container_ul}>
          <div className = {styles.dropped_text}>{t('common:modals.game_info.dropped')}</div>
          {
            droppedCards.map((card, index) => {
              return (
                <li className = {styles.dropped_cards_container_li} key = {`droppedCardsModal${card.id}`}>
                    <div className = {styles.dropped_card_container}>
                      <Card
                        card = {{
                          ...card,
                          isBoardModuleCard: true,
                          isAlias: card.codeAlias ? true : false,
                          modalCard: true
                        }}
                        actionCard = {{}}
                      />
                      <style jsx>{`
                        .${styles.dropped_card_container}{
                          ${
                            `
                              margin-top: ${droppedCardsPositions[index].positionX}px;
                              margin-left: ${droppedCardsPositions[index].positionY}px;
                              transform: ${`rotate(${droppedCardsPositions[index].rotation}deg)`}  
                            `
                          }
                        }
                      `}</style>
                    </div>
                </li>
              )
            })
          }
      </ul>
    </div>
  )
}

export default DroppedCards