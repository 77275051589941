import * as styles from '../../styles/scss/components/CustomLoader/CustomLoader.module.scss';

const Loader = ({minimized, loaderSize, background}) => {
  return (
    <div className = {`${styles.loader_outer} ${minimized && styles.minimized} ${styles[loaderSize]} ${background ? styles[background] : ''}`}>
      <div className = {styles.loader_inner}>
        <div className = {`${styles.loader_circle} ${styles.red}`}></div>
        <div className = {`${styles.loader_circle} ${styles.green}`}></div>
        <div className = {`${styles.loader_circle} ${styles.yellow}`}></div>
        <div className = {`${styles.loader_circle} ${styles.blue}`}></div>
      </div>
    </div>
  )
}

export default Loader