import * as styles from '../../../styles/scss/components/EditProfile/EditProfile.module.scss'
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../../../store/auth/authActions';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import { setUserForProfileSidebar } from '../../../store/modals/modalsActions';
import { getCitiesList, getStatesList } from '../../../store/static/staticInfoActions';
import FormInput, {Select, Dropdown} from '../../FormInput/FormInput';
import { useUpdateUser } from '../../../hooks/useUpdateUser';
import useTranslation from 'next-translate/useTranslation';

const EditProfile = (props) => {
  const {t} = useTranslation();
  const {
    userProfile, 
    onUserUpdate,
    profileUpdated, 
    countriesList,
    onSetUserForProfileSidebar,
    citiesList,
    citiesLoading,
    onGetCitiesList,
    statesList,
    statesLoading,
    onGetStatesList,
    countriesLoading,
    onToggleSidebar,
  } = props
  const [editToggle, setEditToggle] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [chosenCountry, setChosenCountry] = useState(null);
  const [filteredCountries, setFilteredCountries] = useState(null);
  const [chosenCity, setChosenCity] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);
  const [citySearchText, setCitySearchText] = useState('');
  const [chosenState, setChosenState] = useState(null);
  const [filteredStates, setFilteredStates] = useState([]);
  const [statesSearchText, setStatesSearchText] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [birthYears, setBirthYears] = useState(null)
  const [loading, setLoading] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [userState, setUserState] = useState({
    username: '',
    // avatar: null,
    dateOfBirth: null,
    // phoneNumber: null,
    // image: {
    //   path: null,
    //   file: null
    // },
    country: '',
    countryId: '',
    city: '',
    cityId: '',
    email: '',
    name: '',
    age: ''
  })
  const {
    emailState, 
    handleInputChange,
    setEmailAsInvalid,
  } = useUpdateUser();

  const cityInputRef = useRef()
  const [saveButtonText, setSaveButtonText] = useState(t('common:buttons.save_button'))

  useEffect(() => {
    for (let item in userState) {
      setUserState(prevState => ({ ...prevState, [item]: userProfile[item] ? userProfile[item] : '' }))
    }
    if (userProfile.country) {
      setUserState(prevState => ({...prevState, countryId: userProfile.country.id}))
      setChosenCountry(userProfile.country)
    }
    if (userProfile.city) {
      setUserState(prevState => ({...prevState, cityId: userProfile.city.id}))
      setChosenCity(userProfile.city)
    }
    if (userProfile.state) {
      setUserState(prevState => ({...prevState, stateId: userProfile.state.id}))
      setChosenState(userProfile.state)
    }
    
    const currentYear = (new Date()).getFullYear();
    const years = [];
    for (let i = currentYear; i >= 1901; i--) {
      years.push({
        value: i,
        id: `yeard${i}`        
      })
    }
    setBirthYears(years)
  }, [])

  useEffect(() => {
    if (cityInputRef.current) {
      const timer = setTimeout(() => {
        if (citySearchText === cityInputRef.current.value) {
          if (cityInputRef.current.value.length >= 2) {
            const payload = {
              countryCode: chosenCountry.code,
              stateCode: chosenState.id,
              name: cityInputRef.current.value
            }
            onGetCitiesList(payload)
          } else {
            setFilteredCities([...citiesList])
          }
        }
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [citySearchText, cityInputRef.current]);

  useEffect(() => {
    if (!!countriesList) {
      setFilteredCountries([...countriesList])
    }
  }, [countriesList])

  useEffect(() => {
    if (searchText === '') {
      setFilteredCountries(countriesList)
    } else {
      setFilteredCountries(countriesList.filter(country => country.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0))
    }
  }, [searchText])

  useEffect(() => {
    if (!!citiesList) {
      setFilteredCities([...citiesList])
    }
  }, [citiesList])

  // useEffect(() => {
  //   if (citySearchText === '') {
  //     setFilteredCities(citiesList)
  //   } else {
  //     setFilteredCities(citiesList.filter(city => city.name.toLowerCase().indexOf(citySearchText.toLowerCase()) >= 0))
  //   }
  // }, [citySearchText])

  useEffect(() => {
    if (!!statesList) {
      setFilteredStates([...statesList])
    }
  }, [statesList])

  useEffect(() => {
    if (statesSearchText === '') {
      setFilteredStates(statesList)
    } else {
      setFilteredStates(statesList.filter(state => state.name.toLowerCase().indexOf(statesSearchText.toLowerCase()) >= 0))
    }
  }, [statesSearchText])

  useEffect(() => {
    if (profileUpdated) {
      onSetUserForProfileSidebar({
        ...userProfile,
        isLoggedInUser: true
      })
      // onToggleSidebar({
      //   isOpen: true,
      //   sidebarType: 'profile',
      //   hasTitle: true
      // })
    }
  }, [profileUpdated])

  const handleChange = (e, disabled) => {
    if (disabled) {
      return
    }
    e.preventDefault();
    if(e.target.name === 'email') {
      handleInputChange(e);
    } else {
      setUserState({ ...userState, [e.target.name]: e.target.value });
    }
    setEnabled(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    onToggleSidebar({
      isOpen: true,
      sidebarType: 'profile',
      hasTitle: true,
      hasBackArrow: false,
      showSidebarCloseButton: true,
    })
    // debugger;
    if (!enabled || !emailState.valid) {
      return
    }
    setLoading(true)
    const date = userState.dateOfBirth ? `${(new Date(userState.dateOfBirth)).getFullYear()}-01-01` : null
    const editedProfile = {
      name: userState.name,
      dateOfBirth: date,
      phoneNumber: userState.phoneNumber,
      avatarId: userProfile.avatar.id,
      countryCode: chosenCountry?.code,
      locationId: chosenCity?.id,
      stateCode: chosenState?.id,
      age: !!date,
      email: emailState.value
    }
    setSaveButtonText(t('common:buttons.saving'))
    await onUserUpdate(editedProfile, userProfile.id)
    .then(response => {
      if (response.message === 'EMAIL_IS_TAKEN') {
        setEmailAsInvalid();
      }
      setSaveButtonText(t('common:buttons.save_button'))
      setLoading(false)
      setEnabled(false)
    }).catch(e => {
      setSaveButtonText(t('common:buttons.error'))
      setLoading(false)
      setEnabled(true)
    });
  }

  const handleChooseCountry = (country) => {
    setChosenCountry(country)
    setChosenState(null)
    setFilteredStates([])
    setSearchText('')
    setStatesSearchText('')
    setChosenCity(null)
    setCitySearchText('')
    setFilteredCities([])
    setEnabled(true)
  }

  const handleChooseState = (state) => {
    setChosenState(state)
    setChosenCity(null)
    setFilteredCities([])
    setStatesSearchText('')
    setCitySearchText('')
    setEnabled(true)
  }

  const setBirthYear = (item) => {
    const date = new Date()
    date.setFullYear(item.value)
    date.setMonth(0)
    date.setDate(1)
    setUserState(prevState => {return {
      ...prevState,
      dateOfBirth: date
    }})
    setEnabled(true)
  }

  useEffect(() => {
    if (chosenCountry) {
      const payload = {
        countryCode: chosenCountry.code,
      }
      onGetStatesList(payload)
    }
  }, [chosenCountry])

  useEffect(() => {
    if (chosenState && chosenCountry) {
      const payload = {
        countryCode: chosenCountry.code,
        stateCode: chosenState.id
      }
      onGetCitiesList(payload)
    }
  }, [chosenState, chosenCountry])

  const setChosenCityHandler = (city) => {
    setChosenCity(city)
    setCitySearchText('')
    setEnabled(true)
  }

  const refSelectInputCountry = useRef(null);
  const refSelectInputBirthYear = useRef(null);
  const refSelectInputState = useRef(null);
  const refSelectInputCity= useRef(null);


  return (
    <section className = {styles.edit_ptofile}>
      <div className={styles.user_info_wrapper}>
        <div className = {styles.form_container}>
          <form onSubmit={(e) => { handleSubmit(e) }}>
            <div className = {styles.single_input}>
              <FormInput
                type = 'text'
                value = {userState.username}
                name = 'username'
                change = {(e) => {handleChange(e, true)}}
                active = {userState.username.length > 0}
                labelText = {t('common:inputs.username')}
                showIndicator = {true}
                id = 'editProfileUsername'
                valid = {true}
                disabled = {true}
                // errorText = {errors && errors.username}
                // showIcon = {true}
              />
            </div>
            {/* <div className = {styles.single_input}>
              <FormInput
                type = 'text'
                value = {userState.name}
                name = 'name'
                change = {handleChange}
                active = {userState.name.length > 0}
                labelText = 'Display Name'
                showIndicator = {true}
                id = 'editProfileName'
                valid = {true}
                disabled = {false}
                // errorText = {errors && errors.name}
                // showIcon = {true}
              />
            </div> */}
            <div className = {styles.single_input}>
              <FormInput
                type = 'email'
                value = {emailState.value}
                name = 'email'
                change = {handleChange}
                active = {emailState.value?.length > 0}
                labelText = {t('common:inputs.email')}
                showIndicator = {false}
                id = 'editProfileEmail'
                valid = {emailState.valid}
                disabled = {false}
                changed={emailState.changed}
                errorText = {emailState.errorText}
                // showIcon = {true}
              />
            </div>
            <div className = {`${styles.single_input} ${styles.date_of_birth}`} ref={refSelectInputBirthYear}
              onClick={() => {
                setTimeout(() => {
                  refSelectInputBirthYear.current.scrollIntoView({behavior: 'smooth'})
                }, [350])
               }}
            >
              {/* <div onClick = {() => setShowCalendar(prevState => !prevState)}>
                <DateInput title = 'Date of Birth' delimiter = '/' date = {new Date(userState?.dateOfBirth)}/>
              </div> */}
              {/* <input type="date" name = 'dateOfBirth' value = {userState?.dateOfBirth}/> */}
              {/* {showCalendar && 
                <div className = {styles.calendar_container}>
                  <BirthDateCalendar setBirthDate = {handeChangeBirthDate}  date = {new Date(userState?.dateOfBirth)}/>
                </div>
              } */}
              {birthYears && 
                <Dropdown 
                  list = {birthYears}
                  value = {userState.dateOfBirth ? (new Date(userState.dateOfBirth)).getFullYear() : null}
                  disabled = {false}
                  change = {setBirthYear}
                  label = {t('common:inputs.birth_year')}
                  grid = {true}
                  absolute = {false}
                />
              }
            </div>
            <div className = {styles.select_container} ref={refSelectInputCountry}
            onClick={() => {
              setTimeout(() => {
                refSelectInputCountry.current.scrollIntoView({behavior: 'smooth'})
              }, [350])
            }}
            >
              <Select
                value = {chosenCountry ? chosenCountry.name : ''}
                change = {handleChooseCountry}
                active = {false}
                labelText = {t('common:inputs.country')} // text which is shown as placeholder
                disabled = {!filteredCountries || filteredCountries?.length === 0} // true or false if error is disabled
                showIcon = {false} // show eye icon in input
                list = {filteredCountries}
                searchText = {searchText}
                searchInputChange = {setSearchText}
                hasSearch = {true}
                loading = {countriesLoading}
                absolute = {false}
              />
            </div>
            {!!chosenCountry && (
              <div className = {styles.select_container} ref = {refSelectInputState}
              onClick = {() => {
                setTimeout(() => {
                  refSelectInputState.current.scrollIntoView({behavior: 'smooth'})
                }, [350])
              }}
              >
                <Select
                  value = {chosenState ? chosenState.name : ''}
                  change = {handleChooseState}
                  active = {false}
                  labelText = {t('common:inputs.state')} // text which is shown as placeholder
                  disabled = {!filteredStates || filteredStates?.length === 0} // true or false if error is disabled
                  showIcon = {false} // show eye icon in input
                  list = {filteredStates}
                  searchText = {statesSearchText}
                  searchInputChange = {setStatesSearchText}
                  hasSearch = {true}
                  loading = {statesLoading}
                  absolute = {false}
                />
              </div>
            )}
            {!!chosenState && (
              <div className = {styles.select_container} ref={refSelectInputCity}
              onClick = {() => {
                setTimeout(() => {
                  refSelectInputCity.current.scrollIntoView({behavior: 'smooth'})
                }, [350])
              }}
              >
                <Select
                  value = {chosenCity ? chosenCity.name : ''}
                  change = {(value) => {setChosenCityHandler(value)}}
                  active = {false}
                  labelText = {t('common:inputs.city')} // text which is shown as placeholder
                  disabled = {!filteredCities || filteredCities?.length === 0} // true or false if error is disabled
                  showIcon = {false} // show eye icon in input
                  list = {filteredCities}
                  searchText = {citySearchText}
                  searchInputChange = {setCitySearchText}
                  hasSearch = {true}
                  loading = {citiesLoading}
                  absolute = {false}
                  reference = {cityInputRef}
                />
              </div>
            )}
            {/* <UserInfoInput
              type='tel'
              name='phoneNumber'
              label={`${t('Phone Number')}`}
              value={userState.phoneNumber}
              onChange={handleChange}
              // readOnly={editToggle}
            /> */}
            {/* <button type='button' onClick={() => { setEditToggle(false) }}>Edit</button> */}
          <div className = {styles.save_button_container}>
          <button 
            className = {styles.save_button} 
            onClick={handleSubmit}
            disabled= {loading || !enabled || !emailState.valid}
            
            >
              {saveButtonText}
          </button>
        </div>
          </form>

        </div>

      </div>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    userProfile: state.auth.user,
    countriesLoading: state.static.countriesLoading,
    countriesList: state.static.countriesList,
    profileUpdated: state.auth.profileUpdated,
    citiesList: state.static.citiesList,
    citiesLoading: state.static.citiesLoading,
    statesList: state.static.statesList,
    statesLoading: state.static.statesLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUserUpdate: (user, id) => dispatch(updateUser(user, id)),
    onToggleSidebar: (sidebarData) => dispatch(toggleSidebar(sidebarData)),
    onSetUserForProfileSidebar: (user) => dispatch(setUserForProfileSidebar(user)),
    onGetCitiesList: (payload) => dispatch(getCitiesList(payload)),
    onGetStatesList: (payload) => dispatch(getStatesList(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
