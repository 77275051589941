import FormInput from "../../FormInput/FormInput";
import * as styles from '../../../styles/scss/components/ResetPassword/ResetPassword.module.scss';
import { useRouter } from "next/router";
import getQueryParams from "../../../helpers/GetQueryParams";
import { toggleSidebar } from "../../../store/modals/modalsActions";
import { useEffect, useState } from "react";
import {connect} from 'react-redux';
import {SideBarSuccess} from '../../SidebarSuccess/SidebarSuccess'
import api from '../../../axios/api';
import useTranslation from "next-translate/useTranslation";
const ResetPassword = ({onToggleSidebar}) => {
  const router = useRouter()
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [passworInputType, setPassowrdInputType] = useState(true)
  const [resetPassworInputType, setResetPassowrdInputType] = useState(true)
  const [success, setSuccess] = useState(false);
  const {t} = useTranslation()
  const [successState, setSuccessState] = useState({
    title: t('common:sidebar_body.reset_password.password_reset_successfull'),
    loading: false,
    valid: true,
    handleSubmit: (e) => handleDone(e),
    loadingComponent: null,
    errorText: '',
    success: true,
    size: 'medium',
  });

  const [password, setPassword] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^().{6,}$/,
    changed: false
  })
  const [repeatedPassword, setRepeatedPassword] = useState({
    value: '',
    valid: false,
    errorText: '',
    changed: false
  })

  useEffect(() => {
    if (password.changed) {
      const valid = password.regEx.test(password.value);

      if (valid) {
        setPassword(prevState => {
          return {
            ...prevState,
            valid: true,
            errorText: '',
            changed: true
          }
        })
      } else {
        setPassword(prevState => {
          return {
            ...prevState,
            valid: false,
            errorText: t('errors:password.length'),
            changed: true
          }
        })
      }
    }
  }, [password.value, password.changed]);

  useEffect(() => {
    if (repeatedPassword.changed) {
      if (repeatedPassword.value !== password.value || repeatedPassword.value.length === 0) {
        setRepeatedPassword(prevState => {
          return {
            ...prevState,
            valid: false,
            errorText: t('errors:password.password_equality'),
            changed: true
          }
        })
      } else {
        setRepeatedPassword(prevState => {
          return {
            ...prevState,
            valid: true,
            errorText: '',
            changed: true
          }
        })
      }
    }
  }, [repeatedPassword.value, repeatedPassword.changed]);

  const handleChange = (e) => {
    // debugger;
    e.preventDefault();
    switch (e.target.name) {
      case 'newPassword': {
        setPassword(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }; break;
      case 'repeatPassword': {
        setRepeatedPassword(prevState => {
          return {
            ...prevState,
            value: e.target.value,
            changed: true
          }
        })
      }; break;
    }
  }

  const handlePasswordReset = (e) => {
    // debugger
    e.preventDefault()
    const valid = password.valid && repeatedPassword.valid;
    if (valid) {
      api.post('/reset-password', {
        email : email, 
        password : password.value, 
        token : token, 
        password_confirmation : repeatedPassword.value
      }).then(response => {
        handleRequestSuccess();
      }).catch(e => {
        handleRequestFail();
      })
    }
  }

  const handleRequestSuccess = () => {
    setSuccessState(prevState => ({
      ...prevState,
      title: t('common:sidebar_body.reset_password.password_reset_successfull'),
      errorText: t('common:sidebar_body.reset_password.password_reset_successfull'),
      success: true,
    }))
    setSuccess(true);
  }

  const handleRequestFail = () => {
    setSuccessState(prevState => ({
      ...prevState,
      title: t('common:sidebar_body.reset_password.link_not_valid'),
      errorText: t('common:sidebar_body.reset_password.link_not_valid'),
      success: false,
    }))
    setSuccess(true);
  }

  const handleDone = () => {
    if (successState.success == true) {
      setSuccess(false);
      router.replace('/')
      onToggleSidebar({
        isOpen: true,
        sidebarType: 'logIn',
        hasBackArrow: false,
        showSidebarCloseButton: true,
        hasTitle: true
      })
    } else {
      setSuccess(false);
      onToggleSidebar({
        isOpen: true,
        sidebarType: 'forgotPassword',
        hasTitle: true,
        showSidebarCloseButton: true
      })
    }
   
  }

  useEffect(() => {
    const {email, token} = getQueryParams(router.asPath)
    setEmail(email)
    setToken(token)
  }, [])
  const PasswordResetComponent = (
    <form className={styles.form}>
      <div className = {styles.form_group}>
        <FormInput 
          type = {passworInputType ? 'password' : 'text'}
          value = {password.value}
          name = 'newPassword'
          change = {handleChange}
          active = {password.value.length > 0}
          labelText = {t('common:inputs.new_password')}
          showIndicator = {true}
          id = 'resetPassword'
          showIcon = {true}
          iconFunction = {() => {setPassowrdInputType(prevState => !prevState)}}
          valid = {password.valid}
          errorText = {password.errorText}
          changed = {password.changed}
        />
      </div>
      <div className = {styles.form_group}>
        <FormInput 
          type = {resetPassworInputType ? 'password' : 'text'}
          value = {repeatedPassword.value}
          name = 'repeatPassword'
          change = {handleChange}
          active = {repeatedPassword.value.length > 0}
          labelText = {t('common:inputs.repeat_password')}
          showIndicator = {true}
          id = 'repeatResetPassword'
          showIcon = {true}
          iconFunction = {() => {setResetPassowrdInputType(prevState => !prevState)}}
          valid = {repeatedPassword.valid}
          errorText = {repeatedPassword.errorText}
          changed = {repeatedPassword.changed}
        />
      </div>
      <div className = {styles.reset_button_container}>
        <button type = 'button' onClick = {handlePasswordReset}>{t('common:buttons.reset_password')}</button>
      </div>
    </form>
  )
  const SuccessComponent = <SideBarSuccess {...successState} handleDone = {handleDone} />
  return success ? SuccessComponent : PasswordResetComponent;
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleSidebar: (sidebarState) => dispatch(toggleSidebar(sidebarState)),
  }
}

export default connect(null, mapDispatchToProps)(ResetPassword)
