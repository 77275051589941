export const HANDLE_RULE_CHANGE = 'HANDLE_RULE_CHANGE';
export const SET_GAME_MODE = 'SET_GAME_MODE';
export const SET_GAME_TYPE = 'SET_GAME_TYPE';
export const SET_PLAYER_NUMBER = 'SET_PLAYER_NUMBER';
export const SET_AVILABLE_PLAYERS_NUMBERS = 'SET_AVILABLE_PLAYERS_NUMBERS';
export const SET_VERSUS = 'SET_VERSUS'

export const GET_GAME_SETUP_DATA_REQUEST = 'GET_GAME_SETUP_DATA_REQUEST';
export const GET_GAME_SETUP_DATA_SUCCESS = 'GET_GAME_SETUP_DATA_SUCCESS';
export const GET_GAME_SETUP_DATA_FAIL = 'GET_GAME_SETUP_DATA_FAIL';
export const RESET_SETUP = 'RESET_SETUP';
export const RESET_RULES = 'RESET_RULES';
export const TOGGLE_GAME_PENDING = 'TOGGLE_GAME_PENDING';
export const UPDATE_LIVE_SREARCHING_PLAYERS_NUMBER = 'UPDATE_LIVE_SREARCHING_PLAYERS_NUMBER';