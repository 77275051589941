
import useTranslation from 'next-translate/useTranslation'
import * as styles from '../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss'
const GameFinish = () => {
  const {t} = useTranslation();

  return (
    <div className={`${styles.single_rule_container}`}>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:aproaching_finish.first_paragraph')}
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.bold_text} ${styles.text}`}>
      {t('game_rules:aproaching_finish.first_heading')}
      </p>
      <br></br>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:aproaching_finish.first_move_text')}
      </p>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:aproaching_finish.second_move_text')}
      </p>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:aproaching_finish.third_move_text')}
      </p>
      <br></br>
      <p className={`${styles.standard_paragraph} ${styles.text}`}>
      {t('game_rules:aproaching_finish.second_paragraph')}
      </p>
      <br></br>
      <br></br>
      <div className={`${styles.image_container} ${styles.game_finish} ${styles.landscape}`}>
        <img src='/images/rules/approaching-the-finish-sidebar.svg' />
      </div>
    </div>
  )
}

export default GameFinish