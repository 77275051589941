import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { GetRulesLangKeys } from "../../../../helpers/GetRulesLangKeys";
import * as styles from '../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss'
import useTranslation from "next-translate/useTranslation";

const GameRules = ({currentLang}) => {
  const rules = useSelector(state =>  state.gameSetup.avilableRules)
  const {t} = useTranslation();  
  const langKeys = {
    sc:  {
      label: 'game_rules:rules_list.thief_card_label',
      desc: 'game_rules:rules_list.thief_card_desc',
    },
    cs:  {
      label: 'game_rules:rules_list.canadian_seven_label',
      desc: 'game_rules:rules_list.canadian_seven_desc',
    },
    jf:  {
      label: 'game_rules:rules_list.jocker_finish_label',
      desc: 'game_rules:rules_list.jocker_finish_desc',
    },
    js:  {
      label: 'game_rules:rules_list.jump_start_label',
      desc: 'game_rules:rules_list.jump_start_desc',
    },
  }
  
  return (
    <div className={styles.rules_explanation_container}>
      <ul className={styles.rules_explanation_ul}>
        <li 
          className={styles.rules_explanation_li}
          >
          <div
            className = {`${styles.button_wrapper}`}
            >
            <div className = {styles.image_container}>
              <img src={`/images/rules/sbd.svg`} />
            </div>
            <div className = {styles.text_container}>
              <h5 className = {styles.rule_title}>{t('game_rules:rules_list.basic_braendi_label')}</h5>
              <h6 className = {styles.rule_description}>{t('game_rules:rules_list.basic_braendi_desc')}</h6>
            </div>
          </div>
        </li>
        <li 
          className={styles.rules_explanation_li}
          >
          <div
            className = {`${styles.button_wrapper}`}
            >
            <div className = {styles.image_container}>
              <img src={`/images/rules/sc.svg`} />
            </div>
            <div className = {styles.text_container}>
              <h5 className = {styles.rule_title}>{t('game_rules:rules_list.thief_card_label')}</h5>
              <h6 className = {styles.rule_description}>{t('game_rules:rules_list.thief_card_desc')}</h6>
            </div>
          </div>
        </li>
        <li 
          className={styles.rules_explanation_li}
          >
          <div
            className = {`${styles.button_wrapper}`}
            >
            <div className = {styles.image_container}>
              <img src={`/images/rules/cs.svg`} />
            </div>
            <div className = {styles.text_container}>
              <h5 className = {styles.rule_title}>{t('game_rules:rules_list.canadian_seven_label')}</h5>
              <h6 className = {styles.rule_description}>{t('game_rules:rules_list.canadian_seven_desc')}</h6>
            </div>
          </div>
        </li>
        <li 
          className={styles.rules_explanation_li}
          >
          <div
            className = {`${styles.button_wrapper}`}
            >
            <div className = {styles.image_container}>
              <img src={`/images/rules/jf.svg`} />
            </div>
            <div className = {styles.text_container}>
              <h5 className = {styles.rule_title}>{t('game_rules:rules_list.jocker_finish_label')}</h5>
              <h6 className = {styles.rule_description}>{t('game_rules:rules_list.jocker_finish_desc')}</h6>
            </div>
          </div>
        </li>
        <li 
          className={`${styles.landscape} ${styles.rules_explanation_li}`}
          >
          <div
            className = {`${styles.button_wrapper}`}
            >
            <div className = {styles.image_container}>
              <img src={`/images/rules/js.svg`} />
            </div>
            <div className = {styles.text_container}>
              <h5 className = {styles.rule_title}>{t('game_rules:rules_list.jump_start_label')}</h5>
              <h6 className = {styles.rule_description}>{t('game_rules:rules_list.jump_start_desc')}</h6>
            </div>
          </div>
        </li>
        {/* {rules.map(rule => {
          if (rule.symbol === 'ccs') return
            return (
              <li 
                className={styles.rules_explanation_li}
                key={`explanation${rule.id}`}
                >
                <div
                  className = {`${styles.button_wrapper}`}
                  >
                  <div className = {styles.image_container}>
                    <img src={`/images/rules/${rule.symbol}.svg`} />
                  </div>
                  <div className = {styles.text_container}>
                    <h5 className = {styles.rule_title}>{t(langKeys[rule.symbol]['label'])}</h5>
                    <h6 className = {styles.rule_description}>{t(langKeys[rule.symbol]['desc'])}</h6>
                  </div>
                </div>
              </li>
            )
          })} */}
      </ul>
    </div>
  )
}

export default GameRules