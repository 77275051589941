import {useState, useEffect} from 'react'

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(null)
    
    const updateOrientation = event => {
        // setOrientation((screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation)
        if(window.innerWidth > window.innerHeight){
            setOrientation("landscape-primary")
        }else{
            setOrientation("portrait-primary")
        }
    }

    useEffect(() => {
        updateOrientation();
        window.addEventListener(
            'resize',
            updateOrientation
        )
        return () => {
            window.removeEventListener(
            'resize',
            updateOrientation
            )
        }
    }, [])

    return orientation
}

export default useScreenOrientation