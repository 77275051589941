import NotificationsModal from "../Notifications/NotificationsModal/NotificationsModal"
import HexSvg from '../../public/images/common/hex.svg';
import RedHexSvg from '../../public/images/common/redhex.svg';
import Avatar from "../Avatar/Avatar";
import { useComponentVisible } from "../../helpers/hooks";
import { handleReturnAsAdmin } from "../../helpers/Impersonate";
import * as styles from '../../styles/scss/components/Header/Header.module.scss';
import { useEffect, useRef } from "react";
import {api} from "../../axios/api";
import {ButtonRuby} from "../Buttons/ButtonRuby";
const AuthorizedHeader = (props) => {
  const {
    openSidebar, 
    unreadNotificationCount, 
    user, 
    onSetUserForProfileSidebar, 
    onToggleResponsivePopUp,
    router,
    onboardingInProgress
  } = props
  
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  // const navigationRef = useRef()
  // const profileRef = useRef()
  // const hamburgerRef = useRef()

  // useEffect(() => {
  //   if (!!navigationRef.current && !!profileRef.current && !!hamburgerRef.current) {
  //     if (window.innerWidth < 768) {
  //       console.log('responsive view')
  //       navigationRef.current.classList.remove('onboardingElement')
  //       navigationRef.current.removeAttribute('data-stepnumber')
  //       profileRef.current.classList.remove('onboardingElement')
  //       profileRef.current.removeAttribute('data-stepnumber')        
  //       hamburgerRef.current.classList.add('onboardingElement')
  //       hamburgerRef.current.setAttribute('data-stepnumber', "7")        
  //     }
  //   }
  // }, [navigationRef, profileRef, hamburgerRef])

  const handleProfileSidebarToggle = () => {
    if (onboardingInProgress) {
      return
    }
    onSetUserForProfileSidebar({
      ...user,
      isLoggedInUser: true
    })
    openSidebar({
      isOpen: true,
      sidebarType: 'profile',
      hasTitle: true,
      showSidebarCloseButton: true
    })
  }

  const handleNotificationModalOpen = () => {
    if (onboardingInProgress) {
      return
    }

    if (document.body.offsetWidth < 1024) {
      openSidebar({
        sidebarType: 'notification',
        isOpen: true,
        hasTitle: true,
        showSidebarCloseButton: true
      })
    } else {
      setIsComponentVisible(prevState => !prevState)
    }
  }

  const handleInspectorClick = async () => {
    if (onboardingInProgress) {
      return
    }

    if (user.hasAnyRole(['admin', 'superadmin']) && !localStorage.getItem('impersonate_token')) {
      router.push('/inspector')
    } else {
      const response = await handleReturnAsAdmin()
      if (response) {
        window.location.href = '/'
      }
    }
  }

  return (
    <div
      className = {`${styles.authorized_header} onboardingElement`} 
      data-stepnumber="5"
      // ref={navigationRef}
      >
      <div 
        className = {`${styles.header_menu_buttons}`}        
        
        >
        {(user.hasAnyRole(['admin', 'superadmin'])) && (
          <ButtonRuby />
        )}

        {(user.hasAnyRole(['admin', 'superadmin']) || (typeof window !== 'undefined' && localStorage.getItem('impersonate_token'))) && (
          <div
            className = {`${styles.header_menu_button} ${styles.add_friend}`}
            onClick = {() => {handleInspectorClick()}}
            >
            {user.hasAnyRole(['admin', 'superadmin']) && (
              <>
                <div className = {styles.hex}>
                  <HexSvg />
                </div>
                <img src='/images/common/incognito.svg' />
              </>
            )}
            {localStorage.getItem('impersonate_token') && (
              <>
                <div className = {`${styles.hex} ${styles.red}`}>
                  <RedHexSvg />
                </div>
                <img src='/images/common/home.svg' />
              </>
            )}
          </div>
        )}
        <div
          className = {`${styles.header_menu_button} ${styles.add_friend}`}
          onClick = {() => {
            if (onboardingInProgress) {
              return
            }        
            router.push('/how-to-play');
          }}
            >
          <div className = {styles.hex}>
            <HexSvg />
          </div>
          <img src='/images/common/tutorialIcon.svg' />
        </div>
        <div
          className = {`${styles.header_menu_button} ${styles.add_friend}`}
          onClick = {() => {
            if (onboardingInProgress) {
              return
            }        
            openSidebar({
              isOpen: true,
              sidebarType: 'inviteFriend',
              hasTitle: true,
              showSidebarCloseButton: true
            })
          }}
            >
          <div className = {styles.hex}>
            <HexSvg />
          </div>
          <img src='/images/common/inviteFriend.svg' />
        </div>
        <div 
          className = {`${styles.header_menu_button} ${styles.notifications}`} 
          ref = {ref}
          >
          <div onClick = {handleNotificationModalOpen}>
            <div className = {styles.hex}>
              <HexSvg />
            </div>
            <img src='/images/common/notification.svg'/>
            {unreadNotificationCount > 0 && <span className = {styles.unread_notif}>{unreadNotificationCount}</span>}
          </div>
          <div className = {styles.notification_modal_container}>
            {isComponentVisible && 
              <NotificationsModal 
                callBackFunction = {() => {setIsComponentVisible(prevState => !prevState)}}
              />
            }
          </div>
        </div>
        {/* <div
          className = {`${styles.header_menu_button} ${styles.settings}`}
          onClick = {() => {
            openSidebar({
              isOpen: true,
              sidebarType: 'accessibility',
              hasTitle: true
            })
          }}
          >
          <div className = {styles.hex}>
            <HexSvg />
          </div>
          <img src='/images/common/accessibility.svg' />
        </div> */}
        <div
          className = {`${styles.header_menu_button} ${styles.settings}`}
          onClick = {() => {
            if (onboardingInProgress) {
              return
            }        
            openSidebar({
              isOpen: true,
              sidebarType: 'settings',
              hasTitle: true,
              showSidebarCloseButton: true
            })
          }}
          >
          <div className = {styles.hex}>
            <HexSvg />
          </div>
          <img src='/images/common/settings.svg' />
        </div>
      </div>
      <div 
        className = {`${styles.user_information}`}
        onClick = {handleProfileSidebarToggle}
        >
        <div className = {styles.header_avatar}>
          <Avatar
            hasFlag = {false}
            size = 'medium'
            mode = 'normal'
            avatarUrls = {{...user.avatar}}
          />
        </div>
        <div className = {styles.user_name}>
          {user.username}
          {/* <ul className={styles.user_menu}>
            <li><Link href="/profile">{t('Profile Page')}</Link></li>
            <li
              onClick = {handleProfileSidebarToggle}
            >
              {t('Profile Page')}
            </li>
            <li><button type="button" onClick={onLogoutRequest}>log out</button></li>
          </ul> */}
        </div>
      </div>
      <div 
        className = {styles.responsive_header} 
        onClick = {() => {
          if (onboardingInProgress) {
            return
          }      
          onToggleResponsivePopUp({
            isOpen:true,
            type: 'menu'
          })
        }}
        // ref={hamburgerRef}
        >
        <div
          className = {`${styles.header_menu_button} ${styles.add_friend}`}
          >
          <div className = {styles.hex}>
            <HexSvg />
          </div>
          <img src='/images/common/hamburger.svg' />
          {unreadNotificationCount > 0 && <span className = {styles.unread_notif}>{unreadNotificationCount}</span>}
        </div>
      </div>
    </div>
  )
}

export default AuthorizedHeader