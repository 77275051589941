import * as actionTypes from './pusherActionTypes';

export const instantiatePusher = () => {
  return {
    type: actionTypes.INSTANTIATE_PUSHER
  }
}

export const setSocketId = (socketId) => {
  return {
    type: actionTypes.SET_SOCKET_ID,
    payload: {
      socketId: socketId
    }
  }
}

export const setBlockedBySocket  = (state) => {
  return {
    type: actionTypes.SET_BLOCKED_BY_SOCKET,
    payload: {
      state: state
    }
  }
}