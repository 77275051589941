export const GAME_COMMON_CARD_EVENT_REQUEST = 'GAME_COMMON_CARD_EVENT_REQUEST';
export const GAME_COMMON_CARD_EVENT_SUCCESS = 'GAME_COMMON_CARD_EVENT_SUCCESS';
export const GAME_COMMON_CARD_EVENT_FAIL = 'GAME_COMMON_CARD_EVENT_FAIL';

export const CARD_EXCHANGE = 'CARD_EXCHANGE';
export const SET_PLAYERS_READY_FOR_EXCHANGE = 'SET_PLAYERS_READY_FOR_EXCHANGE';
export const CARD_PLAY = 'CARD_PLAY';

export const SET_MARBLE_SWAP = 'SET_MARBLE_SWAP';
export const GAME_SEVEN_MARBLE_EVENT_SUCCESS = "GAME_SEVEN_MARBLE_EVENT_SUCCESS";


export const GAME_COMMON_MARBLE_EVENT_REQUEST = 'GAME_COMMON_MARBLE_EVENT_REQUEST';
export const GAME_COMMON_MARBLE_EVENT_SUCCESS = 'GAME_COMMON_MARBLE_EVENT_SUCCESS';
export const GAME_COMMON_MARBLE_EVENT_FAIL = 'GAME_COMMON_MARBLE_EVENT_FAIL';
export const MARBLE_MOVE = 'MARBLE_MOVE';

export const GET_GAME_DATA_REQUEST = 'GET_GAME_DATA_REQUEST';
export const GET_GAME_DATA_SUCCESS = 'GET_GAME_DATA_SUCCESS';
export const GET_GAME_DATA_FAIL = 'GET_GAME_DATA_FAIL';

// export const DROP_CARDS_REQUEST = 'DROP_CARDS_REQUEST';
// export const DROP_CARDS_SUCCESS = 'DROP_CARDS_SUCCESS';
// export const DROP_CARDS_FAIL = 'DROP_CARDS_FAIL';
export const DROP_CARDS = 'DROP_CARDS';
// export const SET_DROP_DETAILS = 'SET_DROP_DETAILS';

export const CHANGE_PLAYER_TURN = 'CHANGE_PLAYER_TURN';
export const START_NEW_ROUND = 'START_NEW_ROUND';
export const SET_IS_PLAYING_JOKER = 'SET_IS_PLAYING_JOKER';
export const SET_ACTION_CARD = 'SET_ACTION_CARD';
export const SET_CARD_ALIASES = 'SET_CARD_ALIASES';

export const SET_IS_PLAYING_TWO = 'SET_IS_PLAYING_TWO';
export const SET_GAME_STATE = 'SET_GAME_STATE';
export const SET_GAME_CARD_PLAY_ACTION = 'SET_GAME_CARD_PLAY_ACTION';
export const SET_PLAYERS_FOR_STEALING = 'SET_PLAYERS_FOR_STEALING';
export const CARD_STEAL = 'CARD_STEAL';
export const SET_CAN_PLAY_STATUS_TO_CARDS = 'SET_CAN_PLAY_STATUS_TO_CARDS';


export const SET_MARBLE_MOVE = 'SET_MARBLE_MOVE';
export const SET_MARBLE_KILL = 'SET_MARBLE_KILL';
export const SET_REPLAY_SEVEN = 'SET_REPLAY_SEVEN';
export const SET_MARBLE_POSITION = 'SET_MARBLE_POSITION';


export const ON_GAME_FINISH = 'ON_GAME_FINISH';

export const SET_REMATCH_PLAYER = 'SET_REMATCH_PLAYER';
export const SET_PLAYER_MESSAGE = 'SET_PLAYER_MESSAGE';

export const SET_RECENT_ACTIONS = 'SET_RECENT_ACTIONS';
export const SET_DROPPED_ACTIONS = 'SET_DROPPED_ACTIONS';

export const SET_BOARD_TOGGLE = 'SET_BOARD_TOGGLE';

export const SET_CHOSEN_VICTIM = 'SET_CHOSEN_VICTIM';

export const UPDATE_SCORE_BOARD = 'UPDATE_SCORE_BOARD';

export const RESET_GAME_DATA = 'RESET_GAME_DATA';
export const UPDATE_PLAYER_FRIENDSHIP_STATUS = 'UPDATE_PLAYER_FRIENDSHIP_STATUS';
export const UPDATE_PLAY_TIME = 'UPDATE_PLAY_TIME';
export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const SET_FORCE_FINISHED_DATA = 'SET_FORCE_FINISHED_DATA';
export const TOGGLE_PREVENT_CARD_CLICK_WHILE_PLAYING_JOKER = 'TOGGLE_PREVENT_CARD_CLICK_WHILE_PLAYING_JOKER';
