import { connect } from 'react-redux';
import {EditTeam} from '../PopUpModal/PopUpModal';

const EditTeamSidebar = ({sidebarData}) => {
  return (
    <div>
      <EditTeam 
        saveFunction = {sidebarData.saveEditFunction}
        oldTeamName = {sidebarData.oldTeamName}
        oldTeamSymbol = {sidebarData.teamSymbol}
        teamId = {sidebarData.teamId}
      />
    </div>
  )
}


const mapStateToProps = state => {
  return {
    sidebarData: state.modals.sidebarData
  }
}

// const mapDispatchToProps = dispatch => {
//   return {
//   }
// }

export default connect(mapStateToProps, null)(EditTeamSidebar)
