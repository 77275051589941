
import * as styles from '../../styles/scss/components/ConfirmModal/ConfirmModal.module.scss';
import {CloseButton} from '../Buttons/Buttons';
const ConfirmModal = (props) => {
  const {
    text, 
    acceptText, 
    rejectText,
    acceptFunc,
    rejectFunc,
    closeFunc,
    showOneButton,
    sameButtons,
    subText,
    actionDisabled = false
  } = props
  return (
    <div className = {styles.confirm_modal}>
      <div className = {styles.modal_header}>
        <CloseButton click = {closeFunc} />
      </div>
      <div className = {styles.modal_text_container}>
        <p className = {styles.modal_text}>
          {text}
        </p>
        {subText && (<span className={styles.sub_text}>{subText}</span>)}        
      </div>
      <div className = {styles.modal_buttons_container}>
        {!showOneButton && (
          <div className = {`${styles.modal_button} ${styles.reject_button} ${sameButtons ? styles.same_button : ''}`}>
            <button onClick = {() => {!actionDisabled && rejectFunc()}}>
              {rejectText}
            </button>
          </div>
        )}
        <div className = {`${styles.modal_button} ${styles.accept_button}`}>
          <button onClick = {acceptFunc}>
            {acceptText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal