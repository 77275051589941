import * as actionTypes from './gameSetupActionTypes';

const initialState = {
  avilableRules: [],
  gameModes: [],
  gameStatuses: [],
  gameTypes: [],
  versues: [],

  selectedRules: [],
  selectedGameType: null,
  selectedGameMode: null,
  selectedPlayerNumber: '',
  selectedVersus: null,

  playersNumbers: [2,3,4,5,6],
  isLoading: false,
  dataLoaded: false,
  gamePending: false,
  /*
    array for storing number of players, who are currently searching game
    index 0 -> solo 2 players
    index 1 -> solo 3 players
    index 2 -> solo 4 players
    index 3 -> team 4 players
    index 4 -> solo any
    */
  playersSearching: [0,0,0,0,0]
}

const gameSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HANDLE_RULE_CHANGE:
      let newSelectedRules = [...state.selectedRules]

      if (state.selectedRules.indexOf(action.payload.id) < 0) {
        newSelectedRules = [...newSelectedRules, action.payload.id]
      } else {
        newSelectedRules = [...newSelectedRules.filter(selectedRule => selectedRule !== action.payload.id)]
      }
      
      return {
        ...state,
        selectedRules: [...newSelectedRules]
      };

    case actionTypes.SET_GAME_MODE:
      let playersNumbers = []
      if (action.payload.gameMode.value === 'solo') {
        playersNumbers = [2,3,4,5,6]
      } else {
        playersNumbers = [4,6]
      }
      return {
        ...state,
        selectedGameMode: action.payload.gameMode,
        playersNumbers: playersNumbers
      }

    case actionTypes.SET_GAME_TYPE:
      return {
        ...state,
        selectedGameType: action.payload.gameType
      }
    case actionTypes.SET_PLAYER_NUMBER:
      const playerNumber = action.payload.playerNumber
      const gameMode = state.selectedGameMode
      let versus = ''
      if (gameMode.value === 'solo') {
        versus = {
          value: "1v1", 
          label: "1vs1"
        }
      } else if (playerNumber === 4 && gameMode.value === 'team') {
        versus = {
          value: "2v2",
          label: "2vs2"
        }
      }
      return {
        ...state,
        selectedPlayerNumber: action.payload.playerNumber,
        selectedVersus: {...versus}
      };
    // case actionTypes.SET_AVILABLE_PLAYERS_NUMBERS:
    //   return {
    //     ...state,
    //     playersNumbers: action.payload.playersNumbers
    //   }
    case actionTypes.GET_GAME_SETUP_DATA_REQUEST: 
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_GAME_SETUP_DATA_SUCCESS:
      const searchData = {...action.payload.setupData.activeSearches}
      return {
        ...state,
        isLoading: false,
        dataLoaded: true,
        avilableRules: [...action.payload.setupData.rules],
        gameModes: [...action.payload.setupData.gameModes],
        gameStatuses: [...action.payload.setupData.gameStatuses],
        gameTypes: [...action.payload.setupData.gameTypes],
        versues: [...action.payload.setupData.versus],
        playersSearching: [searchData.solo_2, searchData.solo_3, searchData.solo_4, searchData.team, searchData.solo_any]
      }
    case actionTypes.GET_GAME_SETUP_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case actionTypes.SET_VERSUS:
      return {
        ...state,
        selectedVersus: action.payload.versus
      }
    case actionTypes.RESET_SETUP:
      return {
        ...state,      
        selectedRules: [],
        selectedGameType: null,
        selectedGameMode: null,
        selectedPlayerNumber: '',
        selectedVersus: null,
        playersNumbers: [2,3,4,5,6],
      };
    case actionTypes.UPDATE_LIVE_SREARCHING_PLAYERS_NUMBER: {
      const playersSearching = [...action.payload]
      return {
        ...state,
        playersSearching
      }
    };
    case actionTypes.RESET_RULES:{
      return {
        ...state,
        selectedRules: []
      }
    };
    case actionTypes.TOGGLE_GAME_PENDING: {
      // debugger;
      return {
        ...state,
        gamePending: action.payload.state
      }
    }
    default:
      return state;
  }
}

export default gameSetupReducer;