import api from "../axios/api";
import { BASE_URL, WS_HOST, WS_PORT, FORCE_TLS } from "./api.config";

const AUTH_URL = `${BASE_URL}/broadcasting/auth`;

export default {
  cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
  encrypted: false,
  wsHost: WS_HOST,
  enableStats: false,
  forceTLS: FORCE_TLS,
  enabledTransports: ["ws", "wss"],
  authEndpoint: "",
  wsPort: WS_PORT,
  wssPort: WS_PORT,
  authorizer: (channel, options) => {
    const token = localStorage.getItem("access_token");
    return {
      authorize: (socketId, callback) => {
        fetch(AUTH_URL, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }),
          body: JSON.stringify({
            socket_id: socketId,
            channel_name: channel.name,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`Received ${res.statusCode} from ${AUTH_URL}`);
            }
            return res.json();
          })
          .then((data) => {
            callback(null, data);
          })
          .catch((err) => {
            callback(new Error(`Error calling auth endpoint: ${err}`), {
              auth: "",
            });
          });
      },
    };
  },
};
