import Loader from "../CustomLoader/Loader";
import * as styles from '../../styles/scss/components/SubmitButton/submitButton.module.scss';
import { useEffect } from "react";

export const SubmitButton = (props) => {
    let {
        text,
        loading,
        valid = true,
        handleSubmit,
        loadingComponent = <Loader loaderSize={'size35'} />,
        errorText = '',
        size = ''
    } = props;
    useEffect(() => {
    }, [])
    const handleClick = (e) => {
        handleSubmit(e);
    }

    return (
        <div className={`${styles.submitButtonContainer}`}>
            <button 
                type='button' 
                onClick={(e) => handleClick(e)} 
                disabled={!valid}
                className={`${size == 'medium' ? styles.medium : ''}`}
            >
                {loading 
                ? loadingComponent 
                : <span>{text}</span>}
            </button>
        </div>
    )
}