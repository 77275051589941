import { useState, useEffect, useRef } from 'react';
import { login } from '../../../store/auth/authActions';
import { toggleSidebar } from '../../../store/modals/modalsActions';
import {sendStatsToBackend} from '../../../store/static/staticInfoActions'
import FormInput from '../../FormInput/FormInput';
import *  as styles from '../../../styles/scss/components/NewLogin/NewLogin.module.scss';
import SocialButtons from '../../SocialButtons/SocialButtons';
import Wizard from './wizard';
import useTranslation from 'next-translate/useTranslation';
import { getMobileOS, deviceType } from '../../../helpers/functions';
import { connect, useSelector } from 'react-redux';

const NewLogin = ({onLoginRequest, onToggleSidebar, onSendStatToBackend}) => {
  const [os, setOs] = useState(getMobileOS())
  const [isMobile, setIsmobile] = useState(deviceType() === 'mobile')
  const [password, setPassword] = useState('');
  const [passworInputType, setPassowrdInputType] = useState(true)
  const [showWizard, setShowWizard] = useState(false)
  const formRef = useRef()
  const {isCookiePopupHidden} = useSelector(state => state.toggles)

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.value === 0 || !email.valid || password.length === 0 ) {
      return;
    }
    const credentials = {
      email: email.value,
      password: password
    }
    onLoginRequest(credentials)
    onSendStatToBackend({type: "CLICK_BUTTON_SIGN_IN", data: isCookiePopupHidden || localStorage.getItem('isCookiePopupHidden') ? "COOKIES_ACCEPTED": "COOKIES_NOT_ACCEPTED"})
  }

  const [email, setEmail] = useState({
    value: '',
    valid: false,
    errorText: '',
    regEx: /^[a-zA-Z0-9.@_-]{2,}$/,
    changed: false
  })

  const {t} = useTranslation()

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'WizardSignInEmail':
      case 'email':
      {
        const valid = email.regEx.test(e.target.value.trim());
        if (!valid) {
          setEmail(prevState => {
            return {
              ...prevState,
              value: e.target.value.trim(),
              changed: true,
              valid: false,
              errorText: t('errors:email_or_username.invalid'),
            }
          })
        } else {
          setEmail(prevState => {
            return {
              ...prevState,
              value: e.target.value.trim(),
              changed: true,
              valid: true,
            }
          })
        }
      }; break;
    }
  }

  const openWizardHandler = (e) => {
    e.preventDefault()
    if (deviceType() === 'mobile') {
      setShowWizard(true)
    }
  }

  return (
    <div className = {`${styles.logIn} ${isMobile && os === 'ios' ? styles.fixed_buttons: ''}`}>
      {!showWizard && <>
        <form ref={formRef} onSubmit={handleLogin}>
          <div 
            className = {styles.forms_group}>
            <FormInput
              type = 'text'
              value = {email.value}
              name = 'email'
              change = {handleChange}
              active = {email.value.length > 0}
              labelText = {t('common:inputs.email_or_username')}
              showIndicator = {true}
              id = 'someSignupEmail'
              valid = {email.valid}
              errorText = {email.errorText}
              changed = {email.changed}
              disabled = {deviceType() === 'mobile' && showWizard}
            />
          </div>
          <div className = {styles.forms_group}>
            <FormInput 
              type = {passworInputType ? 'password' : 'text'}
              value = {password}
              name = 'password'
              change = {(e) => setPassword(e.target.value)}
              active = {password.length > 0}
              labelText = {t('common:inputs.password')}
              showIndicator = {false}
              id = 'signinPassword'
              showIcon = {true}
              iconFunction = {() => {setPassowrdInputType(prevState => !prevState)}}
              valid = {true}
              disabled = {deviceType() === 'mobile' && showWizard}
            />
          </div>
          <div className = {styles.forms_group} onClick = {() => {
              onToggleSidebar({
                isOpen: true,
                sidebarType: 'forgotPassword',
                hasTitle: true,
                showSidebarCloseButton: true
              })
            }}>
            <span className = {styles.forgot_text}>
              {t('common:sidebar_body.sign_in.forgot_password')}
            </span>
          </div>
          <button type='submit' style={{display: 'none'}}></button>
        </form>
        <div className = {styles.button_container}>
          <div className={`${styles.forms_group} ${styles.login_button}`}>
            <button
              className = {styles.log_in_button}
              type = 'button'
              onClick = {(e) => {handleLogin(e)}}
              >
              {t('common:buttons.login_button')}
            </button>
          </div>
          <div className={styles.have_an_account}>
            <h3>{t('common:sidebar_body.sign_in.dont_have_account')} <span className = {styles.log_in} onClick = {() => {
                onToggleSidebar({
                  isOpen: true,
                  sidebarType: 'signUp',
                  customTitle: t('common:sidebar_titles.signup_sidebar_header'),
                  hasBackArrow: false,
                  hasTitle: true,
                  showSidebarCloseButton: true
                  })
                }}
              >
                {t('common:buttons.signup_button')}
              </span>
            </h3>
          </div>        
        </div>
      </>}
      {showWizard && 
        <Wizard
          handleShowWizard = {setShowWizard}
          wizardData = {[
            {
              id: 'wizardSignUpEmail',
              valid: email.valid,
              element: (
                  <FormInput
                    type = 'text'
                    value = {email.value}
                    name = 'WizardSignInEmail'
                    change = {handleChange}
                    active = {email.value.length > 0}
                    labelText = {t('common:inputs.email_or_username')}
                    showIndicator = {true}
                    id = 'signupEmail'
                    valid = {email.valid}
                    errorText = {email.errorText}
                    changed = {email.changed}
                    autoFocus = {true}
                  />
              )
            },
            {
              id: 'wizardSigninPassword',
              valid: true,
              element: (
                <>
                  <FormInput 
                    type = {passworInputType ? 'password' : 'text'}
                    value = {password}
                    name = 'password'
                    change = {(e) => setPassword(e.target.value)}
                    active = {password.length > 0}
                    labelText = {t('common:inputs.password')}
                    showIndicator = {false}
                    id = 'signinPassword'
                    showIcon = {true}
                    iconFunction = {() => {setPassowrdInputType(prevState => !prevState)}}
                    valid = {true}
                    autoFocus = {true}
                  />
                  <span className = {`${styles.forgot_text} ${styles.inline_padding}`} onClick = {() => {
                      onToggleSidebar({
                        isOpen: true,
                        sidebarType: 'forgotPassword',
                        hasTitle: true,
                        showSidebarCloseButton: true
                      })
                    }}>
                    {t('common:sidebar_body.sign_in.forgot_password')}
                  </span>
                </>
              )
            }
          ]}
        />
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginRequest: (credentials) => dispatch(login(credentials)),
    onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    onSendStatToBackend: (payload) => dispatch(sendStatsToBackend(payload))
  }
}

export default connect(null,mapDispatchToProps)(NewLogin)
