import api from '../axios/api';

export const getGamePreview = async (gameId, lobbyId = '') => {
  return api.get(`/my-games/${gameId ?? 0}`)
  .then(response => {
    const previewData = {
      gameId: response.data.gameId,
      lobbyId: response.data.lobbyId,
      players: [...response.data.players],
      marbles: [...response.data.marbles],
      boardCards: [...response.data.boardCards]
    }
    // setPreviewData(previewData)
    return previewData
  })
  .catch(e => {
    console.log(e)
    return false
  })
}

export const handleJoinAsAdmin = async (player) => {
  return api.post('/impersonate', {
    userId: player.user.id
  }).then(response => {
    const impersonateToken = localStorage.getItem('access_token')
    localStorage.setItem('impersonate_token', impersonateToken)
    localStorage.setItem('access_token', response.token)
    return response
  }).catch(e => {
    return false
  })
}

export const handleReturnAsAdmin = async () => {
  const access_token = localStorage.getItem('impersonate_token')
  localStorage.setItem('access_token', access_token)
  localStorage.removeItem('impersonate_token')
  return api.get('/user')
  .then(response => {
    return response
  }).catch(e => {
    return e
  })
}