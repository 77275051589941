import { connect, useDispatch, useSelector } from 'react-redux';
import Avatar from '../../Avatar/Avatar';
import * as styles from '../../../styles/scss/components/Profile/Profile.module.scss';
import { toggleModalSize, toggleSidebar } from '../../../store/modals/modalsActions';
import { friendRequest, sendAccept, sendDecline, sendDelete, clearFriendsList } from '../../../store/friends/friendActions';
import { setChosenAvatar } from '../../../store/avatars/avatarsActions';
import { setIsProfileUdated, logout } from '../../../store/auth/authActions';
import EyeSvg from '../../../public/images/common/eye.svg'
import api from '../../../axios/api';
import { resetMm } from '../../../store/matchMaking/matchMakingActions';
import { resetSetup } from '../../../store/gameSetup/gameSetupActions';
import { saveSettings } from '../../../store/settings/settingsActions';
import { useEffect, useState } from 'react';
import { Circle, Text } from '../../SkeletonLoader/SkeletonLoader';
import { useRouter } from 'next/router';
import { createPrivateLobby } from '../../../store/lobby/publicLobby/lobbyActions';
import ChevronDown from '../../../public/images/gamePageButtons/chevron_down.svg';
import useTranslation from 'next-translate/useTranslation';
import {getQueryParams} from '../../../helpers/functions'

const Profile = (props) => {
  const enumFilter = {
    world: 'world',
    country: 'country',
    state: 'state',
    city: 'city',
    age: 'age',
    friends: 'friends',
    dateOfBirth: 'dataOfBirth'
  }

  const enumType = {
    overall: 'overall',
    solo: 'solo',
    team: 'team'
  }

  const router = useRouter();
  const {
    user,
    onToggleSidebar,
    onFriendRequest,
    onSetChosenAvatar,
    onSetIsProfileUpdated,
    authorizedUser,
    onResetMM,
    onResetSetup,
    onLogout,
    stats,
    onSendAccept,
    onSendReject,
    showOnlineStatus,
    onSaveSettings,
    onSendDelete,
    onClearFriendsList
  } = props

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [userDataIsLoading, setUserDataIsLoading] = useState(true)
  const [showUnfriendModal, setShowUnfriendMoal] = useState(false);
  const searchInProcess = useSelector(state => state.matchMaking.searching);
  const [requestGameBtnDisabled, setRequestGameBtnDisabled] = useState(router.pathname === '/lobby/[lobbyId]/game/[gameId]');
  const matchMakingInProgress  = useSelector(state => state.matchMaking.searching)
  const [isClickLock, setIsClickLock] = useState(false)
 
  const handleRedirect = (stat) => {
    if(router.pathname === '/'){
      router.push('/leaderboard', {query: {tab: enumType[stat.type.toLowerCase()], actionUserId: user.id, filter: enumFilter.world}})
    }
  }


  const handleUserRequest = async (isFriend) => {
    if (!isFriend) {
      const credentials = {
        recipient: user.id,
      }
      return await onFriendRequest(credentials)
        .then(response => {
            if (response.message === 'FRIENDSHIP_REQUEST_SENT') {
              setUserDetails(prevState => {
                return {
                  ...prevState,
                  user: {
                    ...prevState.user,
                    friendship: {
                      senderId: response.data.sender.id,
                      recipientId: response.data.recipient.id,
                    },
                    friendshipStatus: response.data.status
                  }
                }
              })
            }
          }
        )
        .catch(e => {console.log(e)})
    } else {
    }
  }

  const [h2h, seth2h] = useState(null)

  const handleAvatarEdit = (avatarId) => {
    if (matchMakingInProgress) return
    if (user.isLoggedInUser) {
      onToggleSidebar({
        isOpen: true,
        sidebarType: 'editAvatar',
        hasTitle: true,
        hasBackArrow: true,
        showSidebarCloseButton: false,
        callBackSidebarFunction: () => {
          onToggleSidebar({
            isOpen: true,
            sidebarType: 'profile',
            hasBackArrow: false,
            showSidebarCloseButton: true,
            hasTitle: true
          })
        }
      })
    }
  }
  const handleFriendDelete = async (friendshipId) => {
    if(showUnfriendModal){
      setShowUnfriendMoal(false)
      const response = await onSendDelete(friendshipId)
      if (response) {
        setUserDetails(prevState => {
          return {
            ...prevState,
            user: {
              ...prevState.user,
              friendshipStatus: null
            }
          }
        })
      }
    }
  }

  const [toggle, setToggle] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [totalStats, setTotalStats] = useState(null)
  
  useEffect(() => {
    if (user?.avatar) {
      // debugger
      onSetChosenAvatar(user.avatar)
    }
  }, [user?.avatar])

  useEffect(() => {
    onSetIsProfileUpdated(false)
    setTotalStats(null) 
    setUserDetails(null)
  }, [])

  const handleLogout = () => {
    onResetMM();
    onResetSetup();
    onLogout();
    localStorage.removeItem('impersonate_token')
    onClearFriendsList();
    onToggleSidebar({
      isOpen: false,
      sidebarType: '',
      hasTitle: true
    })
    router.push('/')
  }

  const totalStatsSetHandler = (stats) => {
    let totalGames = 0;
    let totalWins = 0;
    for (let i = 0; i < stats.length; i++) {
      totalGames += stats[i].totalGames
      totalWins += stats[i].wins
    }
    setTotalStats({
      totalGames: totalGames,
      totalWins: totalWins
    })
  }

  const handleFriendRequestResponse = async (id, uuId, accept) => {
    if (accept) {
      onSendAccept(id, uuId)
      .then(response => {
        setUserDetails(prevState => {
          return {
            ...prevState,
            user: {
              ...prevState.user,
              friendshipStatus: 'accepted'
            }
          }
        })
      }).catch(error => {
        console.log(error)
      })
    } else {
      onSendReject(id, uuId)
      .then(response => {
        setUserDetails(prevState => {
          return {
            ...prevState,
            user: {
              ...prevState.user,
              friendshipStatus: 'declined'
            }
          }
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }

  useEffect(() => {
    const func = async () => {
      if (!user?.isLoggedInUser) {
        setUserDataIsLoading(true)
        await api.get(`users/${user.id}`).then(response => {
          const oponentWins = response.data.opponentStats.wins;
          const userWins = response.data.userStats.wins;
          const h2hData = {
            ...response.data,
            opponentStats: {
              ...response.data.opponentStats,
              hasWon: oponentWins > userWins ? 'more_win' : oponentWins < userWins ? 'less_win' : 'equal'
            },
            userStats: {
              ...response.data.userStats,
              hasWon: userWins > oponentWins ? 'more_win' : userWins < oponentWins ? 'less_win' : 'equal'
            },
          }
          seth2h(h2hData)
          setUserDetails({
            user: {...response.data.user, isLoggedInUser: false},
            stats: [...response.data.gameStats]
          })
          totalStatsSetHandler(response.data.gameStats)
          setUserDataIsLoading(false)
        }).catch(e => {
          console.log(e)
          setUserDataIsLoading(false)
        });
      } else {
        setUserDetails({
          user: {...authorizedUser, isLoggedInUser: true},
          stats: [...stats]
        })
        totalStatsSetHandler(stats)
        setUserDataIsLoading(false)
      }
    }
    func();
  }, [user])

  const handleVisibilityToggle = async () => {
    // debugger;
    await onSaveSettings({
      hideOnlineStatus: showOnlineStatus
    }).then(response => {
    }).catch(e => {
      console.log(e)
    })
  }

  const handleGameRequest = (user) => {
    // debugger;
    setRequestGameBtnDisabled(true);
    if(searchInProcess) {
      dispatch(toggleModalSize(false));
      return;
    }
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    const lobbyData = {
      players: 2,
      members: [user.id],
      type: 'instant',
      date: dateString,
      name: t('common:sidebar_body.profile.request_game_title'),
    }
    api.post('/lobby', lobbyData)
    .then(response => {
      if(response?.data.games[0]) {
        router.push(`/lobby/${response.data.games[0].lobbyId}`)
      }
      return response.data
    })
    .catch(error =>{
      console.log(error);
      // dispatch(createPrivateLobbyFail(error));
      return error
    })
  }
  return (
      <div className = {`${styles.profile} ${(userDetails && !userDetails.user.isLoggedInUser && userDetails.user.friendshipStatus === 'accepted') ? styles.pdBottom : ""}`}>
        <div className = {styles.profile_avatar_holder}>
          {userDataIsLoading ? (
            <Circle size = {'128px'}/>
          ) : (
            <div className = {styles.avatar_inside_container} onClick = {handleAvatarEdit}>
              <Avatar
                size = 'large'
                hasFlag = {true}
                avatarUrls = {userDetails.user.avatar}
                showEditIcon = {userDetails.user.isLoggedInUser}
                country = {userDetails.user.country ? userDetails.user.country.code : '00'}
                editButtonDisabled = {matchMakingInProgress}
              />
            </div>
            )
          }
          {/* <h3 className = {styles.display_name}>
            {userDataIsLoading ? (
              <div className={styles.center_skeleton}>
                <Text width={'70px'} height={'25px'} />
              </div>
            ): 
            (
              <>
                {!user.name && user.isLoggedInUser ? <span onClick = {() => {
                  onToggleSidebar({
                    isOpen: true,
                    sidebarType: 'editProfile',
                    hasTitle: true,
                    hasBackArrow: true,
                    showSidebarCloseButton: false,
                    callBackSidebarFunction: () => {
                      onToggleSidebar({
                        isOpen: true,
                        sidebarType: 'profile',
                        hasBackArrow: false,
                        showSidebarCloseButton: true,
                        hasTitle: true
                      })
                    }
                  })
                }}>Set Display Name</span> : userDetails.user.name}
              </>
            )
            }

          </h3> */}
          {userDataIsLoading ? (
            <div className={styles.center_skeleton} style={{marginTop: '10px'}}>
              <Text width={'70px'} height={'25px'} />
            </div>
          ) : (
            <h3 className={styles.userName}>
              {userDetails.user.username}
            </h3>
          )}
          <div className = {styles.online_status_container}>
            {userDataIsLoading ? (
              <div className={styles.center_skeleton}>
                <Text width={'70px'} height={'25px'} />
              </div>
            ): (
              <div className = {`${styles.online_status}`}>
                {authorizedUser.id !== userDetails.user.id && (<span className = {`${styles.status_text} ${userDetails.user.online && styles.online}`}>{userDetails.user.online ? t('common:online') : t('common:offline')}</span>)}
                {authorizedUser.id === userDetails.user.id && (<span className = {`${styles.status_text} ${showOnlineStatus && styles.online}`}>{showOnlineStatus ? t('common:online') : t('common:offline')}</span>)}
                {authorizedUser.id === userDetails.user.id && (
                  <div className = {styles.online_status_toggle} onClick = {() => {handleVisibilityToggle()}}>
                    <div className = {styles.icon_container}>
                      <EyeSvg />
                    </div>
                    <div className = {`${styles.line} ${showOnlineStatus && styles.active}`}></div>
                  </div>            
                )}
              </div>              
            )}
          </div>

          {userDataIsLoading ? (
            <div className={styles.center_skeleton} style={{marginTop: '10px'}}>
              <Text width={'70px'} height={'25px'} />
            </div>          
          ) : (
            <>
              {userDetails.user.isLoggedInUser && (
                <div className = {styles.edit_profile_button_container}>
                  <button
                    disabled = {matchMakingInProgress}
                    onClick={() => {
                      if (matchMakingInProgress) return
                      onToggleSidebar({
                        isOpen: true,
                        sidebarType: 'editProfile',
                        hasTitle: true,
                        hasBackArrow: true,
                        showSidebarCloseButton: false,
                        callBackSidebarFunction: () => {
                          onToggleSidebar({
                            isOpen: true,
                            sidebarType: 'profile',
                            hasBackArrow: false,
                            showSidebarCloseButton: true,
                            hasTitle: true
                          })
                        }
                      })
                    }}
                  >
                    {t('common:buttons.edit_profile')}
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* <div className = {styles.profile_team_info}>
          <span className = {styles.profile_team_text}>
            Team
          </span>
          <div className = {styles.team_info}>
            <span className = {styles.team_name}>Killers</span>
            <div className={styles.team_image_holder}>
              <img className = {styles.team_image} src='/images/teamIcon.png'/>
            </div>
          </div>
        </div> */}
        {/* <div className = {styles.user_level}>
          <div className={styles.user_level_texts}>
            <span className={styles.user_level_standard_text}>Current level</span>
            <span className={styles.user_level_current_level}>Master</span>
          </div>
          <div className={styles.progress_bar}>
            <div className={styles.progress_main}>
              <div className={styles.progress_level}>
                <style jsx>{`
                  .${styles.progress_level}{
                    ${
                      `
                        width: ${90}%;
                      `
                    }
                  }
                `}</style>
              </div>
            </div>
          </div>
          <div className={styles.level_list}>
            <ul className={styles.levels_ul}>
              {levels.map((level, index) => {
                return (
                  <li className={styles.level_li} key = {`level${index}`}>
                    {
                      index === 0 && (
                      <div className={`${styles.single_level} ${level.achieved ? styles.achieved : ''}`}>
                        N
                      </div>
                      )
                    }
                    {
                      index >= 1 && index < levels.length - 1 && (
                      <div className={`${styles.single_level} ${level.achieved ? styles.achieved : ''}`}>
                        {[...Array(level.level)].map((el, index) => {
                          return (
                            <div className={styles.level_image} key = {`item${index}`}>
                              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.04894 0.927049C5.3483 0.00573826 6.6517 0.00573993 6.95106 0.927051L7.5716 2.83688C7.70547 3.2489 8.08943 3.52786 8.52265 3.52786H10.5308C11.4995 3.52786 11.9023 4.76748 11.1186 5.33688L9.49395 6.51722C9.14347 6.77187 8.99681 7.22323 9.13068 7.63525L9.75122 9.54508C10.0506 10.4664 8.9961 11.2325 8.21238 10.6631L6.58778 9.48278C6.2373 9.22813 5.7627 9.22814 5.41221 9.48278L3.78761 10.6631C3.0039 11.2325 1.94942 10.4664 2.24878 9.54508L2.86932 7.63526C3.00319 7.22323 2.85653 6.77186 2.50604 6.51722L0.881445 5.33688C0.0977311 4.76748 0.500508 3.52786 1.46923 3.52786H3.47735C3.91057 3.52786 4.29453 3.2489 4.4284 2.83688L5.04894 0.927049Z" fill="white"/>
                              </svg>
                            </div>
                          )
                        })}
                      </div>
                      )
                    }
                    {
                      index === levels.length - 1 && (
                        <div className={`${styles.single_level} ${level.achieved ? styles.achieved : ''}`}>
                          <div className={styles.level_image}>
                            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.3705 3.14389C15.3729 3.18478 15.3697 3.22639 15.3586 3.26772L14.3717 6.93439C14.3219 7.11911 14.1437 7.249 13.9388 7.25L8.01729 7.27778C8.01651 7.27778 8.01579 7.27778 8.01501 7.27778H2.09346C1.88746 7.27778 1.70796 7.14744 1.6582 6.96178L0.671282 3.28122C0.659918 3.23878 0.656628 3.196 0.659499 3.15406C0.277712 3.04233 0 2.7105 0 2.31944C0 1.83694 0.422579 1.44444 0.942056 1.44444C1.46153 1.44444 1.88411 1.83694 1.88411 2.31944C1.88411 2.59117 1.75007 2.83428 1.54019 2.99489L2.77581 4.15122C3.08809 4.4435 3.52144 4.61106 3.96477 4.61106C4.48897 4.61106 4.98877 4.37983 5.30351 3.99244L7.33416 1.49333C7.16357 1.335 7.05794 1.11622 7.05794 0.875C7.05794 0.3925 7.48052 0 8 0C8.51948 0 8.94206 0.3925 8.94206 0.875C8.94206 1.109 8.84205 1.32139 8.68037 1.47856C8.68091 1.47922 8.68157 1.47978 8.68211 1.4805L10.698 3.9865C11.0127 4.37761 11.5143 4.61111 12.04 4.61111C12.4874 4.61111 12.9081 4.44928 13.2244 4.15539L14.4678 3.0005C14.2535 2.83994 14.1159 2.5945 14.1159 2.31944C14.1159 1.83694 14.5385 1.44444 15.0579 1.44444C15.5774 1.44444 16 1.83694 16 2.31944C16 2.70006 15.7365 3.02378 15.3705 3.14389ZM14.2754 8.58333C14.2754 8.35322 14.0746 8.16667 13.8268 8.16667H2.22307C1.97533 8.16667 1.77447 8.35322 1.77447 8.58333V9.58333C1.77447 9.81344 1.97533 10 2.22307 10H13.8268C14.0746 10 14.2754 9.81344 14.2754 9.58333V8.58333Z" fill="#25282B" fillOpacity="0.64"/>
                            </svg>
                          </div>
                        </div>
                      )
                    }
                  </li>
                )
              })}
            </ul>
          </div>
        </div> */}
        <div className={styles.stats_container}>
          <div className={styles.stats_content}>
            {userDataIsLoading ? (
              <div className={styles.center_skeleton} style={{marginTop: '10px'}}>
                <Text width={'100%'} height={'200px'} />
              </div>
            ) : (
            <>
              {!userDetails.user.isLoggedInUser && !!h2h && authorizedUser.id !== userDetails.user.id &&
                <div className = {styles.head_to_head}>
                  <div className = {`${styles.left_side} ${styles.sides}`}>
                    <div className = {styles.top_side}>
                      <Avatar
                        size = 'rematch-size'
                        hasFlag = {true}
                        avatarUrls = {userDetails.user.avatar}
                        country = {userDetails.user.country ? userDetails.user.country.code : '00'}
                      />
                      <h2>{userDetails.user.username}</h2>
                    </div>
                    <div className = {`${styles.bottom_side} ${styles[h2h.opponentStats.hasWon]}`}>
                      {h2h.opponentStats.wins}
                    </div>
                  </div>
                  <div className = {`${styles.middle_side} ${styles.sides}`}>
                    <div className = {styles.top_side}>
                      <h2 className = {styles.versus_text}>{t('common:sidebar_body.profile.vs')}</h2>
                    </div>
                    <div className = {styles.bottom_side}>
                      {t('common:sidebar_body.profile.wins')}
                    </div>
                  </div>
                  <div className = {`${styles.right_side} ${styles.sides}`}>
                    <div className = {styles.top_side}>
                      <Avatar
                        size = 'rematch-size'
                        hasFlag = {true}
                        avatarUrls = {authorizedUser.avatar}
                        country = {authorizedUser.country?.code || '00'}
                      />
                      <h2>{t('common:sidebar_body.profile.you')}</h2>
                    </div>
                    <div className = {`${styles.bottom_side} ${styles[h2h.userStats.hasWon]}`}>
                      {h2h.userStats.wins}
                    </div>
                  </div>
                </div>
              }
            </>
            )}
            <div className = {styles.user_statistics}>
              {userDataIsLoading ? (
                <div className={styles.center_skeleton} style={{marginTop: '10px', width: '100%'}}>
                  <Text width={'100%'} height={'50px'} />
                </div>
            ) : (
              <>
                {!!totalStats && 
                  <>
                    <h2 className = {styles.statistics_title}>{t('common:sidebar_body.profile.online_stats')}</h2>       
                    <div className = {`${styles.played_game} ${styles.statistic_box}`}>
                      <span className = {styles.statistic_number}>
                        {totalStats.totalGames}
                      </span>
                      <span className = {styles.statistic_text}>
                      {t('common:sidebar_body.profile.played')}
                      </span>
                    </div>
                    <div className = {`${styles.wins} ${styles.statistic_box}`}>
                      <span className = {styles.statistic_number}>
                        {totalStats.totalWins}
                      </span>
                      <span className = {styles.statistic_text}>
                      {t('common:sidebar_body.profile.win')}
                      </span>
                    </div>
                  </>
                }
              </>
              )}
              {userDataIsLoading ? (
                <div className={styles.center_skeleton} style={{marginTop: '10px', width: '100%'}}>
                  <Text width={'100%'} height={'200px'} />
                </div>
              ) : (
                <>
                  {!!userDetails && (
                    <div className = {styles.stats_table_container}>
                      <table className = {styles.stats_table}>
                        <thead>
                          <tr>
                            <th className = {styles.game_type}>{t('common:sidebar_body.profile.game_type')}</th>
                            <th className = {styles.Played}>{t('common:sidebar_body.profile.played')}</th>
                            <th className = {styles.Won}>{t('common:sidebar_body.profile.won')}</th>
                            <th className = {styles.Ranking}>{t('common:sidebar_body.profile.ranking')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userDetails.stats.map(stat => {
                            return (
                              <tr key = {`stat${stat.type}`} onClick={()=>handleRedirect(stat)}>
                                <td className = {styles.game_type}><span className={styles.type_text}>{t(`common:sidebar_body.lobby_sidebar.mode.${stat.type.toLowerCase()}`)}</span></td>
                                <td className = {styles.Played}>{stat.totalGames}</td>
                                <td className = {styles.Won}>{stat.wins}</td>
                                <td className = {styles.Ranking}>{stat.rating}</td>
                              </tr>  
                            )
                          })}
                          {/* <tr>
                            <td className = {styles.game_type}><span className={styles.type_text}> Team Tactics Game</span></td>
                            <td className = {styles.Played}>24</td>
                            <td className = {styles.Won}>22</td>
                            <td className = {styles.Ranking}>560</td>
                          </tr>
                          <tr>
                            <td className = {styles.game_type}><span className={styles.type_text}> Hero Game</span></td>
                            <td className = {styles.Played}>8</td>
                            <td className = {styles.Won}>8</td>
                            <td className = {styles.Ranking}>426</td>
                          </tr>
                          <tr>
                            <td className = {styles.game_type}><span className={styles.type_text}> Online Game</span></td>
                            <td className = {styles.Played}>20</td>
                            <td className = {styles.Won}>14</td>
                            <td className = {styles.Ranking}>354</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  )}

                </>
              )}
              {/* <div className = {`${styles.losses} ${styles.statistic_box}`}>
                <span className = {styles.statistic_number}>
                  48
                </span>
                <span className = {styles.statistic_text}>
                  Losses
                </span>
              </div> */}
            </div>
          </div>
        </div>
        {userDataIsLoading ? (
          <div className = {styles.button_container}>
            <div className={styles.center_skeleton} style={{marginTop: '10px'}}>
              <Text width={'100%'} height={'50px'} />
            </div>
          </div>
        ) : (
          <>
            {userDetails.user.isLoggedInUser && (
              <div 
              className = {styles.button_container}
              >
                <button
                  className={styles.logout_button}
                  onClick={handleLogout}
                  >
                  {t('common:buttons.logout_button')}
                </button>
              </div>
            )}
            {!userDetails.user.isLoggedInUser && (!userDetails.user.friendshipStatus || userDetails.user.friendshipStatus === 'declined') && authorizedUser.id !== userDetails.user.id && (
              <div className = {styles.button_container}>
                {
                  userDetails.user.friendshipStatus === 'declined' && userDetails.user.friendship.senderId === authorizedUser.id 
                  ? <button
                      type = 'button'
                      onClick = {() => {}}
                      className = {styles.user_actions}
                      disabled
                      >
                      {t('common:buttons.friendship_rejected')}
                    </button>
                  : <button
                      type = 'button'
                      onClick = {() => {handleUserRequest(userDetails.user.isFriend)}}
                      className = {styles.user_actions}
                      >
                      {/* {'add friend'} */}
                      {t('common:buttons.add_friend')}
                    </button>
                }
              </div>
            )}
            {!userDetails.user.isLoggedInUser && userDetails.user.friendshipStatus === 'pending' && authorizedUser.id !== userDetails.user.id && authorizedUser.id !== userDetails.user.friendship.recipientId && (
              <div className = {styles.button_container}>
                <button
                  type = 'button'
                  // onClick = {() => {handleUserRequest(userDetails.user.isFriend)}}
                  className = {`${styles.user_actions}`}
                  disabled
                  >
                  {t('common:buttons.pending')}
                </button>
              </div>
            )}
            {!userDetails.user.isLoggedInUser && userDetails.user.friendshipStatus === 'pending'&& authorizedUser.id !== userDetails.user.id && authorizedUser.id === userDetails.user.friendship.recipientId && (
              <div className = {styles.friendship_button_container}>
                  <button
                    type = 'button'
                    onClick = {() => {handleFriendRequestResponse(userDetails.user.friendship.id, null, false)}}
                    className = {`${styles.user_actions} ${styles.reject}`}
                    >
                    {t('common:buttons.reject')}
                  </button>
                  <button
                    type = 'button'
                    onClick = {() => {handleFriendRequestResponse(userDetails.user.friendship.id, null, true)}}
                    className = {`${styles.user_actions} ${styles.accept}`}
                    >
                    {t('common:buttons.accept')}
                  </button>
              </div>
            )}
            {!userDetails.user.isLoggedInUser && userDetails.user.friendshipStatus === 'accepted' && authorizedUser.id !== userDetails.user.id && (
              <div className = {`${styles.button_container}`}>
                
                <button
                  type = 'button'
                  onClick = {() => {handleGameRequest(userDetails.user)}}
                  className =  {`${styles.user_actions}  ${styles.spaceBottom} ${showUnfriendModal && styles.hide}`}
                  disabled={requestGameBtnDisabled}
                >
                  {t('common:buttons.request_game')}
                </button>
                
                <div 
                  className = {`${styles.unfriend}`} 
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowUnfriendMoal(!showUnfriendModal)
                  }}>
                  <p>
                    {t('common:buttons.friends')}
                  </p>
                  <span className={styles.chevronCont}>
                    <ChevronDown className={showUnfriendModal ? styles.rotate : ""}/>
                  </span>
                  {
                    showUnfriendModal && <div 
                      onClick = {(e) => {
                        // e.stopPropagation();
                        handleFriendDelete(userDetails.user.friendship.id)
                      }} 
                      className={styles.unfriendPopup}
                    >
                      {t('common:buttons.unfriend')}
                    </div>
                  }
                </div>
                
                {/* <button
                  type = 'button'
                  onClick = {() => {handleFriendDelete(userDetails.user.friendship.id)}}
                  className = {`${styles.unfriend} ${styles.user_actions}`}
                  >
                  unfriend
                </button> */}
              </div>
            )}
          </>
        )}
      </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.modals.userToShow,
    authorizedUser: state.auth.user,
    stats: state.auth.stats,
    showOnlineStatus: state.settings.showOnlineStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleSidebar: (payload) => dispatch(toggleSidebar(payload)),
    onFriendRequest: (payload) => dispatch(friendRequest(payload)),
    onSetChosenAvatar: (avatar) => dispatch(setChosenAvatar(avatar)),
    onSetIsProfileUpdated: (state) => dispatch(setIsProfileUdated(state)),
    onResetMM: () => dispatch(resetMm()),
    onResetSetup: () => dispatch(resetSetup()),
    onLogout: () => dispatch(logout()),
    onSendAccept: (friendshipId, notificationUuid) => dispatch(sendAccept(friendshipId, notificationUuid)),
    onSendReject: (friendshipId, notificationUuid) => dispatch(sendDecline(friendshipId, notificationUuid)),
    onSendDelete: (friendshipId) => dispatch(sendDelete(friendshipId)),
    onSaveSettings: (payload) => dispatch(saveSettings(payload)),
    onClearFriendsList: (payload) => dispatch(clearFriendsList(payload))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile)