import * as styles from '../../../styles/scss/components/BranediShop/Branedishop.module.scss'
const BranediShop = () => {
  return (
    <div className= {styles.braendi_shop_container}>
      <div className = {styles.branedi_shop_list}>
        <div className = {`${styles.branedi_button} ${styles.for_swiss}`}>
          <img src='/images/shopImages/swiss.png'/>
          <a href='https://www.braendi-shop.ch/de/searchresult?q=dog' target="_blank"></a>
        </div>
        <div className = {`${styles.branedi_button} ${styles.rest}`}>
          <img src='/images/shopImages/rest.png'/>
          <a href='https://www.braendi-dog.de/?ref=dog-online' target="_blank"></a>
        </div>
      </div>
    </div>
  )
}

export default BranediShop