import useTranslation from 'next-translate/useTranslation'
import * as styles from '../../../../styles/scss/components/CardsAndValues/CardsAndValues.module.scss'

const CardsAndValues = () => {
  const {t} = useTranslation();

  const cards = [
    {
      cardName: t('game_rules:cards_and_values.jocker_label'),
      description: t('game_rules:cards_and_values.jocker_desc'),
      image: '/images/cards/classic/JK.png'
    },
    {
      cardName: t('game_rules:cards_and_values.ace_label'),
      description: t('game_rules:cards_and_values.ace_desc'),
      image: '/images/cards/classic/AH.png'
    },
    {
      cardName: t('game_rules:cards_and_values.king_label'),
      description: t('game_rules:cards_and_values.king_desc'),
      image: '/images/cards/classic/KH.png'
    },
    {
      cardName: t('game_rules:cards_and_values.queen_label'),
      description: t('game_rules:cards_and_values.queen_desc'),
      image: '/images/cards/classic/QH.png'
    },
    {
      cardName: t('game_rules:cards_and_values.number_cards_label'),
      description: t('game_rules:cards_and_values.number_cards_desc'),
      image: '/images/cards/classic/10H.png'
    },
    {
      cardName: t('game_rules:cards_and_values.card_four_label'),
      description: t('game_rules:cards_and_values.card_four_desc'),
      image: '/images/cards/classic/4H.png'
    },
    {
      cardName: t('game_rules:cards_and_values.card_two_label'),
      description: t('game_rules:cards_and_values.card_two_desc'),
      image: '/images/cards/classic/2H.png'
    },
    {
      cardName: t('game_rules:cards_and_values.card_jack_label'),
      description: t('game_rules:cards_and_values.card_jack_desc'),
      image: '/images/cards/classic/JH.png'
    },
    {
      cardName: t('game_rules:cards_and_values.card_seven_label'),
      description: t('game_rules:cards_and_values.card_seven_desc'),
      image: '/images/cards/classic/7H.png'
    }
  ]

  return (
    <div className = {styles.cardsAndValues_container}>
      <p className = {styles.module_description}>
        {t('game_rules:cards_and_values.first_paragraph')}
      </p>
        <ul className={styles.list_container} style={{marginTop: '30px'}}>
          {cards.map(card => {
            return (
              <li key = {`moduelCard${card.cardName}`} className={styles.card_container}>
                <div className={styles.card}>
                  <img className={styles.image} src = {card.image}/>
                  <p className={styles.description}>{card.description}</p>
                </div>
              </li>
            )
          })}
        </ul>
    </div>
  )
}

export default CardsAndValues;