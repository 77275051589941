import * as styles from '../../styles/scss/components/OfflineModal/OfflineModal.module.scss';
import Avatar from '../Avatar/Avatar';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { deviceType } from '../../helpers/functions';
const OfflineModal = (props) => {
  const {
    offlineStatus,
    players,
    newGameSearchHandler,
    currentPlayerId,
    gameStarted,
    currentPlayerTeamId
  } = props
  const {t} = useTranslation()
  const router = useRouter()
  const [isCurrentPlayerLeaver, setIsCurrentPlayerLeaver] = useState(players.filter(player => player.id === currentPlayerId || player._id === currentPlayerId)?.length > 0)
  const [isMobile, setIsMobile] = useState(deviceType() === 'mobile')
  //online statuses
  //'offlineStatus' 'player:disconnected' 'player:left' 'player:afk'
  const isALoser = () => {
    let index;
    for(index = 0; index< players.length;index++){
      if(players[index].team.id === currentPlayerTeamId || isCurrentPlayerLeaver)
        return true
    } return false
  }

  const getTitle = () => {
    if (!gameStarted) return ''
    if (isALoser()) {
      return t('common:modals.player_offline.you_lost')
    } else {
      return t('common:modals.player_offline.you_won')
    }
  }

  return (
    <div className = {`${styles.wizard_container} ${isMobile ? styles.mobile : ''}`}>
      <div className = {styles.upper_part}>
        <div className={styles.modal_image}>
          <img src='/images/player-left.jpg' />
        </div>
        <div className = {styles.wizard_head}>
          <div className = {styles.wizard_details_container}>
            <div className = {styles.wizard_title_container}>
              <h2 className = {`${styles.wizard_title} ${isALoser() ? styles.leaver : styles.winner }`}>{getTitle()}</h2>
            </div>
          </div>
        </div>
        <div className = {styles.dynamic_part}>
          <div className = {styles.dynamic_body}>
            <div className={styles.upper_part_text}> 
              <div className={styles.player_avatars}>
                {players.map(player => {
                  const {user} = player
                  return (
                    <div 
                      key={`leaver_avatar${user.id}`} 
                      className={styles.avatar_container}
                      >
                      <Avatar 
                        color = {player.color}
                        hasFlag = {false}
                        avatarUrls = {user.avatar}
                      />  
                    </div>
                  )
                })}
              </div>
              <div className={styles.modal_text}>
                {players.map(player => {
                  const {user} = player
                  return (
                    <span  key = {`leaver_${user.id}`}className={styles.user_name}>{user.username}{', '}</span>
                  )
                })}
                <span>
                  {gameStarted ? 
                    t('common:modals.player_offline.player_disconnect_text')
                    :
                    t('common:modals.player_offline.game_not_started')
                  }
                </span>
              </div>
            </div>
            {/* <div className={styles.bottom_part_text}>
              {t('common:modals.player_offline.player_disconnect_modal_desc')}
            </div> */}
          </div>
        </div>
      </div>
      <div className = {`${styles.bottom_part}`}>
        <button className={styles.new_game_button}
          onClick = {() => {newGameSearchHandler()}}
          >
          {t('common:buttons.search_new_game')}
        </button>
        <button className={styles.go_to_dashboard} 
          onClick = {() => {
            window.location.href = `/${router.locale}`
          }}>
          {t('common:buttons.go_to_dashboard')}
        </button>
      </div>
    </div>
  )
}

export default OfflineModal