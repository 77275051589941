import Avatar from "../../Avatar/Avatar"
import * as styles from '../../../styles/scss/components/Notifications/NotificationTypes.module.scss'
import api from '../../../axios/api';
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { makeActionSuccess } from "../../../store/notifications/notificationActions";
import useTranslation from "next-translate/useTranslation";
import { toggleModalSize } from "../../../store/modals/modalsActions";
import { useState } from "react";
const Notification = (props) => {
  const {data, handleUserProfileOpen} = props
  const [clickDisabled, setClickDisabled] = useState(false);
  const router = useRouter()
  const dispatch = useDispatch();
  const modalIsMinimized = useSelector(state => state.modals.isSearchModalMinimized)
  const matchMakingInProgress = useSelector(state => state.matchMaking.searching)
  const {t} = useTranslation()
  const getPropperDate = (date) => {
    const newDate = new Date(date)
    let month = ''
    switch (newDate.getMonth()) {
      case 0:  month = t('common:months.January'); break;
      case 1:  month = t('common:months.February'); break;
      case 2:  month = t('common:months.March'); break;
      case 3:  month = t('common:months.April'); break;
      case 4:  month = t('common:months.May'); break;
      case 5:  month = t('common:months.June'); break;
      case 6:  month = t('common:months.July'); break;
      case 7:  month = t('common:months.August'); break;
      case 8:  month = t('common:months.September'); break;
      case 9:  month = t('common:months.October'); break;
      case 10:  month = t('common:months.November'); break;
      case 11:  month = t('common:months.December'); break;
    }
    return `${newDate.getDate()} ${month.substring(0, 3)}`
  }

  const getGameRequestNotificationButtonText = () => {
    switch(data.message) {
      case 'REDIRECT_TO_LOBBY': return t('common:notifications.go_to_lobby');
      case 'REDIRECT_TO_GAME': return t('common:notifications.join_game');
    }
  }

  // const handleGameRequestNotificationAction = (response) => {
  //   switch (response.message) {
  //     case 'REDIRECT_TO_LOBBY' : {
  //       router.push(response.data.url)
  //     }; return;
  //     case 'REDIRECT_TO_GAME' : {
  //       router
  //     }
  //   }
  // }

  const GameRequestNotificationActionHandler = () => {
    if(clickDisabled) {
      return;
    }
    setClickDisabled(true);
    if (modalIsMinimized || matchMakingInProgress) {
      dispatch(toggleModalSize(false))
      return
    }
    api.post(`/notifications/${data.id}`)
    .then(response => {
      if (response.message === "NO_ACTION") {
        setClickDisabled(false);
        dispatch(makeActionSuccess({...data, message: 'NO_ACTION'}))
        data.accept({
          message: "NO_ACTION",
          notificationId: data.id
        })
        return
      }
      data.accept({
        notificationId: data.id,
        message: response.message
      })
      // router.push(response.data.url, { shallow: true })
      window.location.href ='/' + router.locale + response.data.url
    }).catch(e => {
      console.log(e)
    })
  }
  
  return (
    <div 
      className = {`${styles.notification_container} ${!data.readAt ? styles.notSeen : ''}`}>
      <div className = {styles.notification}>
        <div className = {styles.right_side}>

        <div style={{display: 'flex', alignItems:'center'}}>
        <div className = {styles.left_side}>
          <div className = {styles.notification_avatar_container} onClick = {() => {
              if (!data.user || data.type === 'MatchReady') {
                return
              }
              handleUserProfileOpen({
                ...data.user,
                isFriend: false,
                friendshipRequested: true,
              })
            }}>
            <Avatar
              size = 'small'
              hasFlag = {false}
              avatarUrls = {data?.user?.avatar}
            />
          </div>
        </div>

          <div className = {styles.top_side}>
            <div className = {styles.notification_texts}>
              <h1 className = {styles.title}>{data.title_translation_needed ? t(`common:notifications.${data.title}`) : data.title}</h1>
              <p className = {styles.text}>{data.connected_translation ? t(`common:notifications.${data.text_1}`) + ' ' + data.text_2 : t(`common:notifications.${data.text}`)}</p>
            </div>
            <div className = {styles.notification_status}>
              {!data.readAt && <span className = {styles.new}>{t('common:new')}</span>}
              <span className = {styles.date}>{getPropperDate(data.createdAt)}</span>
            </div>
          </div>
        </div>




          <div className = {styles.bottom_side}>
            {data.type === 'FriendshipRequested' && (
              <>
                {
                  data.friendship.status === 'pending' && 
                  <>
                    <button onClick = {data.decline} className = {styles.decline}>{t('common:buttons.decline')}</button>
                    <button onClick = {data.accept} className = {styles.accept}>{t('common:buttons.accept')}</button>
                  </>
                }
                {
                  data.friendship.status === 'accepted' && 
                  <>
                    <p className = {styles.positive}>{t('common:accepted')}</p>
                  </>
                }
                {
                  data.friendship.status === 'declined' && 
                  <>
                    <p className = {styles.negative}>{t('common:declined')}</p>
                  </>
                }
              </>
            )}
            {data.type === 'GameRequested' && data.message !== 'NO_ACTION' && data.message !== 'GAME_FINISHED' && (
              <>
                {/* <button onClick = {data.decline}>Decline</button> */}
                <button disabled={clickDisabled} className = {styles.go_to} onClick = {GameRequestNotificationActionHandler}>
                  {getGameRequestNotificationButtonText()}
                </button>
              </>
            )}
            {data.type === 'MatchReady' && data.message === "REDIRECT_TO_GAME" && data.message !== 'GAME_FINISHED' && (
              <button disabled={clickDisabled} className = {styles.go_to} onClick = {GameRequestNotificationActionHandler}>
                {getGameRequestNotificationButtonText()}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Notification