import { useState } from 'react';
import * as styles from '../../../styles/scss/components/Accessibility/Accessibility.module.scss';
import Trigger from '../../Trigger/Trigger';
import useTranslation from 'next-translate/useTranslation'


const Accessibility = () => {

  const [isTriggerActive, setIsTriggerActive] = useState(false)
  const {t} = useTranslation();
  return (
    <div className = {styles.accessibility}>
      <div className = {styles.accessibility_container}>
        <div className = {styles.single_accessibility}>
          <div className = {styles.accessibility_texts}>
            <span className = {styles.accessibility_title}>{t('common:sidebar_body.accessibility.sub_title')}</span>
            <span className = {styles.accessibility_description}>{t('common:sidebar_body.accessibility.description')}</span>
          </div>
          <div className = {styles.accessibility_actions}>
            <Trigger
              active = {isTriggerActive}
              clickFunction = {() => {setIsTriggerActive(isMusicOn => !isMusicOn)}}
            />
          </div>
        </div>
        <div className = {styles.single_accessibility}>
          <div className = {styles.accessibility_texts}>
            <span className = {styles.accessibility_title}>{t('common:sidebar_body.accessibility.text_reader_title')}</span>
            <span className = {styles.accessibility_description}>
            {t('common:sidebar_body.accessibility.text_reader_desc')}
            </span>
          </div>
          <div className = {styles.accessibility_actions}>
            <Trigger
              active = {isTriggerActive}
              clickFunction = {() => {setIsTriggerActive(isMusicOn => !isMusicOn)}}
            />
          </div>
        </div>
        <div className = {styles.single_accessibility}>
          <div className = {styles.accessibility_texts}>
            <span className = {styles.accessibility_title}>{t('common:sidebar_body.accessibility.text_magnifier_title')}</span>
            <span className = {styles.accessibility_description}>
            {t('common:sidebar_body.accessibility.text_magnifier_desc')}
            </span>
          </div>
          <div className = {styles.accessibility_actions}>
            <Trigger
              active = {isTriggerActive}
              clickFunction = {() => {setIsTriggerActive(isMusicOn => !isMusicOn)}}
            />
          </div>
        </div>
        <div className = {`${styles.single_accessibility} ${styles.accessibility_for_font}`}>
          <div className = {styles.accessibility_texts}>
            <span className = {styles.accessibility_title}>{t('common:sidebar_body.accessibility.font_size_title')}</span>
          </div>
          <div className = {styles.accessibility_actions}>
            <button className = {styles.active}>{t('common:buttons.default')}</button>
            <button>+1</button>
            <button>+2</button>
          </div>
        </div>
        <div className = {styles.single_accessibility}>
          <div className = {styles.accessibility_texts}>
            <span className = {styles.accessibility_title}>{t('common:sidebar_body.accessibility.contrast_title')}</span>
            <span className = {styles.accessibility_description}>{t('common:sidebar_body.accessibility.contrast_desc')}</span>
          </div>
          <div className = {styles.accessibility_actions}>
            <Trigger
              active = {isTriggerActive}
              clickFunction = {() => {setIsTriggerActive(isMusicOn => !isMusicOn)}}
            />
          </div>
        </div>
        <div className = {styles.single_accessibility}>
          <div className = {styles.accessibility_texts}>
            <span className = {styles.accessibility_title}>{t('common:sidebar_body.accessibility.monochrome_title')}</span>
            <span className = {styles.accessibility_description}>
            {t('common:sidebar_body.accessibility.monochrome_desc')}
            </span>
          </div>
          <div className = {styles.accessibility_actions}>
            <Trigger
              active = {isTriggerActive}
              clickFunction = {() => {setIsTriggerActive(isMusicOn => !isMusicOn)}}
            />
          </div>
        </div>
      </div>
      <div className={styles.save_accessibility}>
        <button>{t('common:buttons.save_button')}</button>
      </div>
    </div>
  )
}

export default Accessibility